import '../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Load Actions
import * as AppActions from '../Store/Actions/App';
import * as RegisterActions from '../Store/Actions/Register';

// UpdateService dialog component
const ValidateView = (props) => {

// set vars from props
const {
	alert,
	setAlert,
	setAlertTitle,
	setAlertBody,
	setAlertAction,
	scrollBlock,
	setDialog,
	dialog,
	activateProfileGenerate,
	activateProfileConfirm,
	setActivationStatus
} = props;		

const [email, setEmail] = React.useState('');
const [mobilePhone, setMobilePhone] = React.useState('');
const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
const [savingCode, setSavingCode] = React.useState(false); 
const [selectPhone, setSelectPhone] = React.useState(false);
const [tryPhone, setTryPhone] = React.useState(false);
const [codeGenerated, setCodeGenerated] = React.useState(true);
const [passwordChange, setPasswordChange] = React.useState(false); 
const [code, setCode] = React.useState("");
const [generatingCode, setGeneratingCode] = React.useState(true);

const close = ()=> {
	// setValidateRegistry(false);
	setDialog('validateEmail',false);
	setTimeout(function() {
		if (scrollBlock) {
			document.body.classList.add('p-overflow-hidden');	
		} // end if
	},300);
}

/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}

	
const validate = ()=> {

	/* Check if code if ok */
	if (!code || code.length < 6) {
		setAlert(true);
		setAlertTitle("Verifique o Código");
		setAlertBody("Informe o código de 6 digitos para para prosseguir.");	
		return;		
	} // end if	
	
	setSaving(true);

	var props = {
		setDialog:setDialog,
		setSaving:setSaving,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setActivationStatus:setActivationStatus
	}
	var data = {
		code:code
	}
	activateProfileConfirm(data,props);		
	
}	
	

const sendCode = ()=> {
	
	var props = {
		setDialog:setDialog,
		setSaving:setSaving,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setGeneratingCode:setGeneratingCode
	}
	activateProfileGenerate(props);	
	
}

const accept = () => {
	close();
}

const reject = () => {
	
}

const confirm1 = () => {
	confirmDialog({
		message: 'O seu email é a principal forma de comunicação entre a Memovo e você. Por isso é importante que valide o mesmo. Mesmo que seu cadastro seja aprovado, só será possível acessar a plataforma após validar o email informado.',
		header: 'Tem certeza que quer validar depois?',
		icon: 'pi pi-exclamation-triangle',
		accept,
		reject,
		acceptLabel:"Sim, tenho certeza!",
		rejectLabel:"Fechar",
		contentStyle:{maxWidth:"30vw",width:"30vw"}
	});
};
	
const footerContent = (
	<div className="modalFooterButtonsEnd">
		
			<div className="modalFooterButtonsRight">
				<ConfirmDialog />

				{!generatingCode &&
				<>
				<Button label="Validar depois" disabled={saving} icon="pi pi-times" className="p-button-text" onClick={() => confirm1()} />
				<Button label="Validar" icon="pi pi-check" loading={saving} onClick={() => validate()} />
				</>
				}
			</div>		
		
	</div>
);		

// Execute when route changes
React.useEffect(() => {


	// Only runs when  dialog opens
	if (dialog.target == 'validateEmail' && dialog.status) {

		var props = {
			setDialog:setDialog,
			setSaving:setSaving,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setGeneratingCode:setGeneratingCode
		}
		activateProfileGenerate(props);		
				
	} // end if
	
    return () => {
      	  
		setGeneratingCode(true);
		
    };	

    
  }, [dialog]); // Dependência do useEffect é a localização atual

	
	return (
	<>
			<Dialog header="Valide o email do cadastro" closeOnEscape={false} draggable={false} closable={false} visible={dialog.target == 'validateEmail' && dialog.status} style={{ width: '35vw' }} breakpoints={{ '1400px': '45vw','960px': '50vw', '641px': '100vw' }} onHide={() => close()} footer={footerContent} >
			

			{generatingCode &&
			<Box
				  sx={{
					width: "100%",
				  }}
				  className="terms"
				>
					<div className="wrapper" style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100%",minHeight:"45vh"}}>

					<div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"90%"}}>
						<ProgressSpinner />
						<div className="formTitle" style={{marginTop:"15px",fontSize:"16px"}}>Gerando e enviando código...</div>	
					</div>

					</div>				
			</Box>
			}

			{(codeGenerated && !generatingCode) &&
			<Box
				  sx={{
					width: "100%",
				  }}
				  className="terms"
				>
				
					<div className="termsModalIntro"> Enviamos um código de 6 dígitos para o email informado. Informe o código recebido para validar o seu cadastro e ser avisado quando seu cadastro for aprovado ou reprovado. A validação é necessária para acessar a plataforma. <br /><br /> <small> O código tem a validade de 10 minutos. </small> </div>
				
					<Divider />
					
					<span className="p-float-label" style={{marginTop:"1.975rem"}}>
						<InputText id="code" keyfilter="int" maxLength={6} autoComplete="off" disabled={saving} className="emailInput" value={code} onChange={(e) => setCode(e.target.value)} style={{width:"50%",fontSize:"2rem"}} />
						<label htmlFor="code">Código de 6 dígitos</label>
					</span>	
					
					<Divider />
					
					<div className="termsModalIntro" style={{marginBottom:"10px"}}>Não recebeu o código? Tente reenviar </div>
					<Button label="Reenviar código" style={{marginBottom:"10px"}} loading={savingCode}  disabled={savingCode} outlined onClick={(e) => { sendCode() }} />				

					<div className="termsModalIntro"> <small>Caso não tenha recebido o email de validação e o email está correto, entre em contato para darmos o suporte necessário. </small> </div>
					
			</Box>
			}
						
            </Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions, ...RegisterActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ValidateView);	
