import '../Assets/Styles/general.css';
import React from "react";

import { Route, Routes, Navigate } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

// Components Loading
import { Button } from 'primereact/button'; 
import { OverlayPanel } from 'primereact/overlaypanel';
import { ScrollTop } from 'primereact/scrolltop';
import { Divider } from 'primereact/divider';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

// External Libs loading
import routes from "../routes.js";

// Import template images
import logo from '../Assets/Images/logoHeader.svg'; 

// Load Actions
import * as AppActions from '../Store/Actions/App'

// Load Components
import RegisterView from '../Screens/Home/RegisterModal';

const Mobile = (props) => {
	
	// Set var from props
	const {
		setDialog,
		dialog,
		loginStatus,
		setLoginStatus		
	} = props;	
	
	const op = React.useRef(null);

	const [menuOpened,setMenuOpened] = React.useState(false);
	
	// set Use of history for PUSH 
	var navigate = useNavigate();		

	// Dialog control
	const [alert, setAlert] = React.useState(false); 
	const [alertTitle, setAlertTitle] = React.useState("Informações"); 
	const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico");   
  
  const onHide = () => {
    setMenuOpened(false);
  }  
  
  const onShow = () => {
    setMenuOpened(true);
  }    
   
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
		return (
		  <Route
			exact path={prop.path}
			element={<prop.component />}
			key={key}
		  />
		);
    });
  };  
  
  const handleClickScroll = (target) => {
	  
    const element = document.getElementById(target);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };    
  
	
const logOut = () => {

	// Remove userData 
	localStorage.removeItem("@motuum/userData");	
	setLoginStatus(false);
    navigate("/home");
	
}	

// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (userData) {
		  setLoginStatus(true);
		  return;
	  } // end if
 
  // set scroll to top
  window.scrollTo(0, 1);
    
  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, []); // end useEffect 

	/* Logout prompt START  */
    const accept = (e) => {
	   logOut();
	   op.current.toggle(e);
    };

    const reject = (e) => {
       op.current.toggle(e);
    };
	
    const confirm = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Tem certeza que deseja sair?',
            icon: 'pi pi-exclamation-triangle',
			accept,
            reject
        });
    };
	/* Logout prompt END  */  
 
  return (
	<>
	<div className="wrapper">
      <div className="wrapper__header">
		<div className="wrapper__header_container">
			<div className="flex-5 tAlignL pL10">
				<img src={logo} style={{width:"auto"}} className="noSelect" />
			</div>		
			<div className="flex-1 tAlignR pR10">
			
				<Button icon={!menuOpened ? "pi pi-align-left" : "pi pi-times"} size="large" rounded text aria-label="Menu" onClick={!menuOpened ? (e) => {op.current.toggle(e);e.currentTarget.blur()} : (e) => {op.current.toggle(e);e.currentTarget.blur()} } />
				<OverlayPanel className="overlayPanel" onHide={onHide} onShow={onShow}  ref={op} style={{padding:"0 !important",backgroundColor:"#FFFFFF",minHeight:"150px"}}>
				
				
				{!loginStatus &&
				<>
					<div style={{width:"100%",height:"50px",backgroundColor:"#F8F8F8",marginBottom:"2px",fontSize:"15px",color:"#222222",fontWeight:"600",display:"flex",alignItems:"center",paddingLeft:"20px"}} onClick={(e)=> {handleClickScroll('memovoSection');op.current.toggle(e);e.currentTarget.blur()}} ><span>A Memovo</span></div>
					
					<div style={{width:"100%",height:"50px",backgroundColor:"#F8F8F8",marginBottom:"2px",fontSize:"15px",color:"#222222",fontWeight:"600",display:"flex",alignItems:"center",paddingLeft:"20px"}} onClick={(e)=>{handleClickScroll('contatoSection');op.current.toggle(e);e.currentTarget.blur()}} > Contato </div>						
					<Button label="Login" className="mR10" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{handleClickScroll('loginSection');op.current.toggle(e);e.currentTarget.blur()}}  />
					
					<Button label="Cadastre-se" text style={{height:"42px",width:"100%"}} onClick={(e) => {;op.current.toggle(e);e.currentTarget.blur();setDialog('registerModal',true)}} />
				</>
				}

				{loginStatus &&
				<>		
					<div style={{width:"100%",height:"50px",backgroundColor:"#F8F8F8",marginBottom:"2px",fontSize:"15px",color:"#222222",fontWeight:"600",display:"flex",alignItems:"center",paddingLeft:"20px"}} onClick={(e)=> {handleClickScroll('memovoSection');op.current.toggle(e);e.currentTarget.blur()}} ><span>A Memovo</span></div>
					
					<div style={{width:"100%",height:"50px",backgroundColor:"#F8F8F8",marginBottom:"2px",fontSize:"15px",color:"#222222",fontWeight:"600",display:"flex",alignItems:"center",paddingLeft:"20px"}} onClick={(e)=>{handleClickScroll('contatoSection');op.current.toggle(e);e.currentTarget.blur()}} > Contato </div>						
					   <Divider layout="horizontal" className="flex" align="center">
							<b>Área do cliente</b>
						</Divider>					
					
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Reservas" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{handleClickScroll('loginSection');op.current.toggle(e);e.currentTarget.blur()}}  /></div>					

						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Roteiros" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{handleClickScroll('loginSection');op.current.toggle(e);e.currentTarget.blur()}}  /></div>							
						
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Funcionários" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{handleClickScroll('loginSection');op.current.toggle(e);e.currentTarget.blur()}}  /></div>
						
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Clientes" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{handleClickScroll('loginSection');op.current.toggle(e);e.currentTarget.blur()}}  /></div>
						
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",display:"flex",alignItems:"center"}} > <Button severity="secondary"  text label="Relatórios" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{handleClickScroll('loginSection');op.current.toggle(e);e.currentTarget.blur()}}  /> </div>
						
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Perfil usuário" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{handleClickScroll('loginSection');op.current.toggle(e);e.currentTarget.blur()}}  /></div>						

						<ConfirmPopup />
						<Button label="Sair" className="mR10" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e) => {confirm(e)}} />
				</>				
				}					
					
				</OverlayPanel>					
			</div>				
		</div>
	  </div>	  
      <div className="wrapper__content">        
          <div className="wrapper__content_container mobile bgPurple">
			  <Routes>
				{getRoutes(routes)}	
				<Route
					path="*"
					element={<Navigate to="/home" replace />}
				/>				
			  </Routes>
          </div>  		  
      </div>
	  <div className="wrapper__footer">
		<div className="wrapper__content_container">
			&copy;2023 Todos os direitos reservados a MeMovo Ltda
		</div>
	  </div>
	  <ScrollTop threshold={100} />
    </div>
	<RegisterView
		setDialog={setDialog}
		dialog={dialog}
		setAlertTitle={setAlertTitle}
		setAlertBody={setAlertBody}
		setAlert={setAlert}
	/>
	</>	
  );
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  loginStatus:store.appReducer.loginStatus
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Mobile);	
