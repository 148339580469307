import axios from 'axios';
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';
import moment from "moment";
import jwtDecode from 'jwt-decode';
	
export const setInnerLoading  = (loadingStatus) => { 
	return { 
		type: types.INNER_LOADING,
		payload: loadingStatus 
	}
};

export const setDialog  = (target,status) => { 
	return { 
		type: types.SET_DIALOG,
		payload: {target:target,status:status} 
	}
};

export const setLoginStatus  = (status) => { 
	return { 
		type: types.SET_LOGINSTATUS,
		payload: status
	}
};

export const setActivationStatus  = (status) => { 
	return { 
		type: types.SET_ACTIVATIONSTATUS,
		payload: status
	}
};

export const setCurrentCompanyStatus  = (status) => { 
	return { 
		type: types.SET_CURRENTCOMPANYSTATUS,
		payload: status
	}
};

export const setMasterUser  = (status) => { 
	return { 
		type: types.SET_MASTERUSER,
		payload: status
	}
};

export const setAgencyName  = (value) => { 
	return { 
		type: types.SET_AGENCYNAME,
		payload: value
	}
};


export const setAgencyUser  = (value) => { 
	return { 
		type: types.SET_AGENCYUSER,
		payload: value
	}
};

// convert input to desired output JSON	
function converterChaves(jsonEntrada) {
  const jsonSaida = jsonEntrada.map(obj => {
    return {
      code: obj.id,
      name: obj.nome
    };
  });

  return jsonSaida;
}

export const setRoutePanel  = (status) => { 

	return { 
		type: types.SET_ROUTEPANEL,
		payload: status
	}
};

export const setRouteManage  = (status) => { 

	return { 
		type: types.SET_ROUTEMANAGE,
		payload: status
	}
};

export const setCurrentAgency  = (status) => { 

	return { 
		type: types.SET_CURRENTAGENCY,
		payload: status
	}
};

export const retrieveAppCities  = (id,props) => { 
		
		return dispatch => {
					
					var value = id;
	
					// Connect to retrieve content endpoint
					axios({
						   url    : 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+value+'/municipios',
						   method : 'GET',
						   headers: {
							Accept: 'application/json'
						}
					})				
					
					.then((response) => {

						//console.log("[response]--->"+JSON.stringify(response,null,2));

						// Harded CODE for TERMS...
						props.setDropdownCityItems(converterChaves(response.data));
						props.setCityResult(true);
						props.setCityLoading(false);						
						return;
						
					})
					.catch(error=> {
						console.log("[alertMessage]--->"+JSON.stringify("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.",null,2));
						return;
					});
					
	} // end dispatch

};

export const retrieveAppAddressGooglePlaces  = (data,props) => { 
		
		return dispatch => {
					
					var preparedData = {
						keyword:data.keyword
					}
					// Connect to retrieve content endpoint
					axios({
						   url    : config.API_URL+'/order/transfer/retrieveAddress',
						   method : 'POST',
						   data   : preparedData,						   
						   headers: {
							Accept: 'application/json',
						}
					})				
					
					.then((response) => {

						console.log("[response GOOGLE PLACES DATA]--->"+JSON.stringify(response.data.data,null,2));

						// Harded CODE for TERMS...
						//props.setDropdownCityItems(converterChaves(response.data));
						//props.setAddressResult(true);
						//props.setCityLoading(false);	

						
						var returnArray = []
						var predictions = response.data.data.predictions;
						for (var i = 0; i < predictions.length; i++) {
							
							let obj = {
								index: i,
								description: predictions[i].description,
								place_id: predictions[i].place_id ? predictions[i].place_id : null,
								reference: predictions[i].reference ? predictions[i].reference : null,
								structured_formatting: predictions[i].structured_formatting ? predictions[i].structured_formatting.main_text : null,
								terms: predictions[i].terms,
								types: predictions[i].types
							}
							returnArray.push(obj);							
							
						} // end for						
												
						props.setItems(returnArray);
						return;
						
					})
					.catch(error=> {
						console.log("[alertMessage]--->"+JSON.stringify("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.",null,2));
						return;
					});
					
	} // end dispatch

};

export const retrieveAppAddressesGoogleMatrixDistance  = (data,props) => { 
		
		return dispatch => {
					
					var preparedData = {
						from:data.from,
						to:data.to,
					}
					// Connect to retrieve content endpoint
					axios({
						   url    : config.API_URL+'/order/transfer/retrieveAddressesDistance',
						   method : 'POST',
						   data   : preparedData,						   
						   headers: {
							Accept: 'application/json',
						}
					})				
					
					.then((response) => {

						//console.log("[response GOOGLnE MATRIX DISTANCE DATA]--->"+JSON.stringify(response.data.data,null,2));
						
						var distanceText = response.data.data.rows[0].elements[0].distance.text;
						var distanceValue = response.data.data.rows[0].elements[0].distance.value;
						
						console.log("[response GOOGLE MATRIX DISTANCE DATA Distancia em km]--->"+JSON.stringify(distanceText,null,2));
						
						console.log("[response GOOGLE MATRIX DISTANCE DATA Distancia em metros]--->"+JSON.stringify(distanceValue,null,2));
						
						var distanceLimit = 500000;
						
						if (distanceValue > distanceLimit) {
							
							props.setAlertTitle("Ops! Verifique os endereços!");
							props.setAlertBody("A distância entre os endereços informados é maior que 500km, o que é muito distante para o tipo de serviço. Verifique os endereços e tente novamente ou escolha outro tipo de transporte para longas distâncias como ônibus e trem.");
							props.setAlert(true);						
							return;							
							
						} // end if	
						else {
							props.confirmPrompt();
						} // end else	

						return;
						
					})
					.catch(error=> {
						console.log("[alertMessage]--->"+JSON.stringify("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.",null,2));
						return;
					});
					
	} // end dispatch

};
	
export const retrieveAppFlightsInfo  = (data,props) => { 
		
	return dispatch => { 
				
				var preparedData = {
					keyword:data.keyword,
					airport:data.airport,
					flightCompany: data.flightCompany,
					flightNumber: data.flightNumber,
					flightDirection:data.flightDirection,
					flightDate:data.flightDate
				}

				var flightDateFormatted = moment(data.flightDate).toISOString();

				// set target url
				if (data.flightNumber) {
					var targetUrl = 'https://api-testing.mozio.com/v2/flights/?airline_code='+data.flightCompany+'&airport_code='+data.airport+'&flight_number='+data.flightNumber+'&flight_direction='+data.flightDirection+'&pickup_datetime='+flightDateFormatted;
				} else {
					var targetUrl = 'https://api-testing.mozio.com/v2/flights/?airline_code='+data.flightCompany+'&airport_code='+data.airport+'&flight_direction='+data.flightDirection+'&pickup_datetime='+flightDateFormatted;
				}

				// Connect to retrieve content endpoint
				axios({
					  // url    : 'https://api.mozio.com/v2/flights/?airline_code='+data.keyword+'&airport_code=GIG&flight_direction=arr&pickup_datetime=2024-02-22T11%3A00%3A00-03%3A00',
					   url: targetUrl,  
					   method : 'GET',
					   headers: {
						Accept: 'application/json',
						"API-KEY":"af496b3b08354ab49dba54dc77386225"
					}
				})				
				
				.then((response) => {

					//console.log("[response GOOGLE PLACES DATA]--->"+JSON.stringify(response,null,2));

					// Harded CODE for TERMS...
					//props.setDropdownCityItems(converterChaves(response.data));
					//props.setAddressResult(true);
					//props.setCityLoading(false);	
					
					var returnArray = []
					var predictions = response.data.flights;
					for (var i = 0; i < predictions.length; i++) {
						
						let template = predictions[i].airline.airline_name+"("+predictions[i].airline.airline_code+") "+predictions[i].flight_number+" / "+predictions[i].origin+" → "+predictions[i].destination+" / "+moment(predictions[i].datetime).format("DD MMM")+" / "+predictions[i].time

						let obj = {
							index: i,
							description: template,
							...predictions							
						}
						returnArray.push(obj);							
						
					} // end for	
					
					props.setItems(returnArray);
					return;
					
				})
				.catch(error=> {
					console.log("[alertMessage]--->"+JSON.stringify("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.",null,2));
					return;
				});
				
} // end dispatch

}

export const retrieveAppFlightAirportInfo  = (data,props) => { 
		
	return dispatch => { 
			
		var preparedData = {
			term:data.term
		}
		// Connect to retrieve content endpoint
		axios({
			   url    : config.API_URL+'/order/transfer/retrieveIATAbyName',
			   method : 'POST',
			   data   : preparedData,						   
			   headers: {
				Accept: 'application/json',
			}
		})				
		
		.then((response) => {

			console.log("[response IATA service]--->"+JSON.stringify(response.data,null,2));
			
			// Treat error and empty reesult
			// ...

			var returnArray = []
			var predictions = response.data.data;

			if (predictions.length == 0) {

				props.setAlert(true);
				props.setAlertTitle("Ops! algo deu errado!");
				props.setAlertBody("Não encontramos um aeroporto com os termos informados. Busque pela sigla de 3 caracteres do aeroporto ou pelo nome (Exemplo: GIG ou Galeao). Não utilize acentos.");
				props.setItems([]);
				return;
			} // end if

			for (var i = 0; i < predictions.length; i++) {
				
				let obj = {
					index: i,
					description: "("+predictions[i].code+") "+predictions[i].name,
					code: predictions[i].code,
					name: predictions[i].name,
					country: predictions[i].countryId,
					city: predictions[i].city,
					state: predictions[i].state,
				}
				returnArray.push(obj);							
				
			} // end for						
									
			props.setItems(returnArray);
			return;
			
		})
		.catch(error=> {
			console.log("[alertMessage]--->"+JSON.stringify("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.",null,2));
			return;
		});
				
} // end dispatch

}

export const tryRetrieveAppFlightAirportInfo  = (data,props) => { 
		
	return dispatch => { 
			
		var preparedData = {
			address:data.address
		}

		// Connect to retrieve content endpoint
		axios({
			   url    : config.API_URL+'/order/transfer/retrieveGeoCodeAddress',
			   method : 'POST',
			   data   : preparedData,						   
			   headers: {
				Accept: 'application/json',
			}
		})				
		
		.then((response) => { 

			console.log("[response retrieveAddressGeoCode]--->"+JSON.stringify(response.data.data,null,2));

					var responseData = response.data.data;

					// Can check if its an airport
					// ...

					var latitude = null;
					var longitude = null;

					if (responseData.results.length > 0) {

						// Get first result
						var location = responseData.results[0].geometry.location;
						latitude = location.lat;
						longitude = location.lng;
					
					} else {
						
						props.setLoadingRightPanel(false);
						props.setFlightAirportName(null);
						return;

					}			

					var preparedData = {
						lat:latitude,
						lng:longitude
					}

					// Connect to retrieve content endpoint
					axios({
						url: config.API_URL+'/order/transfer/retrieveIATAcode',
						method : 'POST',
						data   : preparedData,
						headers: {
						Accept: 'application/json'
					}
				})				
				
				.then((responseIATA) => {

					console.log('responseInner >>>> '+JSON.stringify(responseIATA.data.data,null,2));

					var code = responseIATA.data.data.code;
 
					if (code) {
						props.setFlightAirport(code);
						props.setFlightAirportName(code);
					} // end if
					else {
						props.setFlightAirportName(null);
					} // end else

					props.setLoadingRightPanel(false);
					return;
					
				})
				.catch(error=> {
					console.log("[alertMessage]--->"+JSON.stringify("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.",null,2));
					return;
				});
			
			
		})
		.catch(error=> {
			console.log("[alertMessage]--->"+JSON.stringify("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.",null,2));
			return;
		});

				
} // end dispatch


};

export const retrieveAndSetUserInfo  = (data,props) => { 
		
	return dispatch => {
				
		// Retrieve userData and userToken
		var result = localStorage.getItem("@memovo/userData");
		var userData = JSON.parse(result); 
		var userDataInfo = jwtDecode(userData.userToken); 
		var resultCompanyStatus = localStorage.getItem("@memovo/companyStatus");
		var resultActivationStatus = localStorage.getItem("@memovo/activationStatus");	

		// Set Company Short Name
		let tempCompanyName = userDataInfo.AgencyLegalName.split(" ");
		userDataInfo.AgencyShortLegalName = tempCompanyName[0]+" "+tempCompanyName[1];

		// Prepare POST data
		var preparedData = {
			statusToken:resultCompanyStatus,
			activationToken:resultActivationStatus
		}

		// Connect to retrieve content endpoint
		axios({
			   url    : config.API_URL+'/api/v1/user/retrieveData',
			   method : 'POST',				   
			   data   : preparedData,
			   headers: {
				"x-access-token": userData.userToken,
				Accept: 'application/json',
			}
		})	
		.then((response) => {
		
			// Set Global loading off after 2 seconds
			setTimeout(() => {
				props.setGlobalLoading(false);
			}, 2000); 

			// If an error occured. Check retry flag 
			if (response.data.error) {					

				props.setAlertTitle("Ops! Não encontramos o seu usuário com a chave utilizada!");
				props.setAlertBody("Não foi possível encontrar o seu usuário e atualizar os dados de seu perfil de acesso. Faça o login novamente para corrigir.");				
				props.setAlert(true);

				props.logOut(); 				
				
				// Set logoff flag
				// localStorage.setItem("retryUserData",1);
				// localStorage.setItem("retryUserDataReason","Token error");
				// localStorage.setItem("retryUserDataLastUpdate",moment().format("YYYY-MM-DD HH:mm"));
				
				return;						
			} // end if	*/
								
			// Suceed.
			if (response.data.success) {
				
				// If user was not found, delete local info and go to login
				if (response.data.invalidToken ) {

					props.setAlertTitle("Ops! Parece que sua chave de acesso foi alterada!");
					props.setAlertBody("A chave de seu usuário está diferente da plataforma. A os dados do seu usuário ou agência podem ter sido atualizados enquanto estava ausente. Faça o login novamente para atualizar a chave de acesso. [1] ");				
					props.setAlert(true);

					props.logOut(); 					

					return;
				} // end if	
				
				// If token is expired, force login [MIGHT INVOKE THIS ACTION BY WEBSOCKET]
				if (response.data.expiredToken ) {

					props.setAlertTitle("Ops! Parece que há um sua chave de acesso expirou!");
					props.setAlertBody("A chave de acesso do seu usuário está expirada. Faça o login novamente para corrigir. [1] ");				
					props.setAlert(true);

					props.logOut(); 
									
					return;
				} // end if	
				
				// If no changes on sent token and received token 
				if (response.data.noChanges) {
	
					props.setMasterUser(userDataInfo.Master === 0 ? false : true);
					props.setAgencyName(userDataInfo.AgencyShortLegalName);
					props.setAgencyUser(userDataInfo.PersonCompleteName); 	

					// Set CurrentCompanyStatus
					try {
						var companyStatusObjDecoded = jwtDecode(resultCompanyStatus); 
						var currentCompanyStatus = companyStatusObjDecoded.currentStatus;
						if (currentCompanyStatus == null) {
							props.setAlertTitle("Ops! Parece que há um erro nos dados armazenados!");
							props.setAlertBody("Desconectamos o seu usuário da plataforma pois ocorreu uma inconsistência nos dados armazenados no seu dispositivo. Faça o login novamente para corrigir. [1] ");				
							props.setAlert(true);
							props.logOut(); 
							return; 
						}
						props.setCurrentCompanyStatus(currentCompanyStatus);
					} catch(e) {
						console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (4) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
						props.logOut();
						return;
					} // end catch
	
					// Set ActivationStatus
					try {
						var activationStatusObjDecoded = jwtDecode(resultActivationStatus);
						var activationStatus = activationStatusObjDecoded.currentStatus;
						if (activationStatus == null) {
							props.setAlertTitle("Ops! Parece que há um erro nos dados armazenados!");
							props.setAlertBody("Desconectamos o seu usuário da plataforma pois ocorreu uma inconsistência nos dados armazenados no seu dispositivo. Faça o login novamente para corrigir. [2] ");				
							props.setAlert(true);
							props.logOut(); 
							return; 
						}
						props.setActivationStatus(activationStatus);
					} catch(e) {
						console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (5) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
						props.logOut();
						return;
					} // end catch						
					
									
					return;
				} // end if					
				
				props.setMasterUser(userDataInfo.Master === 0 ? false : true);
				props.setAgencyName(userDataInfo.AgencyShortLegalName);
				props.setAgencyUser(userDataInfo.PersonCompleteName); 	

				// Check if status token has updateAt date before received token. 
				if (response.data.changedStatusToken) {
				
					try {
						var companyStatusObjDecoded = jwtDecode(response.data.companyStatusToken); 
						var currentCompanyStatus = companyStatusObjDecoded.currentStatus;
						if (currentCompanyStatus == null) {
							props.setAlertTitle("Ops! Parece que há um erro nos dados armazenados!");
							props.setAlertBody("Desconectamos o seu usuário da plataforma pois ocorreu uma inconsistência nos dados armazenados no seu dispositivo. Faça o login novamente para corrigir. [1] ");				
							props.setAlert(true);
	
							props.logOut(); 
							return; 
						}
						localStorage.setItem("@memovo/companyStatus",response.data.companyStatusToken)
						props.setCurrentCompanyStatus(currentCompanyStatus);
					} catch(e) {
						console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (4) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
						props.logOut();
						return;
					} // end catch		 		
					
				}				
				
				// Check if activation token has updateAt date before received token. 
				if (response.data.changedActivationtoken) {

					try {
						var activationStatusObjDecoded = jwtDecode(response.data.activationStatusToken);
						var activationStatus = activationStatusObjDecoded.currentStatus;
						if (activationStatus == null) {
							props.setAlertTitle("Ops! Parece que há um erro nos dados armazenados!");
							props.setAlertBody("Desconectamos o seu usuário da plataforma pois ocorreu uma inconsistência nos dados armazenados no seu dispositivo. Faça o login novamente para corrigir. [2] ");				
							props.setAlert(true);
	
							props.logOut(); 
							return; 
						}
						localStorage.setItem("@memovo/activationStatus",response.data.activationStatusToken);
						props.setActivationStatus(activationStatus);
					} catch(e) {
						console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (5) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
						props.logOut();
						return;
					} // end catch
					
				}

				
			} // end if			

			return;
			
		})
		.catch(error=> {

			setTimeout(() => {
				props.setGlobalLoading(false);
			}, 2000); 	

				// Check if status token has updateAt date before received token. 
				// ... If yes, update LocalStorage Token and data

				// Set CurrentCompanyStatus
				try {
					var companyStatusObjDecoded = jwtDecode(resultCompanyStatus); 
					var currentCompanyStatus = companyStatusObjDecoded.currentStatus;
					if (currentCompanyStatus == null) {
						props.setAlertTitle("Ops! Parece que há um erro nos dados armazenados!");
						props.setAlertBody("Desconectamos o seu usuário da plataforma pois ocorreu uma inconsistência nos dados armazenados no seu dispositivo. Faça o login novamente para corrigir. [1] ");				
						props.setAlert(true);

						props.logOut(); 
						return; 
					}
					props.setCurrentCompanyStatus(currentCompanyStatus);
				} catch(e) {
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (4) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					props.logOut();
					return;
				} // end catch
				
				// Check if activation token has updateAt date before received token. 
				// ... If yes, update LocalStorage Token and data	

				// Set ActivationStatus
				try {
					var activationStatusObjDecoded = jwtDecode(resultActivationStatus);
					var activationStatus = activationStatusObjDecoded.currentStatus;
					if (activationStatus == null) {
						props.setAlertTitle("Ops! Parece que há um erro nos dados armazenados!");
						props.setAlertBody("Desconectamos o seu usuário da plataforma pois ocorreu uma inconsistência nos dados armazenados no seu dispositivo. Faça o login novamente para corrigir. [2] ");				
						props.setAlert(true);

						props.logOut(); 
						return; 
					}
					props.setActivationStatus(activationStatus);
				} catch(e) {
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (5) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					props.logOut();
					return;
				} // end catch		

				// Set Retry User Data flags
				// localStorage.setItem("retryUserData",1);
				// localStorage.setItem("retryUserDataReason",error.message);
				// localStorage.setItem("retryUserDataLastUpdate",moment().format("YYYY-MM-DD HH:mm"));					
				
				props.setMasterUser(userData.Master);
				props.setAgencyName(userData.AgencyShortLegalName);
				props.setAgencyUser(userData.PersonCompleteName); 	
					

			console.log("[alertMessage]--->"+JSON.stringify("Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.",null,2));
			return;
		});
				
} // end dispatch

};



	



