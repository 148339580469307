import '../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import expiredImage from '../Assets/Images/expiredImage.png';

// Load Actions
import * as AppActions from '../Store/Actions/App';



// UpdateService dialog component
const ExpireView = (props) => {

// set vars from props
const {
	alertExpire,
	setAlertExpire,
	alertTitleExpire,
	alertBodyExpire,
	alertActionExpire,
	scrollBlock
} = props;		

// set Use of history for PUSH 
var navigate = useNavigate();

const close = ()=> {
	setAlertExpire(false);
	
	if (alertActionExpire == "restartTransportSearch") {
		navigate("/order/transport");		
	} // end if	
	else if (alertActionExpire == "restartTransferSearch") {
		navigate("/order/transfer");		
	} // end if
	
	setTimeout(function() {
		if (scrollBlock) {
			document.body.classList.add('p-overflow-hidden');	
		} // end if
	},300);
}
	

const footerContent = (
	<div className="modalFooterButtonsEnd">
		<div>
			<Button label="Fazer nova busca" icon="pi pi-search" className="p-button-text" onClick={() => close()} />
		</div>
	</div>
);		
	
	return (
	<>
		<Dialog visible={alertExpire} style={{ width: '30vw' }} breakpoints={{ '1400px': '40vw','960px': '50vw', '641px': '100vw' }} closeOnEscape={false} draggable={false} closable={false} footer={footerContent} dismissableMask={true} >
		
		<Box
		  sx={{
			width: "100%",
			display: "flex",
			flexDirection: "column",
			gap:"15px",
			justifyContent:"center",
			alignItems:"center"
		  }}
		  className="terms"
		>
			<div><img src={expiredImage} style={{width:"auto",height:"200px"}} className="noSelect" /></div>
			<div style={{marginTop:"10px",marginBottom:"10px",color: "#2E0249", fontFamily: "Titillium Web", fontSize: "28px", fontStyle: "normal", fontWeight: 600, lineHeight: "120%", letterSpacing: "2.8px"}}>{alertTitleExpire}</div>
			<div>{alertBodyExpire}</div>
		</Box>
		</Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ExpireView);	
