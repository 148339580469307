import '../Assets/Styles/general.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate,useLocation,Route,Routes,Navigate} from 'react-router-dom';
import io from 'socket.io-client';

// Components Loading
import { Button } from 'primereact/button'; 
import { ScrollTop } from 'primereact/scrolltop';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { ProgressSpinner } from 'primereact/progressspinner';

import jwtDecode from 'jwt-decode';

// External Libs loading
import routes from "../routes.js";
import * as config from '../Config/api.js';

// Import template images
import logo from '../Assets/Images/logoHeader.svg'; 
import iconProfile from '../Assets/Images/iconProfile.svg'; 
import iconRoute from '../Assets/Images/iconRoute.svg';
import formTopIcons from '../Assets/Images/formTopIcons.svg';  

// Load Actions
import * as AppActions from '../Store/Actions/App'

// Load Components
import RegisterView from '../Screens/Home/RegisterModal';
import SelectAgencyView from '../Components/SelectAgencyModal';
import AlertView from '../Components/AlertModal';
import ValidateView from '../Components/ValidateModal';
import moment from 'moment';
// import RouteManage from '../Screens/Home/RouteManage';

const Desktop = (props) => {

	// Set var from props
	const {
		setDialog,
		dialog,
		loginStatus,
		setLoginStatus,
		routePanel,
		setRoutePanel,
		setRouteManage,
		routeManage,
		currentAgency,
		setCurrentAgency,
		masterUser,
		agencyName,
		agencyUser,
		setMasterUser,
		setAgencyName,	
		setAgencyUser,
		setCurrentCompanyStatus,
		currentCompanyStatus,
		setActivationStatus,
		activationStatus,
		retrieveAndSetUserInfo		
	} = props;
	
	// login status
	const [menuOpened,setMenuOpened] = React.useState(false);
	const [promptStatus,setPromptStatus] = React.useState(false);
	
// Alert control
const [validateRegistry, setValidateRegistry] = React.useState(false); 
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 	

// Global loading for User Data Loading If logged and not approved (only for under review or repproved status)
var logged = localStorage.getItem("@memovo/logged");
const [globalLoading, setGlobalLoading] = React.useState(logged == "1" ? true : false); 	

// Set location
const location = useLocation();
const { hash, pathname, search } = location;
	
// set Use of history for PUSH 
var navigate = useNavigate();	
const op = React.useRef(null);

// Store socket first state
const socketRef = React.useRef(null);	

	const getRoutes = (routes) => {
	return routes.map((prop, key) => {
		return (
			<Route
			exact path={prop.path}
			element={<prop.component />}
			logOut={logOut}
			key={key}
			/>
		);
	});
	};  
  
	const onHide = () => {
	setMenuOpened(false);
	}  

	const onShow = () => {
	setMenuOpened(true);
	}    
		
	const handleClickScroll = (target) => {
		
	const element = document.getElementById(target);
	if (element) {
		// 👇 Will scroll smoothly to the top of the next section
		var elementPosition = element.offsetTop;
		element.scrollIntoView({ top: elementPosition - 70,behavior: 'smooth' });
	}
	};  
	
	 // Logoff method
	const logOut = () => {

		// Set logoff flag
		localStorage.setItem("@memovo/logged",0);
		
		// Remove userData 
		localStorage.removeItem("@memovo/userData");	
		localStorage.removeItem("@memovo/companyStatus");	
		localStorage.removeItem("@memovo/activationStatus");
		
		// Remove Session ALSO
		sessionStorage.removeItem("@motuum/searchingProvider");	
		sessionStorage.removeItem("@motuum/serviceOfferId");	
		sessionStorage.removeItem("@motuum/quotationId");	
		sessionStorage.removeItem("@motuum/sessionId");
		sessionStorage.removeItem("@motuum/selectedProvider");

		if (socketRef.current) {
			socketRef.current.off('connect');
			socketRef.current.close();
		}	
		
		setLoginStatus(false);
		navigate("/home");
		
	}	

	// Actions to process accoring to websocket return
	const processWebsocketAction = (value) => {

		var action = value.action;

		switch (action) {

			case 'updateCompanyStatus':
					
				// Dont change master user activation status
				if (!masterUser) {
					setCurrentCompanyStatus(parseInt(value.newStatus));
					localStorage.setItem("@memovo/companyStatus",value.statusToken);	
				}
				
				break;
			case 'updateActivationStatus':
				
				// Dont change master user activation status
				if (!masterUser) {
					setActivationStatus(value.newStatus === "1" ? true : false);
				} // end if

			break;

			case 'forceLogoff':

				// only force logoff of non master user - to avoid drop main master logged user
				if (!masterUser) {
					setAlert(true);
					setAlertTitle("Você foi desconectado!"); 
					setAlertBody("Você foi desconectado pela administrador do sistema. Faça novamente o seu login."); 				

					logOut();
				} // erd if

			break;

			case 'showMessage':

				// Dont show all message for master user
				if (!masterUser) {
					setAlert(true);
					setAlertTitle(value.title ? value.title : "Você recebeu uma mensagem"); 
					setAlertBody(value.message); 
				} // end if		

			break;

			default:
			  console.log("Nothing to do...");
		  }


	}

  // Execute when LocalStorage Changes TO AVOID HACKING ON BROWSER INSPECTOR
  const handleStorageChange = (event) => {

		console.log('Storage change detected! [2]');
		//console.log('event.key >>>> '+JSON.stringify(event.key,null,2));
		//console.log('event.oldValue >>>> '+JSON.stringify(event.oldValue,null,2));
		//console.log('event.newValue >>>> '+JSON.stringify(event.newValue,null,2));
//		console.log(event.key, event.oldValue, event.newValue, event.url, event.storageArea);

		if (event.key) {
			if (event.oldValue === null) {

			  console.log(`Item adicionado - chave: ${event.key}, valor: ${event.newValue}`);

			} else if (event.newValue === null) {

			  console.log(`Item removido - chave: ${event.key}, valor antigo: ${event.oldValue}`);
 
				// Check if userData Exist. If not, Logoff and redirect to Home
				var result = localStorage.getItem("@memovo/userData");
				var userData = JSON.parse(result); 
				var resultCompanyStatus = localStorage.getItem("@memovo/companyStatus");
				var resultActivationStatus = localStorage.getItem("@memovo/activationStatus");  
				var logged = localStorage.getItem("@memovo/logged");

				console.log('resultCompanyStatus >>>> '+JSON.stringify(resultCompanyStatus,null,2));
				console.log('resultActivationStatus >>>> '+JSON.stringify(resultActivationStatus,null,2));
				console.log('logged >>>> '+JSON.stringify(logged,null,2));

				// If there is no logged localStorage, logout
				if (!logged || logged == "0") {
					logOut();
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (1) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					return;
				} // end if 

				// If any required localStorage Value is present, logout
				if (!userData || !resultCompanyStatus || !resultActivationStatus) {
					logOut();
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (2) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					return;
				} // end if  

				try {
					var userDataTokenObjDecoded = jwtDecode(userData.userToken);
					if (!userDataTokenObjDecoded.CompanyPersonUserId) {
						logOut();
						console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (3) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
						return;
					} // end if 
				} catch(e) {
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (3.1) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					logOut();
				} // end catch				

			} else {
			  console.log(`Item atualizado - chave: ${event.key}, valor antigo: ${event.oldValue}, valor novo: ${event.newValue}`);

				try {
					var userDataTokenObjDecoded = jwtDecode(userData.userToken);
					if (!userDataTokenObjDecoded.CompanyPersonUserId) {
						logOut();
						console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (3) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
						return;
					} // end if 
				} catch(e) {
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (6) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					logOut();
				} // end catch

				// Set CurrentCompanyStatus
				let companyStatusFlag = false;
				if (resultCompanyStatus) { companyStatusFlag = true; }
				try {
					var companyStatusObjDecoded = jwtDecode(resultCompanyStatus); 
					var currentCompanyStatus = companyStatusObjDecoded.currentStatus;
					props.setCurrentCompanyStatus(currentCompanyStatus);
				} catch(e) {
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (7) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					logOut();
				} // end catch
				
				// Set ActivationStatus
				let activationStatusFlag = false;
				if (resultActivationStatus) { activationStatusFlag = true; }	
				try {
					var activationStatusObjDecoded = jwtDecode(resultCompanyStatus);
					var activationStatus = activationStatusObjDecoded.currentStatus;
					props.setActivationStatus(activationStatus);
				} catch(e) {
					console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (8) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
					logOut();
				} // end catch		

			}
		  } else {
			console.log('Todo o storage pode ter sido limpo, ou uma chave nula foi manipulada');
		  }		

  };	
	
// Execute when route changes
React.useEffect(() => {

    // Função que você deseja executar a cada mudança de rota
   // console.log('A rota mudou!', location.pathname);
    
	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData");
	var userData = JSON.parse(result);   

	  // If token dont exist, redirect to onboarding or register
	  if (userData) {
		  // console.log('SET LOGIN TRUE');
		  setLoginStatus(true);
		  return;
	  } // end if
	  else {
		// console.log('SET LOGIN FALSE');
	  }
    
  }, [location]); // Dependência do useEffect é a localização atual

// Run after mount component and Navigate changes
React.useEffect(() => {

	// Check if userData Exist. If not, Logoff and redirect to Home
	var result = localStorage.getItem("@memovo/userData");
	var userData = JSON.parse(result); 
	var resultCompanyStatus = localStorage.getItem("@memovo/companyStatus");
	var resultActivationStatus = localStorage.getItem("@memovo/activationStatus");  

	// If any required localStorage Value is present, logout
	if (!userData || !resultCompanyStatus || !resultActivationStatus) {
		logOut();
		return;
	} // end if  

	try {
		var userDataTokenObjDecoded = jwtDecode(userData.userToken);
		if (!userDataTokenObjDecoded.CompanyPersonUserId) {
			logOut();
			console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (11) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
			return;
		} // end if 
	} catch(e) {
		console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (11.1) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
		logOut();
	} // end catch	


	// redirect according to status
	/*if (loginStatus && currentCompanyStatus === 1 && activationStatus === true) {
		navigate("/order");
		return;
	} // end if*/
	if (loginStatus && currentCompanyStatus === 1 && activationStatus === false) {
		navigate("/home");
		return;
	} // end if		
	else if (loginStatus && (currentCompanyStatus === 0 || currentCompanyStatus === 2 || currentCompanyStatus === 3)) {
		navigate("/home");

		// if review and activation is false, open activation modal. [CHECK IF KEEP]
		if(currentCompanyStatus === 0 && !activationStatus) {
			// setDialog('validateEmail',true);
		}

		return;
	} // end if	

  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, [navigate]); // end useEffect 

// Run one time after mount component
React.useEffect(() => {
  
   setRoutePanel(false);

    // Adiciona o listener do STORAGE ao montar o componente
    window.addEventListener('storage', handleStorageChange);   

   // Check if userData Exist. If not, Logoff and redirect to Home
	var result = localStorage.getItem("@memovo/userData");
	var userData = JSON.parse(result); 
	var resultCompanyStatus = localStorage.getItem("@memovo/companyStatus");
	var resultActivationStatus = localStorage.getItem("@memovo/activationStatus");  

	// If any required localStorage Value is present, logout
	if (!userData || !resultCompanyStatus || !resultActivationStatus) {
		setGlobalLoading(false);
		logOut();
		return;
	} // end if  
	 
	// check userToken
	try {
		var userDataTokenObjDecoded = jwtDecode(userData.userToken);
		if (!userDataTokenObjDecoded.CompanyPersonUserId) {
			setGlobalLoading(false);
			logOut();
			return;
		} // end if 
	} catch(e) {
		logOut();
		return;
	} // end catch	

	// Set Global loading on since its logged
	setGlobalLoading(true);
		
	// Set logged 
	localStorage.setItem("@memovo/logged", "1");
	setLoginStatus(true);

	var props = {
		setGlobalLoading:setGlobalLoading,
		logOut:logOut,
		setLoginStatus:setLoginStatus,
		setMasterUser:setMasterUser,
		setAgencyName:setAgencyName,
		setAgencyUser:setAgencyUser,
		setCurrentCompanyStatus:setCurrentCompanyStatus,
		setActivationStatus:setActivationStatus,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody
	}
	var data = {
	  loginStatus: loginStatus
	}
	retrieveAndSetUserInfo(data,props);	

	// Runs when UNMOUNT component
	return () => {
		
		setRoutePanel(false);
		
		// Remove listener
		window.removeEventListener('storage', handleStorageChange);

		// Disconnect WEBSOCKET
		if (socketRef.current) {
			socketRef.current.off('connect');
			socketRef.current.close();
		}	
		
	};

}, []); // end useEffect 

// Check currentCompanyStatus changes and redirect if needed
React.useEffect(() => {

	  // redirect according to status
	  if (loginStatus && currentCompanyStatus === 1 && activationStatus) {
		  navigate("/order");
		  return;
	  } // end if	
	  // redirect according to status
	  else if (loginStatus && currentCompanyStatus === 1 && !activationStatus) {
		navigate("/home");
		return;
	  } // end if		  
	  else if (loginStatus && (currentCompanyStatus === 0 || currentCompanyStatus === 2 || currentCompanyStatus === 3)) {
		navigate("/home");

		// if review and activation is false, open activation modal. [CHECK IF KEEP]
		if(currentCompanyStatus === 0 && !activationStatus) {
			// setDialog('validateEmail',true);
		}

		return;
	} // end if		  
  
}, [currentCompanyStatus,activationStatus]); // end useEffect

// Execute when loginStatus changes
React.useEffect(() => {
	
	  // if logged connect web socket. 
	  if (loginStatus) {

			// Set WEBSOCKET connection
			var result = localStorage.getItem("@memovo/userData");
			var userData = JSON.parse(result);     	

			socketRef.current = io(config.WS_URL, {
				auth: {
				// Substitua 'seu_jwt_token_aqui' pela lógica para obter o JWT
				token: userData ? userData.userToken : null
				},
				transports: ['websocket'],  // força o uso de WebSocket
			}); 
			

			socketRef.current.on('mensagemDoServidor', (data) => {

				// Process action according to received data
				processWebsocketAction(data);

			});	

			// Connect WEBSOCKET only when mount
			socketRef.current.on('connect', () => {
				console.log('Conectado ao servidor WebSocket');
				socketRef.current.emit('autenticar', { token: userData.userToken }); 
			});

			socketRef.current.on('connect_error', (error) => {
				console.log('Erro de conexão:', error);
			  });
			  
			  socketRef.current.on('connect_timeout', (timeout) => {
				console.log('Timeout de conexão:', timeout);
			  });
			  
			  socketRef.current.on('error', (error) => {
				console.log('Erro geral:', error);
			  });

	  } // end if	
	  else  {
		// Disconnect WEBSOCKET
		if (socketRef.current) {
			socketRef.current.off('connect');
			socketRef.current.close();
		}	
	} // end if		  
  
}, [loginStatus]); // end useEffect 

	/* Logout prompt START  */
    const accept = (e) => {
	   logOut();
	  // op.current.toggle(e);
    };

    const reject = (e) => {
       op.current.toggle(e);
    };
	
    const confirm = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: <div>Tem certeza que deseja <br /> fazer o logoff e sair?</div>,
            icon: 'pi pi-exclamation-triangle',
			accept,
            reject,
			rejectLabel:"Não",
			acceptLabel:"Sim"
        });
    };
	/* Logout prompt END  */	
	

/* Set current agency method */	
const setCurrentAgencyAction = () => {
	
	setDialog('selectAgencyModal',true);
	
} // end method

/* Set current agency method */	
const resetCurrentAgencyAction = () => {
	
	setCurrentAgency({});
	
	// Redirect to Order Start
	navigate("/order");
	
} // end method

// If globalLoading True, show loading screen
if (globalLoading) {
return(
<div className="wrapper" style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%",height:"100vh"}}>

	<div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:"30vw"}}>
		<ProgressSpinner />
		<div className="formTitle" style={{marginBottom:"40px"}}>Carregando...</div>

		<img src={formTopIcons} className="contatoSection__wrapper_content_formTop_image noSelect" style={{marginBottom:"20px"}} />
		<div className="formSubTitle">MEMOVO: Encontre os melhores serviços de mobilidade B2B em um só lugar</div>		
	</div>

</div>
);	
}

// If globalLoading False, keep going
  return (
  <>
    <div className="wrapper">
      <div className="wrapper__header">
		<div className="wrapper__header_container">
			<div className="flex-1" onClick={() => { if (loginStatus && currentCompanyStatus === 1 && activationStatus) {navigate("/order");}}} style={{cursor:"pointer"}}>
				<img src={logo} style={{width:"auto"}} className="noSelect" />
			</div>	
			<div className="flex-5 tAlignL pL40">
				<Button label="A Memovo" text className="pT10 headerButton" onClick={()=>handleClickScroll('memovoSection')} />
				<Button label="Contato" text className="pT10 headerButton" onClick={()=>handleClickScroll('contatoSection')}  />
			</div>			
			<div className={!loginStatus ? "flex-1 dFlex" : "flex-1-5 dFlex"}>
			
			{!loginStatus &&
			<>
				<Button label="Login" rounded className="pT10 mR10 headerButton tColorWhite" style={{width:"130px"}} onClick={()=>handleClickScroll('loginSection')} />
				<Button label="Cadastre-se" text className="pT10 headerButton tColorPink" style={{width:"147px"}} onClick={() => setDialog('registerModal',true)} />
			</>
			}

			{loginStatus &&
			<>	

				
				{/*<Button icon={<img src={iconRoute} style={{marginRight:"5px",width:"30px",height:"auto"}} />} size="large" rounded text aria-label="Menu" onClick={()=>setRouteManage(!routeManage)} label="Meus Roteiros" style={{fontSize:"1rem",color:"#222"}} />*/}
				
				{masterUser &&
				<>
				
						{!currentAgency.agencyCode &&
						<>
						<Button icon="pi pi-plus" size="large" rounded text aria-label="Menu" onClick={()=>setCurrentAgencyAction()} label="Selecionar agência" style={{fontSize:"1rem",color:"#222"}} />
						</>
						}
						
						{currentAgency.agencyCode &&
						<>
						<div className="loggedInWrapper"><span> Reservando como <strong>{currentAgency.agencyName}</strong> <br /> Funcionário: <strong>{currentAgency.employeeName}</strong> </span><Button icon="pi pi-times" size="small" rounded text aria-label="Menu" onClick={()=>resetCurrentAgencyAction()} label="Deslogar" style={{fontSize:"1rem",color:"#222"}} />
						</div>
						</>
						}

				</>
				}
				
				{/* IF inside admin module */}
				{pathname.includes("admin") &&
				<>
				<Button icon="pi pi-sign-out" size="large" rounded text aria-label="Menu" onClick={(e) => {navigate("/order")} } label="Sair da área da agência" style={{fontSize:"1rem"}} />
				</>
				 }				
			
			    {!pathname.includes("admin") &&
				<>
				<Button badge={masterUser ? <svg fill="#fe346e" viewBox="0 0 1920 1920" width="25" height="25" style={{}}  xmlns="http://www.w3.org/2000/svg" stroke="#fe346e"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M983.727 5.421 1723.04 353.62c19.765 9.374 32.414 29.252 32.414 51.162v601.525c0 489.6-424.207 719.774-733.779 887.943l-34.899 18.975c-8.47 4.517-17.731 6.889-27.105 6.889-9.262 0-18.523-2.372-26.993-6.89l-34.9-18.974C588.095 1726.08 164 1495.906 164 1006.306V404.78c0-21.91 12.65-41.788 32.414-51.162L935.727 5.42c15.134-7.228 32.866-7.228 48 0ZM757.088 383.322c-176.075 0-319.285 143.323-319.285 319.398 0 176.075 143.21 319.285 319.285 319.285 1.92 0 3.84 0 5.76-.113l58.504 58.503h83.689v116.781h116.781v83.803l91.595 91.482h313.412V1059.05l-350.57-350.682c.114-1.807.114-3.727.114-5.647 0-176.075-143.21-319.398-319.285-319.398Zm0 112.942c113.732 0 206.344 92.724 205.327 216.62l-3.953 37.271 355.426 355.652v153.713h-153.713l-25.412-25.299v-149.986h-116.78v-116.78H868.108l-63.812-63.7-47.209 5.309c-113.732 0-206.344-92.5-206.344-206.344 0-113.732 92.612-206.456 206.344-206.456Zm4.98 124.98c-46.757 0-84.705 37.948-84.705 84.706s37.948 84.706 84.706 84.706c46.757 0 84.706-37.948 84.706-84.706s-37.949-84.706-84.706-84.706Z" fill-rule="evenodd"></path> </g></svg> : ""} badgeClassName={masterUser ? "p-badge-menu" : "hidden"} icon={<img src={iconProfile} style={{marginRight:"5px",width:"36px",height:"auto"}} />} size="large" rounded text aria-label="Menu" onClick={!menuOpened ? (e) => {op.current.toggle(e);e.currentTarget.blur()} : (e) => {op.current.toggle(e);e.currentTarget.blur()} } label={agencyName} style={{fontSize:"1rem"}} />
				<OverlayPanel className="overlayPanel" onHide={onHide} onShow={onShow}  ref={op} style={{padding:"0 !important",backgroundColor:"#FFFFFF",width:"185px"}}>
				{/*minHeight:"150px"*/}
					<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center",justifyContent:"center",height:"42px",width:"100%",padding:"10px",fontSize:"12px",color:"gray"}} >Logado como {agencyUser}</div>	
				
					{masterUser &&				
					<>
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Painel" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/dashboard");op.current.toggle(e);e.currentTarget.blur()}}  /></div>		
						
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Agências" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/agencies");op.current.toggle(e);e.currentTarget.blur()}} /></div>	

						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Fila de aprovação" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/agenciesApproval");op.current.toggle(e);e.currentTarget.blur()}} /></div>	
					</>
					}				

					{(currentCompanyStatus === 1 || masterUser) &&
					<>
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Perfil da agência" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/agencyProfile");op.current.toggle(e);e.currentTarget.blur()}} /></div>		
						

						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Perfil do usuário" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/userProfile");op.current.toggle(e);e.currentTarget.blur()}} /></div>	
						

						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Usuários" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/users");op.current.toggle(e);e.currentTarget.blur()}}  /></div>
						
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Clientes" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/customers");op.current.toggle(e);e.currentTarget.blur()}}  /></div>
						

						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Roteiros" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/routes");op.current.toggle(e);e.currentTarget.blur()}} /></div>	


						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Reservas" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/reservations");op.current.toggle(e);e.currentTarget.blur()}}  /></div>						
						
						<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",display:"flex",alignItems:"center"}} > <Button severity="secondary"  text label="Relatórios" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/reports");op.current.toggle(e);e.currentTarget.blur()}}  /> </div>
					</>
					}
					
					{masterUser &&
					<>
					<div style={{width:"100%",backgroundColor:"#F8F8F8",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center"}} ><Button severity="secondary"  text label="Configurações" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e)=>{navigate("/admin/configurations");op.current.toggle(e);e.currentTarget.blur()}} /></div>
					</>
					}					
					
					<ConfirmPopup />
					<Button label="Fazer logoff" className="mR10" style={{height:"42px",width:"100%",borderRadius:"0"}} onClick={(e) => {confirm(e)}} />
					
				</OverlayPanel>
				</>				
				}				
				
				
			</>				
			}
			
			
			</div>				
		</div>
	  </div>
      <div className="wrapper__content">        
          <div className="wrapper__content_container home bgPurple">
		     <Routes>
				{getRoutes(routes)}	
				<Route
					path="*"
					element={<Navigate to="/home" replace />}
				/>				
			  </Routes>
          </div>  
      </div>  
	  <div className="wrapper__footer">
		<div className="wrapper__footer_container">
			&copy;2023 Todos os direitos reservados a MeMovo Ltda
		</div>
	  </div>
	  <ScrollTop threshold={100} />
    </div>
	
	<RegisterView
		setDialog={setDialog}
		dialog={dialog}
		alert={alert}
		setAlert={setAlert}
		setAlertTitle={setAlertTitle}
		setAlertBody={setAlertBody}	
		setAlertAction={setAlertAction}	
		setValidateRegistry={setValidateRegistry}		
	/>	

	<ValidateView
		alert={alert}
		setAlert={setAlert}
		setAlertTitle={setAlertTitle}
		setAlertBody={setAlertBody}	
		setAlertAction={setAlertAction}	
		validateRegistry={validateRegistry}
		setValidateRegistry={setValidateRegistry}
		scrollBlock={false}				
	/>	
	
	<SelectAgencyView
		setDialog={setDialog}
		dialog={dialog}
		setAlert={setAlert}
		setAlertTitle={setAlertTitle}
		setAlertBody={setAlertBody}
		scrollBlock={false}			
	/>		

	<AlertView
		alert={alert}
		setAlert={setAlert}
		alertTitle={alertTitle}
		alertBody={alertBody}
		alertAction={alertAction}
		scrollBlock={false}				
	/>

	{/*<RouteManage
		setDialog={setDialog}
		dialog={dialog}
	/>*/}	
	</>	
  );
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  masterUser:store.appReducer.masterUser,
  routePanel:store.appReducer.routePanel,
  routeManage:store.appReducer.routeManage,
  loginStatus:store.appReducer.loginStatus,
  currentAgency:store.appReducer.currentAgency,
  agencyName:store.appReducer.agencyName,
  agencyUser:store.appReducer.agencyUser,
  currentCompanyStatus:store.appReducer.currentCompanyStatus,
  activationStatus:store.appReducer.activationStatus
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);


export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Desktop);	
