import '../../Assets/Styles/register.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'

import desktopConcept from '../../Assets/Images/desktopConcept.png'; 


const AboutUs = (props) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

	return (
	<>
		<div id="memovoSection" className="memovoSection__wrapper">
		
            <div className={isTabletOrMobile ? "memovoSection__wrapper_content_wrapper mobile" : "memovoSection__wrapper_content_wrapper"}>
            
                <div className="flex-1"> 
                    <img src={desktopConcept} className="memovoSection__wrapper_content_image noSelect" />
                </div>
                <div className="flex-1 dFlex aItemsC jContentS"> 
                
                    <div className="textBlockMargin memovoSection__wrapper_content_text" >
                    
                        <div className="memovoSection__wrapper_content_header">Inovando o Mercado B2B de Mobilidade</div>
                                        
                        A MEMOVO é uma plataforma B2B inovadora que facilita a busca e comercialização de serviços de transporte e mobilidade. Pioneira como marketplace B2B, oferece passagens aéreas, trens, ônibus, aluguel de veículos e transfers em um só lugar, permitindo a criação de roteiros completos de mobilidade. Com interface amigável e processo simples, a plataforma proporciona agilidade e eficiência aos profissionais do setor, atendendo às necessidades dos usuários e impulsionando o crescimento do seu negócio.
                    
                    </div> 
                    
                </div>
                
            </div>
        
        </div>									
	</>	
	);
	
}

export default AboutUs;	
