import '../../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Load Actions
import * as AppActions from '../../Store/Actions/App';
import * as RegisterActions from '../../Store/Actions/Register';

// Load Components
import TermsView from './TermsModal';
import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	validarNomeEmpresa,
	maskCNPJ,
	maskCPF,
	maskPhone
} from '../../Components/Utils';

// UpdateService dialog component
const RegisterView = (props) => {

// set vars from props
const { 
	dialog,
	setDialog,
	retrieveAppCities,
	retrieveCompanyDataByDocument,
	retrievePersonDataByDocument,
	setCurrentCompanyStatus,
	setLoginStatus,
	setMasterUser,
	setAgencyName,
	setAgencyUser,
	registerCompany,	
	setActivationStatus,
	setAlert,
	setAlertTitle,
	setAlertBody,
	setAlertAction
} = props;		

const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 

const [termsModal, setTermsModal] = React.useState(false); 

const [companyDocument, setCompanyDocument] = React.useState('');
const [companyDocumentSearched, setCompanyDocumentSearched] = React.useState('');

const [companyLegalName, setCompanyLegalName] = React.useState('');
const [companyFantasyName, setCompanyFantasyName] = React.useState('');
const [companyEmail, setCompanyEmail] = React.useState('');
const [companyPhone, setCompanyPhone] = React.useState('');
const [companyState, setCompanyState] = React.useState('');
const [companyCity, setCompanyCity] = React.useState('');
const [personCompanyName, setPersonCompanyName] = React.useState('');
const [personCompanyNameStatus, setPersonCompanyNameStatus] = React.useState(true);

const [companyDocumentMeta, setCompanyDocumentMeta] = React.useState('');
const [companyPersonDocumentMeta, setCompanyPersonDocumentMeta] = React.useState('');

const [companyStateAuto, setCompanyStateAuto] = React.useState('');
const [companyCityAuto, setCompanyCityAuto] = React.useState('');

const [personName, setPersonName] = React.useState('');
const [birthDay, setBirthday] = React.useState('');
const [personDocument, setPersonDocument] = React.useState('');
const [personPhone, setPersonPhone] = React.useState('');
const [email, setEmail] = React.useState('');
const [emailConfirm, setEmailConfirm] = React.useState('');
const [password, setPassword] = React.useState('');
const [passwordConfirm, setPasswordConfirm] = React.useState('');

// If its a solo company or not
const [soloCompany, setSoloCompany] = React.useState(false);

const [acceptTerm, setAcceptTerm] = React.useState(false);
const [autoFields, setAutoFields] = React.useState(true); // if true, fields are disabled
const [autoFieldsFill, setAutoFieldsFill] = React.useState(true); // if true, fields are auto filled. No manual.
const [autoFieldsFilled, setAutoFieldsFilled] = React.useState(false); // if true, fields was returned
const [autoPersonFieldsFilled, setAutoPersonFieldsFilled] = React.useState(false); // if true, fields was returned
const [documentReady, setDocumentReady] = React.useState(false); // if true, document is ok and ready
const [personDocumentReady, setPersonDocumentReady] = React.useState(false); // if true, document is ok and ready

const [loadingData, setLoadingData] = React.useState(false);
const [loadingPersonData, setPersonLoadingData] = React.useState(false);
const [cityLoading, setCityLoading] = React.useState(false);
const [cityResult, setCityResult] = React.useState(false);

const handleChangeDefault = (e) => {
    e.preventDefault();
  };

const handleTermsChange = (e) => {
	if (e.checked)
		setAcceptTerm(true);
	else
		setAcceptTerm(false);
}  // end function

/* Check if document is valid,. If yes, release button. If not, alert.*/
const handleCompanyDocumentChange = (e) => {

	var currentValue = e.target.value;
	
	setCompanyDocument(maskCNPJ(e.target.value));
	
	if (currentValue.length >= 18) {
		
		// check if cnpj is valid
		if (!validarCNPJ(currentValue)) {
			setAlert(true);
			setAlertTitle("Verifique o CNPJ");
			setAlertBody("O CNPJ informado é inválido. Verifique novamente.");	
			return;	
		} // end if	 
		else {
			setDocumentReady(true);
		} // end else
		
	} // end if
	else {
		setDocumentReady(false);
		setAutoFieldsFilled(false);
		setCompanyLegalName("");
		setCompanyFantasyName("");
		setCompanyEmail("");
		setCompanyPhone("");
		setCompanyStateAuto("");
		setCompanyCityAuto("");
		setPersonCompanyName("");		
		setPersonCompanyNameStatus(true)
		setDropdownPersonNameItems([]);		
	} // end else
	
}  // end function

/* Check if document is valid,. If yes, release button. If not, alert.*/
const handlePersonDocumentChange = (e) => {

	var currentValue = e.target.value;
	
	setPersonDocument(maskCPF(e.target.value));
	
	if (currentValue.length >= 14) {
		
		// check if cnpj is valid
		if (!validarCPF(currentValue)) {
			setAlert(true);
			setAlertTitle("Verifique o CPF");
			setAlertBody("O CPF informado é inválido. Verifique novamente.");	
			return;	
		} // end if	
		else {
			setPersonDocumentReady(true);
		} // end else
		
	} // end if
	else {
		setPersonDocumentReady(false);
		setAutoPersonFieldsFilled(false);
		setPersonName("");		
	} // end else
	
}  // end function

/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}

const retrieveCompanyDocumentInfo = () => {
	
	setLoadingData(true);

	// Set searched document
	setCompanyDocumentSearched(companyDocument);

	// Clean current data
	setCompanyCity('');
	setCityLoading(false);
	setCityResult(false);
	setLoadingData(false);
	setPersonLoadingData(false);
	setPersonDocumentReady(false);
	setDocumentReady(false);
	setDropdownCityItems([]);
	
	setCompanyLegalName('');
	setCompanyFantasyName('');
	setPersonCompanyName('');
	setPersonCompanyNameStatus(true)
	setDropdownPersonNameItems([]);

	setCompanyEmail('');
	setCompanyPhone('');
	setCompanyState('');
	setCompanyCity('');
	setCompanyStateAuto('');
	setCompanyCityAuto('');	
	setPersonName('');
	setBirthday('');
	setPersonDocument('');
	setPersonPhone('');
	setEmail('');
	setEmailConfirm('');
	setPassword('');
	setPasswordConfirm('');
	setAcceptTerm(false);		
	
	var props = {
		setSaving:setSaving,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAutoFields:setAutoFields,
		setAutoFieldsFill:setAutoFieldsFill,
		setAutoFieldsFilled:setAutoFieldsFilled,
		setLoadingData:setLoadingData,
		setCompanyLegalName:setCompanyLegalName,
		setCompanyFantasyName:setCompanyFantasyName,
		setCompanyEmail:setCompanyEmail,
		setCompanyPhone:setCompanyPhone,
		setPersonCompanyName:setPersonCompanyName,
		setPersonCompanyNameStatus:setPersonCompanyNameStatus,
		setCompanyStateAuto:setCompanyStateAuto,
		setCompanyCityAuto:setCompanyCityAuto,
		setPersonName:setPersonName,
		setPersonDocument:setPersonDocument,
		setPersonPhone:setPersonPhone,
		setCompanyDocumentMeta:setCompanyDocumentMeta,
		setDropdownPersonNameItems:setDropdownPersonNameItems,
		setSoloCompany:setSoloCompany
	}
	var data = {
	  companyDocument: companyDocument
	}
	retrieveCompanyDataByDocument(data,props);
	

} // end retrieveDocumentInfo

const retrievePersonDocumentInfo = () => {
	
	setPersonLoadingData(true);
	
	var props = {
		setSaving:setSaving,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAutoPersonFieldsFilled:setAutoPersonFieldsFilled,
		setPersonLoadingData:setPersonLoadingData,
		setPersonName:setPersonName,
		setBirthday:setBirthday,
		setCompanyPersonDocumentMeta:setCompanyPersonDocumentMeta,
		soloCompany:soloCompany
	}
	var data = {
	  personDocument: personDocument
	}
	retrievePersonDataByDocument(data,props);
	

} // end retrieveDocumentInfo

const save = () => {

	
// Check if document on field is the same as searched
if (companyDocumentSearched != companyDocument) {
	setAlert(true);
	setAlertTitle("Verifique os dados");
	setAlertBody("O CNPJ informado não tem relação com os dados obtidos. Faça a busca do CNPJ informado.");	
	return;	
} // end if	
	
// Check required fields
if (!companyDocument || !companyLegalName || !companyEmail || !companyPhone || !companyStateAuto || !companyCityAuto || !personName || !personDocument || !personPhone || !email || !emailConfirm || !password || !passwordConfirm) {
	setAlert(true);
	setAlertTitle("Verifique as informações");
	setAlertBody("Um ou mais campos não estão preenchidos. É necessário que preencha todos os campos marcados com * para prosseguir com o cadastro.");	
	return;
} // end if
 
// Check Document (CNPJ and CPF), phone and email consistency
if (!validarNome(personName) || !validarNomeEmpresa(companyLegalName)) {
	setAlert(true);
	setAlertTitle("Verifique os dados");
	setAlertBody("A Razão social ou nome completo parece estar inválido. Verifique novamente.");	
	return;	
} // end if
if (!validarCNPJ(companyDocument)) {
	setAlert(true);
	setAlertTitle("Verifique o CNPJ");
	setAlertBody("O CNPJ informado é inválido. Verifique novamente.");	
	return;	
} // end if
if (!validarCPF(personDocument)) {
	setAlert(true);
	setAlertTitle("Verifique o CPF");
	setAlertBody("O CPF informado é inválido. Verifique novamente.");	
	return;		
} // end if
if (!validarEmail(companyEmail) || !validarEmail(email)) {
	setAlert(true);
	setAlertTitle("Verifique o Email");
	setAlertBody("Um ou mais emails informados é inválido. Verifique novamente.");	
	return;		
} // end if
if (!validarTelefone(companyPhone) || !validarTelefone(personPhone)) {
	setAlert(true);
	setAlertTitle("Verifique o Telefone");
	setAlertBody("Um ou mais telefones informados é inválido. Verifique novamente.");	
	return;		
} // end if
	
// Check if email fields are equal
if (email  != emailConfirm) {
	setAlert(true);
	setAlertTitle("Verifique o email de acesso");
	setAlertBody("O campo de email e confirmação de email devem ser iguais. Verifique.");	
	return;
} // end if

// Check if password fields are equal
if (password  != passwordConfirm) {
	setAlert(true);
	setAlertTitle("Verifique a senha de acesso");
	setAlertBody("O campo de senha deve ser igual ao de confirmação de senha. Verifique.");	
	return;
} // end if

// Check if password is strong enough
var term = password;
var mediumPwd = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
if (!mediumPwd.test(term)) {
	setAlert(true);
	setAlertTitle("Verifique a senha de acesso");
	setAlertBody("A complexidade da senha deve ser no mínimo média. Verifique.");	
	return;    
} // end if	

// Check if Company Name is equal to Person Name
if ( (personName  != personCompanyName.code) && (!soloCompany)) {
	setAlert(true);
	setAlertTitle("Verifique as informações");
	setAlertBody("O campo Nome Responsável Legal em dados da empresa deve ser o mesmo nome do campo Nome Completo em dados do responsável legal pela empresa. O CPF informado deve ser o documento do responsável legal que consta nos dados da empresa.");	
	return;
} // end if

// Check if accepted terms
if (!acceptTerm) {
	setAlert(true);
	setAlertTitle("Verifique o aceite aos termos");
	setAlertBody("Para prosseguir com o cadastro, é necessário que leia e aceite os termos e condições de uso.");	
	return;
} // end if

// Set button loading on and freeze form 	
setSaving(true);

// Execute Recaptcha Token
	executeReCaptcha().then(function(token) {

		var props = {
			setDialog:setDialog,
			setSaving:setSaving,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setLoginStatus:setLoginStatus,
			setActivationStatus:setActivationStatus,
			setCurrentCompanyStatus:setCurrentCompanyStatus,
			setMasterUser:setMasterUser,
			setAgencyName:setAgencyName,
			setAgencyUser:setAgencyUser
		}
		var data = {
		  companyDocument: companyDocument,
		  companyLegalName: companyLegalName,
		  companyFantasyName: companyFantasyName,
		  companyEmail: companyEmail,
		  companyPhone: companyPhone,
		  companyState: companyStateAuto,
		  companyCity: companyCityAuto,
		  personCompanyName:personCompanyName,
		  personName: personName,
		  personDocument: personDocument,
		  personPhone: personPhone,
		  birthDay:birthDay,
		  email: email,
		  password: password,
		  companyPersonDocumentMeta:companyPersonDocumentMeta,
		  companyDocumentMeta:companyDocumentMeta,
		}
		registerCompany(data,props);
	
					
	}).catch(error=> {

		setAlertTitle("Ops! Parece que há um erro no ReCaptcha do Google!");
		setAlertBody("O ReCaptcha do Google não carregou corretamente. Recarregue a página para corrigir.");	     			
		setAlert(true);
		return; 

	});
	
} // end function save()

	// Suggested password panel
    const PasswordTip = (
        <>
            <Divider />
            <p className="mt-1" style={{fontSize:"0.9rem"}}>Sugestão de senha</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3" style={{fontSize:"0.8rem"}}>
                <li>Ao menos 1 letra maiúscula</li>
				<li>Ao menos 1 letra minúscula</li>
                <li>Ao menos 1 número </li>
				<li>Ao menos 1 símbolo ! # $ % & * + - = ? @ </li>
                <li>De 6 a 12 caracteres</li>
            </ul>
        </>
    );
	
    const footerContent = (
        <div className="modalFooterButtons" style={{}}>
		
			<div className="modalFooterButtonsLeft">
				<div className="flex align-items-center">
					<Checkbox inputId="acceptTerm" disabled={saving} name="acceptTerm" value="sim" onChange={handleTermsChange} checked={acceptTerm} className="mR10" />
					<label htmlFor="acceptTerm" className="ml-2">Estou de acordo com os <Button label="termos e condições de uso" link style={{padding:"unset"}} onClick={() => setTermsModal(true)} /></label>
				</div>
			</div>
			<div className="modalFooterButtonsRight">
				<Button label="Fechar" disabled={saving} icon="pi pi-times" className="p-button-text" onClick={() => closeDialog()} />
				<Button label="Cadastrar" icon="pi pi-check" loading={saving} onClick={() => save()} />
			</div>
			
        </div>
    );		

const StateItems = [
			{
			"code": 12,
			"name": "AC - Acre"
			},
			{
			"code": 27,
			"name": "AL - Alagoas"
			},
			{
			"code": 16,
			"name": "AP - Amapá"
			},
			{
			"code": 13,
			"name": "AM - Amazonas"
			},
			{
			"code": 29,
			"name": "BA - Bahia"
			},
			{
			"code": 23,
			"name": "CE - Ceará"
			},
			{
			"code": 53,
			"name": "DF - Distrito Federal"
			},
			{
			"code": 32,
			"name": "ES - Espírito Santo"
			},
			{
			"code": 52,
			"name": "GO - Goiás"
			},
			{
			"code": 21,
			"name": "MA - Maranhão"
			},
			{
			"code": 51,
			"name": "MT - Mato Grosso"
			},
			{
			"code": 50,
			"name": "MS - Mato Grosso do Sul"
			},
			{
			"code": 31,
			"name": "MG - Minas Gerais"
			},
			{
			"code": 15,
			"name": "PA - Pará"
			},
			{
			"code": 25,
			"name": "PB - Paraíba"
			},
			{
			"code": 41,
			"name": "PR - Paraná"
			},
			{
			"code": 26,
			"name": "PE - Pernambuco"
			},
			{
			"code": 22,
			"name": "PI - Piauí"
			},
			{
			"code": 33,
			"name": "RJ - Rio de Janeiro"
			},
			{
			"code": 24,
			"name": "RN - Rio Grande do Norte"
			},
			{
			"code": 43,
			"name": "RS - Rio Grande do Sul"
			},
			{
			"code": 11,
			"name": "RO - Rondônia"
			},
			{
			"code": 14,
			"name": "RR - Roraima"
			},
			{
			"code": 42,
			"name": "SC - Santa Catarina"
			},
			{
			"code": 35,
			"name": "SP - São Paulo"
			},
			{
			"code": 28,
			"name": "SE - Sergipe"
			},
			{
			"code": 17,
			"name": "TO - Tocantins"
			}
];	
const [dropdownStateItems, setDropdownStateItems] = React.useState(StateItems);
const [dropdownCityItems, setDropdownCityItems] = React.useState([]);


const [dropdownPersonNameItems, setDropdownPersonNameItems] = React.useState([]);


/* Retrieve cities linked to selcted state */
const retrieveDropdownCities = (value) => {
	
	// set loading on
	setCompanyCity('');
	setCityLoading(true);
	setCityResult(false);
	setDropdownCityItems([]);
	
	// set props
	var props = {
		setCityLoading:setCityLoading,
		setCityResult:setCityResult,
		setDropdownCityItems:setDropdownCityItems		
	}	
	// retrieve cities based on state
	retrieveAppCities(value.code,props);
	
	/*setTimeout(function() {
		
		setCityLoading(false);
		setCityResult(true);
		
	},2000);*/
	
}

/* Set and retrieve cities linked to selcted state */
const processCompanyState = (value) => {	
	setCompanyState(value);
	retrieveDropdownCities(value);	
}

/* Reset form fields and close dialog */
const closeDialog = () => {
	
	// Reset form fields
	setCompanyCity('');
	setCityLoading(false);
	setCityResult(false);
	setLoadingData(false);
	setPersonLoadingData(false);
	setPersonDocumentReady(false);
	setDocumentReady(false);
	setDropdownCityItems([]);
	setCompanyDocument('');
	setCompanyLegalName('');
	setCompanyFantasyName('');
	setPersonCompanyName('');
	setCompanyEmail('');
	setCompanyPhone('');
	setCompanyState('');
	setCompanyCity('');
	setCompanyStateAuto('');
	setCompanyCityAuto('');	
	setPersonName('');
	setBirthday('');
	setPersonDocument('');
	setPersonPhone('');
	setEmail('');
	setEmailConfirm('');
	setPassword('');
	setPasswordConfirm('');
	setAcceptTerm(false);
	
	// close dialog
	setDialog('registerModal',false);
	
	
}

// Execute when dialog opens
React.useEffect(() => {

	// Only runs when  dialog opens
	if (dialog.target == 'registerModal' && dialog.status) {

				
	} // end if
	
    return () => {
      	  
		// Reset form fields
		setCompanyCity('');
		setCityLoading(false);
		setCityResult(false);
		setLoadingData(false);
		setPersonLoadingData(false);
		setPersonDocumentReady(false);
		setDocumentReady(false);
		setDropdownCityItems([]);
		setCompanyDocument('');
		setCompanyLegalName('');
		setCompanyFantasyName('');
		setPersonCompanyName('');
		setPersonCompanyNameStatus(true)
		setDropdownPersonNameItems([]);		
		setCompanyEmail('');
		setCompanyPhone('');
		setCompanyState('');
		setCompanyCity('');
		setCompanyStateAuto('');
		setCompanyCityAuto('');	
		setPersonName('');
		setBirthday('');
		setPersonDocument('');
		setPersonPhone('');
		setEmail('');
		setEmailConfirm('');
		setPassword('');
		setPasswordConfirm('');
		setAcceptTerm(false);	  
		
    };
			
},[dialog]);

	return (
	<>
			<Dialog header="Cadastro de Agência" visible={dialog.target == 'registerModal' && dialog.status} style={{ width: '55vw' }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => closeDialog()} footer={footerContent} blockScroll={true} maximizable={saving ? false : false} closable={saving ? false : true} closeOnEscape={false} >
			
				<Box
				  sx={{
					width: "100%",
				  }}
				>
					<div className="registerModalIntro"> Cadastre sua agência para começar a utilizar a plataforma Memovo. <br /><i>Campos obrigatórios marcados com <sup><span className="requiredField">*</span></sup></i></div>	

					<Divider />
					  
						<Panel header="Dados da empresa" toggleable style={{marginBottom:"20px"}}>
							<Grid container spacing={4} style={{marginTop:"-20px"}}>
							  <Grid item xs={12} sm={6} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
								<div className="p-float-label w100">
									<InputText  id="companyDocument" autoComplete="new-password" disabled={saving ? true : false} className="w100" value={companyDocument} onBlur={(e) => handleCompanyDocumentChange(e)} onChange={(e) => handleCompanyDocumentChange(e)} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus' }}  style={{borderTopRightRadius:0,borderBottomRightRadius:0}}  />
									<label htmlFor="companydocument">CNPJ <span className="requiredField">*</span></label>
								</div>
								<Button label="Buscar" disabled={!documentReady} style={{borderTopLeftRadius:0,borderBottomLeftRadius:0,height:"47px"}} size="small" loading={loadingData} onClick={() => retrieveCompanyDocumentInfo()} />
							  </Grid>
							  <Grid item xs={12} sm={6}>
							  
							  {!autoFieldsFilled &&
							  <>
								<small>Comece preenchendo o CNPJ e aperte "Buscar" para trazer os dados automaticamente.</small>
							  </>
							  }
							  
							  {autoFieldsFilled &&
							  <>
								<small>Os dados abaixo foram trazidos diretamente da RFB. Caso queira alterar finalize o cadastro e entre em contato após aprovação.</small>
							  </>
							  }							  
								
							  </Grid>
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label">
									<InputText id="companyLegalName" autoComplete="new-password" disabled={saving || autoFields ? true : false} className="w100" value={companyLegalName} onChange={(e) => setCompanyLegalName(e.target.value)} tooltip="Como consta no CNPJ" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
									<label htmlFor="companyLegalName">Razão Social <span className="requiredField">*</span></label>
								</span>
							  </Grid>
							  <Grid item xs={12} sm={6}>
								<div className="p-float-label">
									<InputText id="companyFantasyName" autoComplete="new-password" disabled={saving || autoFields ? true : false} className="w100" value={companyFantasyName} onChange={(e) => setCompanyFantasyName(e.target.value)}  />
									<label htmlFor="companyFantasyName">Nome Fantasia</label>
								</div>
							  </Grid>
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label">
									<InputText id="companyEmail" autoComplete="new-password" disabled={saving || autoFields ? true : false} className="w100" value={companyEmail} onChange={(e) => setCompanyEmail(e.target.value)} tooltip="Insira o email principal da empresa. Evite emails pessoais." tooltipOptions={{ position: 'bottom',event: 'focus' }}  />
									<label htmlFor="companyEmail">Email da empresa <span className="requiredField">*</span></label>
								</span>
							  </Grid>
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label">
									<InputText id="companyPhone" autoComplete="new-password" disabled={saving || autoFields ? true : false} className="w100" value={companyPhone} onChange={(e) => setCompanyPhone(maskPhone(e.target.value))} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
									<label htmlFor="companyPhone">Telefone <span className="requiredField">*</span></label>
								</span>
							  </Grid>
							  <Grid item xs={12} sm={4}>
								<span className="p-float-label" >
									{/*<InputText id="personCompanyName" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={personCompanyName} onChange={(e) => setPersonCompanyName(e.target.value)} style={{width:"100%"}} />*/}
									<Dropdown id="personCompanyName" disabled={personCompanyNameStatus} value={personCompanyName} onChange={(e) => setPersonCompanyName(e.target.value)} options={dropdownPersonNameItems} optionLabel="name" placeholder="Selecione um responsável" className="w100" filter={false} filterLocale="pt-BR"></Dropdown>
									<label htmlFor="personCompanyName">Nome do responsável Legal <span className="requiredField">*</span></label>
								</span>		
							  </Grid>							  
							  <Grid item xs={12} sm={3}>
								  {!autoFieldsFill &&
								  <>
									<span className="p-float-label">
										<Dropdown id="companyState" disabled={saving ? true : false} value={companyState} onChange={(e) => processCompanyState(e.value)} options={dropdownStateItems} optionLabel="name" placeholder="Select One" className="w100" filter={true} filterLocale="pt-BR"></Dropdown>
										<label htmlFor="companyState">Estado <span className="requiredField">*</span></label>
									</span>
								  </>
								  }
								  
								  {autoFieldsFill &&
								  <>
									<span className="p-float-label">
										<InputText id="companyStateAuto" autoComplete="new-password" disabled={true} className="w100" value={companyStateAuto} onChange={(e) => setCompanyPhone(maskPhone(e.target.value))} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
										<label htmlFor="companyStateAuto">Estado <span className="requiredField">*</span></label>
									</span>	
								  </>
								  }								
							  </Grid>
							  <Grid item xs={12} sm={4}>

								  {!autoFieldsFill &&
								  <>
									<span className="p-float-label">
										<Dropdown id="companyCity" disabled={saving || !cityResult ? true : false} value={companyCity} onChange={(e) => setCompanyCity(e.value)} options={dropdownCityItems} optionLabel="name" placeholder="Select One" className="w100" filter={true} filterLocale="pt-BR" emptyFilterMessage="Nenhum resultado" emptyMessage="Nenhum resultado" ></Dropdown>
										<label htmlFor="companyCity">
											<ProgressSpinner id="companyCityLoading" style={{width: '15px', height: '15px',display:cityLoading ? "block" : "none"}} strokeWidth="4" />
											<span id="companyCityPlaceholder" style={{display:cityLoading ? "none" : !cityResult ? "block" : "none"}}>- Selecione o estado - <span className="requiredField">*</span></span>
											<span id="companyCityLabel" style={{display:cityResult ? "block" : "none"}}>Cidade <span className="requiredField">*</span></span>
										</label>
									</span>
								  </>
								  }
								  
								  {autoFieldsFill &&
								  <>
									<span className="p-float-label">
										<InputText id="companyCityAuto" autoComplete="new-password" disabled={true} className="w100" value={companyCityAuto} />
										<label htmlFor="companyCityAuto">Cidade <span className="requiredField">*</span></label>
									</span>	
								  </>
								  }	
								
							  </Grid>
							</Grid>
						</Panel>					  

						<Panel header="Dados do responsável legal pela empresa" style={{marginBottom:"20px"}}>

							<Grid container spacing={4} style={{marginTop:"-20px"}}>
						
							<Grid item xs={12} sm={6} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
								<div className="p-float-label w100">
									<InputText id="personDocument" autoComplete="new-password" disabled={saving ? true : false}  value={personDocument} onBlur={(e) => handlePersonDocumentChange(e)} onChange={(e) => handlePersonDocumentChange(e)} className="w100" tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus'}}  style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
									<label htmlFor="personDocument">CPF <span className="requiredField">*</span></label>
								</div>
								<Button label="Buscar" disabled={!personDocumentReady} style={{borderTopLeftRadius:0,borderBottomLeftRadius:0,height:"47px"}} size="small" loading={loadingPersonData} onClick={() => retrievePersonDocumentInfo()} />
							  </Grid>
							  <Grid item xs={12} sm={6}>
							  
							  {autoPersonFieldsFilled &&
							  <>
								<small>Os dados ao lado foram trazidos diretamente da RFB. Caso queira alterar finalize o cadastro e entre em contato após aprovação.</small>
							  </>
							  }								  
							  
							  {!autoPersonFieldsFilled &&
							  <>
								<small>Preencha o CPF e aperte "Buscar" para trazer o nome automaticamente. O nome deve ser do responsável legal da empresa junto a RFB.</small>
							  </>
							  }
							  								
							  </Grid>							
							
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label" >
									<InputText id="personName" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={personName} onChange={(e) => setPersonName(e.target.value)} style={{width:"100%"}} />
									<label htmlFor="peronName">Nome completo <span className="requiredField">*</span></label>
								</span>		
							  </Grid>
							  
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label" >
									<InputText id="personPhone" autoComplete="new-password" disabled={saving ? true : false} value={personPhone} onChange={(e) => setPersonPhone(maskPhone(e.target.value))} style={{width:"100%"}} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
									<label htmlFor="personPhone">Telefone Celular <span className="requiredField">*</span></label>								
								</span>	
							  </Grid>							  
							</Grid>

						</Panel>	
					  
						<Panel header="Dados de acesso" toggleable>

							<Grid container spacing={4} style={{marginTop:"-20px"}}>
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label" >
									<InputText id="email" autoComplete="new-password"  onCut={handleChangeDefault} onCopy={handleChangeDefault} onPaste={handleChangeDefault} disabled={saving ? true : false} value={email} onChange={(e) => setEmail(e.target.value)} style={{width:"100%"}} tooltip="Informe o email do responsável legal da empresa." tooltipOptions={{ position: 'bottom',event: 'focus' }} />
									<label htmlFor="email">E-mail (login) <span className="requiredField">*</span></label>
								</span>		
							  </Grid>
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label" >
									<InputText id="emailConfirm" autoComplete="new-password"  onCut={handleChangeDefault} onCopy={handleChangeDefault} onPaste={handleChangeDefault} disabled={saving ? true : false} value={emailConfirm} onChange={(e) => setEmailConfirm(e.target.value)} style={{width:"100%"}} tooltip="Confirme o email do responsável legal da empresa." tooltipOptions={{ position: 'bottom',event: 'focus' }} />
									<label htmlFor="email">Confirme o E-mail (login) <span className="requiredField">*</span></label>
								</span>		
							  </Grid>
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label">
									<Password inputId="password" autoComplete="new-password" disabled={saving ? true : false} toggleMask value={password} onChange={(e) => setPassword(e.target.value)}  style={{width:"100%"}} feedback={true} promptLabel="Escolha uma senha" weakLabel="Senha fraca" mediumLabel="Senha média" strongLabel="Senha forte" footer={PasswordTip} tooltip="A senha tem que ser no mínimo média" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
									<label htmlFor="password">Senha <span className="requiredField">*</span></label>
								</span>	
							  </Grid>
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label" >
									<Password inputId="passwordConfirm" autoComplete="new-password" disabled={saving ? true : false} toggleMask value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)}  style={{width:"100%"}} feedback={false}  />
									<label htmlFor="passwordConfirm">Confirme a senha <span className="requiredField">*</span></label>
								</span>	
							  </Grid>
							</Grid>

						</Panel>					  
			<TermsView
				setTermsModal={setTermsModal}
				termsModal={termsModal}
				scrollBlock={true}
			/>
			</Box>
            </Dialog>	
	</>	
	);
	
}


const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions, ...RegisterActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(RegisterView);	
