import '../../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive'

import { Button } from 'primereact/button'; 

import contactConcept from '../../Assets/Images/contactConcept.png'; 
import contactWhatsappIcon from '../../Assets/Images/contactWhatsappIcon.svg';
import contactEmailIcon from '../../Assets/Images/contactEmailIcon.svg';
import contactPhoneIcon from '../../Assets/Images/contactPhoneIcon.svg';

import {
	Callto,
	Whatsapp
} from '../../Components/Utils';

// Load Actions
import * as AppActions from '../../Store/Actions/App'

import AlertView from '../../Components/AlertModal';
import MessageView from '../../Components/MessageModal';

const ContactUs = (props) => {

// Set var from props
const {
	setDialog,
	dialog
} = props;    

// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

	return (
	<>
    <div id="contatoSection" className="contatoSection__wrapper">
        
        <div className={isTabletOrMobile ? "contatoSection__wrapper_content_wrapper mobile" : "contatoSection__wrapper_content_wrapper"}  >

            <div className="flex-1 dFlex aItemsC jContentE"> 
            
                <div className="textBlockMargin contatoSection__wrapper_content_text" >
                
                    <div className="contatoSection__wrapper_content_header">Central de Relacionamento</div>
                
                    Estamos a disposição para atender às suas necessidades e responder às suas perguntas. Conheça as principais formas de entrar em contato conosco:
                    
                    <div style={{marginTop: "2.8125rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", gap: "15px"}}>

                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
                            <div><img src={contactWhatsappIcon} style={{width:"49px",height:"auto"}} className="noSelect" /></div>
                            <div className="contatoOptionText"><Whatsapp phone="552140209749" style={{color:"#FFF",textDecoration:"none"}}>Whatsapp 4020-9749</Whatsapp></div>
                        </div>

                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
                            <div><img src={contactPhoneIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
                            <div className="contatoOptionText"><Callto phone="+552140209749" style={{color:"#FFF",textDecoration:"none"}}>Telefone 4020-9749</Callto></div>
                        </div>
                        
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
                            <div><img src={contactEmailIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
                            <div className="contatoOptionText"><Button label="Enviar mensagem" rounded className="pT10 mR10 headerButton tColorWhite w100" style={{marginBottom:"0.625rem"}} onClick={()=>setDialog('messageModal',true)} /></div>
                        </div>
                        

                    </div>					
                
                </div> 
                
            </div>
            
            <div className="tAlignL flex-1_5"> 
                <img src={contactConcept} className="contatoSection__wrapper_content_image noSelect" />
            </div>			
            
        </div>
            <MessageView
                alert={alert}
                setAlert={setAlert}
                alertTitle={alertTitle}
                alertBody={alertBody}	
                scrollBlock={false}				
            />		
            <AlertView
                alert={alert}
                setAlert={setAlert}
                alertTitle={alertTitle}
                alertBody={alertBody}	
                scrollBlock={false}	
            />            
    </div>											
	</>	
	);
	
}

   const mapStateToProps = store => ({
    dialog:store.appReducer.dialog
   });
  const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);
  
  export default connect(
          mapStateToProps,
          mapDispatchToProps
      )(ContactUs);	
  
  
  


