import React from 'react';
import axios from 'axios';
import moment from 'react-moment';
import jwt_decode from "jwt-decode";
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';
	

export const setAgency  = (value) => { 

	return { 
		type: types.SET_AGENCY,
		payload: value
	}
};	

// Retrieve all reservations
export const retrieveAgencies  = (data,props) => { 
		
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@memovo/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwt_decode(userObj.userToken);
								
					// Set general data
					const preparedData = {};
					preparedData.status =  data.status;
					preparedData.pagination = {
						first:data.first,
						rows:data.rows,
						page:data.page,
						sortField:data.sortField ? data.sortField:null,
						sortOrder:data.sortOrder ? data.sortOrder:null,
						pageCount:data.pageCount
					}					
					preparedData.filters =  data.filters ? data.filters:null;
					
					// Connect to Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/agency/list',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
								"x-access-token": userObj.userToken,
								Accept: 'application/json'
							}
					})							
					
					.then((response) => {

						// Document already registered
						if (!response.data.found) {
							
							props.setRows([]);
							props.setTotalRecords(0);
							props.setLoading(false);							
							return;
							
						} // end if

						if (response.data.success) {
							
							// Set list
							props.setRows(response.data.data);
							props.setTotalRecords(response.data.data.length);
							props.setLoading(false);						
							return;
					
							
						} // end if
						else {
							
							props.setRows([]);	
							props.setTotalRecords(0);
							props.setLoading(false);
														
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar a lista de reservas. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {
						
						props.setRows([]);
						props.setTotalRecords(0);
						props.setLoading(false);
						
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar a lista de reservas. Tente novamente. Caso o problema persista entre em contato informando código 02.");
						props.setAlert(true);							
						return;
					});
					
	} // end dispatch

};

// Retrieve agencies state options
export const retrieveAgenciesStates  = (data,props) => { 
		
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@memovo/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwt_decode(userObj.userToken);
					
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/agency/states/dropdown/list',
						   method : 'GET',
						   headers: {
								"x-access-token": userObj.userToken,
								Accept: 'application/json'
							}
					})							
					
					.then((response) => {

						// Document already registered
						if (!response.data.found) {
							
							props.setRows([]);
							props.setLoading(false);							
							return;
							
						} // end if

						if (response.data.success) {
							
							// Set list
							props.setRows(response.data.data);
							props.setLoading(false);
							return;
					
							
						} // end if
						else {
							
							props.setRows([]);	
							props.setLoading(false);
														
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar a lista de reservas. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {
						
						props.setRows([]);
						props.setLoading(false);
						
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar a lista de reservas. Tente novamente. Caso o problema persista entre em contato informando código 02.");
						props.setAlert(true);							
						return;
					});
					
	} // end dispatch

};
// Retrieve agencies comission profiles options
export const retrieveAgenciesComissionProfiles  = (data,props) => { 
		
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@memovo/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwt_decode(userObj.userToken);
					
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/agency/comissionProfile/dropdown/list',
						   method : 'GET',
						   headers: {
								"x-access-token": userObj.userToken,
								Accept: 'application/json'
							}
					})							
					
					.then((response) => {

						// Document already registered
						if (!response.data.found) {
							
							props.setRows([]);
							props.setLoading(false);							
							return;
							
						} // end if

						if (response.data.success) {
							
							// Set list
							props.setRows(response.data.data);
							props.setLoading(false);
							return;
					
							
						} // end if
						else {
							
							props.setRows([]);	
							props.setLoading(false);
														
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar a lista. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {
						
						props.setRows([]);
						props.setLoading(false);
						
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar a lista. Tente novamente. Caso o problema persista entre em contato informando código 02.");
						props.setAlert(true);							
						return;
					});
					
	} // end dispatch

};

// Retrieve agencies comission profiles options
export const retrieveAgenciesGroupProfiles  = (data,props) => { 
		
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@memovo/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwt_decode(userObj.userToken);
					
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/agency/groupProfile/dropdown/list',
						   method : 'GET',
						   headers: {
								"x-access-token": userObj.userToken,
								Accept: 'application/json'
							}
					})							
					
					.then((response) => {

						// Document already registered
						if (!response.data.found) {
							
							props.setRows([]);
							props.setLoading(false);							
							return;
							
						} // end if

						if (response.data.success) {
							
							// Set list
							props.setRows(response.data.data);
							props.setLoading(false);
							return;
					
							
						} // end if
						else {
							
							props.setRows([]);	
							props.setLoading(false);
														
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar a lista. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {
						
						props.setRows([]);
						props.setLoading(false);
						
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar a lista. Tente novamente. Caso o problema persista entre em contato informando código 02.");
						props.setAlert(true);							
						return;
					});
					
	} // end dispatch

};

// Retrieve selected reservation	
export const retrieveAgency  = (data,props) => { 
		
		return dispatch => {

					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@memovo/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwt_decode(userObj.userToken);

					// Set general data
					const preparedData = {};
					preparedData.agencyId = data.agencyId;
					
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/agency/retrieve',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
								"x-access-token": userObj.userToken,
								Accept: 'application/json'
							}
					})							
					
					.then((response) => {

						// console.log('<<<<<<<<<<<<<<<<<<< DATA >>>>>>>>>>>>>>>>>>>>> '+JSON.stringify(response.data.data,null,2));

						// Document already registered
						if (!response.data.found) {
							
							props.setLoadingData(false);
							props.setRow([]);													
							props.setAlertTitle("Ops! Parece que a reserva não foi encontrada!");
							props.setAlertBody("A reserva que está tentando abrir não foi encontrada. Entre em contato com o suporte e forneça o número da reserva.");
							props.setAlert(true);							
							return;
							
						} // end if

						if (response.data.success) {
	
							props.setRow(response.data.data);
							props.setLoadingData(false);	
							
							// Disable body scroll
							setTimeout(function() {
								document.body.classList.add('p-overflow-hidden');	
							},300);							

							return;							
							
						} // end if
						else {
							
							props.setLoadingData(false);	
							props.setRow([]);						
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar os dados da reserva. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {

						props.setLoadingData(false);
						props.setRow([]);						
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar os dados da reserva. Tente novamente. Caso o problema persista entre em contato informando código 02.");
						props.setAlert(true);							
						return;
					});
					
	} // end dispatch

};
	
// Approve pending agency
export const approveAgency  = (data,props) => { 
		
	return dispatch => {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);

				// Set general data
				const preparedData = {};
				preparedData.agencyId = data.agencyId;
				
				// Connect to Agency Approve Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/agency/approve',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})
				.then((response) => {

					if (response.data.success) {

						// Angecy not under review
						if (response.data.wrongStatus) {
							
							props.setLoadingAction(false);
										
							props.setAlertTitle("Ops! Parece que algo mudou durante a aprovação!");
							props.setAlertBody("A agência que está tentando aprovar não está mais em análise. Verifique e tente novamente.");
							props.setAlert(true);

							// Reload parent agencies list
							props.loadLazyData();
							return;
							
						} // end if						

						props.setLoadingAction(false);
			
						props.setAlert(true);
						props.setAlertTitle("Tudo certo! Status alterado!");
						props.setAlertBody("A empresa foi aprovada. O responsável foi notificado. À partir de agora todos os usuários vinculados a empresa poderão utilizar a plataforma.");	
						props.setAlertAction("closeAgencyModal");	
			
						// Load Parent grid
						props.loadLazyData();	
						
						// Disable body scroll
						setTimeout(function() {
							document.body.classList.add('p-overflow-hidden');	
						},300);							

						return;							
						
					} // end if
					else {
						
						props.setLoadingAction(false);
						props.setAlertTitle("Ops! Ocorreu um problema [1]");
						props.setAlertBody("Ocorreu um problema ao aprovar a agência. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
						props.setAlert(true);							
						return;
						
					} // end else
					
				})
				.catch(error=> {

					props.setLoadingAction(false);
					props.setAlertTitle("Ops! Ocorreu um problema [2]");
					props.setAlertBody("Ocorreu um problema ao ao aprovar a agência. Tente novamente. Caso o problema persista entre em contato informando código 02.");
					props.setAlert(true);							
					return;
				});
				
} // end dispatch

};

// Reprove pending agency
export const reproveAgency  = (data,props) => { 
		
	return dispatch => {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);

				// Set general data
				const preparedData = {};
				preparedData.agencyId = data.agencyId;
				preparedData.reproveReason = data.reproveReason;
				preparedData.canUpdate = data.canUpdate;
				
				// Connect to Agency Reprove Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/agency/reprove',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})
				.then((response) => {

					if (response.data.success) {

						// Angecy not under review
						if (response.data.wrongStatus) {
							
							props.setLoadingAction(false);
										
							props.setAlertTitle("Ops! Parece que algo mudou durante a reprovação!");
							props.setAlertBody("A agência que está tentando reprovar não está mais em análise. Verifique e tente novamente.");
							props.setAlert(true);

							// Reload parent agencies list
							props.loadLazyData();
							return;
							
						} // end if						

						props.setLoadingAction(false);
			
						props.setAlert(true);
						props.setAlertTitle("Tudo certo! Cadastro reprovado!");
						props.setAlertBody("A empresa foi reprovada e o responsável foi notificado com o motivo da reprovação. À partir de agora nenhum usuário relacionado a empresa conseguirá utilizar a plataforma até que o cadastro seja aprovado.");	
						props.setAlertAction("closeAgencyModal");	
			
						// Load Parent grid
						props.loadLazyData();
						
						// Disable body scroll
						setTimeout(function() {
							document.body.classList.add('p-overflow-hidden');	
						},300);							

						return;							
						
					} // end if
					else {
						
						props.setLoadingAction(false);
						props.setAlertTitle("Ops! Ocorreu um problema [1]");
						props.setAlertBody("Ocorreu um problema ao reprovar a agência. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
						props.setAlert(true);							
						return;
						
					} // end else
					
				})
				.catch(error=> {

					props.setLoadingAction(false);
					props.setAlertTitle("Ops! Ocorreu um problema [2]");
					props.setAlertBody("Ocorreu um problema ao reprovar a agência. Tente novamente. Caso o problema persista entre em contato informando código 02.");
					props.setAlert(true);							
					return;
				});
				
} // end dispatch

};

// Review pending agency
export const reviewAgency  = (data,props) => { 
		
	return dispatch => {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);

				// Set general data
				const preparedData = {};
				preparedData.agencyId = data.agencyId;
				preparedData.reviewReason = data.reviewReason;
				
				// Connect to Agency Reprove Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/agency/review',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})
				.then((response) => {

					if (response.data.success) {

						// Angecy not under review
						if (response.data.wrongStatus) {
							
							props.setLoadingAction(false);
										
							props.setAlertTitle("Ops! Parece que algo mudou durante a mudança de status!");
							props.setAlertBody("A agência que está tentando colocar em análise não está aprovada ou reprovada. Verifique e tente novamente.");
							props.setAlert(true);

							// Reload parent agencies list
							props.loadLazyData();
							return;
							
						} // end if						

						props.setLoadingAction(false);
			
						props.setAlert(true);
						props.setAlertTitle("Tudo certo! status alterado!");
						props.setAlertBody("A empresa foi colocada em análise.");	
						props.setAlertAction("closeAgencyModal");	
			
						// Load Parent grid
						props.loadLazyData();
						
						// Disable body scroll
						setTimeout(function() {
							document.body.classList.add('p-overflow-hidden');	
						},300);							

						return;							
						
					} // end if
					else {
						
						props.setLoadingAction(false);
						props.setAlertTitle("Ops! Ocorreu um problema [1]");
						props.setAlertBody("Ocorreu um problema ao mudar status da agência. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
						props.setAlert(true);							
						return;
						
					} // end else
					
				})
				.catch(error=> {

					props.setLoadingAction(false);
					props.setAlertTitle("Ops! Ocorreu um problema [2]");
					props.setAlertBody("Ocorreu um problema ao mudar status da agência. Tente novamente. Caso o problema persista entre em contato informando código 02.");
					props.setAlert(true);							
					return;
				});
				
} // end dispatch

};

// Block agency
export const blockAgency  = (data,props) => { 
		
	return dispatch => {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);

				// Set general data
				const preparedData = {};
				preparedData.agencyId = data.agencyId;
				preparedData.blockReason = data.blockReason;
				
				// Connect to Agency Reprove Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/agency/block',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})
				.then((response) => {

					if (response.data.success) {

						// Angecy not under review
						if (response.data.wrongStatus) {
							
							props.setLoadingAction(false);
										
							props.setAlertTitle("Ops! Parece que algo mudou durante a mudança de status!");
							props.setAlertBody("A agência que está tentando bloquear não está aprovada. Verifique e tente novamente.");
							props.setAlert(true);

							// Reload parent agencies list
							props.loadLazyData();
							return;
							
						} // end if						

						props.setLoadingAction(false);
			
						props.setAlert(true);
						props.setAlertTitle("Tudo certo! Cadastro bloqueado!");
						props.setAlertBody("A empresa foi bloqueada e o motivo do bloqueio foi registrado internamente na plataforma (a empresa não saberá o motivo). À partir de agora nenhum usuário relacionado a empresa conseguirá utilizar a plataforma.");	
						props.setAlertAction("closeAgencyModal");	
			
						// Load Parent grid
						props.loadLazyData();
						
						// Disable body scroll
						setTimeout(function() {
							document.body.classList.add('p-overflow-hidden');	
						},300);							

						return;							
						
					} // end if
					else {
						
						props.setLoadingAction(false);
						props.setAlertTitle("Ops! Ocorreu um problema [1]");
						props.setAlertBody("Ocorreu um problema ao bloquear a agência. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
						props.setAlert(true);							
						return;
						
					} // end else
					
				})
				.catch(error=> {

					props.setLoadingAction(false);
					props.setAlertTitle("Ops! Ocorreu um problema [2]");
					props.setAlertBody("Ocorreu um problema ao bloquear a agência. Tente novamente. Caso o problema persista entre em contato informando código 02.");
					props.setAlert(true);							
					return;
				});
				
} // end dispatch

};

// Unblock agency
export const unblockAgency  = (data,props) => { 
		
	return dispatch => {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);

				// Set general data
				const preparedData = {};
				preparedData.agencyId = data.agencyId;
				
				// Connect to Agency Reprove Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/agency/unblock',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})
				.then((response) => {

					if (response.data.success) {

						// Angecy not under review
						if (response.data.wrongStatus) {
							
							props.setLoadingAction(false);
										
							props.setAlertTitle("Ops! Parece que algo mudou durante a mudança de status!");
							props.setAlertBody("A agência que está tentando desbloquear não está bloqueada. Verifique e tente novamente.");
							props.setAlert(true);

							// Reload parent agencies list
							props.loadLazyData();
							return;
							
						} // end if						

						props.setLoadingAction(false);
			
						props.setAlert(true);
						props.setAlertTitle("Tudo certo! Cadastro desbloqueado!");
						props.setAlertBody("A empresa foi desbloqueada e o responsável foi notificado. À partir de agora os usuários vinculados a empresa conseguirão utilizar a plataforma.");	
						props.setAlertAction("closeAgencyModal");	
			
						// Load Parent grid
						props.loadLazyData();
						
						// Disable body scroll
						setTimeout(function() {
							document.body.classList.add('p-overflow-hidden');	
						},300);							

						return;							
						
					} // end if
					else {
						
						props.setLoadingAction(false);
						props.setAlertTitle("Ops! Ocorreu um problema [1]");
						props.setAlertBody("Ocorreu um problema ao desbloquear a agência. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
						props.setAlert(true);							
						return;
						
					} // end else
					
				})
				.catch(error=> {

					props.setLoadingAction(false);
					props.setAlertTitle("Ops! Ocorreu um problema [2]");
					props.setAlertBody("Ocorreu um problema ao desbloquear a agência. Tente novamente. Caso o problema persista entre em contato informando código 02.");
					props.setAlert(true);							
					return;
				});
				
} // end dispatch

};

// Update agency
export const updateAgency  = (data,props) => { 
		
	return dispatch => {

				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT 
				var decoded = jwt_decode(userObj.userToken);

				// Set general data
				const preparedData = {};
				preparedData.agencyId = data.agencyId;
				preparedData.companyEmail = data.companyEmail;
				preparedData.companyPhone = data.companyPhone;
				preparedData.comissionProfile = data.comissionProfile;
				preparedData.groupProfile = data.groupProfile;
				preparedData.dailyCreditLimit = data.dailyCreditLimit;
				preparedData.notifyAgency = data.notifyAgency;

				// Connect to Agency Update Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/agency/update',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})
				.then((response) => {

					if (response.data.success) {

						props.setLoadingAction(false);
			
						props.setAlert(true);
						props.setAlertTitle("Tudo certo! Cadastro atualizado!");
						props.setAlertBody("Os dados da agência foram atualizados com suceso!");	
						props.setAlertAction(null);	
						
						// Reload parent agencies list
						props.loadLazyData();
						props.retrieveAgencyInfo();
						
						// Disable body scroll
						setTimeout(function() {
							document.body.classList.add('p-overflow-hidden');	
						},300);							

						return;							
						
					} // end if
					else {
						
						props.setLoadingAction(false);
						props.setAlertTitle("Ops! Ocorreu um problema [1]");
						props.setAlertBody("Ocorreu um problema ao atualizar a agência. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
						props.setAlert(true);							
						return;
						
					} // end else
					
				})
				.catch(error=> {

					props.setLoadingAction(false);
					props.setAlertTitle("Ops! Ocorreu um problema [2]");
					props.setAlertBody("Ocorreu um problema ao atualizar a agência. Tente novamente. Caso o problema persista entre em contato informando código 02.");
					props.setAlert(true);							
					return;
				});
				
} // end dispatch

};



// Retrieve selected comission profile
export const retrieveProfileComissions  = (data,props) => { 
		
	return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT
				var decoded = jwt_decode(userObj.userToken);

				// Set general data
				const preparedData = {};
				preparedData.agencyId = data.agencyId;
				preparedData.comissionProfile = data.comissionProfile;				
				
				// Connect to REGISTER Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/agency/comissionProfile/retrieve',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {


					if (response.data.success) {

						console.log('>>>>>>>>>>>>>>>>>>>>>>> RESPONSE >>>>>>>>>>>>>>>>>>>>>>>>> '+JSON.stringify(response.data.data,null,2));
						
						// Set list
						props.setRows(response.data.data);
						props.setLoading(false);
						return;
				
						
					} // end if
					else {
						
						props.setRows([]);	
						props.setLoading(false);
						return;
						
					} // end else
					
				})
				.catch(error=> {
					
					props.setRows([]);
					props.setLoading(false);
					return;
				});
				
} // end dispatch

};

// Retrieve daily credit limit
export const retrieveDailyCreditLimit  = (data,props) => { 
		
	return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT
				var decoded = jwt_decode(userObj.userToken);

				// Set general data
				const preparedData = {};
				preparedData.agencyId = data.agencyId;		
				
				// Connect to REGISTER Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/agency/dailycredithistory/list',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {


					if (response.data.success) {

						console.log('>>>>>>>>>>>>>>>>>>>>>>> RESPONSE >>>>>>>>>>>>>>>>>>>>>>>>> '+JSON.stringify(response.data.data,null,2));
						
						// Set list
						props.setRows(response.data.data);
						props.setLoading(false);
						return;
				
						
					} // end if
					else {
						
						props.setRows([]);	
						props.setLoading(false);
						return;
						
					} // end else
					
				})
				.catch(error=> {
					
					props.setRows([]);
					props.setLoading(false);
					return;
				});
				
} // end dispatch

};

// Create Company Custom comission
export const createCompanyCustomComission  = (data,props) => { 
		
	return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT
				var decoded = jwt_decode(userObj.userToken);

				// Set general data
				const preparedData = {};
				preparedData.agencyId = data.agencyId;		
				preparedData.paymentMethod = data.paymentMethod.code;	
				preparedData.transportType = data.transportType.code;	
				preparedData.comission = data.comission;	
				preparedData.expireAt = data.expireAt;
				
				
				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/agency/custom/comission/create',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {

					if (response.data.alreadyExist) {

						props.setSaving(false);
			
						props.setAlert(true);
						props.setAlertTitle("Ops! Comissão customizada já existe!");
						props.setAlertBody("A comissão customizada da agência que está tentando criar já existe. Exclua ou altere existente!");	
						props.setAlertAction(null);	
						
						// Disable body scroll
						setTimeout(function() {
							document.body.classList.add('p-overflow-hidden');	
						},300);	

						return;
										
					} // end if

					if (response.data.success) {

						props.setSaving(false);

						// props.setLoadingAction(false);
						props.hideDialog();
							
						props.setAlert(true);
						props.setAlertTitle("Tudo certo! Comissão customizada foi criada com sucesso!");
						props.setAlertBody("A comissão customizada da agência foi criada com sucesso! Os dados da agência foram recarregados para visualizar as mudanças.");	
						props.setAlertAction(null);	
						
						// Reload parent agencies list
						props.retrieveAgencyInfo();
						
						// Disable body scroll
						setTimeout(function() {
							document.body.classList.add('p-overflow-hidden');	
						},300);		
						
						return;
										
					} // end if
					else {
						
						props.setSaving(false);
			
						props.setAlert(true);
						props.setAlertTitle("Ops! Algo deu errado!");
						props.setAlertBody("Não foi possível criar a comissão customizada da agência! Tente novamente.");	
						props.setAlertAction(null);	

						return;
						
					} // end else
					
				})
				.catch(error=> {
					
					props.setSaving(false);
			
						props.setAlert(true);
						props.setAlertTitle("Ops! Algo deu muito errado!");
						props.setAlertBody("Não foi possível criar a comissão customizada da agência! Fale com o suporte.");	
						props.setAlertAction(null);	
					return;
				});
				
				
} // end dispatch

};

// Update Company Custom comission
export const updateCompanyCustomComission  = (data,props) => { 
		
	return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT
				var decoded = jwt_decode(userObj.userToken);

				// Set general data
				const preparedData = {};
				preparedData.agencyId = data.agencyId;		
				preparedData.customComissionId = data.customComissionId;
				preparedData.paymentMethod = data.paymentMethod.code;	
				preparedData.transportType = data.transportType.code;	
				preparedData.comission = data.comission;	
				preparedData.expireAt = data.expireAt;
				
				
				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/agency/custom/comission/update',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {

					if (response.data.alreadyExist) {

						props.setSaving(false);
			
						props.setAlert(true);
						props.setAlertTitle("Ops! Comissão customizada já existe!");
						props.setAlertBody("A comissão customizada da agência que está tentando criar já existe. Exclua ou altere a existente!");	
						props.setAlertAction(null);	
						
						// Disable body scroll
						setTimeout(function() {
							document.body.classList.add('p-overflow-hidden');	
						},300);	

						return;
										
					} // end if

					if (response.data.success) {

						props.setSaving(false);

						// props.setLoadingAction(false);
						props.hideEditDialog();
							
						props.setAlert(true);
						props.setAlertTitle("Tudo certo! Comissão customizada foi alterada com sucesso!");
						props.setAlertBody("A comissão customizada da agência foi alterada com sucesso! Os dados da agência foram recarregados para visualizar as mudanças.");	
						props.setAlertAction(null);	
						
						// Reload parent agencies list
						props.retrieveAgencyInfo();
						
						// Disable body scroll
						setTimeout(function() {
							document.body.classList.add('p-overflow-hidden');	
						},300);		
						
						return;
										
					} // end if
					else {
						
						props.setSaving(false);
			
						props.setAlert(true);
						props.setAlertTitle("Ops! Algo deu errado!");
						props.setAlertBody("Não foi possível atualizar a comissão customizada da agência! Tente novamente.");	
						props.setAlertAction(null);	

						return;
						
					} // end else
					
				})
				.catch(error=> {
					
					props.setSaving(false);
			
						props.setAlert(true);
						props.setAlertTitle("Ops! Algo deu muito errado!");
						props.setAlertBody("Não foi possível atualizar a comissão customizada da agência! Fale com o suporte.");	
						props.setAlertAction(null);	
					return;
				});
				
} // end dispatch

};

// Delete Company Custom comission
export const deleteCompanyCustomComission  = (data,props) => { 
		
	return dispatch => {
		
				// Check if there is a local USER OBEJECt with a TOKEN
				var result = localStorage.getItem("@memovo/userData", undefined,true);
				var userObj = JSON.parse(result);  
				
				// decode JWT
				var decoded = jwt_decode(userObj.userToken);

				// Set general data 
				const preparedData = {};
				preparedData.agencyId = data.agencyId;		
				preparedData.companyCustomComissionsIds = data.companyCustomComissionsIds;	

				var multipleUpdates = false;
				if (preparedData.companyCustomComissionsIds.length > 1) {
					multipleUpdates = true;
				}
				
				// Connect to Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/agency/custom/comission/delete',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
							"x-access-token": userObj.userToken,
							Accept: 'application/json'
						}
				})							
				
				.then((response) => {

					if (response.data.success) {

						props.setSaving(false);

						// props.setLoadingAction(false);
							if (multipleUpdates) { props.hideDeleteComissionsDialog(); } else { props.hideDeleteComissionDialog(); }
										
						props.setAlert(true);
						props.setAlertTitle(multipleUpdates ? "Tudo certo! Comissões customizadas selecionadas excluídas!" : "Tudo certo! Comissão customizada excluída!");
						props.setAlertBody(multipleUpdates ? "As comissões customizadas selecionadas da agência foram excluídas com sucesso!" : "A comissão customizada da agência foi excluída com sucesso!");	
						props.setAlertAction(null);	
						
						// Reload parent agencies list
						props.retrieveAgencyInfo();
						
						// Disable body scroll
						setTimeout(function() {
							document.body.classList.add('p-overflow-hidden');	
						},300);	

						return;
										
					} // end if
					else {
						
						props.setSaving(false);
						
						props.setAlert(true);
						props.setAlertTitle("Ops! Algo deu errado!");
						props.setAlertBody("Não foi possível excluir a comissão customizada da agência! Tente novamente.");	
						props.setAlertAction(null);	

						return;
						
					} // end else
					
				})
				.catch(error=> {
					
						props.setSaving(false);
			
						props.setAlert(true);
						props.setAlertTitle("Ops! Algo deu muito errado!");
						props.setAlertBody("Não foi possível excluir a comissão customizada da agência! Fale com o suporte.");	
						props.setAlertAction(null);	
					return;
				});
				
} // end dispatch

};




	


	



