import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { OverlayPanel } from 'primereact/overlaypanel';
import { MultiSelect } from 'primereact/multiselect';
import { Card } from 'primereact/card';
import { TabView, TabPanel } from 'primereact/tabview';
import { Fieldset } from 'primereact/fieldset';
import { Tag } from 'primereact/tag';
import { Message } from 'primereact/message';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { Panel } from 'primereact/panel';
import Box from '@mui/material/Box';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Grid from '@mui/material/Grid';

import moment from "moment";

import multiTransportConcept from '../../Assets/Images/multiTransportConcept11.png'; 
import desktopConcept from '../../Assets/Images/desktopConcept.png'; 
import contactConcept from '../../Assets/Images/contactConcept.png'; 
import formPlaceholder from '../../Assets/Images/formPlaceholder.png'; 
import formTopIcons from '../../Assets/Images/formTopIcons.svg'; 
import cornerTop from '../../Assets/Images/cornerTop.svg';
import cornerBottom from '../../Assets/Images/cornerBottom.svg';

import contactWhatsappIcon from '../../Assets/Images/contactWhatsappIcon.svg';
import contactEmailIcon from '../../Assets/Images/contactEmailIcon.svg';
import contactPhoneIcon from '../../Assets/Images/contactPhoneIcon.svg';
import transportPicture from '../../Assets/Images/transportPicture.svg';
import rentcarPicture from '../../Assets/Images/rentcarPicture.svg';
import transferPicture from '../../Assets/Images/transferPicture.svg';
import transportIcon from '../../Assets/Images/transportIcons.svg';
import transferIcon from '../../Assets/Images/transferIcon.svg';
import rentcarIcon from '../../Assets/Images/rentcarIcon.svg';

import titleTrainIcon from '../../Assets/Images/titleTrainIcon.svg';
import titleBusIcon from '../../Assets/Images/titleBusIcon.svg';
import titleTransferIcon from '../../Assets/Images/titleBusIcon.svg';
import titleRentCarIcon from '../../Assets/Images/titleBusIcon.svg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as UserActions from '../../Store/Actions/User'

// Load Componentes
import SideMenu from '../../Components/SideMenu';
import MessageView from '../../Components/MessageModal';
import AlertView from '../../Components/AlertModal';
import UserView from './UserModal';

import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone
} from '../../Components/Utils';

import {
	Callto,
	Whatsapp,

} from '../../Components/Utils';

// export default function Reservation() {
const Users = (props) => {
	
// Set var from props
const {
	setDialog,
	dialog,
	setUser,
	selectedUser,
	retrieveUsers,
	masterUser
} = props;

// Set Overlay obj
const op = React.useRef(null);

const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
	
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })
const isSpecialDesktop = useMediaQuery({ query: '(max-width: 1500px)' })

const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Basic filter fields  and controls
const [basicFilterStatus, setBasicFilterStatus] = React.useState(false);
const [basicFilter, setBasicFilter] = React.useState({});
const [filterValue, setFilterValue] = React.useState("");

// Advanced filter fields and controls
const [advancedFilterStatus, setAdvancedFilterStatus] = React.useState(false);
const [advancedFilter, setAdvancedFilter] = React.useState({});
const [advancedSearch, setAdvancedSearch] = React.useState(false);

// CUSTOM ADVANCED FILTER FIELDS
const [filterByReservationId, setFilterByReservationId] = React.useState("");
const [filterByAgencyName, setFilterByAgencyName] = React.useState("");
const [filterByAgencyDocument, setFilterByAgencyDocument] = React.useState("");
const [filterByCustomerName, setFilterByCustomerName] = React.useState("");
const [filterByCustomerDocument, setFilterByCustomerDocument] = React.useState("");
const [filterByUserName, setFilterByUserName] = React.useState("");
const [filterByTransportType, setFilterByTransportType] = React.useState([]);
const [filterByCreateAtFrom, setFilterByCreateAtFrom] = React.useState("");
const [filterByCreateAtTo, setFilterByCreateAtTo] = React.useState("");
const [filterByServiceAtFrom, setFilterByServiceAtFrom] = React.useState("");
const [filterByServiceAtTo, setFilterByServiceAtTo] = React.useState("");
const [filterByPaymentMethod, setFilterByPaymentMethod] = React.useState(null);
const [filterByStatus, setFilterByStatus] = React.useState(null);

// Table row selection
const [selectedItem, setSelectedItem] = React.useState(null);

// set this month dateFormat
const [periodDate, setPeriodDate] = React.useState(null);

// Set Fake Data
//var rowsData = ReservationData;
const [rows, setRows] = React.useState([]);
const [totalRecords, setTotalRecords] = React.useState(0);

// selector
const [selectedPlaces, setSelectedPlaces] = React.useState(null);
 
/* -------------------------------- */ 
/* Custom methods and options START */
/* -------------------------------- */

// Select reservation item method
const selectAgencyAction = (value) => {

	// Set local data
	setSelectedItem(value);	

	// Set reservation on store (index 0)
	setUser(value[0]);

	// Open Modal
	setDialog('userModal',true);
	
}

// Retrieve reservations
const retrieveItemsList = () => {
		
	var props = {
		setSaving:setSaving,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setRows:setRows,
		setLoading:setLoading,
		setTotalRecords:setTotalRecords
	}
	var data = {
	  lazyState:lazyState
	}
	retrieveUsers(data,props);

} // end retrieveDocumentInfo
 
  
// Dropdown Values for Basic Search Fields
const [filter, setFilter] = React.useState(masterUser ? {
	"code": "5",
	"field":"agencyName",
	"name": "Nome da Agência"
} :  {
	"code": "2",
	"field":"customerName",
	"name": "Nome do Usuário"
  });
  
const FilterItems = masterUser ? [
  {
	"code": "5",
	"field":"agencyName",
	"name": "Nome da Agência"
  },  
  {
	"code": "2",
	"field":"customerName",
	"name": "Nome do Usuário"
  },   
   { 
	"code": "1",
	"field":"cpf",
	"name": "CPF"
  },   
   { 
	"code": "4",
	"field":"email",
	"name": "Email"
  }
] : [
  {
	"code": "2",
	"field":"customerName",
	"name": "Nome do Usuário"
  },   
   { 
	"code": "1",
	"field":"cpf",
	"name": "CPF"
  },   
   { 
	"code": "4",
	"field":"email",
	"name": "Email"
  }
];

const [dropdownFilterItems, setDropdownFilterItems] = React.useState(FilterItems);

// Datatable default state
const [lazyState, setlazyState] = React.useState({
	first: 0,
	rows: 10,
	page: 1,
	sortField: "createdAt",
	sortOrder: -1,
	filters: {
	}
});

// Default columns
const columnsValues = masterUser ? [
'userId',
'agency',
'name',
'contacts',
'profile',
'createdAt',
'status'
] : [
'userId',
'name',
'contacts',
'profile',
'createdAt',
'status'
];
const [visibleColumns, setVisibleColumns] = React.useState(columnsValues);

// Field value format and enrichement
const bodyTemplate = (type,data) => {

	let rowValue = "Não definido";

	if (type == "userId") {

		rowValue = (
		<>
		<div>{data.userId}</div>
		</>
		);	
		
	}
	
	if (type == "agency") {		

		rowValue = (
		<>
		<div>{data.legalName}</div>
		<div><small style={{color:"gray"}}>{data.fantasyName}</small></div>
		<div><small style={{color:"gray"}}>{data.document}</small></div>
		</>
		);	
		
	}
	
	if (type == "name") {	
			
		rowValue = (
		<>
		<div>{data.name}</div>
		<div><small style={{color:"gray"}}>{data.document}</small></div>
		</>
		);				
	}	
	
	if (type == "contacts") {	
		rowValue = (
		<>
		<div>{data.phone}</div>
		<div><small style={{color:"gray"}}>{data.email}</small></div>
		</>
		);		
	}	
	
	if (type == "creationDate") {		
		rowValue = moment(data.createdAt).format("DD/MM/YYYY")
	}	

	if (type == "profile") {		
		//rowValue = data.totalAmount;
		rowValue = (
		<>
		<div>{data}</div>
		</>
		);		
		
	}	

	if (type == "status") {		

		if (data.status == 1) {
			var activationStatus = "ativado";
			var activationStatusLabel = "Ativo";
		} // end if	
		else if (data.status == 2) {
			var activationStatus = "review";
			var activationStatusLabel = "Inativo";
		} // end if
		else if (data.status == 3) {
			var activationStatus = "bloqueado";
			var activationStatusLabel = "Bloqueado";
		} // end if	
		else if (data.status == 4) {
			var activationStatus = "encerrado";
			var activationStatusLabel = "Excluído";
		} // end if
			
		return (
		<>
		<div className={`product-badge status-${activationStatus}`}>{activationStatusLabel}</div>
		</>
		)	
		rowValue = data;
	}	
	
    return rowValue;
};
 
const applyBasicSearch = () => {
	
	// Check if search term is filled and with at least 3 chars
	if (!filterValue) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Você precisa preencher o termo a ser buscado pra prosseguir.");	
		return;
	} // end if
	
	if (filterValue.length < 3) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Para prosseguir, você precisa preencher o termo a ser buscado com ao menos 3 caracteres.");	
		return;
	} // end if	
 	
	// Set filter on
	setBasicFilterStatus(true);
	
	// Set advanced filter off
	setAdvancedFilterStatus(false);
	
	// Clean Advanced fields
	setFilterByReservationId("");
	setFilterByAgencyName("");
	setFilterByAgencyDocument("");
	setFilterByCustomerName("");
	setFilterByCustomerDocument("");
	setFilterByUserName("");
	setFilterByTransportType([]);
	setFilterByCreateAtFrom("");
	setFilterByCreateAtTo("");
	setFilterByServiceAtFrom("");
	setFilterByServiceAtTo("");
	setFilterByPaymentMethod("");
	setFilterByStatus("");		
	
	// Set filter to lazyData
	var currentData = {...lazyState}
	
	// Set current filter
	currentData.filters = {
		mode:"basic",
		term: filterValue,
		field: filter
	}
	
	// Set LazyState
	setlazyState(currentData);
	
} // end function

const resetSearch = (type)=> {
	
	if (type == "basic") {
		// Set filter on
		setBasicFilterStatus(false);
		
		// Clean Terms
		setFilterValue("");		
	} // end if
	else {
		// Set filter on
		setAdvancedFilterStatus(false);
		
		setFilterByReservationId("");
		setFilterByAgencyName("");
		setFilterByAgencyDocument("");
		setFilterByCustomerName("");
		setFilterByCustomerDocument("");
		setFilterByUserName("");
		setFilterByTransportType([]);
		setFilterByCreateAtFrom("");
		setFilterByCreateAtTo("");
		setFilterByServiceAtFrom("");
		setFilterByServiceAtTo("");
		setFilterByPaymentMethod("");
		setFilterByStatus("");		
		
	} // end else
	
	// Set filter to lazyData
	var currentData = {...lazyState}
	
	// Set current filter
	currentData.filters = {};
	
	// Set LazyState
	setlazyState(currentData);	
	
} // end fucntion

const applyAdvancedSearch = () => {
	
	// Check if at least one field is filled
	if (!filterByReservationId && !filterByAgencyName && !filterByAgencyDocument && !filterByCustomerName &&  !filterByCustomerDocument && !filterByUserName && filterByTransportType.length == 0 &&  !filterByCreateAtFrom && !filterByCreateAtTo && !filterByServiceAtFrom && !filterByServiceAtTo && !filterByPaymentMethod && !filterByStatus) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Você precisa preencher ao menos um campo pra prosseguir com a busca.");	
		return;
	} // end if	
	
	// Check if createdAt From and To are filled 
	if (filterByCreateAtFrom || filterByCreateAtTo) {
		if (!filterByCreateAtFrom || !filterByCreateAtTo) {
			setAlert(true);
			setAlertTitle("Verifique as informações");
			setAlertBody("Você precisa preencher ao os dois campos da data de criação para prosseguir.");	
			return;
		}
	} // end if

	// Check if createdAt From and To are filled 
	if (filterByServiceAtFrom || filterByServiceAtTo) {
		if (!filterByServiceAtFrom || !filterByServiceAtTo) {
			setAlert(true);
			setAlertTitle("Verifique as informações");
			setAlertBody("Você precisa preencher ao os dois campos da data do serviço para prosseguir.");	
			return;
		}
	} // end if	
	
	// Check company document if ok
	if (filterByCustomerDocument) {
		if (!validarCPF(filterByCustomerDocument)) {
			setAlert(true);
			setAlertTitle("Verifique as informações");
			setAlertBody("O CPF informado é inválido. Verifique novamente.");		
			return;
		}
	} // end if	

	// Check customer document if ok
	if (filterByAgencyDocument) {
		if (!validarCNPJ(filterByAgencyDocument)) {
			setAlert(true);
			setAlertTitle("Verifique as informações");
			setAlertBody("O CNPJ informado é inválido. Verifique novamente.");	
			return;
		}
	} // end if		
	 	
	// Set filter on
	setBasicFilterStatus(false);
	
	// Clean Basic Search term
	setFilterValue("");
	
	// Set advanced filter off
	setAdvancedFilterStatus(true);
	
	// Set filter to lazyData
	var currentData = {...lazyState}	
	
	var filters = {};
	filters.mode = "advanced"; 
	filters.reservationId = filterByReservationId ? filterByReservationId : null; 
	filters.agencyName = filterByAgencyName ? filterByAgencyName : null; 
	filters.agencyDocument = filterByAgencyDocument ? filterByAgencyDocument : null; 
	filters.customerName = filterByCustomerName ? filterByCustomerName : null; 
	filters.customerDocument = filterByCustomerDocument ? filterByCustomerDocument : null; 
	filters.userName = filterByUserName ? filterByUserName : null; 
	filters.transportType = filterByTransportType ? filterByTransportType : null; 
	filters.createAtFrom = filterByCreateAtFrom ? filterByCreateAtFrom : null; 
	filters.createAtTo = filterByCreateAtTo ? filterByCreateAtTo : null; 
	filters.serviceAtFrom = filterByServiceAtFrom ? filterByServiceAtFrom : null; 
	filters.serviceAtTo = filterByServiceAtTo ? filterByServiceAtTo : null; 
	filters.paymentMethod = filterByPaymentMethod ? filterByPaymentMethod : null; 
	filters.status = filterByStatus ? filterByStatus : null; 
	
	// Apply filter on Data from service 
	let _lazyParams = { ...lazyState, filters:{ fields: filters, mode:"advanced" } };
	setlazyState(_lazyParams);	
	
	// Closes panel
	setAdvancedSearch(!advancedSearch)
	
} // end function
 
/* -------------------------------- */ 
/* Custom methods and options END */
/* -------------------------------- */
 
 
/* -------------------- */ 
/* Common methods START */
/* -------------------- */

// Set selected columns
const onColumnsChange = (e) => {
	
	let _visibleColumns = [...visibleColumns];

	if (e.checked)
		_visibleColumns.push(e.value);
	else
		_visibleColumns.splice(_visibleColumns.indexOf(e.value), 1);

	setVisibleColumns(_visibleColumns);
}

// Datatable related controls START
const loadLazyData = () => {	
	setLoading(true);
	retrieveItemsList();	
};

// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if

  // set scroll to top
  window.scrollTo(0, 1);
  
  // Load list
  retrieveItemsList();
      
  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, []); // end useEffect 

const onPage = (event) => {	
	setlazyState(event);
};

const onSort = (event) => {
	setlazyState(event);
};

const onFilter = (event) => {
	event['first'] = 0;
	setlazyState(event);
};
// Datatable related controls END

// Reacts when lazyState changes
React.useEffect(() => {
	loadLazyData();
}, [lazyState]);
 
// Set Currency format
let EURO = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'EUR',
});

// Set calendar locale
addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});

// set Use of history for PUSH 
var navigate = useNavigate(); 


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanelObj(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

 const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  
/* -------------------- */ 
/* Common methods END */
/* -------------------- */

  
/* -------------------- */ 
/* SCRATCH				*/
/* -------------------- */
const stationsRepo = [
  {
    "id": 1,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Germany",
      "code": "DE",
      "coordinates": {
        "longitude": "10.451526",
        "latitude": "51.165691"
      }
    },
    "city": {
      "name": "Berlin",
      "code": "DE BER",
      "coordinates": {
        "longitude": "13.405",
        "latitude": "52.52"
      }
    },
    "station": {
      "name": "Berlin Hauptbahnhof - Germany",
      "coordinates": {
        "longitude": "13.369549",
        "latitude": "52.525589"
      }
    }
  },
  {
    "id": 2,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "France",
      "code": "FR",
      "coordinates": {
        "longitude": "2.213749",
        "latitude": "46.227638"
      }
    },
    "city": {
      "name": "Paris",
      "code": "FR PAR",
      "coordinates": {
        "longitude": "2.352222",
        "latitude": "48.856613"
      }
    },
    "station": {
      "name": "Paris Gare du Nord - France",
      "coordinates": {
        "longitude": "2.35484",
        "latitude": "48.880948"
      }
    }
  },
  {
    "id": 3,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Italy",
      "code": "IT",
      "coordinates": {
        "longitude": "12.56738",
        "latitude": "41.87194"
      }
    },
    "city": {
      "name": "Rome",
      "code": "IT ROM",
      "coordinates": {
        "longitude": "12.496366",
        "latitude": "41.902783"
      }
    },
    "station": {
      "name": "Roma Termini - Italy",
      "coordinates": {
        "longitude": "12.502156",
        "latitude": "41.900932"
      }
    }
  },
  {
    "id": 4,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Spain",
      "code": "ES",
      "coordinates": {
        "longitude": "-3.74922",
        "latitude": "40.463667"
      }
    },
    "city": {
      "name": "Madrid",
      "code": "ES MAD",
      "coordinates": {
        "longitude": "-3.70379",
        "latitude": "40.416775"
      }
    },
    "station": {
      "name": "Madrid Atocha - Spain",
      "coordinates": {
        "longitude": "-3.68991",
        "latitude": "40.4068"
      }
    }
  },
  {
    "id": 5,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "United Kingdom",
      "code": "GB",
      "coordinates": {
        "longitude": "-3.435973",
        "latitude": "55.378051"
      }
    },
    "city": {
      "name": "London",
      "code": "GB LON",
      "coordinates": {
        "longitude": "-0.127758",
        "latitude": "51.507351"
      }
    },
    "station": {
      "name": "London St Pancras International - United Kingdom",
      "coordinates": {
        "longitude": "-0.124625",
        "latitude": "51.531427"
      }
    }
  },
  {
    "id": 6,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Netherlands",
      "code": "NL",
      "coordinates": {
        "longitude": "5.291266",
        "latitude": "52.132633"
      }
    },
    "city": {
      "name": "Amsterdam",
      "code": "NL AMS",
      "coordinates": {
        "longitude": "4.904139",
        "latitude": "52.367575"
      }
    },
    "station": {
      "name": "Amsterdam Centraal - Netherlands",
      "coordinates": {
        "longitude": "4.900277",
        "latitude": "52.378901"
      }
    }
  },
  {
    "id": 7,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Belgium",
      "code": "BE",
      "coordinates": {
        "longitude": "4.469936",
        "latitude": "50.503887"
      }
    },
    "city": {
      "name": "Brussels",
      "code": "BE BRU",
      "coordinates": {
        "longitude": "4.35171",
        "latitude": "50.85034"
      }
    },
    "station": {
      "name": "Brussels-South - Belgium",
      "coordinates": {
        "longitude": "4.336531",
        "latitude": "50.835707"
      }
    }
  },
  {
    "id": 8,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Switzerland",
      "code": "CH",
      "coordinates": {
        "longitude": "8.227512",
        "latitude": "46.818188"
      }
    },
    "city": {
      "name": "Zurich",
      "code": "CH ZRH",
      "coordinates": {
        "longitude": "8.541694",
        "latitude": "47.376887"
      }
    },
    "station": {
      "name": "Zurich Hauptbahnhof - Switzerland",
      "coordinates": {
        "longitude": "8.540192",
        "latitude": "47.378177"
      }
    }
  },
  {
    "id": 9,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Sweden",
      "code": "SE",
      "coordinates": {
        "longitude": "18.643501",
        "latitude": "60.128161"
      }
    },
    "city": {
      "name": "Stockholm",
      "code": "SE STO",
      "coordinates": {
        "longitude": "18.068581",
        "latitude": "59.329323"
      }
    },
    "station": {
      "name": "Stockholm Central Station - Sweden",
      "coordinates": {
        "longitude": "18.059616",
        "latitude": "59.330622"
      }
    }
  },
  {
    "id": 10,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Russia",
      "code": "RU",
      "coordinates": {
        "longitude": "105.318756",
        "latitude": "61.524010"
      }
    },
    "city": {
      "name": "Moscow",
      "code": "RU MOS",
      "coordinates": {
        "longitude": "37.6173",
        "latitude": "55.7558"
      }
    },
    "station": {
      "name": "Moscow Kazansky Railway Station - Russia",
      "coordinates": {
        "longitude": "37.657898",
        "latitude": "55.773838"
      }
    }
  }
]


const countriesOnlyRepo = [
  {
    "id": 1,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Germany",
      "code": "DE",
      "coordinates": {
        "longitude": "10.451526",
        "latitude": "51.165691"
      }
    }
  },
  {
    "id": 2,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "France",
      "code": "FR",
      "coordinates": {
        "longitude": "2.213749",
        "latitude": "46.227638"
      }
    }
  },
  {
    "id": 3,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Italy",
      "code": "IT",
      "coordinates": {
        "longitude": "12.567380",
        "latitude": "41.871940"
      }
    }
  },
  {
    "id": 4,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "Spain",
      "code": "ES",
      "coordinates": {
        "longitude": "-3.749220",
        "latitude": "40.463667"
      }
    }
  },
  {
    "id": 5,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "United Kingdom",
      "code": "GB",
      "coordinates": {
        "longitude": "-3.435973",
        "latitude": "55.378051"
      }
    }
  },
  {
    "id": 6,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "Russia",
      "code": "RU",
      "coordinates": {
        "longitude": "105.318756",
        "latitude": "61.524010"
      }
    }
  },
  {
    "id": 7,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Netherlands",
      "code": "NL",
      "coordinates": {
        "longitude": "5.291266",
        "latitude": "52.132633"
      }
    }
  },
  {
    "id": 8,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "Belgium",
      "code": "BE",
      "coordinates": {
        "longitude": "4.469936",
        "latitude": "50.503887"
      }
    }
  },
  {
    "id": 9,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Switzerland",
      "code": "CH",
      "coordinates": {
        "longitude": "8.227512",
        "latitude": "46.818188"
      }
    }
  },
  {
    "id": 10,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "Sweden",
      "code": "SE",
      "coordinates": {
        "longitude": "18.643501",
        "latitude": "60.128161"
      }
    }
  },
  {
    "id": 11,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Norway",
      "code": "NO",
      "coordinates": {
        "longitude": "8.468946",
        "latitude": "60.472024"
      }
    }
  },
  {
    "id": 12,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "Poland",
      "code": "PL",
      "coordinates": {
        "longitude": "19.145136",
        "latitude": "51.919438"
      }
    }
  },
  {
    "id": 13,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Austria",
      "code": "AT",
      "coordinates": {
        "longitude": "14.550072",
        "latitude": "47.516231"
      }
    }
  },
  {
    "id": 14,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "Greece",
      "code": "GR",
      "coordinates": {
        "longitude": "21.824312",
        "latitude": "39.074208"
      }
    }
  },
  {
    "id": 15,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Denmark",
      "code": "DK",
      "coordinates": {
        "longitude": "9.501785",
        "latitude": "56.263920"
      }
    }
  }
]


const countriesRepo = [
  {
    "id": 1,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Germany",
      "code": "DE",
      "coordinates": {
        "longitude": "10.451526",
        "latitude": "51.165691"
      }
    },
    "city": {
      "name": "Berlin",
      "code": "DE BER",
      "coordinates": {
        "longitude": "13.404954",
        "latitude": "52.520006"
      }
    }
  },
  {
    "id": 2,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "Germany",
      "code": "DE",
      "coordinates": {
        "longitude": "10.451526",
        "latitude": "51.165691"
      }
    },
    "city": {
      "name": "Hamburg",
      "code": "DE HAM",
      "coordinates": {
        "longitude": "9.993682",
        "latitude": "53.551086"
      }
    }
  },
  {
    "id": 3,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "United Kingdom",
      "code": "GB",
      "coordinates": {
        "longitude": "-3.435973",
        "latitude": "55.378051"
      }
    },
    "city": {
      "name": "London",
      "code": "GB LON",
      "coordinates": {
        "longitude": "-0.127758",
        "latitude": "51.507351"
      }
    }
  },
  {
    "id": 4,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "United Kingdom",
      "code": "GB",
      "coordinates": {
        "longitude": "-3.435973",
        "latitude": "55.378051"
      }
    },
    "city": {
      "name": "Manchester",
      "code": "GB MAN",
      "coordinates": {
        "longitude": "-2.242631",
        "latitude": "53.480759"
      }
    }
  },
  {
    "id": 5,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "France",
      "code": "FR",
      "coordinates": {
        "longitude": "2.213749",
        "latitude": "46.227638"
      }
    },
    "city": {
      "name": "Paris",
      "code": "FR PAR",
      "coordinates": {
        "longitude": "2.352222",
        "latitude": "48.856613"
      }
    }
  },
  {
    "id": 6,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "France",
      "code": "FR",
      "coordinates": {
        "longitude": "2.213749",
        "latitude": "46.227638"
      }
    },
    "city": {
      "name": "Marseille",
      "code": "FR MAR",
      "coordinates": {
        "longitude": "5.369780",
        "latitude": "43.296482"
      }
    }
  },
  {
    "id": 7,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Italy",
      "code": "IT",
      "coordinates": {
        "longitude": "12.567380",
        "latitude": "41.871940"
      }
    },
    "city": {
      "name": "Rome",
      "code": "IT ROM",
      "coordinates": {
        "longitude": "12.496365",
        "latitude": "41.902783"
      }
    }
  },
  {
    "id": 8,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "Italy",
      "code": "IT",
      "coordinates": {
        "longitude": "12.567380",
        "latitude": "41.871940"
      }
    },
    "city": {
      "name": "Milan",
      "code": "IT MIL",
      "coordinates": {
        "longitude": "9.185924",
        "latitude": "45.465422"
      }
    }
  },
  {
    "id": 9,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Spain",
      "code": "ES",
      "coordinates": {
        "longitude": "-3.749220",
        "latitude": "40.463667"
      }
    },
    "city": {
      "name": "Madrid",
      "code": "ES MAD",
      "coordinates": {
        "longitude": "-3.703790",
        "latitude": "40.416775"
      }
    }
  },
  {
    "id": 10,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "Spain",
      "code": "ES",
      "coordinates": {
        "longitude": "-3.749220",
        "latitude": "40.463667"
      }
    },
    "city": {
      "name": "Barcelona",
      "code": "ES BCN",
      "coordinates": {
        "longitude": "2.173404",
        "latitude": "41.385064"
      }
    }
  },
  {
    "id": 11,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Russia",
      "code": "RU",
      "coordinates": {
        "longitude": "105.318756",
        "latitude": "61.524010"
      }
    },
    "city": {
      "name": "Moscow",
      "code": "RU MOS",
      "coordinates": {
        "longitude": "37.617299",
        "latitude": "55.755826"
      }
    }
  },
  {
    "id": 12,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "Russia",
      "code": "RU",
      "coordinates": {
        "longitude": "105.318756",
        "latitude": "61.524010"
      }
    },
    "city": {
      "name": "Saint Petersburg",
      "code": "RU SPB",
      "coordinates": {
        "longitude": "30.335098",
        "latitude": "59.934280"
      }
    }
  },
  {
    "id": 13,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Netherlands",
      "code": "NL",
      "coordinates": {
        "longitude": "5.291266",
        "latitude": "52.132633"
      }
    },
    "city": {
      "name": "Amsterdam",
      "code": "NL AMS",
      "coordinates": {
        "longitude": "4.904139",
        "latitude": "52.367575"
      }
    }
  },
  {
    "id": 14,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "Netherlands",
      "code": "NL",
      "coordinates": {
        "longitude": "5.291266",
        "latitude": "52.132633"
      }
    },
    "city": {
      "name": "Rotterdam",
      "code": "NL RTM",
      "coordinates": {
        "longitude": "4.477733",
        "latitude": "51.924420"
      }
    }
  },
  {
    "id": 15,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Switzerland",
      "code": "CH",
      "coordinates": {
        "longitude": "8.227512",
        "latitude": "46.818188"
      }
    },
    "city": {
      "name": "Zurich",
      "code": "CH ZRH",
      "coordinates": {
        "longitude": "8.541694",
        "latitude": "47.376886"
      }
    }
  },
  {
    "id": 16,
    "status": 0,
    "continent": "Europe",
    "country": {
      "name": "Switzerland",
      "code": "CH",
      "coordinates": {
        "longitude": "8.227512",
        "latitude": "46.818188"
      }
    },
    "city": {
      "name": "Geneva",
      "code": "CH GVA",
      "coordinates": {
        "longitude": "6.143157",
        "latitude": "46.204391"
      }
    }
  },
  {
    "id": 17,
    "status": 1,
    "continent": "Europe",
    "country": {
      "name": "Sweden",
      "code": "SE",
      "coordinates": {
        "longitude": "18.643501",
        "latitude": "60.128161"
      }
    },
    "city": {
      "name": "Stockholm",
      "code": "SE STO",
      "coordinates": {
        "longitude": "18.068581",
        "latitude": "59.329323"
      }
    }
  }]
  
  
    const countryBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt="flag" src={`https://flagcdn.com/16x12/${rowData.country.code.toLowerCase()}.png`} className={`flag flag-${rowData.country.code.toLowerCase()}`} style={{ width: '24px',marginRight:"5px" }} />
                <span>{rowData.country.name}</span>
            </div>
        );
    };
	
    const cityBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.city.name}</span>
            </div>
        );
    };	
	
    const stationBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.station.name}</span> <br />
				<span><Button label="ver no mapa"  icon="pi pi-map" size="small" text outlined style={{marginTop:"10px"}} /></span>
            </div>
        );
    };	

   const statusBodyTemplate = (rowData) => {
	   
	   if (rowData.status === 0) {
		   var label = "Habilitado";
		   var severity = "success";
	   } else {
		   var label = "Não habilitado";
		   var severity = "danger";
	   } 
	   
        return <Tag value={label} severity={severity} />;
    };
 
     const getSeverity = (status) => {
        switch (status) {
            case '0':
                return 'warning';

            case '1':
                return 'success';
        }
    };
	
   const statusFilterTemplate = (options) => {
        return (
            <Dropdown optionLabel="name" optionValue="code" options={statuses} value={selectedStatus} onChange={(e) => {setSelectedStatus(e.value);options.filterCallback(e.value)}} itemTemplate={statusItemTemplate} placeholder="Selecione" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );		
    };	
	
   const statusItemTemplate = (options) => {
			  
	  if (options.code === "0") {
		   var label = "Habilitado";
		   var severity = "success";
	   } else {
		   var label = "Não habilitado";
		   var severity = "danger";
	   } 		
        return <Tag value={label} severity={severity} />;
    };	

    const statuses = [
        { name: 'Habilitado', code: "0" },
        { name: 'Não habilitado', code: "1" }
    ]; 
	
	const [selectedStatus, setSelectedStatus] = React.useState(null);
	
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left" style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",alignItems:"center",gap:"20px"}}>
                    <Button outlined size="small" severity="danger" label="Desabilitar selecionados" icon="pi pi-times" loading={saving} onClick={() => console.log("UPDATE")} />			
                    <Button outlined  size="small" severity="success" label="Habilitar selecionados" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
				</span>				
            </div>
        );
    };	
	 const header = renderHeader();
 
  return (
	<>
	<div id="adminSection" style={{marginTop:"0"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
		<div style={{height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
			<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
						
				<div style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"flex-start",gap:"10px",padding:"40px",marginTop:"0px",height:"auto",marginLeft:"auto",marginRight:"auto",width:"100%",border:"0px solid red"}}>
				
						{/* Admin Side Menu */}
						<SideMenu state="open" />
						
						{/* Admin Content */}
						<div style={{flex:1,height:"100%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
						
							<div style={{height:"64px",width:"100%",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
							
								<div style={{textAlign:"left",flex:2,color: '#2E0249', fontFamily: 'Titillium Web', fontSize: '36px', fontStyle: 'normal', fontWeight: 600, lineHeight: '120%', letterSpacing: '3.6px',padding:"10px"}}>Configurações</div>
								
								<div style={{flex:5,display:"flex",flexDirection:"row",justifyContent:"end",alignItems:"center"}}>
								
							
								
								</div>
							
							</div>
							
							
							<div style={{flex:1,width:"100%",backgroundColor:"#FFF"}}>
							
								<div className="card" style={{minHeight:"66vh",zIndex: 8,position:"relative"}}>
								
											<Box
											  sx={{
												width: "100%",
												padding:"50px"
											  }}
											>
											
											<TabView scrollable>

												<TabPanel header="Parâmetros do Sistema">

													<div style={{padding:"20px",width:"70%"}}>

														<Panel header="Configurações Gerais" style={{textAlign:"left",marginBottom:"20px"}} toggleable>
														
																														
															<Grid container spacing={5}>
	
	
															<Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Distância máxima Trem</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Limite distância (km) 
																</small>	
															  </Grid>
															  
															<Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Conexões máximas Trem</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Limite conexões 
																</small>	
															  </Grid>															  
															  
															<Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Distância máxima Ônibus</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Limite distância (km) 
																</small>	
															  </Grid>
															  
															<Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Conexões máximas Ônibus</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Limite conexões 
																</small>	
															  </Grid>																  
	
															<Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Distância máxima Transfer</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Limite distância (km) 
																</small>	
															  </Grid>
															  
	
															<Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Distância máxima Carro</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Limite distância (km)  
																</small>	
															  </Grid>
															  															  
															  
																  <Grid item xs={12} sm={3}>
																	<label htmlFor="minComission">Teto Comissão (%)</label>
																	<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																	<small id="username-help">
																		Teto Comissão global default
																	</small>	
																  </Grid>															  
																		
															</Grid>	

															
															<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																<div className="modalFooterButtonsRight">
																	<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																</div>
																
															</div>																
															

														</Panel>
														
														<Panel header="Regras de Interface" style={{marginBottom:"20px"}} toggleable collapsed={true}>

															<Grid container spacing={5} style={{marginTop:"-20px"}}>

														
															  <Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Qtde resultados Trem</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Limite lista inicial
																</small>	
															  </Grid>
															  
															  <Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Qtde resultados Ônibus</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Limite lista inicial
																</small>	
															  </Grid>

															  <Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Qtde resultados Transfer</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Limite lista inicial
																</small>	
															  </Grid>

															  <Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Qtde resultados Carro</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Limite lista inicial
																</small>	
															  </Grid>															  
								  
															  <Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Tempo Trem e Ônibus (min)</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Tempo de validade da busca
																</small>	
															  </Grid>
															  
															  <Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Tempo Transfer (min)</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Tempo de validade da busca
																</small>	
															  </Grid>
															  
															  <Grid item xs={12} sm={3}>
																<label htmlFor="minComission">Tempo Aluguel Carro (min)</label>
																<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																<small id="username-help">
																	Tempo de validade da busca
																</small>	
															  </Grid>
															  
															  
															</Grid>
															
															<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																<div className="modalFooterButtonsRight">
																	<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																</div>
																
															</div>																

														</Panel>	

														<Panel header="Países e cidades habilitados" style={{marginBottom:"20px"}} toggleable collapsed={true}>
										
															<div style={{padding:"15px"}}>
										
																<DataTable  header={header} value={countriesRepo} selection={selectedPlaces} onSelectionChange={(e) => setSelectedPlaces(e.value)} paginator rows={15} filterDisplay="row" 
																selectionMode='checkbox'
																emptyMessage="Nenhum país / cidade encontrado">

																	<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>

																	<Column header="País" filterField="country.name" style={{ minWidth: '12rem' }} body={countryBodyTemplate} filter filterPlaceholder="Filtre por país" />
																	
																	<Column header="Cidade" filterField="city.name"  filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }}
																		body={cityBodyTemplate}
																		filter filterPlaceholder="Filtre por cidade"  />
																		
																	<Column field="status" header="Status" filter filterElement={statusFilterTemplate} dataType="boolean" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} showFilterMatchModes={false} body={statusBodyTemplate} />
																	
																</DataTable>

															</div>																
								
														</Panel>															
													

													</div>

												</TabPanel>
												
												<TabPanel header="Notificações">

													<div style={{padding:"20px",width:"70%"}}>
													
														<Panel header="Configurações" style={{marginBottom:"20px"}}>

															<Grid container spacing={5} style={{marginTop:"-20px"}}>

														
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">Vinculado a agência</label>
																</span>		
															  </Grid>
														
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="peronName">Nome completo</label>
																</span>		
															  </Grid>
															  
															<Grid item xs={12} sm={6} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
																<div className="p-float-label w100">
																	<InputText id="personDocument" autoComplete="new-password"   value="" className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																	<label htmlFor="personDocument">CPF</label>
																</div>
															 </Grid>												  
															  
															<Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="email" autoComplete="new-password"  value="" className="w100" />
																	<label htmlFor="email">E-mail</label>
																</span>		
															  </Grid>												  
															  
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="personPhone" autoComplete="new-password" disabled={saving ? true : false} value="" className="w100" />
																	<label htmlFor="personPhone">Telefone Celular</label>
																</span>	
															  </Grid>

															<Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="email" autoComplete="new-password"  value="" className="w100" />
																	<label htmlFor="email">Perfil</label>
																</span>		
															  </Grid>	
															  
															<Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="email" autoComplete="new-password"  value="" className="w100" />
																	<label htmlFor="email">Status</label>
																</span>		
															  </Grid>													  
																											  
															  
															</Grid>

														</Panel>
														
																										
														<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

															<div className="modalFooterButtonsRight">
																<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
															</div>
															
														</div>															
													
													</div>

												</TabPanel>

												<TabPanel header="Perfis de usuário">

													<div style={{padding:"20px",width:"70%"}}>
													
														<Panel header="Grupos e direitos" style={{marginBottom:"20px"}}>

															<Grid container spacing={5} style={{marginTop:"-20px"}}>

															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">Vinculado a agência</label>
																</span>		
															  </Grid>
														
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="peronName">Nome completo</label>
																</span>		
															  </Grid>
															  
															<Grid item xs={12} sm={6} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
																<div className="p-float-label w100">
																	<InputText id="personDocument" autoComplete="new-password"   value="" className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																	<label htmlFor="personDocument">CPF</label>
																</div>
															 </Grid>												  
															  
															<Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="email" autoComplete="new-password"  value="" className="w100" />
																	<label htmlFor="email">E-mail</label>
																</span>		
															  </Grid>												  
															  
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="personPhone" autoComplete="new-password" disabled={saving ? true : false} value="" className="w100" />
																	<label htmlFor="personPhone">Telefone Celular</label>
																</span>	
															  </Grid>

															<Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="email" autoComplete="new-password"  value="" className="w100" />
																	<label htmlFor="email">Perfil</label>
																</span>		
															  </Grid>	
															  
															<Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="email" autoComplete="new-password"  value="" className="w100" />
																	<label htmlFor="email">Status</label>
																</span>		
															  </Grid>													  
																											  
															  
															</Grid>

														</Panel>
													
																										
														<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

															<div className="modalFooterButtonsRight">
																<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
															</div>
															
														</div>														
													
													</div>

												</TabPanel>	
												

												<TabPanel header="Perfis de comissão">

													<div style={{padding:"20px",width:"70%",marginLeft:"auto",marginRight:"auto"}}>
													
																<Panel header={(<div>Perfis ativos</div>)} style={{marginBottom:"20px",textAlign:"left",maxWidth:"100%"}} toggleable >
																	
																	<div style={{display:"flex",flexDirection:"column",gap:"20px"}}>

																		<Fieldset legend="Perfil 1 (Padrão)" toggleable collapsed={true}>
																																																				<Divider align="center" style={{margin:"unset"}}>
																				<span className="p-tag" style={{backgroundColor:"gray"}}>Comissão todos serviços</span>
																			</Divider>			
																		
																			<Grid container spacing={5} style={{marginTop:"-20px"}}>
																	
																			  <Grid item xs={12} sm={6}>
																				
																					<label htmlFor="minComission">Comissão (%)</label>
																					<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																					<small id="username-help">
																						Pagamento Cartão de crédito
																					</small>								
																					
																			  </Grid>

																			  <Grid item xs={12} sm={6}>
																				
																					<label htmlFor="minComission">Comissão (%)</label>
																					<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																					<small id="username-help">
																						Pagamento PIX
																					</small>								
																					
																			  </Grid>
																								  
																			</Grid>
																			
																			<div className="modalFooterButtons" style={{marginTop:"30px",textAlign:"right",justifyContent:"flex-end",gap:"15px"}}>

																				<div >
																					<Button text label="Histórico de alterações" icon="pi pi-list" loading={saving} onClick={() => console.log("UPDATE")} />
																				</div>

																				<div >
																					<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																				</div>
																				
																			</div>			

																		</Fieldset>

																		<Fieldset legend="Perfil 2 (Agências Prata)" toggleable collapsed={true}>
																		
																			<Divider align="center" style={{margin:"unset"}}>
																				<span className="p-tag" style={{backgroundColor:"gray"}}>Comissão todos serviços</span>
																			</Divider>			
																		
																			<Grid container spacing={5} style={{marginTop:"-20px"}}>
																	
																			  <Grid item xs={12} sm={6}>
																				
																					<label htmlFor="minComission">Comissão (%)</label>
																					<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																					<small id="username-help">
																						Pagamento Cartão de crédito
																					</small>								
																					
																			  </Grid>

																			  <Grid item xs={12} sm={6}>
																				
																					<label htmlFor="minComission">Comissão (%)</label>
																					<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																					<small id="username-help">
																						Pagamento PIX
																					</small>								
																					
																			  </Grid>
																								  
																			</Grid>

																			<Panel headerTemplate={(options) => {return (<Message severity="info" text="Abrir comissão por tipo de serviço" style={{width:"100%",cursor:"pointer"}}  onClick={options.onTogglerClick} />)}} toggleable collapsed={true} className="cardPanel" style={{marginTop:"2rem"}}>
																				
																				<Divider align="center" style={{margin:"unset",marginTop:"1.5rem"}}>
																					<span className="p-tag">Comissão TREM</span>
																				</Divider>			
																			
																				<Grid container spacing={5} style={{marginTop:"-20px"}}>
																		
																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento Cartão de crédito
																						</small>								
																						
																				  </Grid>

																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento PIX
																						</small>								
																						
																				  </Grid>
																									  
																				</Grid>

																				<Divider align="center" style={{margin:"unset",marginTop:"1.5rem"}}>
																					<span className="p-tag">Comissão ÔNIBUS</span>
																				</Divider>			
																			
																				<Grid container spacing={5} style={{marginTop:"-20px"}}>
																		
																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento Cartão de crédito
																						</small>								
																						
																				  </Grid>

																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento PIX
																						</small>								
																						
																				  </Grid>
																									  
																				</Grid>

																				<Divider align="center" style={{margin:"unset",marginTop:"1.5rem"}}>
																					<span className="p-tag">Comissão TRANSFER</span>
																				</Divider>			
																			
																				<Grid container spacing={5} style={{marginTop:"-20px"}}>
																		
																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento Cartão de crédito
																						</small>								
																						
																				  </Grid>

																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento PIX
																						</small>								
																						
																				  </Grid>
																									  
																				</Grid>																
																				<Divider align="center" style={{margin:"unset",marginTop:"1.5rem"}}>
																					<span className="p-tag">Comissão ALUGUEL CARRO</span>
																				</Divider>			
																			
																				<Grid container spacing={5} style={{marginTop:"-20px"}}>
																		
																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento Cartão de crédito
																						</small>								
																						
																				  </Grid>

																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento PIX
																						</small>								
																						
																				  </Grid>
																									  
																				</Grid>

																			</Panel>

																			<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right",justifyContent:"flex-end",gap:"15px"}}>

																				<div >
																					<Button text label="Histórico de alterações" icon="pi pi-list" loading={saving} onClick={() => console.log("UPDATE")} />
																				</div>

																				<div >
																					<Button severity="danger" label="Excluir" icon="pi pi-times" loading={saving} onClick={() => console.log("EXCLUDE")} />
																				</div>

																				<div >
																					<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																				</div>
																				
																			</div>					
																			
																		</Fieldset>															
																		<Fieldset legend="Perfil 3 (Agências Platinum)" toggleable collapsed={true}>

																			<Divider align="center" style={{margin:"unset"}}>
																				<span className="p-tag" style={{backgroundColor:"gray"}}>Comissão todos serviços</span>
																			</Divider>			
																		
																			<Grid container spacing={5} style={{marginTop:"-20px"}}>
																	
																			  <Grid item xs={12} sm={6}>
																				
																					<label htmlFor="minComission">Comissão (%)</label>
																					<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																					<small id="username-help">
																						Pagamento Cartão de crédito
																					</small>								
																					
																			  </Grid>

																			  <Grid item xs={12} sm={6}>
																				
																					<label htmlFor="minComission">Comissão (%)</label>
																					<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																					<small id="username-help">
																						Pagamento PIX
																					</small>								
																					
																			  </Grid>
																								  
																			</Grid>

																			<Panel headerTemplate={(options) => {return (<Message severity="info" text="Abrir comissão por tipo de serviço" style={{width:"100%",cursor:"pointer"}}  onClick={options.onTogglerClick} />)}} toggleable collapsed={true} className="cardPanel" style={{marginTop:"2rem"}}>
																				
																				<Divider align="center" style={{margin:"unset",marginTop:"1.5rem"}}>
																					<span className="p-tag">Comissão TREM</span>
																				</Divider>			
																			
																				<Grid container spacing={5} style={{marginTop:"-20px"}}>
																		
																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento Cartão de crédito
																						</small>								
																						
																				  </Grid>

																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento PIX
																						</small>								
																						
																				  </Grid>
																									  
																				</Grid>

																				<Divider align="center" style={{margin:"unset",marginTop:"1.5rem"}}>
																					<span className="p-tag">Comissão ÔNIBUS</span>
																				</Divider>			
																			
																				<Grid container spacing={5} style={{marginTop:"-20px"}}>
																		
																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento Cartão de crédito
																						</small>								
																						
																				  </Grid>

																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento PIX
																						</small>								
																						
																				  </Grid>
																									  
																				</Grid>

																				<Divider align="center" style={{margin:"unset",marginTop:"1.5rem"}}>
																					<span className="p-tag">Comissão TRANSFER</span>
																				</Divider>			
																			
																				<Grid container spacing={5} style={{marginTop:"-20px"}}>
																		
																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento Cartão de crédito
																						</small>								
																						
																				  </Grid>

																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento PIX
																						</small>								
																						
																				  </Grid>
																									  
																				</Grid>																
																				<Divider align="center" style={{margin:"unset",marginTop:"1.5rem"}}>
																					<span className="p-tag">Comissão ALUGUEL CARRO</span>
																				</Divider>			
																			
																				<Grid container spacing={5} style={{marginTop:"-20px"}}>
																		
																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento Cartão de crédito
																						</small>								
																						
																				  </Grid>

																				  <Grid item xs={12} sm={6}>
																					
																						<label htmlFor="minComission">Comissão (%)</label>
																						<InputText id="minComission" aria-describedby="username-help" style={{width:"100%"}} />
																						<small id="username-help">
																							Pagamento PIX
																						</small>								
																						
																				  </Grid>
																									  
																				</Grid>

																			</Panel>

																			<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right",justifyContent:"flex-end",gap:"15px"}}>

																						<div >
																							<Button text label="Histórico de alterações" icon="pi pi-list" loading={saving} onClick={() => console.log("UPDATE")} />
																						</div>

																						<div >
																							<Button severity="danger" label="Excluir" icon="pi pi-times" loading={saving} onClick={() => console.log("EXCLUDE")} />
																						</div>

																						<div >
																							<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																						</div>
																						
																			</div>	
																	
																		</Fieldset>
																		
																	  </div>

																		<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right",justifyContent:"flex-end",gap:"15px"}}>

																					<div >
																						<Button label="Criar novo perfil" icon="pi pi-check" severity="info" loading={saving} onClick={() => console.log("UPDATE")} />
																					</div>
																					
																		</div>	

																	</Panel>														
																						
																	
																	
																	<Panel header={(<div>Ferramentas</div>)} style={{marginBottom:"20px",textAlign:"left",maxWidth:"100%"}} toggleable >
																	
																	<div style={{display:"flex",flexDirection:"column",gap:"20px"}}>
															
																		<Fieldset legend="Migração de Perfis" toggleable collapsed={false}>

																			<Divider align="center" style={{margin:"unset"}}>
																				<span className="p-tag" style={{backgroundColor:"gray"}}>Selecione abaixo</span>
																			</Divider>			
																		
																			<Grid container spacing={5} style={{marginTop:"-20px"}}>
																	
																			  <Grid item xs={12} sm={6}>
																				
																					<label htmlFor="minComission">Grupo da Agência</label>
																					<Dropdown  onChange={(e) => setFilterByPaymentMethod(e.target.value)} options={[{"code":1,name:"Grupo 1"},{"code":2,name:"Grupo 2"}]}  optionLabel="name"  className="w100"></Dropdown>
																					<small id="username-help">
																						Grupo origem
																					</small>								
																					
																			  </Grid>

																			  <Grid item xs={12} sm={6}>
																				
																					<label htmlFor="minComission">Perfil de Comissão</label>
																					<Dropdown  onChange={(e) => setFilterByPaymentMethod(e.target.value)} options={[{"code":1,name:"Perfil 1"},{"code":2,name:"Perfil 2"}]} optionLabel="name"  className="w100"></Dropdown>
																					<small id="username-help">
																						Perfil destino
																					</small>								
																					
																			  </Grid>
																								  
																			</Grid>


																			<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right",justifyContent:"flex-end",gap:"15px"}}>

																						<div >
																							<Button label="Migrar" icon="pi pi-check" severity="danger" loading={saving} onClick={() => console.log("UPDATE")} />
																						</div>
																						
																			</div>	
																	
																		</Fieldset>
																		
																	  </div>

																	</Panel>																	
													
													</div>

												</TabPanel>													

												<TabPanel header="Provedores de serviço">

													<div style={{padding:"20px",width:"100%"}}>

													<Box
													  sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: "auto" }}
													>													
													
															<Tabs
																orientation="vertical"
																variant="scrollable"
																value={value}
																onChange={handleChange}
																aria-label="Vertical tabs example"
																sx={{ borderRight: 1, borderColor: 'divider',overflow:"unset",minWidth:"200px" }}
															  >
																<Tab label={(<div>Railhub  <img src={titleTrainIcon} style={{width:"20px",height:"auto",marginLeft:"15px"}} className="noSelect" /></div>)} className="verticalTabLabel" {...a11yProps(0)} />
																<Tab label={(<div>Omio  <img src={titleTrainIcon} style={{width:"20px",height:"auto",marginLeft:"15px"}} className="noSelect" /></div>)} className="verticalTabLabel" {...a11yProps(1)} />
																<Tab label={(<div>FlixBus  <img src={titleBusIcon} style={{width:"20px",height:"auto",marginLeft:"15px"}} className="noSelect" /></div>)} className="verticalTabLabel" {...a11yProps(2)} />
																<Tab label={(<div>Mozio  <img src={titleTrainIcon} style={{width:"20px",height:"auto",marginLeft:"15px"}} className="noSelect" /></div>)} className="verticalTabLabel" {...a11yProps(3)} />
																<Tab label={(<div>Europcar  <img src={titleTrainIcon} style={{width:"20px",height:"auto",marginLeft:"15px"}} className="noSelect" /></div>)} className="verticalTabLabel" {...a11yProps(4)} />
															  </Tabs>
															  
															  <TabPanelObj value={value} index={0} style={{width:"100%"}}>

																	<Panel header={(<div>Configurações API</div>)} style={{marginBottom:"20px",maxWidth:"70%"}} toggleable>

																		<Grid container spacing={5} style={{marginTop:"-20px"}}>

																		  <Grid item xs={12} sm={6}>
																			<span className="p-float-label" style={{width:"100%",textAlign:"left"}}>
																				<Dropdown  value={filterByPaymentMethod} onChange={(e) => setFilterByPaymentMethod(e.target.value)} options={[{"code":1,name:"Ativo"},{"code":2,name:"Inativo"}]} value={{"code":1,name:"Ativo"}} optionLabel="name"  className="w100"></Dropdown>
																				<label htmlFor="agency">Status serviço</label>
																			</span>		
																		  </Grid>
																	
																		  <Grid item xs={12} sm={6}>
																			<span className="p-float-label" >
																				<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																				<label htmlFor="peronName">Client ID</label>
																			</span>		
																		  </Grid>
																		  
																		 <Grid item xs={12} sm={6}>
																			<div className="p-float-label">
																				<InputText id="personDocument" autoComplete="new-password"   value="" className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">Password</label>
																			</div>
																		 </Grid>	
																		 
																		 <Grid item xs={12} sm={6}>
																			<div className="p-float-label">
																				<InputText id="personDocument" autoComplete="new-password"   value="" className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">Partner Code</label>
																			</div>
																		 </Grid>

																							  
																		</Grid>
																			
																		<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																			<div className="modalFooterButtonsRight">
																				<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																			</div>
																			
																		</div>

																	</Panel>
																	

																	<Panel header="Países / Cidades / Estações habilitadas" style={{marginBottom:"20px",maxWidth:"90%"}} toggleable collapsed={false}>
										
																		<div style={{padding:"15px"}}>
													
																			<DataTable  header={header} value={stationsRepo} selection={selectedPlaces} onSelectionChange={(e) => setSelectedPlaces(e.value)} paginator rows={15} filterDisplay="row" 
																			selectionMode='checkbox'
																			emptyMessage="Nenhum país / cidade encontrado">

																				<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>

																				<Column header="País" filterField="country.name"  body={countryBodyTemplate} filter showFilterMatchModes={false} showFilterMenu={false} filterPlaceholder="Filtre por país" />
																				
																				
																				<Column header="Cidade" filterField="city.name" body={cityBodyTemplate} filter showFilterMatchModes={false} showFilterMenu={false} filterPlaceholder="Filtre por cidade" />
																				
																				
																				<Column header="Estação" filterField="station.name"  body={stationBodyTemplate} filter showFilterMatchModes={false} showFilterMenu={false} filterPlaceholder="Filtre por estação" />
																					
																					
																					
																				<Column field="status" header="Status" filter filterElement={statusFilterTemplate} dataType="boolean" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} showFilterMatchModes={false} body={statusBodyTemplate} />
																				
																			</DataTable>

																		</div>															
																	</Panel>																	
																	
															  </TabPanelObj>
															  <TabPanelObj value={value} index={1} style={{width:"100%"}}>

																	<Panel header={(<div>Configurações API</div>)} style={{marginBottom:"20px",maxWidth:"70%"}} toggleable>

																		<Grid container spacing={5} style={{marginTop:"-20px"}}>

																		  <Grid item xs={12} sm={6}>
																			<span className="p-float-label" style={{width:"100%",textAlign:"left"}}>
																				<Dropdown  value={filterByPaymentMethod} onChange={(e) => setFilterByPaymentMethod(e.target.value)} options={[{"code":1,name:"Ativo"},{"code":2,name:"Inativo"}]} value={{"code":1,name:"Ativo"}} optionLabel="name"  className="w100"></Dropdown>
																				<label htmlFor="agency">Status serviço</label>
																			</span>		
																		  </Grid>
																																																			  <Grid item xs={12} sm={6}>
																			<span className="p-float-label" >
																				<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																				<label htmlFor="peronName">Username</label>
																			</span>		
																		  </Grid>
																		  
																		 <Grid item xs={12} sm={6}>
																			<div className="p-float-label">
																				<InputText id="personDocument" autoComplete="new-password"   value="" className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">Password</label>
																			</div>
																		 </Grid>	
																		 
																		 <Grid item xs={12} sm={6}>
																			<div className="p-float-label">
																				<InputText id="personDocument" autoComplete="new-password"   value="" className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">Token</label>
																			</div>
																		 </Grid>
																		 
																		</Grid>
																		
																		<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																			<div className="modalFooterButtonsRight">
																				<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																			</div>
																			
																		</div>																		
																	</Panel>
																																		
																																																		<Panel header="Países / Cidades / Estações habilitadas" style={{marginBottom:"20px",maxWidth:"90%"}} toggleable collapsed={false}>
										
																		<div style={{padding:"15px"}}>
													
																			<DataTable  header={header} value={stationsRepo} selection={selectedPlaces} onSelectionChange={(e) => setSelectedPlaces(e.value)} paginator rows={15} filterDisplay="row" 
																			selectionMode='checkbox'
																			emptyMessage="Nenhum país / cidade encontrado">

																				<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>

																				<Column header="País" filterField="country.name"  body={countryBodyTemplate} filter showFilterMatchModes={false} showFilterMenu={false} filterPlaceholder="Filtre por país" />
																				
																				
																				<Column header="Cidade" filterField="city.name" body={cityBodyTemplate} filter showFilterMenu={false} showFilterMatchModes={false} filterPlaceholder="Filtre por cidade" />
																				
																				
																				<Column header="Estação" filterField="station.name"  body={stationBodyTemplate} filter showFilterMenu={false} showFilterMatchModes={false} filterPlaceholder="Filtre por estação" />
																					
																					
																					
																				<Column field="status" header="Status" filter filterElement={statusFilterTemplate} dataType="boolean" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} showFilterMatchModes={false} body={statusBodyTemplate} />
																				
																			</DataTable>

																		</div>															
																	</Panel>
																	

															  </TabPanelObj>
															  <TabPanelObj value={value} index={2} style={{width:"100%"}}>

																		<Panel header={(<div>Configurações API</div>)} style={{marginBottom:"20px",maxWidth:"70%"}} toggleable>

																		<Grid container spacing={5} style={{marginTop:"-20px"}}>

																		  <Grid item xs={12} sm={6}>
																			<span className="p-float-label" style={{width:"100%",textAlign:"left"}}>
																				<Dropdown  value={filterByPaymentMethod} onChange={(e) => setFilterByPaymentMethod(e.target.value)} options={[{"code":1,name:"Ativo"},{"code":2,name:"Inativo"}]} value={{"code":1,name:"Ativo"}} optionLabel="name"  className="w100"></Dropdown>
																				<label htmlFor="agency">Status serviço</label>
																			</span>		
																		  </Grid>
																		  
																		  <Grid item xs={12} sm={6}>
																			<span className="p-float-label" >
																				<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																				<label htmlFor="peronName">Username</label>
																			</span>		
																		  </Grid>
																		  
																		 <Grid item xs={12} sm={6}>
																			<div className="p-float-label">
																				<InputText id="personDocument" autoComplete="new-password"   value="" className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">Password</label>
																			</div>
																		 </Grid>	
																		 
																		 <Grid item xs={12} sm={6}>
																			<div className="p-float-label ">
																				<InputText id="personDocument" autoComplete="new-password"   value="" className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">Token</label>
																			</div>
																		 </Grid>			
																	
																		</Grid>

																		<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																			<div className="modalFooterButtonsRight">
																				<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																			</div>
																			
																		</div>																		
																	</Panel>
																	
																	<Panel header="Países / Cidades / Estações habilitadas" style={{marginBottom:"20px",maxWidth:"90%"}} toggleable collapsed={false}>
										
																		<div style={{padding:"15px"}}>
													
																			<DataTable  header={header} value={stationsRepo} selection={selectedPlaces} onSelectionChange={(e) => setSelectedPlaces(e.value)} paginator rows={15} filterDisplay="row" 
																			selectionMode='checkbox'
																			emptyMessage="Nenhum país / cidade encontrado">

																				<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>

																				<Column header="País" filterField="country.name"  body={countryBodyTemplate} filter showFilterMenu={false} showFilterMatchModes={false} filterPlaceholder="Filtre por país" />
																				
																				
																				<Column header="Cidade" filterField="city.name" body={cityBodyTemplate} filter showFilterMenu={false} showFilterMatchModes={false} filterPlaceholder="Filtre por cidade" />
																				
																				
																				<Column header="Estação" filterField="station.name"  body={stationBodyTemplate} showFilterMenu={false} showFilterMatchModes={false} filter filterPlaceholder="Filtre por estação" />
																					
																					
																					
																				<Column field="status" header="Status" filter filterElement={statusFilterTemplate} dataType="boolean" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} showFilterMatchModes={false} body={statusBodyTemplate} />
																				
																			</DataTable>

																		</div>															
																	</Panel>

																	
															  </TabPanelObj>
															  <TabPanelObj value={value} index={3} style={{width:"100%"}}>

																	<Panel header={(<div>Configurações API</div>)} style={{marginBottom:"20px",maxWidth:"70%"}} toggleable>

																		<Grid container spacing={5} style={{marginTop:"-20px"}}>

																		  <Grid item xs={12} sm={6}>
																			<span className="p-float-label" style={{width:"100%",textAlign:"left"}}>
																				<Dropdown  value={filterByPaymentMethod} onChange={(e) => setFilterByPaymentMethod(e.target.value)} options={[{"code":1,name:"Ativo"},{"code":2,name:"Inativo"}]} value={{"code":1,name:"Ativo"}} optionLabel="name"  className="w100"></Dropdown>
																				<label htmlFor="agency">Status serviço</label>
																			</span>		
																		  </Grid>
																																																																																				  <Grid item xs={12} sm={6}>
																			<span className="p-float-label" >
																				<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																				<label htmlFor="peronName">Username</label>
																			</span>		
																		  </Grid>
																		  
																		 <Grid item xs={12} sm={6}>
																			<div className="p-float-label ">
																				<InputText id="personDocument" autoComplete="new-password"   value="" className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">Password</label>
																			</div>
																		 </Grid>	
																		 
																		 <Grid item xs={12} sm={6}>
																			<div className="p-float-label ">
																				<InputText id="personDocument" autoComplete="new-password"   value="" className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">Token</label>
																			</div>
																		 </Grid>	
																		  
																		</Grid>
																		
																		<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																			<div className="modalFooterButtonsRight">
																				<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																			</div>
																			
																		</div>						

																	</Panel>

																	<Panel header="Países habilitados" style={{marginBottom:"20px",maxWidth:"70%"}} toggleable collapsed={false}>
										
																		<div style={{padding:"15px"}}>
													
																			<DataTable  header={header} value={countriesOnlyRepo} selection={selectedPlaces} onSelectionChange={(e) => setSelectedPlaces(e.value)} paginator rows={15} filterDisplay="row" 
																			selectionMode='checkbox'
																			emptyMessage="Nenhum país / cidade encontrado">

																				<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>

																				<Column header="País" filterField="country.name" style={{ minWidth: '12rem' }} body={countryBodyTemplate} filter  showFilterMenu={false} showFilterMatchModes={false} filterPlaceholder="Filtre por país" />
																				
																					
																				<Column field="status" header="Status" filter filterElement={statusFilterTemplate} dataType="boolean" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} showFilterMatchModes={false} body={statusBodyTemplate} />
																				
																			</DataTable>

																		</div>															
																	</Panel>																	
	

															  </TabPanelObj>
															  <TabPanelObj value={value} index={4} style={{width:"100%"}}>


																	<Panel header={(<div>Configurações API</div>)} style={{marginBottom:"20px",maxWidth:"70%"}} toggleable>

																		<Grid container spacing={5} style={{marginTop:"-20px"}}>

																		  <Grid item xs={12} sm={6}>
																			<span className="p-float-label" style={{width:"100%",textAlign:"left"}}>
																				<Dropdown  value={filterByPaymentMethod} onChange={(e) => setFilterByPaymentMethod(e.target.value)} options={[{"code":1,name:"Ativo"},{"code":2,name:"Inativo"}]} value={{"code":1,name:"Ativo"}} optionLabel="name"  className="w100"></Dropdown>
																				<label htmlFor="agency">Status serviço</label>
																			</span>		
																		  </Grid>
																	
																																																			<Grid item xs={12} sm={6}>
																			<span className="p-float-label" >
																				<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																				<label htmlFor="peronName">Username</label>
																			</span>		
																		  </Grid>
																		  
																		 <Grid item xs={12} sm={6}>
																			<div className="p-float-label ">
																				<InputText id="personDocument" autoComplete="new-password"   value="" className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">Password</label>
																			</div>
																		 </Grid>	
																		 
																		 <Grid item xs={12} sm={6}>
																			<div className="p-float-label ">
																				<InputText id="personDocument" autoComplete="new-password"   value="" className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																				<label htmlFor="personDocument">Token</label>
																			</div>
																		 </Grid>	
																		  
																		</Grid>
																		
																		<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																			<div className="modalFooterButtonsRight">
																				<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																			</div>
																			
																		</div>						

																	</Panel>
																	
																	<Panel header="Países habilitados" style={{marginBottom:"20px",maxWidth:"70%"}} toggleable collapsed={false}>
										
																		<div style={{padding:"15px"}}>
													
																			<DataTable  header={header} value={countriesRepo} selection={selectedPlaces} onSelectionChange={(e) => setSelectedPlaces(e.value)} paginator rows={15} filterDisplay="row" 
																			selectionMode='checkbox'
																			emptyMessage="Nenhum país / cidade encontrado">

																				<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>

																				<Column header="País" filterField="country.name" style={{ minWidth: '12rem' }} body={countryBodyTemplate} filter showFilterMenu={false} filterPlaceholder="Filtre por país" />
																				
																					
																				<Column field="status" header="Status" filter filterElement={statusFilterTemplate} dataType="boolean" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} showFilterMatchModes={false} body={statusBodyTemplate} />
																				
																			</DataTable>

																		</div>															
																	</Panel>
																																	
															  </TabPanelObj>
															  
														  </Box>
													

													</div>

												</TabPanel>

												<TabPanel header="Serviços Externos">

													<div style={{padding:"20px",width:"70%",marginLeft:"auto",marginRight:"auto"}}>

														<Panel header={(<div>Google  - API Google Maps</div>)} style={{marginBottom:"20px"}}>

															<Grid container spacing={5} style={{marginTop:"-20px"}}>

															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">Token acesso API</label>
																</span>		
															  </Grid>
															  
															</Grid>
															
															<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																<div className="modalFooterButtonsRight">
																	<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																</div>
																
															</div>														
															

														</Panel>
														
														<Panel header={(<div>Mailgun - Email Transacional</div>)} style={{marginBottom:"20px"}}>

															<Grid container spacing={5} style={{marginTop:"-20px"}}>

															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">Token acesso API</label>
																</span>		
															  </Grid>
														
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="peronName">URL API</label>
																</span>		
															  </Grid>
															  
															</Grid>
															
															<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																<div className="modalFooterButtonsRight">
																	<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																</div>
																
															</div>														
															

														</Panel>	

														<Panel header={(<div>CPFCNPJ.com.br - Busca de dados na RFB</div>)} style={{marginBottom:"20px"}}>

															<Grid container spacing={5} style={{marginTop:"-20px"}}>

															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">Token acesso API</label>
																</span>		
															  </Grid>
														
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="peronName">Id Pacote Pessoa Jurídica</label>
																</span>		
															  </Grid>
															  
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="peronName">Id Pacote Pessoa Física</label>
																</span>		
															  </Grid>
															  
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="peronName">URL da API</label>
																</span>		
															  </Grid>
															  
															</Grid>
															
															<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																<div className="modalFooterButtonsRight">
																	<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																</div>
																
															</div>														
															

														</Panel>

														<Panel header={(<div>OpenAI - API chatGPT</div>)} style={{marginBottom:"20px"}}>

															<Grid container spacing={5} style={{marginTop:"-20px"}}>

															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">Token de acesso a API</label>
																</span>		
															  </Grid>
														
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="peronName">URL da API</label>
																</span>		
															  </Grid>
															  
															</Grid>
															
															<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																<div className="modalFooterButtonsRight">
																	<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																</div>
																
															</div>														
															

														</Panel>

														<Panel header={(<div>Freshdesk - Helpdesk</div>)} style={{marginBottom:"20px"}}>

															<Grid container spacing={5} style={{marginTop:"-20px"}}>

															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">Token de acesso a API</label>
																</span>		
															  </Grid>
														
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="peronName">URL da API</label>
																</span>		
															  </Grid>
															  
															</Grid>
															
															<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																<div className="modalFooterButtonsRight">
																	<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																</div>
																
															</div>														
															

														</Panel>

														<Panel header={(<div>Pipedrive - CRM</div>)} style={{marginBottom:"20px"}}>

															<Grid container spacing={5} style={{marginTop:"-20px"}}>

															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">Token de acesso a API</label>
																</span>		
															  </Grid>
														
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText id="personName" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="peronName">URL da API</label>
																</span>		
															  </Grid>
															  
															</Grid>
															
															<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																<div className="modalFooterButtonsRight">
																	<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																</div>
																
															</div>														
															

														</Panel>														

													</div>

												</TabPanel>	

												<TabPanel header="Gateways pagamento">

													<div style={{padding:"20px",width:"70%",marginLeft:"auto",marginRight:"auto"}}>

														<Panel header={(<div>CIELO E-commerce</div>)} style={{marginBottom:"20px"}}>

															<Grid container spacing={5} style={{marginTop:"-20px"}}>

															  
															  <Grid item xs={12} sm={12}>
																<span className="p-float-label" style={{width:"50%",textAlign:"left"}}>
																	<Dropdown value={filterByPaymentMethod} onChange={(e) => setFilterByPaymentMethod(e.target.value)} options={[{"code":1,name:"Sandbox"},{"code":2,name:"Produção"},{"code":3,name:"Inativo"}]} optionLabel="name"  className="w100"></Dropdown>
																	<label htmlFor="agency">Modo operação</label>
																</span>		
															  </Grid>
																					 							  
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">URL consulta (sandbox)</label>
																</span>		
															  </Grid>
														
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">URL consulta (produção)</label>
																</span>		
															  </Grid>

															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">URL transacional (sandbox)</label>
																</span>		
															  </Grid>
															  
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">URL transacional (produção)</label>
																</span>		
															  </Grid>															  
														
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">Credencial de acesso: MerchantId</label>
																</span>		
															  </Grid>															  
														
															  <Grid item xs={12} sm={6}>
																<span className="p-float-label" >
																	<InputText text id="agency" autoComplete="new-password"  value="" style={{width:"100%"}} />
																	<label htmlFor="agency">Credencial de acesso: MerchantKey </label>
																</span>		
															  </Grid>															  
														
															</Grid>
															
															<div className="modalFooterButtons" style={{marginTop:"20px",textAlign:"right"}}>

																<div className="modalFooterButtonsRight">
																	<Button label="Gravar" icon="pi pi-check" loading={saving} onClick={() => console.log("UPDATE")} />
																</div>
																
															</div>														
															

														</Panel>
													

													</div>

												</TabPanel>													

											</TabView>											
											
								
										<AlertView
											alert={alert}
											setAlert={setAlert}
											alertTitle={alertTitle}
											alertBody={alertBody}
											alertAction={alertAction}
											scrollBlock={true}				
										/>
										</Box>
									
								</div>							
							
							</div>
						
						</div>
						
				</div>
			
			</div> 
		</div>
		
		<UserView
			setDialog={setDialog}
			dialog={dialog}
			scrollBlock={false}
		/>			
		
    </div>
	
	<div id="contatoSection" className="contatoSection__wrapper">

		<div className={isTabletOrMobile ? "contatoSection__wrapper_content_wrapper mobile" : "contatoSection__wrapper_content_wrapper"}  >

			<div className="flex-1 dFlex aItemsC jContentE"> 
			
				<div className="textBlockMargin contatoSection__wrapper_content_text" >
				
					<div className="contatoSection__wrapper_content_header">Central de Relacionamento</div>
				
					Estamos a disposição para atender às suas necessidades e responder às suas perguntas. Conheça as principais formas de entrar em contato conosco:
					
					<div style={{marginTop: "2.8125rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", gap: "15px"}}>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactWhatsappIcon} style={{width:"49px",height:"auto"}} className="noSelect" /></div>
							<div className="contatoOptionText"><Whatsapp phone="552140209749" style={{color:"#FFF",textDecoration:"none"}}>Whatsapp 4020-9749</Whatsapp></div>
						</div>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactPhoneIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Callto phone="+552140209749" style={{color:"#FFF",textDecoration:"none"}}>Telefone 4020-9749</Callto></div>
						</div>
						
						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactEmailIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Button label="Enviar mensagem" rounded className="pT10 mR10 headerButton tColorWhite w100" style={{marginBottom:"0.625rem"}} onClick={()=>setDialog('messageModal',true)} /></div>
						</div>
						

					</div>					
				
				</div> 
				
			</div>
			
			<div className="tAlignL flex-1_5"> 
				<img src={contactConcept} className="contatoSection__wrapper_content_image noSelect" />
			</div>			
			
		</div>
			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
			<MessageView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
    </div>
	
	<div id="memovoSection" className="memovoSection__wrapper">
	
		<div className={isTabletOrMobile ? "memovoSection__wrapper_content_wrapper mobile" : "memovoSection__wrapper_content_wrapper"}>
		
			<div className="flex-1"> 
				<img src={desktopConcept} className="memovoSection__wrapper_content_image noSelect" />
			</div>
			<div className="flex-1 dFlex aItemsC jContentS"> 
			
				<div className="textBlockMargin memovoSection__wrapper_content_text" >
				
					<div className="memovoSection__wrapper_content_header">Inovando o Mercado B2B de Mobilidade</div>
									
					A MEMOVO é uma plataforma B2B inovadora que facilita a busca e comercialização de serviços de transporte e mobilidade. Pioneira como marketplace B2B, oferece passagens aéreas, trens, ônibus, aluguel de veículos e transfers em um só lugar, permitindo a criação de roteiros completos de mobilidade. Com interface amigável e processo simples, a plataforma proporciona agilidade e eficiência aos profissionais do setor, atendendo às necessidades dos usuários e impulsionando o crescimento do seu negócio.
				
				</div> 
				
			</div>
			
		</div>
		
    </div>	
	
	</>
  );
    
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  masterUser:store.appReducer.masterUser
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions,...UserActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(Users);	


