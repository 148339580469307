import * as types from '../actionTypes';

const initialState = { 
	selectedCustomer:{},
	customersRows:[]
}
 
export const customerReducer = (state = initialState, action) => {
		
    switch (action.type) {
        case types.SET_CUSTOMER:
            return { ...state, selectedCustomer: action.payload }	
        case types.SET_CUSTOMERSROWS:
            return { ...state, customersRows: action.payload }				
        default:
            return state
    }
}

