import React from 'react';
import axios from 'axios';
import moment from 'react-moment';
import jwt_decode from "jwt-decode";
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';
	

export const setCustomer  = (value) => { 

	return { 
		type: types.SET_CUSTOMER,
		payload: value
	}
};	

// Retrieve all customers
export const retrieveCustomers  = (data,props) => { 
		
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@memovo/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwt_decode(userObj.userToken);
								
					// Set general data
					const preparedData = {};
					preparedData.agencyId = data.agencyId;
					
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/customer/list',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
								"x-access-token": userObj.userToken,
								Accept: 'application/json'
							}
					})							
					
					.then((response) => {

						// Document already registered
						if (!response.data.found) {
							
							props.setRows([]);
							props.setTotalRecords(0);
							props.setLoading(false);							
							return;
							
						} // end if

						if (response.data.success) {
							
							// Set list
							props.setRows(response.data.data);
							props.setTotalRecords(response.data.data.length);
							props.setLoading(false);
							return;
					
							
						} // end if
						else {
							
							props.setRows([]);	
							props.setTotalRecords(0);
							props.setLoading(false);
														
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar a lista de clientes. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {
						
						props.setRows([]);
						props.setTotalRecords(0);
						props.setLoading(false);
						
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar a lista de clientes. Tente novamente. Caso o problema persista entre em contato informando código 02.");
						props.setAlert(true);							
						return;
					});
					
	} // end dispatch

};

// Retrieve selected customer	
export const retrieveCustomer  = (data,props) => { 
		
		return dispatch => {

					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@memovo/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwt_decode(userObj.userToken);

					// Set general data
					const preparedData = {};
					preparedData.agencyId = data.agencyId;
					
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/agency/retrieve',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
								"x-access-token": userObj.userToken,
								Accept: 'application/json'
							}
					})							
					
					.then((response) => {

						// Document already registered
						if (!response.data.found) {
							
							props.setAlertTitle("Ops! Parece que a reserva não foi encontrada!");
							props.setAlertBody("A reserva que está tentando abrir não foi encontrada. Entre em contato com o suporte e forneça o número da reserva.");
							props.setAlert(true);							
							return;
							
						} // end if

						if (response.data.success) {
							
							// Simulate delay on return
							setTimeout(function() {
								props.setLoadingData(false);
							},2000);
							
							return;
							
						} // end if
						else {
							
							props.setLoadingData(false);							
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar os dados da reserva. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar os dados da reserva. Tente novamente. Caso o problema persista entre em contato informando código 02.");
						props.setAlert(true);							
						return;
					});
					
	} // end dispatch

};

	


	



