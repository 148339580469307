import * as types from '../actionTypes';

const initialState = { 
	transportFilters:{},
	transferFilters:{},
	rentcarFilters:{},
	selectedReservation:{},
	selectedRoute:{},
}
 
export const orderReducer = (state = initialState, action) => {
		
    switch (action.type) {
        case types.SET_TRANSPORTFILTERS:		
            return { ...state, transportFilters: action.payload }	
        case types.SET_TRANSFERFILTERS:
            return { ...state, transferFilters: action.payload }	
        case types.SET_RENTCARFILTERS:
            return { ...state, rentcarFilters: action.payload }				
        default:
            return state
    }
}

