import React from 'react';
import axios from 'axios';
import moment from 'react-moment';
import jwt_decode from "jwt-decode";
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';
	

export const setReservation  = (value) => { 

	return { 
		type: types.SET_RESERVATION,
		payload: value
	}
};	

// Retrieve all reservations
export const retrieveReservations  = (data,props) => { 
		
		return dispatch => {
			
					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@memovo/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwt_decode(userObj.userToken);
								
					// Set general data
					const preparedData = {};
					preparedData.reservationId = data.reservationId;
					
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/reservation/list',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
								"x-access-token": userObj.userToken,
								Accept: 'application/json'
							}
					})							
					
					.then((response) => {

						// Document already registered
						if (!response.data.found) {
							
							props.setRows([]);
							props.setTotalRecords(0);
							props.setLoading(false);							
							return;
							
						} // end if

						if (response.data.success) {
							
							// Set list
							props.setRows(response.data.data);
							props.setTotalRecords(response.data.data.length);
							props.setLoading(false);
							return;
					
							
						} // end if
						else {
							
							props.setRows([]);	
							props.setTotalRecords(0);
							props.setLoading(false);
														
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar a lista de reservas. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {
						
						props.setRows([]);
						props.setTotalRecords(0);
						props.setLoading(false);
						
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar a lista de reservas. Tente novamente. Caso o problema persista entre em contato informando código 02.");
						props.setAlert(true);							
						return;
					});
					
	} // end dispatch

};

// Retrieve selected reservation	
export const retrieveReservation  = (data,props) => { 
		
		return dispatch => {

					// Check if there is a local USER OBEJECt with a TOKEN
					var result = localStorage.getItem("@memovo/userData", undefined,true);
					var userObj = JSON.parse(result);  
					
					// decode JWT 
					var decoded = jwt_decode(userObj.userToken);

					// Set general data
					const preparedData = {};
					preparedData.reservationId = data.reservationId;
					
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/api/v1/reservation/retrieve',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
								"x-access-token": userObj.userToken,
								Accept: 'application/json'
							}
					})							
					
					.then((response) => {

						// Document already registered
						if (!response.data.found) {
							
							props.setAlertTitle("Ops! Parece que a reserva não foi encontrada!");
							props.setAlertBody("A reserva que está tentando abrir não foi encontrada. Entre em contato com o suporte e forneça o número da reserva.");
							props.setAlert(true);							
							return;
							
						} // end if

						if (response.data.success) {
							
							// Simulate delay on return
							setTimeout(function() {
								props.setLoadingData(false);
							},2000);
							
							return;
							
						} // end if
						else {
							
							props.setLoadingData(false);							
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar os dados da reserva. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar os dados da reserva. Tente novamente. Caso o problema persista entre em contato informando código 02.");
						props.setAlert(true);							
						return;
					});
					
	} // end dispatch

};


export const retrieveCompanyDataByDocument  = (data,props) => { 
		
		return dispatch => {

					// Set general data
					const preparedData = {};
					preparedData.documentNumber = data.companyDocument;
					
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/company/retrieveCompanydocumentInfo',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							Accept: 'application/json'
						}
					})							
					
					.then((response) => {

						// Document already registered
						if (response.data.exists) {
							
							props.setAlertTitle("Ops! Parece que já está cadastrado!");
							props.setAlertBody("O CNPJ informado já está cadastrado na plataforma Memovo. Faça o login para acessar a área das agências. Caso você acredite que isso seja um erro, entre em contato informando código 03. ");
							props.setAlert(true);							
							return;
							
						} // end if

						if (response.data.success) {

							// IF SUCCEED
							if (response.data.status === 1) {
								
								// Check if Document is active
								let documentStatus = response.data.data.situacao ? response.data.data.situacao.nome : null;
								if (documentStatus != "Ativa") {
									
									props.setAlertTitle("Ops! Parece que o CNPJ tem um problema!");
									props.setAlertBody("O CNPJ informado não está ativo na RFB. Dessa forma, não é possível seguir com o cadastro. Regularize a situação do CNPJ junto a RFB e tente novamente. Caso você acredite que isso seja um erro, entre em contato informando código 05. ");
									props.setAlert(true);	
									props.setLoadingData(false);									
									return;
									
								} // end if	
    							
								// Fill received fileds							
								props.setCompanyLegalName(response.data.data.razao);
								props.setCompanyFantasyName(response.data.data.fantasia);
								props.setCompanyEmail(response.data.data.email);
								props.setCompanyPhone("("+response.data.data.telefones['1'].ddd+") "+response.data.data.telefones['1'].numero);
								props.setCompanyStateAuto(response.data.data.matrizEndereco.uf);
								props.setCompanyCityAuto(response.data.data.matrizEndereco.cidade);
								props.setPersonCompanyName(response.data.data.responsavel);	

								props.setAutoFieldsFilled(true);
								props.setLoadingData(false);
								return;
							
							} // end if
							else {

								props.setCompanyLegalName("");
								props.setCompanyFantasyName("");
								props.setCompanyEmail("");
								props.setCompanyPhone("");
								props.setCompanyStateAuto("");
								props.setCompanyCityAuto("");
								props.setPersonCompanyName("");
								
								props.setLoadingData(false);								
								props.setAlertTitle("Ops! Ocorreu um problema");
								props.setAlertBody(response.data.data.erro+" Se você acredita que isso seja um erro, entre em contato e informe o código 04.");
								props.setAlert(true);							
								return;								
								
							} // end else
							
						} // end if
						else {
							
							props.setCompanyLegalName("");
							props.setCompanyFantasyName("");
							props.setCompanyEmail("");
							props.setCompanyPhone("");
							props.setCompanyStateAuto("");
							props.setCompanyCityAuto("");
							props.setPersonCompanyName("");
							
							props.setLoadingData(false);							
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar os dados do CNPJ. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar os dados do CNPJ. Tente novamente. Caso o problema persista entre em contato informando código 02.");
						props.setAlert(true);							
						return;
					});
					
	} // end dispatch

};

	
export const retrievePersonDataByDocument  = (data,props) => { 
		
		return dispatch => {

					// Set general data
					const preparedData = {};
					preparedData.documentNumber = data.personDocument;
					
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/company/retrievePersondocumentInfo',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							Accept: 'application/json'
						}
					})	
					.then((response) => {

						// Document already registered
						if (response.data.exists) {
							
							props.setAlertTitle("Ops! Parece que já está cadastrado!");
							props.setAlertBody("O CPF informado já está cadastrado na plataforma Memovo e vinculado a uma empresa. Faça o login para acessar a área das agências. Caso você acredite que isso seja um erro, entre em contato informando código 03. ");
							props.setAlert(true);							
							return;
							
						} // end if

						if (response.data.success) {
							
							// IF SUCCEED
							if (response.data.status === 1) {
								
								// Check if Document is active
								let documentStatus = response.data.data.situacao ? response.data.data.situacao : null;
								if (documentStatus != "Regular") {
									
									props.setAlertTitle("Ops! Parece que o CPF tem um problema!");
									props.setAlertBody("O CPF informado não está regular junto a RFB. Dessa forma, não é possível seguir com o cadastro utilizando esse CPF. Regularize a situação do CPF junto a RFB e tente novamente. Caso você acredite que isso seja um erro, entre em contato informando código 06. ");
									props.setAlert(true);	
									props.setPersonLoadingData(false);									
									return;
									
								} // end if									
    							
								// Fill received fileds							
								props.setPersonName(response.data.data.nome);	
								props.setBirthday(response.data.data.nascimento);	

								props.setAutoPersonFieldsFilled(true);
								props.setPersonLoadingData(false);
								return;
							
							} // end if
							else {

								props.setPersonName("");
								props.setBirthday("");
								
								props.setPersonLoadingData(false);								
								props.setAlertTitle("Ops! Ocorreu um problema");
								props.setAlertBody(response.data.data.erro+" Se você acredita que isso seja um erro, entre em contato e informe o código 04.");
								props.setAlert(true);							
								return;								
								
							} // end else
							
						} // end if
						else {
							
							props.setPersonName("");
							props.setBirthday("");
							
							props.setPersonLoadingData(false);							
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar os dados do CPF. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar os dados do CPF. Tente novamente. Caso o problema persista entre em contato informando código 02. ("+error.message+")");
						props.setAlert(true);							
						return;
					});
					
	} // end dispatch

};


export const register  = (data,props) => {

/*		return dispatch => {
			
			// We are on Step 0
			if (nextStep == 1) {
		
				axios.post(config.API_URL+'/user/checkEmail', {
				  email: data.email,
				  mobilePhone: data.mobilePhone,
				  type:data.type
				},{timeout:10000})
				.then((response) => {
					
					if (response.data.exist) {
						
						dispatch(setButtonLoading(false));
						var dialogMessageText = data.type == 'email' ? 
						"O email informado já está vinculado a outro cadastro. Caso seja seu, faça o login." :
						"O telefone informado já está vinculado a outro cadastro. Caso seja seu, faça o login."
						
						props.setDialogMessage(dialogMessageText);
						props.setDialog(true);	
						return;						
												
					} // end if
					else {
							
						// Turn button loading off
						dispatch(setButtonLoading(false));
						
						// Navigate to next step
						props.history.push("/register/step1");
						return;

					} // end else
					
					
				}, (error) => {
					
					dispatch(setButtonLoading(false));
					
					// verify if timeout happened
					var alertMessage = (error.code == "ECONNABORTED") ? 
					"Ocorreu um erro ao se comunicar com a API. Verifique se está com acesso a internet.":
					"Ocorreu um erro ao se comunicar com a API. Verifique os dados informados e tente novamente.";					
					props.setDialogMessage(alertMessage);
					props.setDialog(true);	
					return;
										
				})
				.catch(error=> {
					
					console.log("error: "+JSON.stringify(error,null,2));

					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
					props.setDialog(true);
					return;					

				});
		
		
			} // end if
			// We are on Step 1
			else if (nextStep == 2) {

				axios.post(config.API_URL+'/user/checkEmail', {
				  email: data.email,
				  mobilePhone: data.mobilePhone,
				  type:"emailPhone"
				})
				.then((response) => {
					
					if (response.data.exist) {
						
					dispatch(setButtonLoading(false));	
						
					// verify if timeout happened
					if (response.data.conflict == "emailAndmobilePhone") {
						var alertMessage = "O Email e o telefone informado já estão vinculados a outro cadastro. Caso seja seu, faça o login."
					} // end if
					else if (response.data.conflict == "mobilePhone") {
						var alertMessage = "O telefone informado já está vinculado a outro cadastro. Caso seja seu, faça o login."
					} // end else false
					else {
						var alertMessage = "O email informado já está vinculado a outro cadastro. Caso seja seu, faça o login."
					} // end else
					
					// Dispatch alert				
					props.setDialogMessage(alertMessage);
					props.setDialog(true);	
					return;								
						
					} // end if
					else {
						
						// Turn button loading off
						dispatch(setButtonLoading(false));
						
						// Navigate to next step
						props.history.push("/register/step2");

					} // end else					
					
				}, (error) => {
										
					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					props.setDialog(true);
					return;								
										
				})
				.catch(error=> {

					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');
					props.setDialog(true);
					return;						

				});
		

			} // end else if
			// We are on Step 2
			else if (nextStep == 3) {
				
				// Prepare for user creation
				const preparedData = {};
				
				// Set general data
				preparedData.email = data.email;
				preparedData.password = data.password;
				preparedData.completeName = data.completeName;
				preparedData.mobilePhone =data.mobilePhone
				preparedData.alias = data.alias;
				preparedData.device = data.device;
				
				// Connect to REGISTER Endpoint
				axios({
					   url    : config.API_URL+'/user/register',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
						Accept: 'application/json'
					}
				})				
				
				.then((response) => {
	
				// If an error occured
				if (response.data.error) {					
					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					props.setDialog(true);
					return;			
				} // end if	
	
  
				if (response.data.success) {
					
					dispatch(setButtonLoading(false));
					
					// If company already exist
					if (response.data.exist) {
						props.setDialogMessage('Já existe um usuário cadastrado com os dados enviados.');
						props.setDialog(true);						
					} // end if
					
					
					// If company already exist
					if (response.data.token) {
						
						// Check if there is a local USER OBEJECt with a TOKEN
						var userObj = {
							userToken:response.data.token,
							userChatToken:response.data.chatToken,
							firstName:response.data.firstName,
							completeName:data.completeName,
							userStatus:0,
							validated:0
						}
						const saveObj = JSON.stringify(userObj);
						
						// save User Session Id on Session Storage
						sessionStorage.setItem("@motuum/sessionId",response.data.sessionId);						
						
						// save User Data on Local Storage
						localStorage.setItem("@motuum/userData",saveObj);
						
						// Set User Data
						var userData = {
							firstName:response.data.firstName
						}
						dispatch(setUserData(userData));				
						
						// Navigate to next step
						props.history.push("/register/step3/activate/step1");
						return;
																
					} // end if
					
					
				} // end if
				
				// If any other error, what do do???
				// ...  							
					
				}, (error) => {
															
					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro ao se comunicar com o servidor. Tente novamente.');
					props.setDialog(true);					
										
				})
				.catch(error=> {
						
					dispatch(setButtonLoading(false));
					props.setDialogMessage('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');	
					props.setDialog(true);					

				});

			} // end else if
		
		
} // end dispatch
*/
		
};



	



