import axios from 'axios';
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';
	

export const setTransportFilters  = (obj) => { 
	return { 
		type: types.SET_TRANSPORTFILTERS,
		payload: obj
	}
};

export const setTransferFilters  = (obj) => { 
	return { 
		type: types.SET_TRANSFERFILTERS,
		payload: obj
	}
};

export const setRentcarFilters  = (obj) => { 
	return { 
		type: types.SET_RENTCARFILTERS,
		payload: obj
	}
};

