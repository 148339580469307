import React from 'react';
import axios from 'axios';
import moment from 'react-moment';
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';
import jwtDecode from 'jwt-decode';	

/* Authenticate user */	
export const processLogin = (data,props) => {
	
		return dispatch => {

				// Prepare for login
				const preparedData = {};
				
				// Set general data
				preparedData.email = data.email;
				preparedData.password = data.password;
				
				// Connect to REGISTER Endpoint
				axios({
					   url    : config.API_URL+'/user/login',
					   method : 'POST',
					   data   : preparedData,
					   headers: {
						Accept: 'application/json'
					}
				})				
				.then((response) => {

				
					// If an error occured
					if (response.data.error) {							
						props.setAlertTitle("Ops! Parece que ocorreu um erro!");
						props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. Erro retornado: '+response.data.errorMessage+' ('+response.data.errorContext+')');				
						props.setAlert(true);
						props.setSaving(false);
						return;			
					} // end if	
		
					// If success
					if (response.data.success) {
		
						// If company already exist
						if (response.data.notFound) {
							props.setAlertTitle("Ops! Parece que esse usuário não está cadastrado!");
							props.setAlertBody("O email informado não está cadastrado na plataforma Memovo. Faça o cadastro de sua agência para ter acesso. Caso você acredite que isso seja um erro, entre em contato informando código LOG01. ");				
							props.setAlert(true);	
							props.setSaving(false);		
							return;			
						} // end if
		
						// User email already used
						if (response.data.wrongData) {
							props.setAlertTitle("Ops! Parece que houve um problema com os dados fornecidos");
							props.setAlertBody("O e-mail ou a senha digitados estão incorretos. Caso não se lembre da sua senha, selecione a opção 'Esqueceu sua senha?' para redefini-la. Se o problema persistir, por favor, entre em contato conosco e informe o código de erro LOG04.");				
							props.setAlert(true);	
							props.setSaving(false);
							return;					
						} // end if			
		
						// If company already exist
						if (response.data.token) {
							
								// set button loading off
								props.setSaving(false);

								// Clean fields
								props.setEmail("");
								props.setPassword("");
	
								// Set @memovo/userData obj
								var userObj = {
									userToken:response.data.token,
									AgencyLegalName:response.data.AgencyLegalName,
									AgencyShortLegalName:response.data.AgencyShortLegalName,
									AgencyTradingName:response.data.AgencyTradingName,
									PersonFirstName:response.data.PersonFirstName,
									PersonCompleteName:response.data.PersonCompleteName,
									Master:response.data.Master
								}		
								const saveObj = JSON.stringify(userObj);
								
								// save User Data on Local Storage
								localStorage.setItem("@memovo/userData",saveObj);
								localStorage.setItem("@memovo/companyStatus",response.data.companyStatusToken);	
								localStorage.setItem("@memovo/activationStatus",response.data.activationStatusToken);	
								localStorage.setItem("@memovo/logged",1);

								// Set login status true
								props.setLoginStatus(true);

								var result = localStorage.getItem("@memovo/userData");
								var userData = JSON.parse(result);
								var userDataInfo = jwtDecode(userData.userToken); 
								var resultCompanyStatus = localStorage.getItem("@memovo/companyStatus");
								var resultActivationStatus = localStorage.getItem("@memovo/activationStatus");									
								
								// Set Company Short Name
								let tempCompanyName = userDataInfo.AgencyLegalName.split(" ");
								userDataInfo.AgencyShortLegalName = tempCompanyName[0]+" "+tempCompanyName[1];

								// Propagate received data to interface
								props.setMasterUser(userDataInfo.Master === 0 ? false : true);
								props.setAgencyName(userDataInfo.AgencyShortLegalName);
								props.setAgencyUser(userDataInfo.PersonCompleteName);							

								// Set CurrentCompanyStatus
								try {
									var companyStatusObjDecoded = jwtDecode(resultCompanyStatus); 
									var currentCompanyStatus = companyStatusObjDecoded.currentStatus;
									if (currentCompanyStatus == null) {
										props.setAlertTitle("Ops! Parece que há um erro nos dados armazenados!");
										props.setAlertBody("Desconectamos o seu usuário da plataforma pois ocorreu uma inconsistência nos dados armazenados no seu dispositivo. Faça o login novamente para corrigir. [1] ");				
										props.setAlert(true);

										props.logOut(); 
										return; 
									}
									props.setCurrentCompanyStatus(currentCompanyStatus);
								} catch(e) {
									console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (4) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
									props.logOut();
									return;
								} // end catch
								
								// Check if activation token has updateAt date before received token. 
								// ... If yes, update LocalStorage Token and data	

								// Set ActivationStatus
								try {
									var activationStatusObjDecoded = jwtDecode(resultActivationStatus);
									var activationStatus = activationStatusObjDecoded.currentStatus;
									if (activationStatus == null) {
										props.setAlertTitle("Ops! Parece que há um erro nos dados armazenados!");
										props.setAlertBody("Desconectamos o seu usuário da plataforma pois ocorreu uma inconsistência nos dados armazenados no seu dispositivo. Faça o login novamente para corrigir. [2] ");				
										props.setAlert(true);

										props.logOut(); 
										return; 
									}
									props.setActivationStatus(activationStatus);
								} catch(e) {
									console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (5) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
									props.logOut();
									return;
								} // end catch	

								// Navigate to home
								props.navigate("/order");
										
							return;
																	
						} // end if
						// If Token not received show message without automatic login [EXCEPTION]
						else {

							props.setAlertTitle("Ops! Parece que esse usuário não está cadastrado!");
							props.setAlertBody("O email informado não está cadastrado na plataforma Memovo. Faça o cadastro de sua agência para ter acesso. Caso você acredite que isso seja um erro, entre em contato informando código LOG4. ");				
							props.setAlert(true);	
							props.setSaving(false);		
							props.logOut();
							return;								
		
						} // end else
		
		
					} // end if
		
					}, (error) => {
						props.setAlertTitle("Ops! Parece que ocorreu um erro de rede!");
						props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. [3]');				
						props.setAlert(true);
						props.setSaving(false);
						props.logOut();
						return;				
					})
					.catch(error=> {
						props.setAlertTitle("Ops! Parece que ocorreu um erro de rede!");
						props.setAlertBody('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente. [4]');				
						props.setAlert(true);
						props.setSaving(false);
						props.logOut();
						return;	
					});	

			
		} // end dispatch
	
};


export const recoverPasswordGenerate  = (data,props) => {

	return dispatch => {
				// Prepare POST data
				var preparedData = {
					email:data.email
				}

				// Connect to REGISTER Endpoint
				axios({
					   url    : config.API_URL+'/user/recoverPasswordGenerate',
					   method : 'POST',
					   data: preparedData,
					   headers: {
						Accept: 'application/json'
					}
				})	
				.then((response) => {
			
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {

						props.setAlertTitle("Ops! Parece que ocorreu um erro!");
						props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. Erro retornado: '+response.data.errorMessage+' ('+response.data.errorContext+')');				
						props.setAlert(true);
						props.setSaving(false);
						props.setDialog('validateEmail',false); 
						return;	
					
					} // end if	*/
			
					if (response.data.success) {
											
						// If company already exist
						if (response.data.notFound) {

							props.setAlertTitle("Hummm! Parece que o email não existe em nossa base de dados");
							props.setAlertBody('Não encontramos o email fornecido em nossa base de dados ou vinculado a algum usuário. Verifique o email e tente novamente. Se entender que é um erro, entre em contato e informe o código RP001');				
							props.setAlert(true);
							props.setSaving(false);
							return;	

						}
					
							// set Recover Token on sessionId
							sessionStorage.setItem("@memovo/recoverToken",response.data.recoverToken);

							props.setCodeGenerated(true);
						
							props.setAlertTitle(data.resend ? "Tudo certo! Código reenviado pro seu email!" : "Tudo certo! Código enviado pro seu email!");
							props.setAlertBody('Um código de 6 números foi enviado para o seu email. Digite o código enviado para recuperar a sua senha. O código tem validade de 5 minutos.');				
							props.setAlert(true);
							props.setSaving(false);
							return;	

							
					} // end if
					else {
						props.setAlertTitle("Ops! Parece que ocorreu um erro!");
						props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. [2]');				
						props.setAlert(true);
						props.setSaving(false);
						return;	
					} // end else
						
					
				}, (error) => {

					props.setAlertTitle("Ops! Parece que ocorreu um erro!");
					props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. [3]');				
					props.setAlert(true);
					props.setSaving(false);
					return;						
		
				})
				.catch(error=> {

					props.setAlertTitle("Ops! Parece que ocorreu um erro!");
					props.setAlertBody('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');				
					props.setAlert(true);
					props.setSaving(false);
					return;								

				});

	}		
};

export const recoverPasswordConfirm  = (data,props) => {

	return dispatch => {
	
				// Retrieve userData and userToken
				var resultRecoverToken = sessionStorage.getItem("@memovo/recoverToken");	

				// Prepare POST data
				var preparedData = {
					code: data.code,
					recoverToken:resultRecoverToken
				}

				// Connect to REGISTER Endpoint
				axios({
					   url    : config.API_URL+'/user/recoverPasswordConfirm',
					   method : 'POST',
					   data: preparedData,
					   headers: {
						Accept: 'application/json'
					}
				})	
				.then((response) => {

		
				// If an error occured. Check error is blocking or non-blocking.
				if (response.data.error) {
						
					// Code expired...
					if (response.data.codeExpired) {
						
						props.setAlertTitle("Ops! Parece que o código está expirado!");
						props.setAlertBody('O código informado está expirado. Reenvie o código e tente novamente. Lembre-se que você tem até 5 minutos para validar o código.');				
						props.setAlert(true);
						props.setSaving(false);
						return;	

					} // end if
					
					// Wrong code
					if (response.data.codeIsWrong) {
						
						props.setAlertTitle("Ops! Parece que o código está errado!");
						props.setAlertBody('O código informado não é igual ao enviado. Verifique o email ou mensagem recebida e tente novamente. Caso o erro persista, reenvie o código e tente novamente.');				
						props.setAlert(true);
						props.setSaving(false);
						return;							

					} // end if

					// Customer Not found...
					if (response.data.notFound) {

						props.setAlertTitle("Ops! Parece que há um problema!");
						props.setAlertBody('Não encontramos o código informado na nossa base de dados e isso é um problema crítico. Tente novamente.');				
						props.setAlert(true);
						props.setSaving(false);
						props.setDialog('validateEmail',false); 
						return;	

					} // end if
										
					props.setAlertTitle("Ops! Parece que ocorreu um erro!");
					props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. Erro retornado: '+response.data.errorMessage+' ('+response.data.errorContext+')');				
					props.setAlert(true);
					props.setSaving(false);
					return;												
				} // end if
				
				if (response.data.success) {
					
					// set Recover Token on sessionId
					sessionStorage.setItem("@memovo/recoverToken",response.data.recoverToken);
					
					props.setEmail("");
					props.setCode("");
					
					props.setSaving(false);			
					props.setCodeGenerated(false);
					props.setPasswordChange(true);
					
					props.setAlert(true);
					props.setAlertTitle("Tudo certo! O Código foi validado!");
					props.setAlertBody("Você pode agora alterar a sua senha. Digite a nova senha e confirme em seguida. Lembre-se de escolher uma senha forte para evitar acessos não autorizados. ATENÇÃO: Você tem 5 minutos para mudar a senha. Caso não mude nesse tempo, terá que gerar um novo código.");	
					return;						
					
				} // end if
				
				// If any other error, what do do???
				// ...
					
				}, (error) => {

					props.setAlertTitle("Ops! ocorreu um erro! [1]");
					props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. '+error.message+'');				
					props.setAlert(true);
					props.setSaving(false);
					return;						
										
				})
				.catch(error=> {

					props.setAlertTitle("Ops! ocorreu um erro! [2]");
					props.setAlertBody('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');				
					props.setAlert(true);
					props.setSaving(false);
					return;						

				});


	}	
		
};

export const recoverPasswordUpdate  = (data,props) => {

	return dispatch => {
	
		// Retrieve userData and userToken
		var resultRecoverToken = sessionStorage.getItem("@memovo/recoverToken");	

		// Prepare POST data
		var preparedData = {
			password: data.newPassword,
			recoverToken:resultRecoverToken
		}

		// Connect to REGISTER Endpoint
		axios({
			   url    : config.API_URL+'/user/recoverPasswordUpdate',
			   method : 'PUT',
			   data: preparedData,
			   headers: {
				Accept: 'application/json'
			}
		})	
		.then((response) => {


		// If an error occured. Check error is blocking or non-blocking.
		if (response.data.error) {

			
			// Code expired...
			if (response.data.codeNotValidated) {
				
				props.setAlertTitle("Ops! Parece que o código não foi validado!");
				props.setAlertBody('Não foi possível prosseguir com a alteração da senha pois identificamos que o código não foi validado. Refaça o processo.');				
				props.setAlert(true);
				props.setSaving(false);
				return;	

			} // end if

			// Code expired...
			if (response.data.codeExpired) {
				
				props.setAlertTitle("Ops! Parece que você demorou demais!");
				props.setAlertBody('Não foi possível alterar a senha pois a chave de segurança expirou. Lembre-se que você tem até 5 minutos para alterar a senha após a validação do código. Refaça o processo.');				
				props.setAlert(true);
				props.setSaving(false);
				return;	

			} // end if
			
			// Wrong code
			if (response.data.codeIsWrong) {
				
				props.setAlertTitle("Ops! Ocorreu um erro de integridade!");
				props.setAlertBody('Você está tentando atualizar a sua senha com uma chave de segurança que contém um código diferente do código que confirmou. Refaça o processo e caso o erro persista, entre em contato.');				
				props.setAlert(true);
				props.setSaving(false);
				return;							

			} // end if

			// Customer Not found...
			if (response.data.notFound) {

				props.setAlertTitle("Ops! Parece que há um problema!");
				props.setAlertBody('Não encontramos o usuário informado na chave de segurança em nossa base de dados. Isso é um problema crítico. Tente novamente e caso o erro persista entre em contato.');				
				props.setAlert(true);
				props.setSaving(false);
				return;	

			} // end if
								
			props.setAlertTitle("Ops! Parece que ocorreu um erro!");
			props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. Erro retornado: '+response.data.errorMessage+' ('+response.data.errorContext+')');				
			props.setAlert(true);
			props.setSaving(false);
			return;												
		} // end if
		
		if (response.data.success) {
			
			// Remove Recover Token on sessionId
			sessionStorage.removeItem("@memovo/recoverToken");
			
			props.setEmail("");
			props.setCode("");
			
			props.setSaving(false);			
			props.setCodeGenerated(false);
			props.setPasswordChange(false);
			
			props.setAlert(true);
			props.setAlertTitle("Tudo certo! Sua senha foi alterada!");
			props.setAlertBody("Você pode agora fazer o login com a nova senha. Se tiver problema no login, entre em contato com o suporte.");	

			props.setForgotPassword(false); // close parent modal			
			return;						
			
		} // end if
		
		// If any other error, what do do???
		// ...
			
		}, (error) => {

			props.setAlertTitle("Ops! ocorreu um erro! [1]");
			props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. '+error.message+'');				
			props.setAlert(true);
			props.setSaving(false);
			return;						
								
		})
		.catch(error=> {

			props.setAlertTitle("Ops! ocorreu um erro! [2]");
			props.setAlertBody('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');				
			props.setAlert(true);
			props.setSaving(false);
			return;						

		});


	}	
		
};





