import { appReducer } from './appReducer';
import { orderReducer } from './orderReducer';
import { reservationReducer } from './reservationReducer';
import { agencyReducer } from './agencyReducer';
import { userReducer } from './userReducer';
import { customerReducer } from './customerReducer';
import { routeReducer } from './routeReducer';

import { combineReducers } from 'redux';

export const Reducers = combineReducers({
  appReducer: appReducer,
  orderReducer: orderReducer,
  reservationReducer: reservationReducer,
  agencyReducer:agencyReducer,
  userReducer:userReducer,
  customerReducer:customerReducer,
  routeReducer:routeReducer
});