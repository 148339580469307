import '../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Load Actions
import * as AppActions from '../Store/Actions/App';

// UpdateService dialog component
const SelectAgencyView = (props) => {

// set vars from props
const {
	dialog,
	setDialog,
	scrollBlock,
	setCurrentAgency,
	currentAgency,
	setAlert,
	setAlertTitle,
	setAlertBody
} = props;		

const [agency, setAgency] = React.useState('');
const [agencyEmployee, setAgencyEmployee] = React.useState('');
const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
const [selectedAgency, setSelectedAgency] = React.useState(''); 
const [selectedEmployee, setSelectedEmployee] = React.useState(''); 

var navigate = useNavigate();

const AgencyItems = [
			{
			"code": 12,
			"name": "Agência Turismo Agora"
			},
			{
			"code": 27,
			"name": "Agência Lua Sol"
			},
			{
			"code": 16,
			"name": "Agência By the fly"
			}			
];	
const [agencyItems, setAgencyItems] = React.useState(AgencyItems);

const EmployeeItems = [
			{
			"code": 22,
			"name": "Ronaldo Ramos"
			},
			{
			"code": 37,
			"name": "Luana Silva"
			},
			{
			"code": 46,
			"name": "Rogério Silveira"
			}			
];	
const [employeeItems, setEmployeeItems] = React.useState(EmployeeItems);
	
const applyAgencyAction = ()=> {

	/* Check if code if ok */
	/*if (!code || code.length < 6) {
		setAlert(true);
		setAlertTitle("Verifique o Código");
		setAlertBody("Informe o código de 6 digitos para para prosseguir.");	
		return;		
	} // end if	*/
	
	setSaving(true);
	
		setTimeout(function() {
			
			if (!selectedAgency.code) {
				setAlert(true);
				setAlertTitle("Ops! Ocorreu um problema!");
				setAlertBody("Você precisa selecionar uma agência para confirmar!");	
				setSaving(false);	
				return;				
			} // end if
			
			if (!selectedEmployee.code) {
				setAlert(true);
				setAlertTitle("Ops! Ocorreu um problema!");
				setAlertBody("Você precisa selecionar um funcionário da agência selecionada para confirmar!");	
				setSaving(false);
				return;	
			} // end if			
			
			var currentAgencyObj = {
				agencyCode:selectedAgency.code,
				agencyName:selectedAgency.name,
				employeeName:selectedEmployee.name,
				employeeCode:selectedEmployee.name,
			}
			setCurrentAgency(currentAgencyObj);			
			
			// setValidateRegistry(false);
			setDialog('selectAgencyModal',false);	

			setSaving(false);

			// Redirect to Order Start
			navigate("/order");			

			/*setAlert(true);
			setAlertAction("closeRegisterModal");
			setAlertTitle("Tudo certo! O cadastro foi validado!");
			setAlertBody("O seu cadastro está em análise e você será informado no seu email quando for aprovado. Caso seja reprovado, você receberá um email com o motivo e eventualmente a possibilidade de alterar dados inconsistentes.");	
			return;		*/	
			
			
		},1000);
	
}	
	
const close = ()=> {
	// setValidateRegistry(false);
	setDialog('selectAgencyModal',false);
	
	// Reset Fields
	setSelectedAgency("");
	setSelectedEmployee("");
		
	setTimeout(function() {
		if (scrollBlock) {
			document.body.classList.add('p-overflow-hidden');	
		} // end if 
	},300);
}	
	
const accept = () => {
	close();
}

const reject = () => {
	
}

const confirm1 = () => {
	confirmDialog({
		message: 'O seu email é a principal forma de comunicação entre a Memovo e você. Por isso é importante que valide o mesmo. Mesmo que seu cadastro seja aprovado, só será possível acessar a plataforma após validar o email informado.',
		header: 'Tem certeza que quer validar depois?',
		icon: 'pi pi-exclamation-triangle',
		accept,
		reject,
		acceptLabel:"Sim, tenho certeza!",
		rejectLabel:"Fechar",
		contentStyle:{maxWidth:"30vw",width:"30vw"}
	});
};
	
const footerContent = (
	<div className="modalFooterButtonsEnd">
		
			<div className="modalFooterButtonsRight">
				<ConfirmDialog />
				<Button label="Fechar" disabled={saving} icon="pi pi-times" className="p-button-text" onClick={() => close()} />
				<Button label="Confirmar" icon="pi pi-check" loading={saving} onClick={() => applyAgencyAction()} />
			</div>		
		
	</div>
);		

// Execute when dialog opens
React.useEffect(() => {

	setSaving(false);
	
  // Runs when UNMOUNT component
  return () => {
    
	setSaving(false);
	
	// Reset Fields
	setSelectedAgency("");	
	setSelectedEmployee("");	
	
  };	
			
},[]);


// Execute when dialog opens
React.useEffect(() => {

	// Only runs when  dialog opens
	if (dialog.target == 'selectAgencyModal' && dialog.status) {
		
		// Reset Fields
		setSelectedAgency("");	
		setSelectedEmployee("");
				
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
			
},[dialog]);
	
	return (
	<>
			<Dialog header="Selecione a agência ativa" closeOnEscape={false} draggable={false} closable={false} visible={dialog.target == 'selectAgencyModal' && dialog.status} style={{ width: '35vw' }} breakpoints={{ '1400px': '45vw','960px': '50vw', '641px': '100vw' }} onHide={() => close()} footer={footerContent} >
			
			
			<Box
				  sx={{
					width: "100%",
				  }}
				  className="terms"
				>
				
					<div className="termsModalIntro"> Selecione a agência e o funcionário para realizar compras como se fosse a agência. <br /><br /> <small> O pagamento do pedido deverá ser feito diretamente pela agência após a reserva. </small> </div>
				
					<Divider />
					
					<span className="p-float-label" style={{marginTop:"1.975rem"}}>
						<Dropdown id="agencySelector" disabled={saving} value={selectedAgency} onChange={(e) => setSelectedAgency(e.target.value)} options={agencyItems} optionLabel="name" placeholder="Selecione" className="w100" filter={true} filterLocale="pt-BR" emptyFilterMessage="Nenhum resultado" emptyMessage="Nenhum resultado" ></Dropdown>
						<label htmlFor="agencySelector">Agência</label>
					</span>	
					
					<span className="p-float-label" style={{marginTop:"1.975rem"}}>
						<Dropdown id="employeeSelector" disabled={!selectedAgency} value={selectedEmployee} onChange={(e) => setSelectedEmployee(e.target.value)} options={employeeItems} optionLabel="name" placeholder="Selecione" className="w100" filter={true} filterLocale="pt-BR" emptyFilterMessage="Nenhum resultado" emptyMessage="Nenhum resultado" ></Dropdown>
						<label htmlFor="employeeSelector">Funcionário</label>
					</span>						
					
			</Box>
			
						
            </Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  currentAgency:store.appReducer.currentAgency
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(SelectAgencyView);	
