import * as types from '../actionTypes';

const initialState = { 
	selectedReservation:{},
	reservationsRows:[]
}
 
export const reservationReducer = (state = initialState, action) => {
		
    switch (action.type) {
        case types.SET_RESERVATION:
            return { ...state, selectedReservation: action.payload }	
        case types.SET_RESERVATIONSROWS:
            return { ...state, reservationsRows: action.payload }				
        default:
            return state
    }
}

