import '../../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ReCAPTCHA from "react-google-recaptcha"
import * as Config from '../../Config/api';

// Load Actions
import * as AppActions from '../../Store/Actions/App';
import * as LoginActions from '../../Store/Actions/Login';

import {
	validarEmail,
	validarTelefone,
	maskPhone
} from '../../Components/Utils';

// UpdateService dialog component
const ForgotPasswordView = (props) => {

// set vars from props
const { 
	forgotPassword,
	setForgotPassword,
	scrollBlock,
	setAlert,
	setAlertTitle,
	setAlertBody,
	recoverPasswordGenerate,
	recoverPasswordConfirm,
	recoverPasswordUpdate
} = props;		

const [email, setEmail] = React.useState('');
const [mobilePhone, setMobilePhone] = React.useState('');
const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
const [selectPhone, setSelectPhone] = React.useState(false);
const [tryPhone, setTryPhone] = React.useState(false);
const [codeGenerated, setCodeGenerated] = React.useState(false);
const [passwordChange, setPasswordChange] = React.useState(false); 
const [code, setCode] = React.useState(""); 
const [savingCode, setSavingCode] = React.useState(false); 


const [password, setPassword] = React.useState('');
const [passwordConfirm, setPasswordConfirm] = React.useState('');

/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}


// Suggested password panel
const PasswordTip = (
	<>
		<Divider />
		<p className="mt-1" style={{fontSize:"0.9rem"}}>Sugestão de senha</p>
		<ul className="pl-2 ml-2 mt-0 line-height-3" style={{fontSize:"0.8rem"}}>
			<li>Ao menos 1 letra maiúscula</li>
			<li>Ao menos 1 letra minúscula</li>
			<li>Ao menos 1 número </li>
			<li>Ao menos 1 símbolo ! # $ % & * + - = ? @ </li>
			<li>De 6 a 12 caracteres</li>
		</ul>
	</>
);

const sendBKP = ()=> {
	
	// Reset code
	setCode("");

	/* Check if its email or phone*/
	if (!selectPhone) {

		if (!email) {
			setAlert(true);
			setAlertTitle("Verifique o Email");
			setAlertBody("Informe o email do seu cadastro para prosseguir.");	
			return;		
		} // end if	
			
		if (!validarEmail(email)) {
			setAlert(true);
			setAlertTitle("Verifique o Email");
			setAlertBody("O email informado é inválido. Verifique novamente.");	
			return;		
		} // end if	

	} // end if
	else {
		

		if (!mobilePhone) {
			setAlert(true);
			setAlertTitle("Verifique o Telefone");
			setAlertBody("Informe o telefone celular do seu cadastro para prosseguir.");	
			return;		
		} // end if	
			
		if (!validarTelefone(mobilePhone)) {
			setAlert(true);
			setAlertTitle("Verifique o Telefone");
			setAlertBody("O telefone informado é inválido. Verifique novamente.");	
			return;		
		} // end if		
		
	} // end else
	
	setSaving(true);
	
	// Execute Recaptcha Token
	executeReCaptcha().then(function(token) {

		console.log("[RECPATCHA TOKEN FORGOT PASSWORD]--->"+JSON.stringify(token,null,2));
		setTimeout(function() {
			
			/* Simulate Returns */
			if (!selectPhone) {
				/* Simulate Email was not found */
				/*setAlert(true);
				setAlertTitle("Ops! Ocorreu um problema!");
				setAlertBody("Não encontramos o email informado em nossa base de dados. Verifique o email e tente novamente. Caso o problema persista, tente recuperar a senha utilizando o telefone celular cadastrado.");	
				setSaving(false);
				setTryPhone(true);
				return;*/
			} // end if	
			else {
				/* Simulate Phone was not found */
				/*setAlert(true);
				setAlertTitle("Ops! Ocorreu um problema!");
				setAlertBody("Não encontramos o telefone informado em nossa base de dados. Verifique o telefone e tente novamente. Caso o problema persista, entre em contato para receber suporte.");	
				setSaving(false);
				setTryPhone(false);
				return;	*/	
			} // end else
						
			setEmail("");
			setSaving(false);
			
			setCodeGenerated(true);
			
		},2000);		
		
	});
	
}

const send = ()=> {
	
	// Reset code
	setCode("");

	if (!email) {
		setAlert(true);
		setAlertTitle("Verifique o Email");
		setAlertBody("Informe o email do seu cadastro para prosseguir.");	
		return;		
	} // end if
		
	if (!validarEmail(email)) {
		setAlert(true);
		setAlertTitle("Verifique o Email");
		setAlertBody("O email informado é inválido. Verifique novamente.");	
		return;		
	} // end if	 
	
	setSaving(true);  
	
	// Execute Recaptcha Token
	executeReCaptcha().then(function(token) {

		var props = {
			setSaving:setSaving,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setCodeGenerated:setCodeGenerated,
			setEmail:setEmail
		}
		var data = {
			email:email,
			resend:false
		}
		recoverPasswordGenerate(data,props);	
		
		      
	}).catch(error=> {

		setAlertTitle("Ops! Parece que há um erro no ReCaptcha do Google!");
		setAlertBody("O ReCaptcha do Google não carregou corretamente. Recarregue a página para corrigir.");	     			
		setAlert(true);
		return; 

	});
	
}


const validate = ()=> {

	/* Check if code if ok */
	if (!code || code.length < 6) {
		setAlert(true);
		setAlertTitle("Verifique o Código");
		setAlertBody("Informe o código de 6 digitos para para prosseguir.");	
		return;		
	} // end if	
	
	setSaving(true);
	
	// Execute Recaptcha Token
	executeReCaptcha().then(function(token) {
		
		setSaving(true);  

		var props = {
			setSaving:setSaving,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setCodeGenerated:setCodeGenerated,
			setEmail:setEmail,
			setCode:setCode,
			setPasswordChange:setPasswordChange
		}
		var data = {
			code:code
		}
		recoverPasswordConfirm(data,props);	
			
	});
	
}


const updatePassword = ()=> {

// Check required fields
if (!password || !passwordConfirm) {
	setAlert(true);
	setAlertTitle("Verifique as informações");
	setAlertBody("Preencha a nova senha e a confirmação de senha para prosseguir.");	
	return;
} // end if

// Check if password fields are equal
if (password  != passwordConfirm) {
	setAlert(true);
	setAlertTitle("Verifique a senha");
	setAlertBody("O campo de senha deve ser igual ao de confirmação de senha. Verifique.");	
	return;
} // end if

// Check if password is strong enough
var term = password;
var mediumPwd = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
if (!mediumPwd.test(term)) {
	setAlert(true);
	setAlertTitle("Verifique a senha de acesso");
	setAlertBody("A complexidade da senha deve ser no mínimo média. Verifique.");	
	return;    
} // end if	

	// Execute Recaptcha Token
	executeReCaptcha().then(function(token) {

		setSaving(true);  

		var props = {
			setSaving:setSaving,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setCodeGenerated:setCodeGenerated,
			setEmail:setEmail,
			setCode:setCode,
			setPasswordChange:setPasswordChange,
			setForgotPassword:setForgotPassword
		}
		var data = {
			newPassword:password
		}
		recoverPasswordUpdate(data,props);	
	
		
	});
	
}


const sendCode = ()=> {

	setSavingCode(true);  

	var props = {
		setSaving:setSavingCode,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setCodeGenerated:setCodeGenerated,
		setEmail:setEmail
	}
	var data = {
		email:email,
		resend:true
	}
	recoverPasswordGenerate(data,props);	
		
}


const footerContent = (
	<div className="modalFooterButtonsEnd">
		
			<div className="modalFooterButtonsRight">
				<Button label="Fechar" disabled={saving} icon="pi pi-times" className="p-button-text" onClick={() => close()} />
				<Button label="Enviar" icon="pi pi-check" loading={saving} onClick={() => send()} />
			</div>		
		
	</div>
);		

const footerContentCode = (
	<div className="modalFooterButtonsEnd">
		
			<div className="modalFooterButtonsRight">
				<Button label="Cancelar" disabled={saving} icon="pi pi-times" className="p-button-text" onClick={() => close()} />
				<Button label="Validar" icon="pi pi-check" loading={saving} onClick={() => validate()} />
			</div>		
		
	</div>
);		

const footerContentPassword = (
	<div className="modalFooterButtonsEnd">
		
			<div className="modalFooterButtonsRight">
				<Button label="Cancelar" disabled={saving} icon="pi pi-times" className="p-button-text" onClick={() => close()} />
				<Button label="Atualizar" icon="pi pi-check" loading={saving} onClick={() => updatePassword()} />
			</div>		
		
	</div>
);		
	

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (forgotPassword) {
		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
	
    return () => {
      
	  
	  setCodeGenerated(false);
	  setSelectPhone(false);
	  setPasswordChange(false);
	  setTryPhone(false);
	  
	  setCode("");
	  setPassword("");
	  setPasswordConfirm("");
	  setEmail("");
	  setMobilePhone("");
	  
    };	
			
},[forgotPassword]);

const close = ()=> {
	setForgotPassword(false);

	setTimeout(function() {
		if (scrollBlock) {
			document.body.classList.add('p-overflow-hidden');	
		} // end if
	},300);
}


	return (
	<>
			<Dialog header="Esqueceu sua senha?" visible={forgotPassword} style={{ width: '35vw' }} breakpoints={{ '1400px': '45vw','960px': '50vw', '641px': '100vw' }} onHide={() => close()} footer={!codeGenerated && !passwordChange ? footerContent : (passwordChange ? footerContentPassword : footerContentCode)    } >
			
			{(!codeGenerated && !passwordChange) &&
			<Box
				  sx={{
					width: "100%",
				  }}
				  className="terms"
				>
					<div className="termsModalIntro"> Digite o email cadastrado na MeMovo para recuperar a sua senha.</div>
					
					<Divider />
					
					<span className="p-float-label" style={{marginTop:"1.975rem"}}>
						<InputText id="email" disabled={saving} className="emailInput" value={email} onChange={(e) => setEmail(e.target.value)} style={{width:"100%"}} />
						<label htmlFor="email">E-mail</label>
					</span>		
					
			</Box>
			}
		
			
			{(codeGenerated && !passwordChange) &&
			<Box
				  sx={{
					width: "100%",
				  }}
				  className="terms"
				>
					<div className="termsModalIntro"> Enviamos um código de 6 dígitos para o seu email. Informe o código recebido e altere a sua senha em seguida. <br /><br /> <small> O código tem a validade de 5 minutos. </small> </div>
					
					<Divider />
					
					<span className="p-float-label" style={{marginTop:"1.975rem"}}>
						<InputText id="code" keyfilter="int" maxLength={6} autoComplete="off" disabled={savingCode} className="emailInput" value={code} onChange={(e) => setCode(e.target.value)} style={{width:"50%",fontSize:"2rem"}} />
						<label htmlFor="code">Código de 6 dígitos</label>
					</span>	
					
					<Divider />
					
					<div className="termsModalIntro" style={{marginBottom:"10px"}}>Não recebeu o código? Tente reenviar </div>
					<Button label="Reenviar código" style={{marginBottom:"10px"}} loading={savingCode}  disabled={savingCode} outlined onClick={(e) => { sendCode() }} />					
					
			</Box>
			}
			
			{(passwordChange) &&
			<Box
				  sx={{
					width: "100%",
				  }}
				  className="terms"
				>
				
				<div className="termsModalIntro"> Informe a nova senha, confirme e atualize. Em seguida, faça o login. <strong>ATENÇÃO:</strong> Você tem 5 minutos para mudar a senha. Caso não mude nesse tempo, terá que gerar um novo código.</div>
					
				<Divider />				
				
				<Grid container spacing={4} style={{marginTop:"-20px"}}>
				  <Grid item xs={12} sm={6}>
					<span className="p-float-label">
						<Password inputId="password" autoComplete="new-password" disabled={saving ? true : false} toggleMask value={password} onChange={(e) => setPassword(e.target.value)}  style={{width:"100%"}} feedback={true} promptLabel="Escolha uma senha" weakLabel="Senha fraca" mediumLabel="Senha média" strongLabel="Senha forte" footer={PasswordTip} tooltip="A senha tem que ser no mínimo média" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
						<label htmlFor="password">Senha <span className="requiredField">*</span></label>
					</span>	
				  </Grid>
				  <Grid item xs={12} sm={6}>
					<span className="p-float-label" >
						<Password inputId="passwordConfirm" autoComplete="new-password" disabled={saving ? true : false} toggleMask value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)}  style={{width:"100%"}} feedback={false}  />
						<label htmlFor="passwordConfirm">Confirme a senha <span className="requiredField">*</span></label>
					</span>	
				  </Grid>
				</Grid>				
					
			</Box>
			}			
			
			
            </Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions,...LoginActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ForgotPasswordView);	
