

// Screens
import Blank from "./Screens/Blank.js";
import Home from "./Screens/Home/Main.js";
import Order from "./Screens/Order/Main.js";
import OrderRoute from "./Screens/Order/Route.js";

import OrderRentcar from "./Screens/Order/rentcarSearch.js";

import OrderTransfer from "./Screens/Order/transferSearch.js";
import OrderTransferSearchResult from "./Screens/Order/transferSearchResult.js";
import OrderTransferConfirm from "./Screens/Order/transferSearchResultConfirm.js";
import OrderTransferReservationConfirm from "./Screens/Order/transferSearchReservationConfirm.js";
import OrderTransferReservationPayment from "./Screens/Order/transferReservationPayment.js";

import OrderTransport from "./Screens/Order/transportSearch.js";
import OrderTransportSearchResult from "./Screens/Order/transportSearchResult.js";
import OrderTransportConfirm from "./Screens/Order/transportSearchResultConfirm.js";
import OrderTransportReservationConfirm from "./Screens/Order/transportSearchReservationConfirm.js";
import OrderTransportReservationPayment from "./Screens/Order/transportReservationPayment.js";
import OrderTransportReservationRoutePayment from "./Screens/Order/transportReservationRoutePayment.js";

 
import Admin from "./Screens/Admin/Main.js";
import AdminReservations from "./Screens/Admin/Reservations.js";
import AdminAgencies from "./Screens/Admin/Agencies.js";
import AdminAgenciesApproval from "./Screens/Admin/AgenciesApproval.js";

import AdminUserProfile from "./Screens/Admin/UserProfile.js";
import AdminAgencyProfile from "./Screens/Admin/AgencyProfile.js";

import AdminDashboard from "./Screens/Admin/Dashboard.js";
import AdminUsers from "./Screens/Admin/Users.js";
import AdminCustomers from "./Screens/Admin/Customers.js";
import AdminRoutes from "./Screens/Admin/Routes.js";
import AdminReports from "./Screens/Admin/Reports.js";
import AdminConfigurations from "./Screens/Admin/Configurations.js";

var dashRoutes = [
  {
    path: "/home",
    name: "Início",
    component: Home
  },  
  {
    path: "/order",
    name: "Selecione o transporte",
    component: Order
  },
  {
    path: "/order/route",
    name: "Selecione o transporte",
    component: OrderRoute
  },  
  {
    path: "/order/transport",
    name: "Compra de transporte",
    component: OrderTransport
  }, 
  {
    path: "/order/rentcar",
    name: "Aluguel de Carro",
    component: OrderRentcar
  },  
  {
    path: "/order/transfer",
    name: "Compra de Transfer",
    component: OrderTransfer
  }, 
  {
    path: "/order/transfer/searchResult",
    name: "Compra de Transfer > Buscando",
    component: OrderTransferSearchResult
  }, 
  {
    path: "/order/transport/searchResult",
    name: "Compra de Transporte > Buscando",
    component: OrderTransportSearchResult
  }, 
  {
    path: "/order/transport/confirm",
    name: "Compra de Transporte > Confirmação",
    component: OrderTransportConfirm
  },  
  {
    path: "/order/transport/reservation/confirm",
    name: "Compra de Transporte > Confirmação Reserva",
    component: OrderTransportReservationConfirm
  }, 
  {
    path: "/order/transfer/confirm",
    name: "Compra de Transfer > Confirmação",
    component: OrderTransferConfirm
  },  
  {
    path: "/order/transfer/reservation/confirm",
    name: "Compra de Transfer > Confirmação Reserva",
    component: OrderTransferReservationConfirm
  }, 
  {
    path: "/order/transport/reservation/payment",
    name: "Compra de Transporte > Pagamento de Reserva",
    component: OrderTransportReservationPayment 
  },  
  {
    path: "/order/transfer/reservation/payment",
    name: "Compra de Transfer > Pagamento de Reserva",
    component: OrderTransferReservationPayment 
  },  
  {
    path: "/order/transport/reservation/route/payment",
    name: "Compra de Transporte > Pagamento de Reservas de Roteiro",
    component: OrderTransportReservationRoutePayment 
  },    
  { 
    path: "/admin",
    name: "Área administrativa",
    component: Admin
  },
  { 
    path: "/admin/dashboard",
    name: "Área administrativa - Dashboard",
    component: AdminDashboard
  },  
  { 
    path: "/admin/agencies",
    name: "Área administrativa - Agências",
    component: AdminAgencies
  },  
  { 
    path: "/admin/agenciesApproval",
    name: "Área administrativa - Agências aguardando aprovação",
    component: AdminAgenciesApproval
  },  
  { 
    path: "/admin/userProfile",
    name: "Área administrativa - Perfil do usuário",
    component: AdminUserProfile
  },
  { 
    path: "/admin/agencyProfile",
    name: "Área administrativa - Perfil da Agência",
    component: AdminAgencyProfile
  },  
  { 
    path: "/admin/users",
    name: "Área administrativa - Usuários da Agência",
    component: AdminUsers
  },    
{ 
    path: "/admin/customers",
    name: "Área administrativa - Clientes da Agência",
    component: AdminCustomers
  },      
{ 
    path: "/admin/routes",
    name: "Área administrativa - Roteiros do usuário",
    component: AdminRoutes
  },      
  { 
    path: "/admin/reservations",
    name: "Área administrativa - Reservas",
    component: AdminReservations
  },    
  { 
    path: "/admin/reports",
    name: "Área administrativa - Relatórios",
    component: AdminReports
  },      
  { 
    path: "/admin/configurations",
    name: "Área administrativa - Configurações",
    component: AdminConfigurations
  },      
];
export default dashRoutes;
