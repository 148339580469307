import React from 'react';
import axios from 'axios';
import moment from 'react-moment';
import * as types from '../actionTypes';
import * as config from '../../Config/api.js';
import {
  osVersion,
  osName,
  fullBrowserVersion,
  mobileVendor,
  mobileModel,
  deviceType,
  deviceDetect,
  isBrowser,
  isMobile
} from "react-device-detect";	
	
export const retrieveCompanyDataByDocument  = (data,props) => { 
		
		return dispatch => {

					// Set general data
					const preparedData = {};
					preparedData.documentNumber = data.companyDocument;
					
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/company/retrieveCompanydocumentInfo',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							Accept: 'application/json'
						}
					})							
					
					.then((response) => {

						// Document already registered
						if (response.data.documentAlreadyUsed) {
							
							props.setAlertTitle("Ops! Parece que esse CNPJ já está cadastrado!");
							props.setAlertBody("O CNPJ informado já está cadastrado na plataforma Memovo. Faça o login para acessar a área das agências. Caso você acredite que isso seja um erro, entre em contato informando código REG03. ");
							props.setAlert(true);	
							props.setLoadingData(false);							
							return;
							
						} // end if

						if (response.data.success) {

							// IF SUCCEED
							if (response.data.status === 1) {

								// Tratar possiveis retornos como por exemplo CNPJ nao encontrado
								// ...
								
								// Check if Document is active
								let documentStatus = response.data.data.situacao ? response.data.data.situacao.nome : null;
								if (documentStatus != "Ativa") {
									
									props.setAlertTitle("Ops! Parece que o CNPJ tem um problema!");
									props.setAlertBody("O CNPJ informado não está ativo na RFB. Dessa forma, não é possível seguir com o cadastro. Regularize a situação do CNPJ junto a RFB e tente novamente. Caso você acredite que isso seja um erro, entre em contato informando código REG05. ");
									props.setAlert(true);	
									props.setLoadingData(false);									
									return;
									
								} // end if	
    							
								// Fill received fileds							
								props.setCompanyLegalName(response.data.data.razao);
								props.setCompanyFantasyName(response.data.data.fantasia ? response.data.data.fantasia : "Não definido");
								props.setCompanyEmail(response.data.data.email ? response.data.data.email : "Não definido");

								var phone = response.data.data.telefones.length > 1 ? "("+response.data.data.telefones['0'].ddd+") "+response.data.data.telefones['0'].numero : "("+response.data.data.telefones['1'].ddd+") "+response.data.data.telefones['1'].numero;
								props.setCompanyPhone(phone);
								props.setCompanyStateAuto(response.data.data.matrizEndereco.uf);
								props.setCompanyCityAuto(response.data.data.matrizEndereco.cidade);

								// Try to find responsible person on socios node
								var socios = response.data.data.socios  ? response.data.data.socios : [];
								var responsavelQualificacao = response.data.data.responsavelQualificacao;
								var responsibleName = "Não definido";
								var socioObj = {};
 
								console.log('SOCIOS >>>>>>>>>>>>>>>>> '+JSON.stringify(socios,null,2));

								// Se não encontrar, busca pelo primeiro sócio com qualificacao_socio.id igual a responsavelQualificacao.id
								const socioComQualificacaoCorrespondente = socios.filter(socio => socio.qualificacao_socio.id === responsavelQualificacao.id); 
								var personReturnArray = [];
								if (socioComQualificacaoCorrespondente) {

									for(var i = 0; i < socioComQualificacaoCorrespondente.length; i++) {

										// Set default selection of dropdown
										if (i == 0) {
											var firstPersonObj = {
												code:socioComQualificacaoCorrespondente[i].nome, 
												name:socioComQualificacaoCorrespondente[i].nome
											}
										}

										var personObj = {
											code:socioComQualificacaoCorrespondente[i].nome, 
											name:socioComQualificacaoCorrespondente[i].nome
										}
										personReturnArray.push(personObj);


										socioObj = { cpf: socioComQualificacaoCorrespondente[i].cpf_cnpj_socio, nome: socioComQualificacaoCorrespondente[i].nome };
										responsibleName = socioComQualificacaoCorrespondente[i].nome;										

									}

								} // end if
								
								var responsavel = response.data.data.responsavel ? response.data.data.responsavel : responsibleName;
																
								// Show alert to warn partner can be selected and enalbe selection
								if (personReturnArray.length > 1) {
									props.setAlertTitle("Encontramos mais de um sócio na sua empresa");
									props.setAlertBody("A sua empresa tem mais de um sócio que pode ser considerado o responsável legal. Se necessário, selecione o sócio que deseja que seja o responsável legal.");
									props.setAlert(true);
									props.setPersonCompanyNameStatus(false);

									// set  solo company
									props.setSoloCompany(false);

								} // else if
								else if (personReturnArray.length === 1) {
									props.setPersonCompanyNameStatus(true);

									// set  solo company
									props.setSoloCompany(false);

								} // end else
								else {

									// Check if its a solo company
									if (responsavelQualificacao.id == "50") {

										var personObj = {
											code:"O próprio empresário", 
											name:"O próprio empresário"
										}
										var firstPersonObj = {
											code:"O próprio empresário", 
											name:"O próprio empresário"
										}										
										personReturnArray.push(personObj);

										// set  solo company
										props.setSoloCompany(true);

										props.setAlertTitle("Não encontramos sócios na sua empresa");
										props.setAlertBody("A sua empresa é uma empresa individual. Por não possuir sócios o próprio empresário é o responsável legal. Utilize o CPF do responsável legal da empresa mais abaixo.");
										props.setAlert(true);
							

									} // end if
									else {

										// Para casos que não é solo company e nao retornou socios

										// set  solo company
										props.setSoloCompany(false);

										props.setAlertTitle("Não encontramos sócios na sua empresa");
										props.setAlertBody("A sua empresa NÃO é uma emprea individual, e não foram encontrados sócios. Entre em contato com o suporte.");
										props.setAlert(true);

									} // end else

									props.setPersonCompanyNameStatus(true);


								} // end else

								props.setDropdownPersonNameItems(personReturnArray);
								props.setPersonCompanyName(firstPersonObj); 
								
								props.setAutoFieldsFilled(true);
								props.setLoadingData(false);

								// Store full result
								props.setCompanyDocumentMeta(response.data.data);

								return;
							
							} // end if
							else {

								props.setCompanyLegalName("");
								props.setCompanyFantasyName("");
								props.setCompanyEmail("");
								props.setCompanyPhone("");
								props.setCompanyStateAuto("");
								props.setCompanyCityAuto("");
								props.setPersonCompanyName("");
								
								props.setLoadingData(false);								
								props.setAlertTitle("Ops! Ocorreu um problema");
								props.setAlertBody(response.data.data.erro+" Se você acredita que isso seja um erro, entre em contato e informe o código 04.");
								props.setAlert(true);							
								return;								
								
							} // end else
							
						} // end if
						else {
							
							props.setCompanyLegalName("");
							props.setCompanyFantasyName("");
							props.setCompanyEmail("");
							props.setCompanyPhone("");
							props.setCompanyStateAuto("");
							props.setCompanyCityAuto("");
							props.setPersonCompanyName("");
							
							props.setLoadingData(false);							
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar os dados do CNPJ. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {

						console.log('ERROR '+JSON.stringify(error.message ,null,2));
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar os dados do CNPJ. Tente novamente. Caso o problema persista entre em contato informando código 02.");
						props.setAlert(true);	
						props.setLoadingData(false);							
						return;
					});
					
	} // end dispatch

};

	
export const retrievePersonDataByDocument  = (data,props) => { 
		
		return dispatch => {

					// Set general data
					const preparedData = {};
					preparedData.documentNumber = data.personDocument;
					
					// Connect to REGISTER Endpoint
					axios({
						   url    : config.API_URL+'/company/retrievePersondocumentInfo',
						   method : 'POST',
						   data   : preparedData,
						   headers: {
							Accept: 'application/json'
						}
					})	
					.then((response) => {

						// Document already registered
						if (response.data.documentAlreadyUsed) {
							
							props.setAlertTitle("Ops! Parece que esse CPF já está cadastrado!");
							props.setAlertBody("O CPF informado já está cadastrado na plataforma Memovo e vinculado a uma empresa. Faça o login para acessar a área das agências. Caso você acredite que isso seja um erro, entre em contato informando código 03. ");
							props.setAlert(true);	
							props.setPersonLoadingData(false);								
							return;
							
						} // end if

						if (response.data.success) {
							
							// IF SUCCEED
							if (response.data.status === 1) {
								
								// Tratar possiveis retornos como por exemplo CNPJ nao encontrado
								// ...

								// Check if Document is active
								let documentStatus = response.data.data.situacao ? response.data.data.situacao : null;
								if (documentStatus != "Regular") {
									
									props.setAlertTitle("Ops! Parece que o CPF tem um problema!");
									props.setAlertBody("O CPF informado não está regular junto a RFB. Dessa forma, não é possível seguir com o cadastro utilizando esse CPF. Regularize a situação do CPF junto a RFB e tente novamente. Caso você acredite que isso seja um erro, entre em contato informando código 06. ");
									props.setAlert(true);	
									props.setPersonLoadingData(false);									
									return;
									
								} // end if									
    							
								// Fill received fileds							
								props.setPersonName(response.data.data.nome ? response.data.data.nome : 'Não definido');	
								props.setBirthday(response.data.data.nascimento ? response.data.data.nascimento : 'Não definido');	

								props.setAutoPersonFieldsFilled(true);
								props.setPersonLoadingData(false);

								// Store full result
								props.setCompanyPersonDocumentMeta(response.data.data);

								return;
							
							} // end if
							else {

								props.setPersonName("");
								props.setBirthday("");
								
								props.setPersonLoadingData(false);								
								props.setAlertTitle("Ops! Ocorreu um problema");
								props.setAlertBody(response.data.data.erro+" Se você acredita que isso seja um erro, entre em contato e informe o código 04.");
								props.setAlert(true);							
								return;								
								
							} // end else
							
						} // end if
						else {
							
							props.setPersonName("");
							props.setBirthday("");
							
							props.setPersonLoadingData(false);							
							props.setAlertTitle("Ops! Ocorreu um problema [1]");
							props.setAlertBody("Ocorreu um problema ao buscar os dados do CPF. Tente novamente. Caso o problema persista entre em  contato informando código 01.");
							props.setAlert(true);							
							return;
							
						} // end else
						
					})
					.catch(error=> {
						props.setAlertTitle("Ops! Ocorreu um problema [2]");
						props.setAlertBody("Ocorreu um problema ao buscar os dados do CPF. Tente novamente. Caso o problema persista entre em contato informando código 02. ("+error.message+")");
						props.setAlert(true);	
						props.setPersonLoadingData(false);							
						return;
					});
					
	} // end dispatch

};


export const registerCompany  = (data,props) => {

	return dispatch => {
							
			// Prepare for user creation
			const preparedData = {};
			
			// Set general data
			preparedData.companyLegalName = data.companyLegalName;
			preparedData.companyFantasyName = data.companyFantasyName;
			preparedData.companyDocument = data.companyDocument;
			preparedData.companyEmail = data.companyEmail;
			preparedData.companyPhone = data.companyPhone;
			preparedData.companyLegalResponsibleName = data.personCompanyName.code;
			preparedData.companyState = data.companyState;
			preparedData.companyCity = data.companyCity;
			preparedData.companyMetadata = data.companyDocumentMeta;

			preparedData.companyPersonDocument = data.personDocument;

			let temp = data.birthDay ? data.birthDay.split("/") : null;
			preparedData.companyPersonBirthdate = (temp.length > 0) ? temp[2]+"-"+temp[1]+"-"+temp[0] : null;

			preparedData.companyPersonMetadata = data.companyPersonDocumentMeta;
			preparedData.companyPersonName = data.personName;
			preparedData.companyPersonMobilePhone = data.personPhone;
			preparedData.companyPersonUserEmail = data.email;
			preparedData.companyPersonUserPassword = data.password;
						
			// Connect to REGISTER Endpoint
			axios({
				url    : config.API_URL+'/company/register',
				method : 'POST',
				data   : preparedData,
				headers: {
				Accept: 'application/json'
			}
			})				
			.then((response) => {
				
			// If an error occured
			if (response.data.error) {							
				props.setAlertTitle("Ops! Parece que ocorreu um erro!");
				props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. Erro retornado: '+response.data.errorMessage+' ('+response.data.errorContext+')');				
				props.setAlert(true);
				props.setSaving(false);
				return;			
			} // end if	

			// If success
			if (response.data.success) {

				// If company already exist
				if (response.data.documentAlreadyUsed) {
					props.setAlertTitle("Ops! Parece que esse CNPJ já está cadastrado!");
					props.setAlertBody("O CNPJ informado já está cadastrado na plataforma Memovo. Faça o login para acessar a área das agências. Caso você acredite que isso seja um erro, entre em contato informando código REG03. ");				
					props.setAlert(true);	
					props.setSaving(false);		
					return;			
				} // end if

				// User email already used
				if (response.data.emailAlreadyUsed) {
					props.setAlertTitle("Ops! Parece que esse Email já está cadastrado!");
					props.setAlertBody("O Email informado já está sendo utilizado por outro usuário na plataforma Memovo. Faça o login para acessar a plataforma. Caso você acredite que isso seja um erro, entre em contato informando código REG04. ");				
					props.setAlert(true);	
					props.setSaving(false);
					return;					
				} // end if			

				// If company already exist
				if (response.data.token) {
					
						// set button loading off
						props.setSaving(false);

						// Close Register Modal
						props.setDialog('registerModal',false);
									 
						// Set @memovo/userData obj
						var userObj = {
							userToken:response.data.token,
							AgencyLegalName:response.data.AgencyLegalName,
							AgencyShortLegalName:response.data.AgencyShortLegalName,
							AgencyTradingName:response.data.AgencyTradingName,
							PersonFirstName:response.data.PersonFirstName,
							PersonCompleteName:response.data.PersonCompleteName,
							//Master:true, // force master agency
							Master:false
						}		
						const saveObj = JSON.stringify(userObj);
						
						// save User Data on Local Storage
						localStorage.setItem("@memovo/userData",saveObj);
						localStorage.setItem("@memovo/companyStatus",response.data.statusToken);	
						localStorage.setItem("@memovo/activationStatus",response.data.activationStatusToken);	
						localStorage.setItem("@memovo/logged",1);

						// Propagate received data to interface
						//props.setMasterUser(true); // force master agency
						props.setMasterUser(false);
						props.setAgencyName(userObj.AgencyShortLegalName);
						props.setAgencyUser(userObj.PersonCompleteName);

						props.setLoginStatus(true);
						props.setActivationStatus(false);
						props.setCurrentCompanyStatus(0); // Under review default status

						// Show return Alert
						props.setAlertTitle("Tudo certo!");

						if (!response.data.notified.underReview) {
							props.setAlertBody("O cadastro de sua agência foi realizado com sucesso! Em seguida você deve validar o email de login informado. Lembre-se que só poderá acessar a plataforma após validá-lo. Aguarde a análise do cadastro para começar a usar a plataforma!. ATENÇÃO: Ocorreu um problema ao enviar o email de confirmação.");	
						} else {
							props.setAlertBody("O cadastro de sua agência foi realizado com sucesso! Em seguida você deve validar o email de login informado. Lembre-se que só poderá acessar a plataforma após validá-lo. Aguarde a análise do cadastro para começar a usar a plataforma!");
							
							// Open Validate Screen if email notification suceed
							props.setDialog('validateEmail',true);							

						} // end else

						props.setAlert(true);
								
					return;
															
				} // end if
				// If Token not received show message without automatic login [EXCEPTION]
				else {

						// Show return Alert
						props.setAlert(true);
						props.setAlertTitle("Tudo certo!");
						props.setAlertBody("O cadastro de sua agência foi realizado com sucesso! Aguarde a análise do cadastro para começar a usar a plataforma. Você receberá um email em até 48h úteis.");

					return;

				} // end else


			} // end if

			}, (error) => {
				props.setAlertTitle("Ops! Parece que ocorreu um erro de rede!");
				props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. [3]');				
				props.setAlert(true);
				props.setSaving(false);
				return;				
			})
			.catch(error=> {
				props.setAlertTitle("Ops! Parece que ocorreu um erro de rede!");
				props.setAlertBody('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente. [4]');				
				props.setAlert(true);
				props.setSaving(false);
				return;	
			});			

			/*
{
  "companyLegalName": "Exemplo Empresa Ltda",
  "companyFantasyName": "Exemplo",
  "companyDocument": "03.887.978/0001-05",
  "companyEmail": "contato@exemplo.com.br",
  "companyPhone": "(11) 98765-4321",
  "companyLegalResponsibleName": "João da Silva",
  "companyState": "SP",
  "companyCity": "São Paulo",
  "companyMetadata": {},
  "companyPersonDocument": "805.402.280-87",
  "companyPersonBirthdate": "1990-01-01",
  "companyPersonMetadata": {
    "observacao": "Nenhuma"
  },
  "companyPersonName": "Maria de Souza",
  "companyPersonMobilePhone": "(11) 91234-5678",
  "companyPersonUserEmail": "fmoreno@realweb.com.br",
  "companyPersonUserPassword": "senha123"
}
			setLoginStatus:setLoginStatus,
			setActivationStatus:setActivationStatus,
			setCurrentCompanyStatus:setCurrentCompanyStatus,			
			*/

			
	} // end dispatch
		
};

export const activateProfileGenerate  = (props) => {

	return dispatch => {
	
				// Retrieve userData and userToken
				var result = localStorage.getItem("@memovo/userData");
				var userData = JSON.parse(result); 
				var resultActivationStatus = localStorage.getItem("@memovo/activationStatus");	

				// Prepare POST data
				var preparedData = {
					activationToken:resultActivationStatus
				}

				// Connect to REGISTER Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/user/activateProfileGenerate',
					   method : 'POST',
					   data: preparedData,
					   headers: {
						"x-access-token": userData.userToken,
						Accept: 'application/json'
					}
				})	
				.then((response) => {
			
					// If an error occured. Check error is blocking or non-blocking.
					if (response.data.error) {

						props.setAlertTitle("Ops! Parece que ocorreu um erro!");
						props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. Erro retornado: '+response.data.errorMessage+' ('+response.data.errorContext+')');				
						props.setAlert(true);
						props.setSaving(false);
						props.setDialog('validateEmail',false); 
						return;	
					
					} // end if	*/
			
					if (response.data.success) {
											
						// If company already exist
						if (response.data.alreadyActivated) {

							// Close dialog
							// ...

							props.setAlertTitle("Ops! Parece que o email já está ativado");
							props.setAlertBody('Identificamos que o seu email já está ativado, não sendo necessário gerar o código e ativar novamente.');				
							props.setAlert(true);
							props.setSaving(false);
							props.setDialog('validateEmail',false); 
							return;	

						}
					
							// set loading off
							props.setGeneratingCode(false);

							props.setAlertTitle("Tudo certo! Código enviado pro seu email!");
							props.setAlertBody('Um código de 6 números foi enviado para o seu email. Digite o código enviado para validar o seu email e o seu cadastro. O código tem validade de 10 minutos.');				
							props.setAlert(true);
							props.setSaving(false);
							return;	

							
					} // end if
					else {
						props.setAlertTitle("Ops! Parece que ocorreu um erro!");
						props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. [2]');				
						props.setAlert(true);
						props.setSaving(false);
						props.setDialog('validateEmail',false); 
						return;	
					} // end else
						
					
				}, (error) => {

					props.setAlertTitle("Ops! Parece que ocorreu um erro!");
					props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. [3]');				
					props.setAlert(true);
					props.setSaving(false);
					props.setDialog('validateEmail',false); 
					return;						
		
				})
				.catch(error=> {

					props.setAlertTitle("Ops! Parece que ocorreu um erro!");
					props.setAlertBody('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');				
					props.setAlert(true);
					props.setSaving(false);
					props.setDialog('validateEmail',false); 
					return;								

				});

	}		
};

export const activateProfileConfirm  = (data,props) => {

	return dispatch => {
	
				// Retrieve userData and userToken
				var result = localStorage.getItem("@memovo/userData");
				var userData = JSON.parse(result); 
				var resultActivationStatus = localStorage.getItem("@memovo/activationStatus");	

				// Prepare POST data
				var preparedData = {
					code: data.code,
					activationToken:resultActivationStatus
				}

				// Connect to REGISTER Endpoint
				axios({
					   url    : config.API_URL+'/api/v1/user/activateProfileConfirm',
					   method : 'POST',
					   data: preparedData,
					   headers: {
						"x-access-token": userData.userToken,
						Accept: 'application/json'
					}
				})	
				.then((response) => {

		
				// If an error occured. Check error is blocking or non-blocking.
				if (response.data.error) {
						
					// Code expired...
					if (response.data.codeExpired) {
						
						props.setAlertTitle("Ops! Parece que o código está expirado!");
						props.setAlertBody('O código informado está expirado. Reenvie o código e tente novamente. Lembre-se que você tem até 10 minutos para validar o código.');				
						props.setAlert(true);
						props.setSaving(false);
						return;	

					} // end if
					
					// Wrong code
					if (response.data.codeIsWrong) {
						
						props.setAlertTitle("Ops! Parece que o código está errado!");
						props.setAlertBody('O código informado não é igual ao enviado. Verifique o email ou mensagem recebida e tente novamente. Caso o erro persista, reenvie o código e tente novamente.');				
						props.setAlert(true);
						props.setSaving(false);
						return;							

					} // end if

					// Customer Not found...
					if (response.data.notFound) {

						props.setAlertTitle("Ops! Parece que há um problema!");
						props.setAlertBody('Não encontramos o seu cadastro com a chave informada e isso é um problema crítico. Faça o logoff e em seguida o login para corrigir, e tente novamente.');				
						props.setAlert(true);
						props.setSaving(false);
						props.setDialog('validateEmail',false); 
						return;	

					} // end if
										
					props.setAlertTitle("Ops! Parece que ocorreu um erro!");
					props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. Erro retornado: '+response.data.errorMessage+' ('+response.data.errorContext+')');				
					props.setAlert(true);
					props.setSaving(false);
					return;												
				} // end if	
				
				if (response.data.success) {
					
					if (response.data.alreadyActivated) {
						props.setAlertTitle("Humm, parece que já validou o email!");
						props.setAlertBody('A validação de seu email ocorreu enquanto fazia essa validação. Isso pode ocorrer quando está usando mais de uma janela ou tab.');				
						props.setAlert(true);
						props.setSaving(false);
						props.setDialog('validateEmail',false);
						return;	
					}
					
					// Check received token
					// ...

					// save User Data on Local Storage
					localStorage.setItem("@memovo/activationStatus",response.data.activationStatusToken);	
					props.setActivationStatus(true);					

					props.setAlertTitle("Tudo certo! Email validado!");
					props.setAlertBody('Seu email foi validado com sucesso. A MeMovo agora tem com você um canal validado e seguro para envio de notificações.');				
					props.setAlert(true);
					props.setSaving(false);
					props.setDialog('validateEmail',false); 
					return;						
					
				} // end if
				
				// If any other error, what do do???
				// ...
					
				}, (error) => {

					props.setAlertTitle("Ops! ocorreu um erro! [1]");
					props.setAlertBody('Ocorreu um erro ao se comunicar com o servidor. Tente novamente. '+error.message+'');				
					props.setAlert(true);
					props.setSaving(false);
					return;						
										
				})
				.catch(error=> {

					props.setAlertTitle("Ops! ocorreu um erro! [2]");
					props.setAlertBody('Ocorreu um erro geral ao se comunicar com a API. Verifique sua conexão e tente novamente.');				
					props.setAlert(true);
					props.setSaving(false);
					return;						

				});


	}	
		
};






	



