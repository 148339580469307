import * as types from '../actionTypes';

const initialState = { 
	selectedUser:{},
	usersRows:[]
}
 
export const userReducer = (state = initialState, action) => {
		
    switch (action.type) {
        case types.SET_USER:
            return { ...state, selectedUser: action.payload }	
        case types.SET_USERSROWS:
            return { ...state, usersRows: action.payload }				
        default:
            return state
    }
}

