import '../../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {APIProvider, Map,Marker} from '@vis.gl/react-google-maps';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Fieldset } from 'primereact/fieldset';

import LocalAirportIcon from '@mui/icons-material/LocalAirport';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Load Actions
import * as AppActions from '../../Store/Actions/App';
import * as RegisterActions from '../../Store/Actions/Register';

// Load Components
import AlertView from '../../Components/AlertModal';
import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone
} from '../../Components/Utils';

// UpdateService dialog component
const RentCarSelectView = (props) => {

// set vars from props
const { 
	dialog,
	setDialog,
	setLocal,
	origin
} = props;		

const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 

const [termsModal, setTermsModal] = React.useState(false); 

const [companyDocument, setCompanyDocument] = React.useState('');

const [companyLegalName, setCompanyLegalName] = React.useState('');
const [companyFantasyName, setCompanyFantasyName] = React.useState('');
const [companyEmail, setCompanyEmail] = React.useState('');
const [companyPhone, setCompanyPhone] = React.useState('');
const [companyState, setCompanyState] = React.useState('');
const [companyCity, setCompanyCity] = React.useState('');
const [personCompanyName, setPersonCompanyName] = React.useState('');

const [companyStateAuto, setCompanyStateAuto] = React.useState('');
const [companyCityAuto, setCompanyCityAuto] = React.useState('');

const [personName, setPersonName] = React.useState('');
const [personDocument, setPersonDocument] = React.useState('');
const [personPhone, setPersonPhone] = React.useState('');
const [email, setEmail] = React.useState('');
const [emailConfirm, setEmailConfirm] = React.useState('');
const [password, setPassword] = React.useState('');
const [passwordConfirm, setPasswordConfirm] = React.useState('');

const [acceptTerm, setAcceptTerm] = React.useState(false);
const [autoFields, setAutoFields] = React.useState(true); // if true, fields are disabled
const [autoFieldsFill, setAutoFieldsFill] = React.useState(true); // if true, fields are auto filled. No manual.
const [autoFieldsFilled, setAutoFieldsFilled] = React.useState(false); // if true, fields was returned
const [autoPersonFieldsFilled, setAutoPersonFieldsFilled] = React.useState(false); // if true, fields was returned
const [documentReady, setDocumentReady] = React.useState(false); // if true, document is ok and ready
const [personDocumentReady, setPersonDocumentReady] = React.useState(false); // if true, document is ok and ready

const [loadingData, setLoadingData] = React.useState(false);
const [loadingPersonData, setPersonLoadingData] = React.useState(false);
const [cityLoading, setCityLoading] = React.useState(false);
const [cityResult, setCityResult] = React.useState(false);
const [screen, setScreen] = React.useState('form');

// Alert control
const [validateRegistry, setValidateRegistry] = React.useState(false); 
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 		

const [searchTerm, setSearchTerm] = React.useState(""); 		
const [searchLoading, setSearchLoading] = React.useState(false); 	
const [searchInfoMessage, setSearchInfoMessage] = React.useState(true); 	
const [searchResultPanel, setSearchResultPanel] = React.useState(false); 	
const [searchResultPicker, setSearchResultPicker] = React.useState(false); 
const [searchResultPickerLoading, setSearchResultPickerLoading] = React.useState(false); 
 
const [coordinates, setCoordinates] = React.useState({}); 

const checkSearchTerm = (value) => {

	setSearchTerm(value);
	setSearchResultPicker(false);

	if (value.length >= 3) {

		// Hide Info Message
		setSearchInfoMessage(false);

		// Set loading
		setSearchLoading(true);


		setTimeout(function() {

			// Set loading off
			setSearchLoading(false);

			// Show Station and Location Results Panel
			setSearchResultPanel(true);

		},3000);

	} // end if
	else if (searchResultPanel) {

		// Hide Show Station and Location Results Panel
		setSearchResultPanel(false);

		// Hide result picker
		setSearchResultPicker(false);

		// Show Info message
		setSearchInfoMessage(true);

	} // end else

}

const selectStation = () => {

	// Set Picker loading
	setSearchResultPickerLoading(true);
	setSearchResultPicker(false);

	setCoordinates({
		"lat": "48.77789705521736",
		"lng": "8.083562732476762"
	});

	setTimeout(() => {
		
		setSearchResultPickerLoading(false);
		setSearchResultPicker(true);

	}, 2000);


}

const handleTermsChange = (e) => {
	if (e.checked)
		setAcceptTerm(true);
	else
		setAcceptTerm(false);
}  // end function

/* Check if document is valid,. If yes, release button. If not, alert.*/
const handleCompanyDocumentChange = (e) => {

	var currentValue = e.target.value;
	
	setCompanyDocument(maskCNPJ(e.target.value));
	
	if (currentValue.length >= 18) {
		
		// check if cnpj is valid
		if (!validarCNPJ(currentValue)) {
			setAlert(true);
			setAlertTitle("Verifique o CNPJ");
			setAlertBody("O CNPJ informado é inválido. Verifique novamente.");	
			return;	
		} // end if	
		else {
			setDocumentReady(true);
		} // end else
		
	} // end if
	else {
		setDocumentReady(false);
		setAutoFieldsFilled(false);
		setCompanyLegalName("");
		setCompanyFantasyName("");
		setCompanyEmail("");
		setCompanyPhone("");
		setCompanyStateAuto("");
		setCompanyCityAuto("");
		setPersonCompanyName("");		
	} // end else
	
}  // end function

/* Check if document is valid,. If yes, release button. If not, alert.*/
const handlePersonDocumentChange = (e) => {

	var currentValue = e.target.value;
	
	setPersonDocument(maskCPF(e.target.value));
	
	if (currentValue.length >= 14) {
		
		// check if cnpj is valid
		if (!validarCPF(currentValue)) {
			setAlert(true);
			setAlertTitle("Verifique o CPF");
			setAlertBody("O CPF informado é inválido. Verifique novamente.");	
			return;	
		} // end if	
		else {
			setPersonDocumentReady(true);
		} // end else
		
	} // end if
	else {
		setPersonDocumentReady(false);
		setAutoPersonFieldsFilled(false);
		setPersonName("");		
	} // end else
	
}  // end function

/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}

{/*}
const selectRentCarLocation = () => {
	
	setLoadingData(true);
	
	setTimeout(function() {

        setLoadingData(false);

    },3000);
	

} // end retrieveDocumentInfo
*/}

const selectRentCarLocation = () => {
	
	if (origin == 'departure') {
		setLocal("BADEN AIRPARK - GERMANY");	
	} // end if
	else {
		setLocal("MEMMINGEN AIRPORT PARTNER - GERMANY");	
	} // end else

	setDialog("selectRentCarModal",false);

} // end retrieveDocumentInfo


const save = () => {
	
// Check required fields
if (!companyDocument || !companyLegalName || !companyEmail || !companyPhone || !companyStateAuto || !companyCityAuto || !personName || !personDocument || !personPhone || !email || !emailConfirm || !password || !passwordConfirm) {
	setAlert(true);
	setAlertTitle("Verifique as informações");
	setAlertBody("Um ou mais campos não estão preenchidos. É necessário que preencha todos os campos marcados com * para prosseguir com o cadastro.");	
	return;
} // end if
 
// Set button loading on and freeze form 	
setSaving(true);

// Execute Recaptcha Token
	executeReCaptcha().then(function(token) {

		console.log("[RECPATCHA TOKEN REGISTER]--->"+JSON.stringify(token,null,2));

		// Save RentCarSelectView
		// ...

		var props = {
			setSaving:setSaving,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setScreen:setScreen
		}
		var data = {
		  companyDocument: companyDocument,
		  companyLegalName: companyLegalName,
		  companyFantasyName: companyFantasyName,
		  companyEmail: companyEmail,
		  companyPhone: companyPhone,
		  companyState: companyState,
		  companyCity: companyCity,
		  personName: personName,
		  personDocument: personDocument,
		  personPhone: personPhone,
		  email: email,
		  password: password
		}
		// registerAppAgency(data,props);

			// simulate return
			setTimeout(function() {

				// set button loading off
				setSaving(false);
				
				// Open Validate Screen
				setValidateRegistry(true);
				
				// Show return Alert
				setAlert(true);
				setAlertAction("");
				setAlertTitle("Tudo certo!");
				setAlertBody("O cadastro de sua agência foi realizado com sucesso! Você deve agora validar o email de login informado. Lembre-se que só poderá acessar a plataforma após validá-lo. Aguarde a análise do cadastro para começar a usar a plataforma!");
				
			},3000);		
	});
	
}
	
    const footerContent = (
        <div className="modalFooterButtons" style={{}}>
			<div className="modalFooterButtonsRight">
				<Button label="Fechar" disabled={saving} icon="pi pi-times" className="p-button-text" onClick={() => closeDialog()} />
			</div>
			
        </div>
    );		


/* Retrieve cities linked to selcted state */
const retrieveDropdownCities = (value) => {
	
	// set loading on
	setCompanyCity('');
	setCityLoading(true);
	setCityResult(false);
	/*setDropdownCityItems([]);
	
	// set props
	var props = {
		setCityLoading:setCityLoading,
		setCityResult:setCityResult,
		setDropdownCityItems:setDropdownCityItems		
	}	*/
	
	
	/*setTimeout(function() {
		
		setCityLoading(false);
		setCityResult(true);
		
	},2000);*/
	
}

/* Set and retrieve cities linked to selcted state */
const processCompanyState = (value) => {	
	setCompanyState(value);
	retrieveDropdownCities(value);	
}

/* Reset form fields and close dialog */
const closeDialog = () => {
	
	// Reset form fields
	setCompanyCity('');
	setCityLoading(false);
	setCityResult(false);
	setLoadingData(false);
	setPersonLoadingData(false);
	setPersonDocumentReady(false);
	setDocumentReady(false);
	//setDropdownCityItems([]);
	setCompanyDocument('');
	setCompanyLegalName('');
	setCompanyFantasyName('');
	setPersonCompanyName('');
	setCompanyEmail('');
	setCompanyPhone('');
	setCompanyState('');
	setCompanyCity('');
	setCompanyStateAuto('');
	setCompanyCityAuto('');	
	setPersonName('');
	setPersonDocument('');
	setPersonPhone('');
	setEmail('');
	setEmailConfirm('');
	setPassword('');
	setPasswordConfirm('');
	setAcceptTerm(false);
	
	// close dialog
	setDialog('registerModal',false);
	
	
}

// Execute when dialog opens
React.useEffect(() => {

	// Only runs when  dialog opens
	if (dialog.target == 'registerModal' && dialog.status) {

				
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
			
},[dialog]);

// Execute when dialog closes
React.useEffect(() => {
	
    return () => {
      
		setSearchTerm("");
		setSearchLoading(false);
		setSearchInfoMessage(true);
		setSearchResultPanel(false);
		setSearchResultPicker(false);
			  
				{/*}	const [searchTerm, setSearchTerm] = React.useState(""); 		
const [searchLoading, setSearchLoading] = React.useState(false); 	
const [searchInfoMessage, setSearchInfoMessage] = React.useState(true); 	
const [searchResultPanel, setSearchResultPanel] = React.useState(false); 	
const [searchResultPicker, setSearchResultPicker] = React.useState(false); */}		

    };	
			
},[dialog]);

	return (
	<>
			<Dialog header="Seleção de local" visible={dialog.target == 'selectRentCarModal' && dialog.status} style={{ width: '80vw',height:"90vh" }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => closeDialog()} footer={footerContent} blockScroll={true} maximizable={saving ? false : false} closable={saving ? false : true} closeOnEscape={false} >
			
				<Box
				  sx={{
					width: "100%",
				  }}
				>
					<div className="registerModalIntro"> Busque o local desejado abaixo e depois selecione </div>	

					<Divider />
					  
                    <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start",gap:"0px",height:"50vh"}}>

                        <div style={{flex:1,width:"100%"}}> 
                            
                            <span className="p-input-icon-left" style={{width:"50%"}}>
                                <i className="pi pi-search" />
                                <InputText style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} placeholder="Digite ao menos 3 caracteres" value={searchTerm} onChange={(e) => checkSearchTerm(e.target.value)} />
                            </span>
                        
                        </div>

				  		{searchInfoMessage &&
                        <div style={{flex:6,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",fontSize:"22px",gap:"20px"}}>
                            
                            <i className="pi pi-search" style={{ color: "red",fontSize:"76px" }}></i>
                            <span>Faça a busca para visualizar os <br /> detalhes do local selecionado</span>

                        </div>
						}

						{searchLoading &&
                        <div style={{flex:6,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",fontSize:"22px",gap:"20px"}}>
                            
                            <ProgressSpinner />
                            <span>Buscando estações, <br />países e localidades</span>

                        </div>
						}		

						{!searchLoading && searchResultPanel &&
                        <div style={{width:"100%",flex:6,display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start",gap:"20px"}}>
                            
                           <div style={{width:"40%",padding:"20px"}}>
							
							<ScrollPanel style={{ width: '100%', height: '50vh' }} >


							<div style={{marginTop:"10px"}}>

								<div style={{fontSize:"18px",fontWeight:"bold",marginBottom:"10px"}} className="registerModalIntro">Estações</div>
								<div>
	
								<Button label="BADEN AIRPARK - GERMANY" size="small" icon={() => <LocalAirportIcon style={{fontSize:"18px",marginRight:"10px"}} />} severity="info" text style={{display:"block",padding:"7px"}} onClick={()=>selectStation()} />
								<Button label="MEMMINGEN AIRPORT PARTNER - GERMANY" size="small" icon={() => <LocalAirportIcon style={{fontSize:"18px",marginRight:"10px"}} />} severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARABURDOO AIRPORT - AUSTRALIA" size="small" icon={() => <LocalAirportIcon style={{fontSize:"18px",marginRight:"10px"}} />} severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS CDG AIRPORT LUXURY CARS - FRANCE" size="small" icon={() => <LocalAirportIcon style={{fontSize:"18px",marginRight:"10px"}} />} severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS CDG AIRPORT T2F - FRANCE" size="small" icon={() => <LocalAirportIcon style={{fontSize:"18px",marginRight:"10px"}} />} severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS CDG DELIVERY - FRANCE" size="small" icon={() => <LocalAirportIcon style={{fontSize:"18px",marginRight:"10px"}} />} severity="info" text style={{display:"block",padding:"7px"}} />

								<Button label="PARIS LE BOURGET AIRPORT - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS ORLY AIRPORT - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS ROISSY CDG AIRPORT T1 - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS ROISSY CDG AIRPORT T2D - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PAROS ISLAND PAROS AIRPORT - GREECE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="ADELAIDE CLOVELLY PARK - AUSTRALIA" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="GOLD COAST SURFERS PARADISE - AUSTRALIA" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="JEREZ DE LA FRONTERA SHERRY PARK - SPAIN" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="LESPARRE MEDOC - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="LOCARNO PARKING CENTRO - SWITZERLAND" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="LONDON PARK ROYAL - UNITED KINGDOM" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="LONDON PARK ROYAL PRESTIGE - UNITED KINGDOM" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="LYON PART DIEU RAILWAY STATION - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="MANCHESTER TRAFFORD PARK - UNITED KINGDOM" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="MANCHESTER TRAFFORD PARK PRESTIGE - UNITED KINGDOM" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="NANTES ROUTE DE PARIS - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="OLTEN PARKING NEUHARD - SWITZERLAND" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS BASTILLE - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS ETOILE FOCH - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS GARE DE LYON - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS GARE DU NORD RAILWAY ST. - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS GARE ST LAZARE - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />

								</div>	

							</div>


							<div style={{marginTop:"10px"}}>

								<div style={{fontSize:"18px",fontWeight:"bold",marginBottom:"10px"}} className="registerModalIntro">Localidades</div>
								<div>

								<Button label="MEMMINGEN AIRPORT PARTNER - GERMANY" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARABURDOO AIRPORT - AUSTRALIA" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS CDG AIRPORT LUXURY CARS - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS CDG AIRPORT T2F - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS CDG DELIVERY - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />
								<Button label="PARIS LE BOURGET AIRPORT - FRANCE" size="small" icon="pi pi-map-marker" severity="info" text style={{display:"block",padding:"7px"}} />


								</div>	

							</div>							


							</ScrollPanel>			
							
							</div>
						   
						   {!searchResultPickerLoading && searchResultPicker &&
						   <div style={{width:"60%",padding:"20px"}}>
							
							<ScrollPanel style={{ width: '100%', height: '50vh' }} >

								<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center",fontSize:"22px",gap:"20px",width:"100%"}}>
									
									<div style={{padding:"5px",borderBottom:"1px solid silver",width:"100%",textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 'bold',fontSize: '20px',lineHeight: '120%',letterSpacing: '0.1em',color: '#FE346E'}}>BADEN AIRPARK - GERMANY</div>

									<div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start",gap:"20px"}}>

										<div><Button label="Selecionar local" onClick={()=>selectRentCarLocation()}/></div>

										<div>
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 'bold',fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}>Endereços</div>
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 'regular',fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}>
												HALIFAX AVENUE 420B <br />
												AIRPORT KARLSRUHE/BADEN-BADEN
											</div>
										</div>

										<div style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center",gap:"40px"}}>

											<div>
												<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 'bold',fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}>Telefone</div>
												<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 'regular',fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}>+49 (7229) 665634</div>
											</div>	

											<div>
												<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 'bold',fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}>Código</div>
												<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 'regular',fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}>FKBT01</div>
											</div>	

										</div>	

										<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 'regular',fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',display:"flex",flexDirection:"column",gap:"5px",alignItems:"flex-start",justifyContent:"center"}}>
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 'bold',fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}>Horários</div>
											<div>( 31/10/2023 - 30/03/2024 ) SUN - 08:30 - 18:00</div>
											<div>( 31/10/2023 - 30/03/2024 ) SUN - 08:30 - 18:00</div>
											<div style={{backgroundColor:"whitesmoke"}}>( 31/10/2023 - 30/03/2024 ) SUN - 08:30 - 18:00</div>
											<div style={{backgroundColor:"whitesmoke"}}>( 31/10/2023 - 30/03/2024 ) SUN - 08:30 - 18:00</div>
											<div>( 31/10/2023 - 30/03/2024 ) SUN - 08:30 - 18:00</div>
											<div>( 31/10/2023 - 30/03/2024 ) SUN - 08:30 - 18:00</div>
											<div>( 31/10/2023 - 30/03/2024 ) SUN - 08:30 - 18:00</div>
											<div>( 31/10/2023 - 30/03/2024 ) SUN - 08:30 - 18:00</div>

											<div>( * ) Com taxas extras</div>
										</div>


										<div>
											<div style={{ marginTop:"20px",height: '300px', width: '40vw' }}>
												<APIProvider apiKey="AIzaSyBdE3uu0p9cJ_961a03aXMo0kGSzuUfTVE">
												<Map
												defaultZoom={14}
												defaultCenter={{lat: parseFloat(coordinates.lat), lng:parseFloat(coordinates.lng)}}
												gestureHandling={'greedy'}
												disableDefaultUI={false}
												fullscreenControlOptions={false}
												mapTypeControl={false} 
												streetViewControl={false}
												fullscreenControl={false}
												> 
												<Marker position={{lat: parseFloat(coordinates.lat), lng:parseFloat(coordinates.lng)}} />
												</Map>
												</APIProvider>
											</div>	
										</div>

									</div>

								</div>							
							
								</ScrollPanel>	
							
							</div>
							}      


						   {searchResultPickerLoading && !searchResultPicker &&
						   <div style={{flex:1,flexBasis:"50%",padding:"20px"}}>
							
								<div style={{flex:1,display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",fontSize:"22px",gap:"20px"}}>
									
									<ProgressSpinner />
									<span>Buscando dados da estação</span>

								</div>							
							
							</div>
							}                          

                        </div>
						}												

                    </div>


			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}
				alertAction={alertAction}
				scrollBlock={true}				
			/>
			</Box>
            </Dialog>	
	</>	
	);
	
}


const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions, ...RegisterActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(RentCarSelectView);	
