import * as types from '../actionTypes';

const initialState = { 
	selectedRoute:{},
	routesRows:[]
}
 
export const routeReducer = (state = initialState, action) => {
		
    switch (action.type) {
        case types.SET_ROUTE:
            return { ...state, selectedRoute: action.payload }	
        case types.SET_ROUTESROWS:
            return { ...state, routesRows: action.payload }				
        default:
            return state
    }
}

