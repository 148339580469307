import * as types from '../actionTypes';

const initialState = { 
	selectedAgency:{},
	agenciesRows:[]
}
 
export const agencyReducer = (state = initialState, action) => {
		
    switch (action.type) {
        case types.SET_AGENCY:
            return { ...state, selectedAgency: action.payload }	
        case types.SET_AGENCIESROWS:
            return { ...state, agenciesRows: action.payload }				
        default:
            return state
    }
}

