import * as types from '../actionTypes';

const initialState = { 
	innerLoading: false,
	dialog:{},
	loginStatus:false,
	routePanel:false,
	routeManage:false,
	currentAgency:{},
	masterUser:false,
	agencyName:"",
	agencyUser:"",
    currentCompanyStatus:null,
    activationStatus:false
}

export const appReducer = (state = initialState, action) => {
		
    switch (action.type) {
        case types.INNER_LOADING:
            return { ...state, innerLoading: action.payload }	
        case types.SET_DIALOG:
            return { ...state, dialog: action.payload }	
        case types.SET_LOGINSTATUS:
            return { ...state, loginStatus: action.payload }	
        case types.SET_ROUTEPANEL:
            return { ...state, routePanel: action.payload }		
        case types.SET_ROUTEMANAGE:
            return { ...state, routeManage: action.payload }					
        case types.SET_CURRENTAGENCY:
            return { ...state, currentAgency: action.payload }	
        case types.SET_MASTERUSER:
            return { ...state, masterUser: action.payload }	
        case types.SET_AGENCYNAME:
            return { ...state, agencyName: action.payload }	
        case types.SET_AGENCYUSER:
            return { ...state, agencyUser: action.payload }				
        case types.SET_CURRENTCOMPANYSTATUS:
            return { ...state, currentCompanyStatus: action.payload }				
        case types.SET_ACTIVATIONSTATUS:
            return { ...state, activationStatus: action.payload }				
        default:
            return state
    }
	
}