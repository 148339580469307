import '../../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Load Actions
import * as AppActions from '../../Store/Actions/App';
import * as ReservationActions from '../../Store/Actions/Reservation';

// Load Components
import AlertView from '../../Components/AlertModal';
import ValidateView from '../../Components/ValidateModal';
import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone
} from '../../Components/Utils';

// UpdateService dialog component
const ReservationView = (props) => {

// set vars from props
const { 
	dialog,
	setDialog,
	retrieveReservation,
	updateReservation,
	executeReservationAction,
	selectedReservation,
	scrollBlock
} = props;		

// Loaders
const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
const [loadingData, setLoadingData] = React.useState(true); 

// Reservation Data
const [companyLegalName, setCompanyLegalName] = React.useState('');
const [companyFantasyName, setCompanyFantasyName] = React.useState('');
const [companyEmail, setCompanyEmail] = React.useState('');
const [companyPhone, setCompanyPhone] = React.useState('');
const [companyState, setCompanyState] = React.useState('');
const [companyCity, setCompanyCity] = React.useState('');
const [personCompanyName, setPersonCompanyName] = React.useState('');

const [acceptTerm, setAcceptTerm] = React.useState(false);

// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertAction, setAlertAction] = React.useState(""); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

var loadingTimer = null;		

const handleTermsChange = (e) => {
	if (e.checked)
		setAcceptTerm(true);
	else
		setAcceptTerm(false);
}  // end function

const retrieveReservationInfo = () => {
	
	setLoadingData(true);
	
	var props = {
		setSaving:setSaving,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setLoadingData:setLoadingData
	}
	var data = {
	  reservationId: selectedReservation.reservationId
	}
	retrieveReservation(data,props);

} // end retrieveDocumentInfo

const update = () => {

/*	
// Check required fields
if (!companyDocument || !companyLegalName || !companyEmail || !companyPhone || !companyStateAuto || !companyCityAuto || !personName || !personDocument || !personPhone || !email || !emailConfirm || !password || !passwordConfirm) {
	setAlert(true);
	setAlertTitle("Verifique as informações");
	setAlertBody("Um ou mais campos não estão preenchidos. É necessário que preencha todos os campos marcados com * para prosseguir com o cadastro.");	
	return;
} // end if
 
// Check Document (CNPJ and CPF), phone and email consistency
if (!validarNome(personName) || !validarNome(companyLegalName)) {
	setAlert(true);
	setAlertTitle("Verifique os dados");
	setAlertBody("A Razão social ou nome completo parece estar inválido. Verifique novamente.");	
	return;	
} // end if
if (!validarCNPJ(companyDocument)) {
	setAlert(true);
	setAlertTitle("Verifique o CNPJ");
	setAlertBody("O CNPJ informado é inválido. Verifique novamente.");	
	return;	
} // end if
if (!validarCPF(personDocument)) {
	setAlert(true);
	setAlertTitle("Verifique o CPF");
	setAlertBody("O CPF informado é inválido. Verifique novamente.");	
	return;		
} // end if
if (!validarEmail(companyEmail) || !validarEmail(email)) {
	setAlert(true);
	setAlertTitle("Verifique o Email");
	setAlertBody("Um ou mais emails informados é inválido. Verifique novamente.");	
	return;		
} // end if
if (!validarTelefone(companyPhone) || !validarTelefone(personPhone)) {
	setAlert(true);
	setAlertTitle("Verifique o Telefone");
	setAlertBody("Um ou mais telefones informados é inválido. Verifique novamente.");	
	return;		
} // end if
	
// Check if email fields are equal
if (email  != emailConfirm) {
	setAlert(true);
	setAlertTitle("Verifique o email de acesso");
	setAlertBody("O campo de email e confirmação de email devem ser iguais. Verifique.");	
	return;
} // end if

// Check if password fields are equal
if (password  != passwordConfirm) {
	setAlert(true);
	setAlertTitle("Verifique a senha de acesso");
	setAlertBody("O campo de senha deve ser igual ao de confirmação de senha. Verifique.");	
	return;
} // end if

// Check if password is strong enough
var term = password;
var mediumPwd = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
if (!mediumPwd.test(term)) {
	setAlert(true);
	setAlertTitle("Verifique a senha de acesso");
	setAlertBody("A complexidade da senha deve ser no mínimo média. Verifique.");	
	return;    
} // end if	

// Check if Company Name is equal to Person Name
if (personName  != personCompanyName) {
	setAlert(true);
	setAlertTitle("Verifique as informações");
	setAlertBody("O campo Nome Responsável Legal deve ser o mesmo nome do campo Nome Completo. O CPF informado deve ser o documento do responsável legal.");	
	return;
} // end if

// Check if accepted terms
if (!acceptTerm) {
	setAlert(true);
	setAlertTitle("Verifique o aceite aos termos");
	setAlertBody("Para prosseguir com o cadastro, é necessário que leia e aceite os termos e condições de uso.");	
	return;
} // end if
*/

// Set button loading on and freeze form 	
setSaving(true);

// Execute Recaptcha Token
	
		// Save ReservationView
		// ...

		/*var props = {
			setSaving:setSaving,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setScreen:setScreen
		}
		var data = {
		  companyDocument: companyDocument,
		  companyLegalName: companyLegalName,
		  companyFantasyName: companyFantasyName,
		  companyEmail: companyEmail,
		  companyPhone: companyPhone,
		  companyState: companyState,
		  companyCity: companyCity,
		  personName: personName,
		  personDocument: personDocument,
		  personPhone: personPhone,
		  email: email,
		  password: password
		}
		// registerAppAgency(data,props);

			// simulate return
			setTimeout(function() {

				// set button loading off
				setSaving(false);
				
				// Open Validate Screen
				setValidateRegistry(true);
				
				// Show return Alert
				setAlert(true);
				setAlertAction("");
				setAlertTitle("Tudo certo!");
				setAlertBody("O cadastro de sua agência foi realizado com sucesso! Você deve agora validar o email de login informado. Lembre-se que só poderá acessar a plataforma após validá-lo. Aguarde a análise do cadastro para começar a usar a plataforma!");
				
			},3000);	*/	

	
}

/* Reset form fields and close dialog */
const closeDialog = () => {
	
	// Reset form fields
	// ...

	// close dialog
	setDialog('userModal',false);

	// Clear Timeout
	clearTimeout(loadingTimer);

	setTimeout(function() {
		
		// Set loading content true
		setLoadingData(true);		
		
		if (scrollBlock) {
			document.body.classList.add('p-overflow-hidden');	
		} // end if
	},300);
		
}

// Execute when component opens
React.useEffect(() => {

	// Enter and closes modal
	setDialog('userModal',false);
	
	// Set loading content true
	setLoadingData(true);		
			
},[]);

// Execute when dialog opens
React.useEffect(() => {

	// Only runs when  dialog opens
	if (dialog.target == 'userModal' && dialog.status) {

		// Retrieve current reservation
		retrieveReservationInfo();

		// Simulate Loading
		/*loadingTimer = setTimeout(function() {
			setLoadingData(false);
		},5000);*/
			
	} // end if
	else {
		
		// Clear Timeout
		clearTimeout(loadingTimer);
		
		// Set loading content true
		setLoadingData(true);	
	} // end else
	
	// Reset all fields when closes or open dialog
	// When closes
    return () => {
      
		/*setValidateRegistry(false);
	  
		// Reset form fields
		setCompanyCity('');
		setCityLoading(false);
		setCityResult(false);
		setLoadingData(false);
		setPersonLoadingData(false);
		setPersonDocumentReady(false);
		setDocumentReady(false);
		setDropdownCityItems([]);
		setCompanyDocument('');
		setCompanyLegalName('');
		setCompanyFantasyName('');
		setPersonCompanyName('');
		setCompanyEmail('');
		setCompanyPhone('');
		setCompanyState('');
		setCompanyCity('');
		setCompanyStateAuto('');
		setCompanyCityAuto('');	
		setPersonName('');
		setPersonDocument('');
		setPersonPhone('');
		setEmail('');
		setEmailConfirm('');
		setPassword('');
		setPasswordConfirm('');
		setAcceptTerm(false);	  */
		
		// Clear Timeout
		clearTimeout(loadingTimer);
						
		setTimeout(function() {
			if (scrollBlock) {
				document.body.classList.add('p-overflow-hidden');	
			} // end if
		},300);		
		
    };	
			
},[dialog]);

// Modal footer 	
const footerContent = (
	<div className="modalFooterButtons" style={{}}>
	
		<div className="modalFooterButtonsLeft">
			<div className="flex align-items-center">
				BUTTON
			</div>
		</div>
		<div className="modalFooterButtonsRight">
			<Button label="Fechar" disabled={saving} icon="pi pi-times" className="p-button-text" onClick={() => closeDialog()} />
			<Button label="Cadastrar" icon="pi pi-check" loading={saving} onClick={() => update()} />
		</div>
		
	</div>
);		

	return (
	<>
			<Dialog header={"Visualização de usuário #"+selectedReservation.userId} visible={dialog.target == 'userModal' && dialog.status} style={{ width: '75vw',height:"85vh" }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => closeDialog()} footer={footerContent} blockScroll={true} maximizable={saving ? false : true} closable={saving ? false : true} closeOnEscape={true} >
			
				<Box
				  sx={{
					width: "100%",
				  }}
				>
				
				{/* LOADING  */}
				{loadingData &&
					<>
						<div style={{backgroundColor:"transparent", width:"100%",maxWidth:"1170px", marginTop:"30px",marginBottom:"50px",display:"flex",alignItems:"center",justifyContent:"flex-start",flexDirection:"row"}}>
						
								<ProgressSpinner style={{width: '40px', height: '40px',marginRight: "20px",marginLeft:"20px"}} strokeWidth="4" />
								<div style={{fontSize:"16px",marginTop:"0px"}}> Buscando dados da reserva... </div>
																				
						</div>
						
						<Skeleton width="100%" height="4rem" ></Skeleton>
						<br />
						<Skeleton width="100%" height="4rem" ></Skeleton>
						<br />
						<Skeleton width="100%" height="4rem" ></Skeleton>
						<br />
						<Skeleton width="100%" height="4rem" ></Skeleton>
						
					</>				
				}
				
				{/* CONTENT  */}
				{!loadingData &&
				<>
					<div className="userModalIntro"> Veja os dados da reserva selecionada abaixo. <br /><i> As ações disponíveis estão no rodapé dessa janela. </i></div>	

					<Divider />
					  
						<Panel header="Dados da empresa" toggleable style={{marginBottom:"20px"}}>
							<Grid container spacing={4} style={{marginTop:"-20px"}}>
							  <Grid item xs={12} sm={6} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
								
								CONTENT
								
							  </Grid>
							</Grid>
						</Panel>

						<Panel header="Dados da empresa" toggleable style={{marginBottom:"20px"}}>
							<Grid container spacing={4} style={{marginTop:"-20px"}}>
							  <Grid item xs={12} sm={6} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
								
								CONTENT
								
							  </Grid>
							</Grid>
						</Panel>

						<Panel header="Dados da empresa" toggleable style={{marginBottom:"20px"}}>
							<Grid container spacing={4} style={{marginTop:"-20px"}}>
							  <Grid item xs={12} sm={6} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
								
								CONTENT
								
							  </Grid>
							</Grid>
						</Panel>

						<Panel header="Dados da empresa" toggleable style={{marginBottom:"20px"}}>
							<Grid container spacing={4} style={{marginTop:"-20px"}}>
							  <Grid item xs={12} sm={6} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
								
								CONTENT
								
							  </Grid>
							</Grid>
						</Panel>						
					</>	
				}

			</Box>
			
			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}
				alertAction={alertAction}
				scrollBlock={true}				
			/>			
            </Dialog>	
	</>	
	);
	
}


const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  selectedReservation:store.reservationReducer.selectedReservation
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions, ...ReservationActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(ReservationView);	
