import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import '../../Assets/Styles/orderStep2.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import { SelectButton } from 'primereact/selectbutton';
import { ToggleButton } from 'primereact/togglebutton';
import { Tooltip } from 'primereact/tooltip';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';

import multiTransportConcept from '../../Assets/Images/multiTransportConcept11.png'; 
import desktopConcept from '../../Assets/Images/desktopConcept.png'; 
import contactConcept from '../../Assets/Images/contactConcept.png'; 
import formPlaceholder from '../../Assets/Images/formPlaceholder.png'; 
import formTopIcons from '../../Assets/Images/formTopIcons.svg'; 
import cornerTop from '../../Assets/Images/cornerTop.svg';
import cornerBottom from '../../Assets/Images/cornerBottom.svg';

import contactWhatsappIcon from '../../Assets/Images/contactWhatsappIcon.svg';
import contactEmailIcon from '../../Assets/Images/contactEmailIcon.svg';
import contactPhoneIcon from '../../Assets/Images/contactPhoneIcon.svg';
import transportPicture from '../../Assets/Images/transportPicture.svg';
import rentcarPicture from '../../Assets/Images/rentcarPicture.svg';
import transferPicture from '../../Assets/Images/transferPicture.svg';
import transportIcon from '../../Assets/Images/transportIcons.svg';
import transferIcon from '../../Assets/Images/transferIcon.svg';
import rentcarIcon from '../../Assets/Images/rentcarIcon.svg';
import tooltipIcon from '../../Assets/Images/tooltipIcon.svg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'

// Load Componentes
import SelectRentCarLocationView from './SelectRentCarLocation';
import MessageView from '../../Components/MessageModal';
import AlertView from '../../Components/AlertModal';
import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone
} from '../../Components/Utils';

import {
	Callto,
	Whatsapp
} from '../../Components/Utils';

/*
import {
	stepsData
} from '../../Components/StaticData';*/

// export default function HomePage() {
const HomePage = (props) => {
	
// Set var from props
const {
	setDialog,
	dialog
} = props;

const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
	
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })

const [email, setEmail] = React.useState('');
const [password, setPassword] = React.useState('');
const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

const [forgotPassword, setForgotPassword] = React.useState(false); 		

// Transport selection
const [checkedAll, setCheckedAll] = React.useState(false);
const [checkedAirplane, setCheckedAirplane] = React.useState(false);
const [checkedTrain, setCheckedTrain] = React.useState(false);
const [checkedBus, setCheckedBus] = React.useState(false);
const [directServices, setDirectServices] = React.useState(false);

const [departureLocal, setDepartureLocal] = React.useState(null);
const [departureDate, setDepartureDate] = React.useState(null);
const [departureTime, setDepartureTime] = React.useState(null);
const [destinationLocal, setDestinationLocal] = React.useState(null);
const [destinationDate, setDestinationDate] = React.useState(null);
const [destinationTime, setDestinationTime] = React.useState(null);	

const [pickReturnDate, setPickReturnDate] = React.useState(false);
const [pickReturn, setPickReturn] = React.useState(false);

const [passengerAge, setPassengerAge] = React.useState("");

const [leaveDate, setLeaveDate] = React.useState(null);
const [returnDate, setReturnDate] = React.useState(null);


const [value, setValue] = React.useState('');
const [items, setItems] = React.useState([]);

const options = ['Com franquia', 'Franquia zero/reduzida'];
const [type, setType] = React.useState(options[0]);

const [origin, setOrigin] = React.useState("");


/* Person select START */
const [menuOpened,setMenuOpened] = React.useState(false);
const [personAddLabel,setPersonAddLabel] = React.useState("1 Adulto (Adicionar Mais Pessoas)"); // default
const [personAddAdult,setPersonAddAdult] = React.useState(1); // default adult
const [personAddYoung,setPersonAddYoung] = React.useState(0); // default young
const [personAddTotal,setPersonAddTotal] = React.useState(1); 
const [personAddYoungAges,setPersonAddYoungAges] = React.useState([]); 
const [personAddElder,setPersonAddElder] = React.useState(0); // default elder
const [personAddElderAges,setPersonAddElderAges] = React.useState([]); 

const [selectedElderAge,setSelectedElderAge] = React.useState([]);
const [selectedYoungAge,setSelectedYoungAge] = React.useState([]); 

// temp options
const [timeOptions,setTimeOptions] = React.useState([]); 

    const [selectedLug, setSelectedLug] = React.useState(null);
    const lugs = [
        { name: 'Todas as opções', code: 'all' },
        { name: '2 pequenas ou 1 grande', code: '2_1' },
        { name: '3 pequenas ou 2 grandes', code: '3_2' },
        { name: '4 pequenas ou 3 grandes', code: '4_3' },
        { name: '5 pequenas ou 4 grandes', code: '5_4' }
    ];

let today = new Date();
let month = today.getMonth();
let year = today.getFullYear();
let minDate = new Date();

addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});

const op = React.useRef(null);
const onHide = () => {
setMenuOpened(false);
}  
const onShow = () => {
setMenuOpened(true);
}    
 
const setPerson = (operation,type) => {
	
	// Set total passengers
	let currentTotal = personAddAdult + personAddYoung + personAddElder;
	
	if (type == 'adult') {
		
		let newValue = operation == '+' ? personAddAdult + 1 : personAddAdult - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 9 || operation == '-') {
			setPersonAddAdult(newValue);
		}
		
	} // end if
	else if (type == 'young') {
		
		let newValue = operation == '+' ? personAddYoung + 1 : personAddYoung - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 9 || operation == '-') {
			setPersonAddYoung(newValue);
		}
		
		// reset ages
		if (operation == '-') { setSelectedYoungAge([]); }
		
	} // end if
	else if (type == 'elder') {
		
		let newValue = operation == '+' ? personAddElder + 1 : personAddElder - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 9 || operation == '-') {
			setPersonAddElder(newValue);	
		}
		
		// reset ages
		if (operation == '-') { setSelectedElderAge([]); }		
		
	} // end if

	
} // end function
/* Person select END */

const generateTotalLabel = () => {

	// Set total passengers
	let currentTotal = personAddAdult + personAddYoung + personAddElder;

	if (personAddAdult == 1 && personAddYoung == 0 && personAddElder == 0) {
		var label = "1 Adulto (Adicionar Mais Pessoas)";	
	} // end if
	else if (personAddAdult > 1 && personAddYoung == 0 && personAddElder == 0) {
		var label = ""+personAddAdult+" Adultos (Adicionar Mais Pessoas)";	
	} // end else if
	else if (currentTotal == 0) {
		var label = "Nenhum passageiro, (Adicionar Pessoas)";
	} // end else if	
	else if (currentTotal == 1) {
		var label = ""+currentTotal+" Passageiro (Adicionar Mais Pessoas)";
	} // end else if	
	else {
		var label = ""+currentTotal+" Passageiros (Adicionar Mais Pessoas)";	
	} // end else
	
	// Set Person Control Label
	setPersonAddLabel(label);
	
}

const search = (event) => {
	setItems([...Array(10).keys()].map(item => event.query + '-' + item));
}


const processPickReturn = (value) => {
	
	if (value) {
		setPickReturnDate(true);
	} // end if
	else {
		setPickReturnDate(false);
	} // end else

	setPickReturn(value);		
	
}

// set Use of history for PUSH 
var navigate = useNavigate();
	
/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}

// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if

  // set scroll to top
  window.scrollTo(0, 1);

  // Generate temp times
  const times = [];
  for (let hour = 0; hour < 24; hour++) {

		if (hour == 0) {
			for (let minute of [20, 40]) {
				const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
				times.push({ name: formattedTime, value: formattedTime });
			}
		} 
		else {
			for (let minute of [0, 20, 40]) {
				const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
				times.push({ name: formattedTime, value: formattedTime });
			}			
		}

  }
  setTimeOptions(times);
    
  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, []); // end useEffect 


// Execute generateTotalLabel if value changes
React.useEffect(() => {

	generateTotalLabel();
	  
}, [personAddAdult,personAddYoung,personAddElder]); // end useEffect 

const stepsData = [
        {
            label: 'Tipo deslocamento',
            command: (event) => {
					navigate("/order");
            }
        },
        {
            label: 'Dados da Viagem'
        },
        {
            label: 'Escolha do carro',
            command: (event) => {
                console.log("[Clicou Step 3]--->");
            }
        },
        {
            label: 'Confirmação de Dados',
            command: (event) => {
                console.log("[Clicou Step 5]--->");
            }
        },	
        {
            label: 'Confirmação de Reserva',
            command: (event) => {
                console.log("[Clicou Step 4]--->");
            }
        }
];

var youngAgesRangeArray = [];
for (var i = 0; i <= 25; i++) {
	youngAgesRangeArray.push({
		age:i,
		code:i
	}); 
}
const youngAges = [...youngAgesRangeArray];
const setYoungAge = (index,value) => {

	// Filter for exiting value. IF exist, replace
	var array = [...selectedYoungAge]; // clone
	var indexArray = array.filter(item => item.index != index);	
	
	var arrayObj = {
		index:index,
		value:value
	}
	var finalArray = [...indexArray,arrayObj];
		
	setSelectedYoungAge(finalArray);
}
var elderAgesRangeArray = [];
for (var i = 58; i <= 99; i++) {
	elderAgesRangeArray.push({
		age:i,
		code:i
	}); 
}
const elderAges = [...elderAgesRangeArray];
const setElderAge = (index,value) => {

	// Filter for exiting value. IF exist, replace
	var array = [...selectedElderAge]; // clone
	var indexArray = array.filter(item => item.index != index);	
	
	var arrayObj = {
		index:index,
		value:value
	}
	var finalArray = [...indexArray,arrayObj];
		
	setSelectedElderAge(finalArray);
}


/*
const checkAll = (e) => {
	setCheckedAll(e.value);
	setCheckedAirplane(false);
	setCheckedTrain(false);
	setCheckedBus(false);
}*/

console.log(">>>>>>>>>>>>>> "+JSON.stringify(type,null,2));
 
  return (
	<>
	<div id="loginSection" style={{marginTop:"0"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
		<div style={{minHeight:"728px",height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
			<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
						
				<div style={{flex:1,margin:"2rem",marginTop:"50px",minHeight:"520px",marginLeft:"auto",marginRight:"auto"}}>
				
					<div style={{fontSize:"28px",textAlign:"center",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"400",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249",flex:"1"}}>Aluguel de Carro</div>
					
					<div style={{display:"flex",flexDirection:isTabletOrMobile ? "column" : "row",alignItems:"center",justifyContent:"center",gap:"15px",marginTop:"20px"}}> 
					
						<div style={{marginLeft:"auto",marginRight:"auto",display:"flex",flexDirection:isTabletOrMobile ? "column" : "column",alignItems:"center",justifyContent:"center",gap:"15px",width:"100%",paddingBottom:"20px"}}  className="selectBox">
					
							<div>
								<Steps model={stepsData} activeIndex={1} readOnly={false} />
							</div>

							<div style={{borderRadius:"30px",backgroundColor:"#FFFFFF", width:"70vw",maxWidth:"1010px", minHeight:"400px",marginTop:"20px",display:"flex"}}> 
							
								<div className="searchWrapper" style={{flex:1,width:"100%",height:"100%",margin:"75px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
							
										<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:pickReturnDate ? "70px" : "35px"}}> 
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start",gap:"10px"}}>
											
												<div style={{flex:1}}>										
												
													<SelectButton value={type} onChange={(e) => setType(e.value)} options={options} />				
												</div>
												
												{type == "Com franquia" &&
														<small><i className="pi pi-info-circle" style={{ color: "red",fontSize:"14px" }}></i> Maior participação em danos</small>
  													}

												{type != "Com franquia" &&
													<small><i className="pi pi-info-circle" style={{ color: "red",fontSize:"14px" }}></i> Menor participação em danos</small>
												}
												
												
											</div>

										</div>										
										
										<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:pickReturnDate ? "70px" : "35px"}}> 
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
											
												<div style={{flex:1}}>										
													<span className="p-float-label"  >
														{/*<AutoComplete  inputId="departureLocal" value={departureLocal} suggestions={items} completeMethod={search} onChange={(e) => setDepartureLocal(e.target.value)} minLength={3} placeholder="Digite ao menos 3 caracteres" style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} />*/}
														<InputText id="location" value={departureLocal} onClick={(e)=>{setOrigin("departure");setDialog("selectRentCarModal",true)}} style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} autoComplete="off" />
														<label htmlFor="departureLocal">Local de retirada <span className="requiredField">*</span></label>

													</span>														
												</div>
												
												<div style={{flex:1,marginLeft:"15px"}}>

												{pickReturnDate &&
												<>
													<span className="p-float-label"  >
														<AutoComplete  inputId="destinationLocal" value={destinationLocal} suggestions={items} completeMethod={search} onClick={(e) => {setOrigin("destination");setDialog("selectRentCarModal",true)}} minLength={3} placeholder="Digite ao menos 3 caracteres" style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} autoComplete="off" />
														<label htmlFor="destinationLocal">Local de devolução <span className="requiredField">*</span></label>
													</span>	
												</>
												}

													<div className="flex align-items-center" style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"5px",position:pickReturnDate? "absolute" : "relative",left:pickReturnDate? "45%" : "unset",marginTop:pickReturnDate? "7px" : "unset"}}>
														<Checkbox inputId="pickReturn" name="pickReturn" value="1"  onChange={e => processPickReturn(e.checked)}  checked={pickReturn} />
														<label htmlFor="pickReturn" className="ml-2" style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'14px',lineHeight:'24px',letterSpacing:'0.15px',color:'#2E0249'}}>Devolução em outro local</label> <div className="tooltip" data-pr-tooltip="Você pode devolver o carro em outro local, se desejar" data-pr-position="bottom" data-pr-showdelay="300" ><img src={tooltipIcon} style={{width:"20px",height:"auto"}} className="noSelect" /></div> <Tooltip target=".tooltip" />
													</div>													
	
												</div>	

										
											</div>

										</div>										
										
										<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"35px"}}> 
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
											
												<div style={{flex:1}}>										
													<span className="p-float-label"  >
														<Calendar showButtonBar locale="br"  minDate={minDate} id="departureDate" value={departureDate} onChange={(e) => {setDepartureDate(e.value);setDestinationDate(null)}} style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
														<label htmlFor="departureDate">Data da retirada <span className="requiredField">*</span> </label>
													</span>													
												</div>
												
												<div style={{flex:1,marginLeft:"15px"}}>
													<span className="p-float-label"  >
														<Dropdown showClear value={departureTime} id="departureTime" onChange={(e) => setDepartureTime(e.value)} options={timeOptions} optionLabel="name" style={{height:"56px",width:"100%",paddingTop:"5px"}} inputStyle={{width:"100%"}}  />
														<label htmlFor="lugs"> Hora da retirada <span className="requiredField">*</span>
														</label>
													</span>
												</div>
												
											</div>
										</div>
										
										<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"35px"}}> 
										
											<div style={{width:"50%",textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",position:"relative"}}>
												
												<div style={{flex:1}}>
													<span className="p-float-label"  >
														<Dropdown showClear value={destinationTime} id="destinationTime" onChange={(e) => setDestinationTime(e.value)} options={timeOptions} optionLabel="name" style={{height:"56px",width:"100%",paddingTop:"5px"}} inputStyle={{width:"100%"}}  />
														<label htmlFor="lugs"> Hora de devolução <span className="requiredField">*</span> </label>
													</span>				
												</div>
												
											</div>													

										</div>												
										
										<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"35px"}}> 
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",position:"relative"}}>

												<div style={{flex:1}}>										
													<span className="p-float-label"  >
														<InputText keyfilter="int" inputId="passengerAge" value={passengerAge} onChange={(e) => setPassengerAge(e.target.value)} minLength={2} placeholder="Digite a idade do condutor principal" style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} />
														<label htmlFor="passengerAge"> Idade do condutor <span className="requiredField">*</span></label>
													</span>													
												</div>
												
												<div style={{flex:1,marginLeft:"15px"}}>
													<span className="p-float-label"  >
														<Dropdown value={selectedLug} id="lugs" onChange={(e) => setSelectedLug(e.value)} options={lugs} optionLabel="name" style={{height:"56px",width:"100%",paddingTop:"5px"}} inputStyle={{width:"100%"}}  />
														<label htmlFor="lugs"> Número de malas <span className="requiredField">*</span> </label>
													</span>												
												</div>
												
											</div>													

										</div>										
										
										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"20px"}}> 
										
											<div style={{textAlign:"left",flex:1}}>
												<Button label="Limpar formulário" icon="pi pi-trash" severity="danger" text />
											</div>											
										
											<Button rounded outlined className="pT10" label="Voltar" onClick={()=>navigate("/order")} />
										
											<Button rounded className="pT10" label="Buscar carros" onClick={()=>console.log("[CLICKED]--->")} />
										</div>
							
								</div>
							
							</div>


						</div>						

						
					</div>				
				
				</div>
			
			</div> 
		</div>

		<SelectRentCarLocationView
			setDialog={setDialog}
			dialog={dialog}
			setLocal={origin == 'departure' ? setDepartureLocal : setDestinationLocal}
			origin={origin}
		/>	

    </div>
	
	<div id="contatoSection" className="contatoSection__wrapper">

		<div className={isTabletOrMobile ? "contatoSection__wrapper_content_wrapper mobile" : "contatoSection__wrapper_content_wrapper"}  >

			<div className="flex-1 dFlex aItemsC jContentE"> 
			
				<div className="textBlockMargin contatoSection__wrapper_content_text" >
				
					<div className="contatoSection__wrapper_content_header">Central de Relacionamento</div>
				
					Estamos a disposição para atender às suas necessidades e responder às suas perguntas. Conheça as principais formas de entrar em contato conosco:
					
					<div style={{marginTop: "2.8125rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", gap: "15px"}}>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactWhatsappIcon} style={{width:"49px",height:"auto"}} className="noSelect" /></div>
							<div className="contatoOptionText"><Whatsapp phone="552140209749" style={{color:"#FFF",textDecoration:"none"}}>Whatsapp 4020-9749</Whatsapp></div>
						</div>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactPhoneIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Callto phone="+552140209749" style={{color:"#FFF",textDecoration:"none"}}>Telefone 4020-9749</Callto></div>
						</div>
						
						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactEmailIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Button label="Enviar mensagem" rounded className="pT10 mR10 headerButton tColorWhite w100" style={{marginBottom:"0.625rem"}} onClick={()=>setDialog('messageModal',true)} /></div>
						</div>
						

					</div>					
				
				</div> 
				
			</div>
			
			<div className="tAlignL flex-1_5"> 
				<img src={contactConcept} className="contatoSection__wrapper_content_image noSelect" />
			</div>			
			
		</div>
			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
			<MessageView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
    </div>
	
	<div id="memovoSection" className="memovoSection__wrapper">
	
		<div className={isTabletOrMobile ? "memovoSection__wrapper_content_wrapper mobile" : "memovoSection__wrapper_content_wrapper"}>
		
			<div className="flex-1"> 
				<img src={desktopConcept} className="memovoSection__wrapper_content_image noSelect" />
			</div>
			<div className="flex-1 dFlex aItemsC jContentS"> 
			
				<div className="textBlockMargin memovoSection__wrapper_content_text" >
				
					<div className="memovoSection__wrapper_content_header">Inovando o Mercado B2B de Mobilidade</div>
									
					A MEMOVO é uma plataforma B2B inovadora que facilita a busca e comercialização de serviços de transporte e mobilidade. Pioneira como marketplace B2B, oferece passagens aéreas, trens, ônibus, aluguel de veículos e transfers em um só lugar, permitindo a criação de roteiros completos de mobilidade. Com interface amigável e processo simples, a plataforma proporciona agilidade e eficiência aos profissionais do setor, atendendo às necessidades dos usuários e impulsionando o crescimento do seu negócio.
				
				</div> 
				
			</div>
			
		</div>
		
    </div>	
	
	</>
  );
    
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(HomePage);	


