import '../../Assets/Styles/home.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 

import jwtDecode from 'jwt-decode';

import multiTransportConcept from '../../Assets/Images/multiTransportConcept11.png'; 

import formTopIcons from '../../Assets/Images/formTopIcons.svg'; 
import cornerTop from '../../Assets/Images/cornerTop.svg';
import cornerBottom from '../../Assets/Images/cornerBottom.svg';
 
// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as LoginActions from '../../Store/Actions/Login'

// Load Componentes
import ForgotPasswordView from './ForgotPasswordModal';
import AlertView from '../../Components/AlertModal';

import AboutUs from '../../Components/layout/AboutUs';
import ContactUs from '../../Components/layout/ContactUs';

import {
	validarEmail
} from '../../Components/Utils';

// export default function HomePage() {
const HomePage = (props) => {
	
// Set var from props
const {
	setDialog,
	setLoginStatus,
	loginStatus,
	setMasterUser,
	setAgencyName,	
	setAgencyUser,
	currentCompanyStatus,
	setCurrentCompanyStatus,
	activationStatus,
	setActivationStatus,
	logOut,
	processLogin
} = props;


// set Use of history for PUSH 
var navigate = useNavigate();

const [saving, setSaving] = React.useState(false); 
	
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })

const [email, setEmail] = React.useState('');
const [password, setPassword] = React.useState('');
const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 
const [forgotPassword, setForgotPassword] = React.useState(false); 		
const [reprovalReason, setReprovalReason] = React.useState("Nenhum motivo informado. Entre em contato."); 		
const [canUpdate, setCanUpdate] = React.useState(false); 		
	
/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}
	
const login = () => {
	
	// Check required fields
	if (!email || !password ) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("É necessário que preencha o email e a senha para prosseguir com o login.");
		return;
	} // end if
	
	if (!validarEmail(email)) {
		setAlert(true);
		setAlertTitle("Verifique o Email");
		setAlertBody("O email informado é inválido. Verifique.");	
		return;		
	} // end if
	
	// Check if password fields are equal
	if (password.length <= 4) {
		setAlert(true);
		setAlertTitle("Verifique a senha de acesso");
		setAlertBody("O valor do campo de senha não pode ter tão poucos caracteres. Verifique.");	
		return;
	} // end if		

	// Set button loading on and freeze form 	
	setSaving(true);	

	// Execute Recaptcha Token
	executeReCaptcha().then(function(token) {
		
		var props = {
			setDialog:setDialog,
			setSaving:setSaving,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody,
			setLoginStatus:setLoginStatus,
			setActivationStatus:setActivationStatus,
			setCurrentCompanyStatus:setCurrentCompanyStatus,
			setMasterUser:setMasterUser,
			setAgencyName:setAgencyName,
			setAgencyUser:setAgencyUser,
			setEmail:setEmail,
			setPassword:setPassword,
			logOut:logOut,
			navigate:navigate
		}
		var data = {
		  email: email,
		  password: password
		}
		processLogin(data,props);		

		
	}).catch(error=> {

		setAlertTitle("Ops! Parece que há um erro no ReCaptcha do Google!");
		setAlertBody("O ReCaptcha do Google não carregou corretamente. Recarregue a página para corrigir.");	     			
		setAlert(true);
		return; 

	});	
	
}	
 
// Run after mount component
React.useEffect(() => {
 	   
  // set scroll to top
  window.scrollTo(0, 1);

  var resultCompanyStatus = localStorage.getItem("@memovo/companyStatus");
  try {
	  var CompanyStatusTokenObjDecoded = jwtDecode(resultCompanyStatus);
	  if (CompanyStatusTokenObjDecoded.reprovedReason) {
		  setReprovalReason(CompanyStatusTokenObjDecoded.reprovedReason);
		  setCanUpdate(CompanyStatusTokenObjDecoded.canUpdate ? true : false);
		  return;
	  } // end if
  } catch(e) {
	  console.log('<<<<<<<<<<<<<<<<<<<< LOGGED OUT (10) >>>>>>>>>>>>>>>>>>>>>>>>>> ');
  } // end catch	
      
  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, [currentCompanyStatus]); // end useEffect 


const handleClickScroll = (target) => {
		
	const element = document.getElementById(target);
	if (element) {
		// 👇 Will scroll smoothly to the top of the next section
		var elementPosition = element.offsetTop;
		element.scrollIntoView({ top: elementPosition - 70,behavior: 'smooth' });
	}
	};  


 // If logged but with under review status, show unbder review Screen
 if (loginStatus && currentCompanyStatus === 0) {
	return (
		<>
		<div id="loginSection" className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
			<div className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"} style={{minHeight:"300px",backgroundColor:"#FFF"}}> 
			
				<div style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center",gap:"20px",paddingTop:"5%"}}>

					<div> 
						<svg
						xmlns="http://www.w3.org/2000/svg"
						width="200"
						height="200"
						fill="#000"
						version="1.1"
						viewBox="0 0 512 512"
						xmlSpace="preserve"
						>
							<g>
								<path
								fill="#D7D8D9"
								d="M430.933 102.4H115.2a8.533 8.533 0 00-8.533 8.533v392.533a8.533 8.533 0 008.533 8.533h315.733a8.533 8.533 0 008.533-8.533V110.933a8.533 8.533 0 00-8.533-8.533z"
								></path>
								<path
								fill="#EBEBEC"
								d="M388.267 68.267h-307.2a8.533 8.533 0 00-8.533 8.533v384a8.533 8.533 0 008.533 8.533h307.2a8.533 8.533 0 008.533-8.533v-384a8.533 8.533 0 00-8.533-8.533z"
								></path>
								<path
								fill="#D7D8D9"
								d="M98.133 460.8v-384a8.533 8.533 0 018.533-8.533h-25.6a8.533 8.533 0 00-8.533 8.533v384a8.533 8.533 0 008.533 8.533h25.6a8.533 8.533 0 01-8.533-8.533z"
								></path>
								<path
								fill="#F58F6C"
								d="M320 213.333h-68.267a8.533 8.533 0 01-8.533-8.533v-68.267a8.533 8.533 0 018.533-8.533H320a8.533 8.533 0 018.533 8.533V204.8a8.533 8.533 0 01-8.533 8.533zm-59.733-17.066h51.2v-51.2h-51.2v51.2z"
								></path>
								<g fill="#9B9DA1">
								<path d="M200.533 162.133h-76.8a8.533 8.533 0 010-17.066h76.8a8.533 8.533 0 018.533 8.533 8.532 8.532 0 01-8.533 8.533zM174.933 196.267h-51.2a8.533 8.533 0 010-17.066h51.2a8.533 8.533 0 010 17.066z"></path>
								</g>
								<path
								fill="#F58F6C"
								d="M320 324.267h-68.267a8.533 8.533 0 01-8.533-8.533v-68.267a8.533 8.533 0 018.533-8.533H320a8.533 8.533 0 018.533 8.533v68.267a8.533 8.533 0 01-8.533 8.533zM260.267 307.2h51.2V256h-51.2v51.2z"
								></path>
								<g fill="#9B9DA1">
								<path d="M200.533 273.067h-76.8a8.533 8.533 0 010-17.066h76.8a8.533 8.533 0 010 17.066zM183.467 307.2h-59.733a8.533 8.533 0 010-17.066h59.733a8.533 8.533 0 010 17.066z"></path>
								</g>
								<path
								fill="#F58F6C"
								d="M320 435.2h-68.267a8.533 8.533 0 01-8.533-8.533V358.4a8.533 8.533 0 018.533-8.533H320a8.533 8.533 0 018.533 8.533v68.267A8.533 8.533 0 01320 435.2zm-59.733-17.067h51.2v-51.2h-51.2v51.2z"
								></path>
								<g fill="#9B9DA1">
								<path d="M200.533 384h-76.8a8.533 8.533 0 010-17.066h76.8a8.533 8.533 0 010 17.066zM166.4 418.133h-42.667a8.533 8.533 0 010-17.066H166.4a8.533 8.533 0 010 17.066z"></path>
								</g>
								<path
								fill="#6AC8B7"
								d="M294.402 187.733a8.52 8.52 0 01-6.036-2.499l-17.067-17.067a8.532 8.532 0 010-12.068 8.535 8.535 0 0112.067 0l11.955 11.955 53.851-38.465a8.534 8.534 0 119.92 13.887l-59.733 42.667a8.497 8.497 0 01-4.957 1.59z"
								></path>
								<path
								fill="#E5563C"
								d="M292.789 54.743a8.533 8.533 0 00-6.923-3.543h-102.4a8.535 8.535 0 00-8.096 11.232l17.067 51.2a8.533 8.533 0 008.095 5.835h68.267a8.533 8.533 0 008.095-5.835l17.067-51.2a8.527 8.527 0 00-1.172-7.689z"
								></path>
								<path
								fill="#CF442B"
								d="M209.505 113.632l-17.067-51.2a8.536 8.536 0 011.173-7.689 8.53 8.53 0 016.922-3.543h-17.067a8.535 8.535 0 00-8.096 11.232l17.067 51.2a8.533 8.533 0 008.095 5.835h17.067a8.532 8.532 0 01-8.094-5.835z"
								></path>
								<path
								fill="#606268"
								d="M275.9 13.267C279.614 7.696 275.475 0 268.8 0h-68.267c-6.675 0-10.814 7.696-7.1 13.267l15.633 23.45v82.75h17.067V34.133a8.529 8.529 0 00-1.434-4.734l-8.222-12.333h36.378L244.633 29.4a8.531 8.531 0 00-1.434 4.733v85.333h17.067v-82.75L275.9 13.267z"
								></path>
							</g>
						</svg>
					</div>

					<div className="textBlockMargin memovoSection__wrapper_content_text" style={{color:"#333",textAlign:"justify",maxHeight:"250px"}}>
						<div className="memovoSection__wrapper_content_header" style={{color:"orange",textAlign:"center"}}>Cadastro em análise</div>
											
							<p>O cadastro de sua agência está em análise. As informações estão sendo validadas.</p>
							
							<p>Em até 48h após o envio das informações você receberá uma notificação aprovando ou, caso tenha algum impeditivo, reprovando o seu cadastro. Aguarde a notificação por email ou retorne no site para verificar o status.</p>

					</div>
					
					<div>
						{!activationStatus &&
							<Button label="Validar email" rounded className="pT10 mR10 headerButton tColorWhite" onClick={() => setDialog('validateEmail',true)} />
 						}
						<Button label="Entrar em contato" rounded outlined className="pT10 mR10 headerButton"  onClick={()=>handleClickScroll('contatoSection')}  />					
					</div>
					
				</div>
				

			</div>
		</div>
		
		{/* About Us Section */}
		<AboutUs />
		
		{/* Contac Us Section */}
		<ContactUs />

		</>
	  );
 }

  // If logged but with under review status, show unbder review Screen
  if (loginStatus && currentCompanyStatus === 1 && !activationStatus) {
	return (
		<>
		<div id="loginSection" className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
			<div className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"} style={{minHeight:"300px",backgroundColor:"#FFF"}}> 
			
				<div style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center",gap:"20px",paddingTop:"5%"}}>

					<div> 
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="200"
							height="200"
							fill="orange"
							version="1.1"
							viewBox="0 0 24 24"
							xmlSpace="preserve"
							>
							<g>
								<path d="M12 24c-1.2 0-2.1-1-2.8-1.8-.4-.4-.8-.9-1-1-.3-.1-1-.1-1.5-.1-1.1.1-2.3.1-3.1-.7s-.8-2.1-.7-3.1c0-.6.1-1.2-.1-1.5-.1-.3-.6-.7-1-1C1 14.1 0 13.2 0 12s1-2.1 1.8-2.8c.4-.4.9-.8 1-1 .1-.3.1-1 .1-1.5-.1-1.1-.1-2.3.7-3.1.8-.8 2.1-.8 3.1-.7.6 0 1.2.1 1.5-.1.3-.1.7-.6 1-1C9.9 1 10.8 0 12 0s2.1 1 2.8 1.8c.4.4.8.9 1 1 .3.1 1 .1 1.5.1 1.1-.1 2.3-.1 3.1.7.8.8.8 2.1.7 3.1 0 .6-.1 1.2.1 1.5.1.3.6.7 1 1 .8.7 1.8 1.6 1.8 2.8s-1 2.1-1.8 2.8c-.4.4-.9.8-1 1-.1.3-.1 1-.1 1.5.1 1.1.1 2.3-.7 3.1-.8.8-2.1.8-3.1.7-.6 0-1.2-.1-1.5.1-.3.1-.7.6-1 1-.7.8-1.6 1.8-2.8 1.8zm-4.5-4.9c.5 0 1 .1 1.5.2.7.3 1.3.9 1.8 1.5.4.5 1 1.1 1.3 1.1s.9-.6 1.3-1.1c.5-.6 1.1-1.2 1.8-1.5.7-.3 1.6-.3 2.4-.2.6 0 1.4.1 1.6-.1s.1-1 .1-1.6c-.1-.8-.1-1.7.2-2.4.3-.7.9-1.3 1.5-1.8.4-.3 1-.9 1-1.2s-.6-.9-1.1-1.3c-.6-.5-1.2-1.1-1.5-1.8-.3-.7-.3-1.6-.2-2.4 0-.6.1-1.4-.1-1.6-.2-.2-1-.1-1.6-.1-.8.1-1.7.1-2.4-.2-.7-.3-1.3-.9-1.8-1.5-.4-.5-1-1.1-1.3-1.1s-.9.6-1.3 1.1c-.5.6-1.1 1.2-1.8 1.5s-1.6.3-2.4.2c-.6 0-1.4-.1-1.6.1-.2.2-.1 1-.1 1.6.1.8.1 1.7-.2 2.4-.3.7-.9 1.3-1.5 1.8-.5.4-1.1 1-1.1 1.3s.6.9 1.1 1.3c.6.5 1.2 1.1 1.5 1.8s.3 1.6.2 2.4c0 .6-.1 1.4.1 1.6.2.2 1 .1 1.6.1.3 0 .6-.1 1-.1zm3.5-2.7l-3.7-3.7 1.4-1.4 2.3 2.3 5.3-5.3 1.4 1.4-6.7 6.7z"></path>
							</g>
						</svg>											
					</div>

					<div className="textBlockMargin memovoSection__wrapper_content_text" style={{color:"#333",textAlign:"justify",maxHeight:"250px"}}>
						<div className="memovoSection__wrapper_content_header" style={{color:"orange",textAlign:"center"}}>Ativação de email pendente</div>
											
							<p>O seu email ainda não foi validado. É necessário que valide o seu email para utilizar a plataforma MeMovo.</p>
							
							<p>O seu email validadado garante o envio de notificações da plataforma como confirmação de reservas, recuperação de senhas entre outros.</p>

					</div>
					
					<div>
						{!activationStatus &&
							<Button label="Validar email" rounded className="pT10 mR10 headerButton tColorWhite" onClick={() => setDialog('validateEmail',true)} />
 						}
						<Button label="Entrar em contato" rounded outlined className="pT10 mR10 headerButton"  onClick={()=>handleClickScroll('contatoSection')}  />					
					</div>
					
				</div>
				

			</div>
		</div>
		
		{/* About Us Section */}
		<AboutUs />
		
		{/* Contac Us Section */}
		<ContactUs />

		</>
	  );
 }
 
 // If logged but with repproved status, show reprovved Screen
 if (loginStatus && currentCompanyStatus === 2) {
	return (
		<>
		<div id="loginSection" className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
			<div className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"} style={{minHeight:"300px",backgroundColor:"#FFF"}}> 
			
				<div style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center",gap:"20px",paddingTop:"5%"}}>

					<div> 
						<svg
						xmlns="http://www.w3.org/2000/svg"
						width="200"
						height="200"
						fill="#000"
						version="1.1"
						viewBox="0 0 512 512"
						xmlSpace="preserve"
						>
						<g>
							<path
							fill="#FA6E51"
							d="M512 256.006C512 397.402 397.394 512.004 256.004 512 114.606 512.004 0 397.402 0 256.006-.007 114.61 114.606 0 256.004 0 397.394 0 512 114.614 512 256.006z"
							></path>
							<path
							fill="#E8573F"
							d="M510.218 285.803c-.011-.007-.016-.019-.028-.028-.463-.657-157.825-158.02-158.483-158.482-1.254-1.786-3.227-3.027-5.574-3.027H210.586c-.338-.476-30.745-30.75-31.273-31.197l-.161-.254a6.934 6.934 0 00-5.854-3.216H96.533a6.932 6.932 0 00-6.933 6.933V325.13c0 .569.071 1.131.207 1.679 1.568 6.275 4.823 11.633 9.163 15.882.524.591 167.93 167.941 168.493 168.493.161.181.345.341.514.514 125.856-5.793 227.924-102.445 242.241-225.895z"
							></path>
							<g fill="#F4F6F9">
							<path d="M421.668 412.366l-83.2-166.4c-2.35-4.699-10.054-4.699-12.404 0l-83.2 166.4a6.934 6.934 0 00.305 6.743 6.931 6.931 0 005.898 3.291h166.4a6.934 6.934 0 006.201-10.034zm-161.382-3.833l71.981-143.962 71.981 143.962H260.286z"></path>
							<path d="M325.333 317.14v45.378a6.93 6.93 0 006.933 6.933 6.931 6.931 0 006.933-6.933V317.14a6.93 6.93 0 00-6.933-6.933 6.932 6.932 0 00-6.933 6.933zM332.267 378.275c-3.837 0-6.933 3.116-6.933 6.94a6.919 6.919 0 006.933 6.927 6.923 6.923 0 006.933-6.927c0-3.824-3.103-6.94-6.933-6.94zM221.333 339.2h-68.669c2.755-3.602 4.897-7.73 6.063-12.39.135-.548.207-1.11.207-1.679V172.8h208v48.533a6.93 6.93 0 006.933 6.933 6.931 6.931 0 006.933-6.933v-55.467a6.93 6.93 0 00-6.933-6.933h-20.801V131.2a6.93 6.93 0 00-6.933-6.933H199.121l-19.97-31.451a6.934 6.934 0 00-5.854-3.216H96.533a6.931 6.931 0 00-6.933 6.933V325.13c0 .569.071 1.131.207 1.679 3.765 15.062 16.852 25.25 32.813 25.925.542.135 1.063.332 1.647.332h97.067c3.829 0 6.933-3.101 6.933-6.933s-3.105-6.933-6.934-6.933zm-117.866-14.977V103.467h66.022l19.97 31.451a6.934 6.934 0 005.854 3.216H339.2v20.8H152a6.931 6.931 0 00-6.933 6.933v158.356c-3.129 10.887-13.082 14.774-20.8 14.774s-17.673-3.887-20.8-14.774z"></path>
							</g>
						</g>
						</svg>
					</div>

					<div className="textBlockMargin memovoSection__wrapper_content_text" style={{color:"#333",textAlign:"justify",width:"34vw",maxWidth:"unset",maxHeight:"275px"}}>
						<div className="memovoSection__wrapper_content_header" style={{color:"red",textAlign:"center"}}>Cadastro reprovado</div>
											
							<p>O cadastro de sua agência apresentou inconsistências e foi reprovado.</p>
							
							<p>Verifique abaixo o motivo da reprovação:</p>
							<p>
								<ul>
									<li>{reprovalReason ? reprovalReason : "Nenhum motivo informado. Entre em contato."}</li>
								</ul>
							</p>

							{canUpdate && 
							<>
							<p>Você pode atualizar o cadastro e tentar aprovação novamente.</p>
							</>
 							}

					</div>
					
					<div>
						
						{canUpdate && 
						<>
						<Button label="Atualizar cadastro" rounded className="pT10 mR10 headerButton tColorWhite" onClick={() => console.log('check status')} />
						</>
						}
						
						<Button label="Entrar em contato" rounded outlined className="pT10 mR10 headerButton"  onClick={()=>handleClickScroll('contatoSection')}  />					
					</div>
					
				</div>
				

			</div>
		</div>
		
		{/* About Us Section */}
		<AboutUs />
		
		{/* Contac Us Section */}
		<ContactUs />

		</>
	  );
 }

 // If logged but with repproved status, show reprovved Screen
 if (loginStatus && currentCompanyStatus === 3) {
	return (
		<>
		<div id="loginSection" className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
			<div className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"} style={{minHeight:"300px",backgroundColor:"#FFF"}}> 
			
				<div style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center",gap:"20px",paddingTop:"5%"}}>

					<div> 
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="200"
							height="200"
							fill="#000"
							version="1.1"
							viewBox="0 0 512 512"
							xmlSpace="preserve"
							>
							<g>
								<path
								fill="#FFF"
								d="M494.012 369.545c9.199 72.482-51.985 133.675-124.467 124.467-49.399-6.272-89.293-46.165-95.565-95.565-9.19-72.474 51.994-133.658 124.476-124.459 49.399 6.272 89.284 46.157 95.556 95.557z"
								></path>
								<path
								fill="#F0F0F0"
								d="M401.553 274.441c-31.275-4.873-60.459 3.567-83.166 20.309 19.115-5.666 40.286-6.537 62.31-.222 40.038 11.486 70.963 45.261 78.285 86.263 7.987 44.74-11.11 85.658-43.503 109.534 49.681-14.729 84.932-63.309 78.754-118.946-5.444-48.981-43.989-89.352-92.68-96.938z"
								></path>
								<path d="M264.533 477.867H25.6c-3.866 0-8.533-3.806-8.533-8.533v-25.6c0-4.71-3.814-8.533-8.533-8.533S0 439.023 0 443.733v25.6c0 13.636 11.964 25.6 25.6 25.6h238.933c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533zM8.533 384a8.53 8.53 0 008.533-8.533v-230.4c0-4.702 3.823-8.533 8.533-8.533h443.733c4.71 0 8.533 3.831 8.533 8.533v119.467c0 4.71 3.814 8.533 8.533 8.533s8.533-3.823 8.533-8.533V145.067c0-14.114-11.486-25.6-25.6-25.6V76.8c0-14.114-11.486-25.6-25.6-25.6H261.692a8.593 8.593 0 01-5.129-1.707L197.402 5.12A25.722 25.722 0 00182.042 0H51.2C37.086 0 25.6 11.486 25.6 25.6v93.867c-14.114 0-25.6 11.486-25.6 25.6v230.4A8.53 8.53 0 008.533 384zM409.6 119.467H85.333v-8.533c0-4.702 3.823-8.533 8.533-8.533h307.2c4.71 0 8.533 3.831 8.533 8.533v8.533zM42.667 25.6c0-4.702 3.823-8.533 8.533-8.533h130.842c1.843 0 3.661.606 5.129 1.707l59.162 44.373a25.72 25.72 0 0015.36 5.12h182.042c4.71 0 8.533 3.831 8.533 8.533v42.667h-25.6v-8.533c0-14.114-11.486-25.6-25.6-25.6h-307.2c-14.114 0-25.6 11.486-25.6 25.6v8.533h-25.6V25.6z"></path>
								<path d="M384 256c-70.579 0-128 57.421-128 128s57.421 128 128 128 128-57.421 128-128-57.421-128-128-128zM273.067 384c0-61.167 49.766-110.933 110.933-110.933 27.52 0 52.676 10.129 72.081 26.786L299.853 456.081c-16.657-19.405-26.786-44.561-26.786-72.081zM384 494.933c-27.52 0-52.676-10.129-72.081-26.786L468.156 311.91c16.649 19.413 26.778 44.57 26.778 72.09-.001 61.167-49.767 110.933-110.934 110.933zM102.4 59.733c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533H68.267c-4.719 0-8.533 3.823-8.533 8.533s3.814 8.533 8.533 8.533H102.4zM170.667 59.733h8.533c4.719 0 8.533-3.823 8.533-8.533s-3.814-8.533-8.533-8.533h-8.533c-4.719 0-8.533 3.823-8.533 8.533s3.814 8.533 8.533 8.533z"></path>
								<circle cx="136.533" cy="51.2" r="8.533"></circle>
								<circle cx="8.533" cy="409.6" r="8.533"></circle>
								<path d="M128 324.267a8.536 8.536 0 008.533-8.533c0-46.473 22.588-70.221 30.976-78.635 3.738 1.075 7.612 1.835 11.691 1.835 4.079 0 7.953-.759 11.691-1.835 8.388 8.414 30.976 32.162 30.976 78.635 0 4.71 3.823 8.533 8.533 8.533s8.533-3.823 8.533-8.533c0-47.787-21.018-74.863-32.375-86.997 9.276-7.834 15.309-19.405 15.309-32.469 0-23.526-19.14-42.667-42.667-42.667s-42.667 19.14-42.667 42.667c0 13.065 6.033 24.636 15.309 32.469-11.358 12.134-32.375 39.211-32.375 86.997a8.536 8.536 0 008.533 8.533zm51.2-153.6c14.114 0 25.6 11.486 25.6 25.6s-11.486 25.6-25.6 25.6-25.6-11.486-25.6-25.6 11.486-25.6 25.6-25.6zM281.6 162.133c-18.825 0-34.133 15.309-34.133 34.133 0 8.602 3.302 16.367 8.567 22.374-8.465 10.146-8.567 20.42-8.567 28.826a8.536 8.536 0 008.533 8.533 8.536 8.536 0 008.533-8.533c0-7.68.043-13.278 5.683-19.166 3.575 1.28 7.373 2.099 11.383 2.099s7.808-.819 11.383-2.099c5.641 5.888 5.683 11.486 5.683 19.166 0 4.71 3.823 8.533 8.533 8.533s8.533-3.823 8.533-8.533c0-8.405-.102-18.679-8.567-28.826 5.265-6.007 8.567-13.773 8.567-22.374.002-18.824-15.306-34.133-34.131-34.133zm0 51.2c-9.412 0-17.067-7.654-17.067-17.067S272.188 179.2 281.6 179.2c9.412 0 17.067 7.654 17.067 17.067s-7.655 17.066-17.067 17.066zM76.8 162.133c-18.825 0-34.133 15.309-34.133 34.133 0 8.602 3.302 16.367 8.567 22.374-8.465 10.146-8.567 20.42-8.567 28.826 0 4.71 3.823 8.533 8.533 8.533s8.533-3.823 8.533-8.533c0-7.68.043-13.278 5.683-19.166 3.575 1.28 7.373 2.099 11.383 2.099 4.011 0 7.808-.819 11.383-2.099 5.641 5.888 5.683 11.486 5.683 19.166a8.536 8.536 0 008.533 8.533 8.536 8.536 0 008.533-8.533c0-8.405-.102-18.679-8.567-28.826 5.265-6.007 8.567-13.773 8.567-22.374.002-18.824-15.306-34.133-34.131-34.133zm0 51.2c-9.412 0-17.067-7.654-17.067-17.067S67.388 179.2 76.8 179.2s17.067 7.654 17.067 17.067-7.655 17.066-17.067 17.066z"></path>
								<g fill="#CC004E">
								<path d="M300.629 252.928c-15.189 10.496-29.696 4.864-37.803-.341 1.024-1.451 1.707-3.243 1.707-5.12 0-7.68 0-13.312 5.717-19.2 3.499 1.28 7.339 2.133 11.349 2.133s7.851-.853 11.349-2.133c5.717 5.888 5.717 11.52 5.717 19.2a8.726 8.726 0 001.964 5.461z"></path>
								<circle cx="281.6" cy="196.267" r="17.067"></circle>
								</g>
								<g fill="#0F7BE4">
								<path d="M229.12 324.181c-11.008 34.219-88.832 34.219-99.84 0 4.096-.683 7.253-4.181 7.253-8.448 0-46.507 22.613-70.229 30.976-78.677 3.755 1.109 7.595 1.877 11.691 1.877s7.936-.768 11.691-1.877c8.363 8.448 30.976 32.171 30.976 78.677 0 4.267 3.157 7.766 7.253 8.448z"></path>
								<circle cx="179.2" cy="196.267" r="25.6"></circle>
								</g>
								<path
								fill="#FDB50D"
								d="M409.6 110.933v8.533H85.333v-8.533c0-4.693 3.84-8.533 8.533-8.533h307.2c4.694 0 8.534 3.84 8.534 8.533z"
								></path>
								<path
								fill="#AFAFAF"
								d="M477.867 264.533V145.067c0-4.693-3.84-8.533-8.533-8.533H25.6c-4.693 0-8.533 3.84-8.533 8.533v230.4c0 4.693-3.84 8.533-8.533 8.533v17.067c4.693 0 8.533 3.84 8.533 8.533s-3.84 8.533-8.533 8.533V435.2c4.693 0 8.533 3.84 8.533 8.533v25.6c0 4.693 4.693 8.533 8.533 8.533h238.933c4.693 0 8.533 3.84 8.533 8.533h34.219C276.139 463.019 256 425.813 256 384c0-70.571 57.429-128 128-128 41.813 0 79.019 20.139 102.4 51.285v-34.219c-4.693.001-8.533-3.839-8.533-8.533zm-366.934-17.066c0 4.693-3.84 8.533-8.533 8.533-2.645 0-4.949-1.195-6.571-3.072-15.189 10.496-29.696 4.864-37.803-.341A8.52 8.52 0 0151.2 256c-4.693 0-8.533-3.84-8.533-8.533 0-8.363.085-18.688 8.533-28.843-5.205-5.973-8.533-13.739-8.533-22.357 0-18.859 15.275-34.133 34.133-34.133s34.133 15.275 34.133 34.133c0 8.619-3.328 16.384-8.533 22.357 8.448 10.155 8.533 20.48 8.533 28.843zm119.467 76.8c-.427 0-.853 0-1.28-.085-11.008 34.219-88.832 34.219-99.84 0-.427.085-.853.085-1.28.085-4.693 0-8.533-3.84-8.533-8.533 0-47.787 20.992-74.837 32.341-87.04-9.216-7.765-15.275-19.371-15.275-32.427 0-23.552 19.115-42.667 42.667-42.667s42.667 19.115 42.667 42.667c0 13.056-6.059 24.661-15.275 32.427 11.349 12.203 32.341 39.253 32.341 87.04 0 4.693-3.84 8.533-8.533 8.533zm85.333-76.8c0 4.693-3.84 8.533-8.533 8.533-2.645 0-4.949-1.195-6.571-3.072-15.189 10.496-29.696 4.864-37.803-.341A8.52 8.52 0 01256 256c-4.693 0-8.533-3.84-8.533-8.533 0-8.363.085-18.688 8.533-28.843-5.205-5.973-8.533-13.739-8.533-22.357 0-18.859 15.275-34.133 34.133-34.133 18.859 0 34.133 15.275 34.133 34.133 0 8.619-3.328 16.384-8.533 22.357 8.448 10.155 8.533 20.48 8.533 28.843z"
								></path>
								<g fill="#CC004E">
								<circle cx="76.8" cy="196.267" r="17.067"></circle>
								<path d="M95.829 252.928c-15.189 10.496-29.696 4.864-37.803-.341 1.024-1.451 1.707-3.243 1.707-5.12 0-7.68 0-13.312 5.717-19.2 3.499 1.28 7.339 2.133 11.349 2.133 4.011 0 7.851-.853 11.349-2.133 5.717 5.888 5.717 11.52 5.717 19.2a8.726 8.726 0 001.964 5.461z"></path>
								</g>
								<path
								fill="#7E939E"
								d="M477.867 264.448V144.981c0-4.693-3.84-8.533-8.533-8.533h-25.6v111.019c-12.117-5.12-25.344-8.107-39.168-8.533-1.195-.085-2.304-.085-3.499-.085-15.104 0-29.525 3.072-42.667 8.533h-.085c-13.653 5.717-25.941 14.08-36.096 24.491a119.337 119.337 0 0115.701-7.339c14.251-5.547 29.781-8.619 46.08-8.619 21.589 0 41.899 5.376 59.733 14.848 16.811 8.789 31.317 21.333 42.667 36.437v-34.219c-4.693 0-8.533-3.84-8.533-8.533z"
								></path>
								<path
								fill="#F6981E"
								d="M401.067 102.4h-34.133c4.693 0 8.533 3.84 8.533 8.533v8.533H409.6v-8.533c0-4.693-3.84-8.533-8.533-8.533z"
								></path>
								<g fill="#215EFF">
								<path d="M179.2 170.667c-.137 0-.273.043-.41.043 10.103 3.43 17.476 12.919 17.476 24.149 0 13.935-11.298 25.327-25.19 25.557 2.569.87 5.274 1.451 8.124 1.451 14.08 0 25.6-11.52 25.6-25.6s-11.52-25.6-25.6-25.6zM215.014 325.794c0 4.745 3.14 8.619 7.228 9.463 3.132-3.174 5.521-6.861 6.878-11.076-4.096-.683-7.253-4.181-7.253-8.448 0-46.507-22.613-70.229-30.976-78.677a48.606 48.606 0 01-6.178 1.399c9.284 10.565 30.301 37.18 30.301 87.339z"></path>
								</g>
								<g fill="#9B0045">
								<path d="M291.337 247.467c0 1.724 2.21 6.332 3.644 8.678a36.164 36.164 0 005.658-3.217 8.668 8.668 0 01-1.971-5.461c0-7.68 0-13.312-5.717-19.2-1.451.529-2.987.93-4.54 1.28 3.224 5.307 2.926 11.494 2.926 17.92zM282.223 179.26c5.658 2.782 9.617 8.567 9.617 15.266 0 9.387-7.68 17.067-17.067 17.067-.213 0-.418-.06-.623-.06 2.261 1.118 4.77 1.801 7.45 1.801 9.387 0 17.067-7.68 17.067-17.067 0-9.174-7.348-16.666-16.444-17.007zM86.537 247.467c0 1.724 2.21 6.332 3.644 8.678a36.164 36.164 0 005.658-3.217 8.668 8.668 0 01-1.971-5.461c0-7.68 0-13.312-5.717-19.2-1.451.529-2.987.93-4.54 1.28 3.224 5.307 2.926 11.494 2.926 17.92zM77.423 179.26c5.658 2.782 9.617 8.567 9.617 15.266 0 9.387-7.68 17.067-17.067 17.067-.213 0-.418-.06-.623-.06 2.261 1.118 4.77 1.801 7.45 1.801 9.387 0 17.067-7.68 17.067-17.067 0-9.174-7.348-16.666-16.444-17.007z"></path>
								</g>
								<path
								fill="#7E939E"
								d="M443.733 68.267H261.717c-5.547 0-11.008-1.792-15.36-5.12l-59.221-44.373c-1.451-1.109-3.243-1.707-5.12-1.707H51.2c-4.693 0-8.533 3.84-8.533 8.533v93.867h25.6v-8.533c0-14.08 11.52-25.6 25.6-25.6h307.2c14.08 0 25.6 11.52 25.6 25.6v8.533h25.6V76.8c0-4.693-3.84-8.533-8.534-8.533zM102.4 59.733H68.267c-4.693 0-8.533-3.84-8.533-8.533s3.84-8.533 8.533-8.533H102.4c4.693 0 8.533 3.84 8.533 8.533s-3.84 8.533-8.533 8.533zm34.133 0c-4.693 0-8.533-3.84-8.533-8.533s3.84-8.533 8.533-8.533 8.533 3.84 8.533 8.533-3.839 8.533-8.533 8.533zm42.667 0h-8.533c-4.693 0-8.533-3.84-8.533-8.533s3.84-8.533 8.533-8.533h8.533c4.693 0 8.533 3.84 8.533 8.533s-3.84 8.533-8.533 8.533z"
								></path>
							</g>
						</svg>
					</div>

					<div className="textBlockMargin memovoSection__wrapper_content_text" style={{color:"#333",textAlign:"center",width:"34vw",maxWidth:"unset",maxHeight:"250px"}}>
						<div className="memovoSection__wrapper_content_header" style={{color:"black",textAlign:"center"}}>Cadastro bloqueado</div>
											
							<p>O cadastro de sua agência está bloqueado.</p>
							
							<p>Verifique abaixo os prováveis motivos para bloqueio:</p>
							<p>
								<ul style={{textAlign:"left",marginLeft:"40px"}}>
									<li>Muitos pagamentos recusados por suspeita de fraude</li>
									<li>Reclamações e insatisfação de clientes</li>
									<li>Atividades irregulares</li>
								</ul>
							</p>

							<p>Entre em contato para revisão e liberação do cadastro.</p>

					</div>
					
					<div>
						<Button label="Entrar em contato" rounded outlined className="pT10 mR10 headerButton"  onClick={()=>handleClickScroll('contatoSection')}  />					
					</div>
					
				</div>
				

			</div>
		</div>
		
		{/* About Us Section */}
		<AboutUs />
		
		{/* Contac Us Section */}
		<ContactUs />

		</>
	  );
 }


// If not logged, show login screen
  return (
	<>
	<div id="loginSection" className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
		<div className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 
		
			<img src={cornerBottom} className="login__wrapper_form_wrapper_cornerImage noSelect" />
		
			<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} >
						
				<div className="formWrapper" >
				
					<img src={formTopIcons} className="contatoSection__wrapper_content_formTop_image noSelect" />
				
					<div className="formTitle">Acesse a plataforma</div>
					<div className="formSubTitle">Encontre os melhores serviços de mobilidade B2B em um só lugar</div>
					
					<span className="p-float-label" style={{marginBottom:"1.875rem"}}>
						<InputText id="email" disabled={saving} className="emailInput" value={email} onChange={(e) => setEmail(e.target.value)} style={{width:"100%"}} />
						<label htmlFor="email">E-mail</label>
					</span>				
					
					<span className="p-float-label" style={{marginBottom:"1.875rem"}}>
						<Password inputId="password" disabled={saving} toggleMask value={password} onChange={(e) => setPassword(e.target.value)} feedback={false} className="passwordInput" />
						<label htmlFor="password">Senha</label>
					</span>	
					
					<div className="formTools">
					
						{/*<div style={{flex:1,textAlign:"left"}}>     
							<Checkbox inputId="stillConnected" name="stillConnected" value="yes" onChange={onStillConnectedChange} disabled={saving} checked={stillConnected} />
							<label htmlFor="stillConnected" className="ml-2"> Não manter-me conectado</label>
						</div>*/}
						<div style={{flex:1,textAlign:"center"}} onClick={()=> setForgotPassword(true)}>     
							<span className="forgotPasswordButton">Esqueceu sua senha? Saiba como recuperar.</span>
						</div>			
					
					</div>
					
					<div className="formActions">
					
						<Button label="Entrar" rounded className="mR10 headerButton tColorWhite w100" style={{marginBottom:"0.625rem"}} loading={saving} onClick={() => login()} />
						<Button label="Cadastre-se" disabled={saving} text className="headerButton tColorPink w100" onClick={() => setDialog('registerModal',true)} />		
					
					</div>					

				</div>
			
			</div> 
		</div>
		<div className={isTabletOrMobile ? "login__wrapper_conceptImage_wrapper mobile" : "login__wrapper_conceptImage_wrapper"}> 
		
			<img src={cornerTop} className="login__wrapper_conceptImage_wrapper_cornerImage noSelect" />
		
			<img className={isTabletOrMobile ? "conceptImage login__wrapper_conceptImage_image mobile noSelect" : "conceptImage login__wrapper_conceptImage_image noSelect"}  src={multiTransportConcept} />
		</div>
    </div>
	
	{/* About Us Section */}
	<AboutUs />
	
	{/* Contac Us Section */}
	<ContactUs />	

	<ForgotPasswordView
		forgotPassword={forgotPassword}
		setForgotPassword={setForgotPassword}
		scrollBlock={false}		
		setAlert={setAlert}
		setAlertTitle={setAlertTitle}
		setAlertBody={setAlertBody}					
	/>		

	<AlertView
		alert={alert}
		setAlert={setAlert}
		alertTitle={alertTitle}
		alertBody={alertBody}	
		scrollBlock={false}	
	/>   	

	</>
  );
    
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  loginStatus:store.appReducer.loginStatus,
  currentCompanyStatus:store.appReducer.currentCompanyStatus,
  activationStatus:store.appReducer.activationStatus
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions, ...LoginActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(HomePage);	


