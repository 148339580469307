import '../Assets/Styles/register.css';
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Load Actions
import * as AppActions from '../Store/Actions/App';

// Load Components
import TermsView from '../Screens/Home/TermsModal';
import AlertView from '../Components/AlertModal';
import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone
} from '../Components/Utils';

// UpdateService dialog component
const MessageView = (props) => {

// set vars from props
const { 
	dialog,
	setDialog
} = props;		

const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 

const [termsModal, setTermsModal] = React.useState(false); 

const [companyLegalName, setCompanyLegalName] = React.useState('');
const [personFirstName, setPersonFirstName] = React.useState('');
const [personLastName, setPersonLastName] = React.useState('');
const [personEmail, setPersonEmail] = React.useState('');
const [personPhone, setPersonPhone] = React.useState('');
const [subject, setSubject] = React.useState([]);
const [message, setMessage] = React.useState('');
const [priority, setPriority] = React.useState('');

const [acceptTerm, setAcceptTerm] = React.useState(false);

// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 		

const handleTermsChange = (e) => {
	if (e.checked)
		setAcceptTerm(true);
	else
		setAcceptTerm(false);
}  // end function

/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}

const save = () => {
	
// Check required fields
if ( !companyLegalName || !personFirstName || !personLastName || !personEmail || !subject || !message ) {
	setAlert(true);
	setAlertTitle("Verifique as informações");
	setAlertBody("Um ou mais campos não estão preenchidos. É necessário que preencha todos os campos marcados com * para prosseguir com o envio da mensagem.");	
	return;
} // end if
 
// Check Document (CNPJ and CPF), phone and email consistency
if (!validarNome(personFirstName) || !validarNome(personLastName) || !validarNome(companyLegalName)) {
	setAlert(true);
	setAlertTitle("Verifique os dados");
	setAlertBody("O nome da emprea ou nome completo parece estar inválido. Verifique novamente.");	
	return;	
} // end if
if (!validarEmail(personEmail)) {
	setAlert(true);
	setAlertTitle("Verifique o Email");
	setAlertBody("O email informado é inválido. Verifique novamente.");	
	return;		
} // end if
if ( !validarTelefone(personPhone) && personPhone) {
	setAlert(true);
	setAlertTitle("Verifique o Telefone");
	setAlertBody("O telefone informado é inválido. Verifique novamente.");	
	return;		
} // end if

// Check if accepted terms
if (!acceptTerm) {
	setAlert(true);
	setAlertTitle("Verifique o aceite aos termos");
	setAlertBody("Para prosseguir com a mensagem, é necessário que leia e aceite os termos e condições de uso.");	
	return;
} // end if

// Set button loading on and freeze form 	
setSaving(true);

// Execute Recaptcha Token
	executeReCaptcha().then(function(token) {

		// Save MessageView
		// ...

		var props = {
			setSaving:setSaving,
			setAlert:setAlert,
			setAlertTitle:setAlertTitle,
			setAlertBody:setAlertBody
		}
		var data = {
		  companyLegalName: companyLegalName,
		  personFirstName: personFirstName,
		  personLastName: personLastName,
		  personEmail: personEmail,
		  personPhone: personPhone,
		  subject: subject,
		  message: message
		}
		// registerAppAgency(data,props);

			// simulate return
			setTimeout(function() {

				// set button loading off
				setSaving(false);
				
				setAlert(true);
				setAlertTitle("Tudo certo!");
				setAlertBody("A mensagem foi enviada com sucesso! Você receberá uma cópia da mensagem em seu email. Em breve retornaremos.");

			},3000);		
	});
	
}
	
    const footerContent = (
        <div className="modalFooterButtons" style={{}}>
		
			<div className="modalFooterButtonsLeft">
				<div className="flex align-items-center">
					<Checkbox inputId="acceptTerm" disabled={saving} name="acceptTerm" value="sim" onChange={handleTermsChange} checked={acceptTerm} className="mR10" />
					<label htmlFor="acceptTerm" className="ml-2">Estou de acordo com os <Button label="termos e condições de uso" link style={{padding:"unset"}} onClick={() => setTermsModal(true)} /></label>
				</div>
			</div>
			<div className="modalFooterButtonsRight">
				<Button label="Fechar" disabled={saving} icon="pi pi-times" className="p-button-text" onClick={() => closeDialog()} />
				<Button label="Enviar" icon="pi pi-check" loading={saving} onClick={() => save()} />
			</div>
			
        </div>
    );		

const SubjectItems = [
			{
			"code": 1,
			"name": "Comercial"
			},
			{
			"code": 2,
			"name": "Dúvidas"
			},
			{
			"code": 3,
			"name": "Reclamações"
			},
			{
			"code": 4,
			"name": "Outros"
			}
];	
const [dropdownSubjectItems, setDropdownSubjectItems] = React.useState(SubjectItems);

const PriorityItems = [
			{
			"code": 1,
			"name": "Normal"
			},
			{
			"code": 2,
			"name": "Urgente"
			},
			{
			"code": 3,
			"name": "Crítico"
			}
];	
const [dropdownPriorityItems, setDropdownPriorityItems] = React.useState(PriorityItems);

/* Reset form fields and close dialog */
const closeDialog = () => {
	
	// Reset form fields
	setCompanyLegalName('');
	setPersonFirstName('');
	setPersonLastName('');
	setPersonEmail('');
	setPersonPhone('');
	setSubject('');
	setMessage('');
	
	// close dialog
	setDialog('messageModal',false);
	
}

// Execute when dialog opens
React.useEffect(() => {

	// Only r]uns when  dialog opens
	if (dialog.target == 'messageModal' && dialog.status) {

		
	} // end if
	
	// Reset all fields when closes or open dialog
	// ...
			
},[dialog]);

	return (
	<>
			<Dialog header="Entre em contato" visible={dialog.target == 'messageModal' && dialog.status} style={{ width: '55vw' }} breakpoints={{ '1400px': '75vw','960px': '80vw', '641px': '100vw' }} onHide={() => closeDialog()} footer={footerContent} blockScroll={true} maximizable={saving ? false : false} closable={saving ? false : true} closeOnEscape={saving ? false : true} >
			
				<Box
				  sx={{
					width: "100%",
				  }}
				>
					<div className="messageModalIntro"> Preencha os dados para enviar uma mensagem para a Memovo. <br /><i>Campos obrigatórios marcados com <sup><span className="requiredField">*</span></sup></i></div>					

					<Divider />
					  
						<Panel header="Dados do remetente" toggleable style={{marginBottom:"20px"}}>
							<Grid container spacing={4} style={{marginTop:"-20px"}}>
							  <Grid item xs={12} sm={12}>
								<span className="p-float-label">
									<InputText id="companyLegalName" autoComplete="new-password" disabled={saving ? true : false} className="w100" value={companyLegalName} onChange={(e) => setCompanyLegalName(e.target.value)} tooltip="Como consta no CNPJ" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
									<label htmlFor="companyLegalName">Nome da empresa <span className="requiredField">*</span></label>
								</span>
							  </Grid>
							 <Grid item xs={12} sm={6}>
								<span className="p-float-label" >
									<InputText id="personFirstName" autoComplete="new-password" disabled={saving ? true : false} value={personFirstName} onChange={(e) => setPersonFirstName(e.target.value)} style={{width:"100%"}} />
									<label htmlFor="personFirstName">Primeiro nome<span className="requiredField">*</span></label>   						
								</span>		
							  </Grid>
							 <Grid item xs={12} sm={6}>
								<span className="p-float-label" >
									<InputText id="personLastName" autoComplete="new-password" disabled={saving ? true : false} value={personLastName} onChange={(e) => setPersonLastName(e.target.value)} style={{width:"100%"}} />
									<label htmlFor="personLastName">Último nome<span className="requiredField">*</span></label>   						
								</span>		
							  </Grid>
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label" >
									<InputText id="personEmail" autoComplete="new-password" disabled={saving ? true : false} keyfilter="int" value={personEmail} onChange={(e) => setPersonEmail(e.target.value)} style={{width:"100%"}} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus'}} />
									<label htmlFor="personEmail">Email <span className="requiredField">*</span></label>
								</span>	
							  </Grid>
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label" >
									<InputText id="personPhone" autoComplete="new-password" disabled={saving ? true : false} value={personPhone} onChange={(e) => setPersonPhone(maskPhone(e.target.value))} style={{width:"100%"}} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
									<label htmlFor="personPhone">Telefone </label>								
								</span>	
							  </Grid>

							</Grid>
						</Panel>					  

						<Panel header="Dados da mensagem" toggleable style={{marginBottom:"20px"}}>

							<Grid container spacing={4} style={{marginTop:"-20px"}}>
							  
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label">
									<Dropdown id="subject" disabled={saving ? true : false} value={subject} onChange={(e) => setSubject(e.value)} options={dropdownSubjectItems} optionLabel="name" placeholder="Selecione uma opção" className="w100"></Dropdown>
									<label htmlFor="subject">Assunto <span className="requiredField">*</span></label>
								</span>
							  </Grid>							  
							  
							  <Grid item xs={12} sm={6}>
								<span className="p-float-label">
									<Dropdown id="priority" disabled={saving ? true : false} value={priority} onChange={(e) => setPriority(e.value)} options={dropdownPriorityItems} optionLabel="name" placeholder="Selecione uma opção" className="w100"></Dropdown>
									<label htmlFor="subject">Prioridade </label>
								</span>
							  </Grid>
										
							  <Grid item xs={12} sm={12}>
								<span className="p-float-label" >
									<InputTextarea id="message" autoComplete="new-password" disabled={saving ? true : false} value={message} onChange={(e) => setMessage(e.target.value)} style={{width:"100%"}} tooltip="Digite a mensagem" tooltipOptions={{ position: 'bottom',event: 'focus' }} autoResize  rows={5} cols={30} />
									<label htmlFor="message">Mensagem <span className="requiredField">*</span></label>								
								</span>	
							  </Grid>										
										
							</Grid>
			</Panel>
				  
			<TermsView
				setTermsModal={setTermsModal}
				termsModal={termsModal}
				scrollBlock={true}
			/>
			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={true}				
			/>
			</Box>
            </Dialog>	
	</>	
	);
	
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(MessageView);	
