import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import '../../Assets/Styles/orderStep2.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import { ToggleButton } from 'primereact/togglebutton';
import { Tooltip } from 'primereact/tooltip';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { ProgressSpinner } from 'primereact/progressspinner';

import multiTransportConcept from '../../Assets/Images/multiTransportConcept11.png'; 
import desktopConcept from '../../Assets/Images/desktopConcept.png'; 
import contactConcept from '../../Assets/Images/contactConcept.png'; 
import formPlaceholder from '../../Assets/Images/formPlaceholder.png'; 
import formTopIcons from '../../Assets/Images/formTopIcons.svg'; 
import cornerTop from '../../Assets/Images/cornerTop.svg';
import cornerBottom from '../../Assets/Images/cornerBottom.svg';

import contactWhatsappIcon from '../../Assets/Images/contactWhatsappIcon.svg';
import contactEmailIcon from '../../Assets/Images/contactEmailIcon.svg';
import contactPhoneIcon from '../../Assets/Images/contactPhoneIcon.svg';
import transportPicture from '../../Assets/Images/transportPicture.svg';
import rentcarPicture from '../../Assets/Images/rentcarPicture.svg';
import transferPicture from '../../Assets/Images/transferPicture.svg';
import transportIcon from '../../Assets/Images/transportIcons.svg';
import transferIcon from '../../Assets/Images/transferIcon.svg';
import rentcarIcon from '../../Assets/Images/rentcarIcon.svg';
import tooltipIcon from '../../Assets/Images/tooltipIcon.svg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as OrderActions from '../../Store/Actions/Order'

// Load Componentes
import MessageView from '../../Components/MessageModal';
import AlertView from '../../Components/AlertModal';
import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone
} from '../../Components/Utils';

import {
	Callto,
	Whatsapp
} from '../../Components/Utils';

/*
import {
	stepsData
} from '../../Components/StaticData';*/

// export default function HomePage() {
const HomePage = (props) => {
	
// Set var from props
const {
	setDialog,
	dialog,
	transportFilters,
	setTransportFilters
} = props;

const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
const [countryLoading, setCountryLoading] = React.useState(false); 
const [departureLocalCityLoading, setDepartureLocalCityLoading] = React.useState(false); 
const [destinationLocalCityLoading, setDestinationLocalCityLoading] = React.useState(false); 
		
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })

const [email, setEmail] = React.useState('');
const [password, setPassword] = React.useState('');
const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

const [forgotPassword, setForgotPassword] = React.useState(false); 		

// Transport selection
const [checkedAll, setCheckedAll] = React.useState(false);
const [checkedAirplane, setCheckedAirplane] = React.useState(false);
const [checkedTrain, setCheckedTrain] = React.useState(false);
const [checkedBus, setCheckedBus] = React.useState(false);
const [directServices, setDirectServices] = React.useState(false);
const [selectedTransport, setSelectedTransport] = React.useState(false);

const [departureLocalCountry, setDepartureLocalCountry] = React.useState(null);
const [departureLocalCity, setDepartureLocalCity] = React.useState(null);
const [destinationLocalCountry, setDestinationLocalCountry] = React.useState(null);
const [destinationLocalCity, setDestinationLocalCity] = React.useState(null);
const [pickReturnDate, setPickReturnDate] = React.useState(false);
const [pickReturn, setPickReturn] = React.useState(false);

const [leaveDate, setLeaveDate] = React.useState(null);
const [returnDate, setReturnDate] = React.useState(null);

// const [value, setValue] = React.useState('');
// const [items, setItems] = React.useState([]);

/* Person select START */
const [menuOpened,setMenuOpened] = React.useState(false);
const [personAddLabel,setPersonAddLabel] = React.useState("1 Adulto (Adicionar Mais Pessoas)"); // default
const [personAddAdult,setPersonAddAdult] = React.useState(1); // default adult
const [personAddYoung,setPersonAddYoung] = React.useState(0); // default young
const [personAddTotal,setPersonAddTotal] = React.useState(1); 
const [personAddElder,setPersonAddElder] = React.useState(0); // default elder


// const [personAddElderAges,setPersonAddElderAges] = React.useState([]); 
// const [personAddYoungAges,setPersonAddYoungAges] = React.useState([]); 

const [selectedElderAge,setSelectedElderAge] = React.useState([]);
const [selectedYoungAge,setSelectedYoungAge] = React.useState([]); 

const [departureCityLoading, setDepartureCityLoading] = React.useState(false);
const [destinationCityLoading, setDestinationCityLoading] = React.useState(false);
const [departureCityResult, setDepartureCityResult] = React.useState(false);
const [destinationCityResult, setDestinationCityResult] = React.useState(false);

let today = new Date(); 
let month = today.getMonth();
let year = today.getFullYear();
//let minDate = new Date();

// console.log("[today]--->"+JSON.stringify(today,null,2));

// Add 72 hours to current date
let minDate = new Date(today.setHours(today.getHours() + 72));

// console.log("[minDate]--->"+JSON.stringify(minDate,null,2));


addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});

const op = React.useRef(null);
const onHide = () => {
setMenuOpened(false);
}  
const onShow = () => {
setMenuOpened(true);
}    

 
const setPerson = (operation,type) => {
	
	// Set total passengers
	let currentTotal = personAddAdult + personAddYoung + personAddElder;
	
	if (type == 'adult') {
		
		let newValue = operation == '+' ? personAddAdult + 1 : personAddAdult - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 9 || operation == '-') {
			setPersonAddAdult(newValue);
		}
		
	} // end if
	else if (type == 'young') {
		
		let newValue = operation == '+' ? personAddYoung + 1 : personAddYoung - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 9 || operation == '-') {
			setPersonAddYoung(newValue);
		}
		
		// reset ages
		if (operation == '-') { setSelectedYoungAge([]); }
		
	} // end if
	else if (type == 'elder') {
		
		let newValue = operation == '+' ? personAddElder + 1 : personAddElder - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 9 || operation == '-') {
			setPersonAddElder(newValue);	
		}
		
		// reset ages
		if (operation == '-') { setSelectedElderAge([]); }		
		
	} // end if

	
} // end function
/* Person select END */

const generateTotalLabel = () => {

	// Set total passengers
	let currentTotal = personAddAdult + personAddYoung + personAddElder;

	if (personAddAdult == 1 && personAddYoung == 0 && personAddElder == 0) {
		var label = "1 Adulto (Adicionar Mais Pessoas)";	
	} // end if
	else if (personAddAdult > 1 && personAddYoung == 0 && personAddElder == 0) {
		var label = ""+personAddAdult+" Adultos (Adicionar Mais Pessoas)";	
	} // end else if
	else if (currentTotal == 0) {
		var label = "Nenhum passageiro, (Adicionar Pessoas)";
	} // end else if	
	else if (currentTotal == 1) {
		var label = ""+currentTotal+" Passageiro (Adicionar Mais Pessoas)";
	} // end else if	
	else {
		var label = ""+currentTotal+" Passageiros (Adicionar Mais Pessoas)";	
	} // end else
	
	// Set Person Control Label
	setPersonAddLabel(label);
	
}

const processPickReturn = (value) => {
	
	if (value) {
		setPickReturnDate(true);
	} // end if
	else {
		setPickReturnDate(false);
	} // end else

	setPickReturn(value);		
	
}

const [dropdownCountryItems, setDropdownCountryItems] = React.useState([]);
const [dropdownDepartureCityItems, setDropdownDepartureCityItems] = React.useState([]);
const [dropdownDestinationCityItems, setDropdownDestinationCityItems] = React.useState([]);

const processCountryCities = (source,value) => {

	// Set value and loading
	if (source == "departure") {
		setDepartureLocalCityLoading(true);
		setDepartureLocalCountry(value);
	} // end if
	else {
		setDestinationLocalCityLoading(true);
		setDestinationLocalCountry(value);
	} // end else

	// Retrieve Cities according to source
	// ...
	
		// Set loading countries for dropdown
		
		
		// Remote call to countries dropdown
		// ...
		
		setTimeout(function() {
						
			const CityItems = [
			  {
				"code": "AT INN",
				"name": "Innsbruck"
			  },
			  {
				"code": "AT SZG",
				"name": "Salzburg"
			  },
			  {
				"code": "AT VIE",
				"name": "Vienna"
			  },
			  {
				"code": "BE BRU",
				"name": "Brussels"
			  },
			  {
				"code": "BE BRG",
				"name": "Bruges"
			  },
			  {
				"code": "CZ PRG",
				"name": "Prague"
			  },
			  {
				"code": "DK CPH",
				"name": "Copenhagen"
			  },
			  {
				"code": "FR AIX",
				"name": "Aix-en-Provence"
			  },
			  {
				"code": "FR AVN",
				"name": "Avignon"
			  },
			  {
				"code": "FR BIQ",
				"name": "Biarritz"
			  },
			  {
				"code": "FR BOD",
				"name": "Bordeaux"
			  },
			  {
				"code": "FR CCF",
				"name": "Carcassonne"
			  },
			  {
				"code": "FR DIJ",
				"name": "Dijon"
			  },
			  {
				"code": "FR LYS",
				"name": "Lyon"
			  },
			  {
				"code": "FR MRS",
				"name": "Marseille"
			  },
			  {
				"code": "FR NCE",
				"name": "Nice"
			  },
			  {
				"code": "FR PAR",
				"name": "Paris"
			  },
			  {
				"code": "FR RNS",
				"name": "Rennes"
			  },
			  {
				"code": "FR SML",
				"name": "St. Malo"
			  },
			  {
				"code": "FR SXB",
				"name": "Strasbourg"
			  },
			  {
				"code": "FR TLS",
				"name": "Toulouse"
			  },
			  {
				"code": "DE BAD",
				"name": "Baden Baden"
			  },
			  {
				"code": "DE BAM",
				"name": "Bamberg"
			  },
			  {
				"code": "DE BER",
				"name": "Berlin"
			  },
			  {
				"code": "DE CGN",
				"name": "Cologne"
			  },
			  {
				"code": "DE DUS",
				"name": "Dusseldorf"
			  },
			  {
				"code": "DE FRA",
				"name": "Frankfurt"
			  },
			  {
				"code": "DE FDH",
				"name": "Freiburg"
			  },
			  {
				"code": "DE HAM",
				"name": "Hamburg"
			  },
			  {
				"code": "DE MUC",
				"name": "Munich"
			  },
			  {
				"code": "DE NUE",
				"name": "Nuremberg"
			  },
			  {
				"code": "DE STR",
				"name": "Stuttgart"
			  },
			  {
				"code": "HU BUD",
				"name": "Budapest"
			  },
			  {
				"code": "IT AOI",
				"name": "Ancona"
			  },
			  {
				"code": "IT BGY",
				"name": "Bergamo"
			  },
			  {
				"code": "IT BLQ",
				"name": "Bologna"
			  },
			  {
				"code": "IT FLR",
				"name": "Florence"
			  },
			  {
				"code": "IT GOA",
				"name": "Genova"
			  },
			  {
				"code": "IT LIV",
				"name": "Livorno"
			  },
			  {
				"code": "IT MIL",
				"name": "Milan"
			  },
			  {
				"code": "IT NAP",
				"name": "Napoli"
			  },
			  {
				"code": "IT PSR",
				"name": "Pescara"
			  },
			  {
				"code": "IT PSA",
				"name": "Pisa"
			  },
			  {
				"code": "IT REG",
				"name": "Reggio Calabria"
			  },
			  {
				"code": "IT ROM",
				"name": "Roma"
			  },
			  {
				"code": "IT SAL",
				"name": "Salerno"
			  },
			  {
				"code": "IT TRN",
				"name": "Torino"
			  },
			  {
				"code": "IT VCE",
				"name": "Venice"
			  },
			  {
				"code": "IT VRN",
				"name": "Verona"
			  },
			  {
				"code": "NL AMS",
				"name": "Amsterdam"
			  },
			  {
				"code": "ES ALC",
				"name": "Alicante"
			  },
			  {
				"code": "ES BCN",
				"name": "Barcelona"
			  },
			  {
				"code": "ES CBA",
				"name": "Cordoba"
			  },
			  {
				"code": "ES GRX",
				"name": "Granada"
			  },
			  {
				"code": "ES MAD",
				"name": "Madrid"
			  },
			  {
				"code": "ES SVQ",
				"name": "Seville"
			  },
			  {
				"code": "ES VLC",
				"name": "Valencia"
			  },
			  {
				"code": "CH BSL",
				"name": "Basel"
			  },
			  {
				"code": "CH BRN",
				"name": "Bern"
			  },
			  {
				"code": "CH LSN",
				"name": "Lausanne"
			  },
			  {
				"code": "CH INT",
				"name": "Interlaken"
			  },
			  {
				"code": "CH LUC",
				"name": "Lucerne"
			  },
			  {
				"code": "CH LUG",
				"name": "Lugano"
			  },
			  {
				"code": "CH GVA",
				"name": "Geneva"
			  },
			  {
				"code": "CH ZER",
				"name": "Zermatt"
			  },
			  {
				"code": "CH ZRH",
				"name": "Zurich"
			  },
			  {
				"code": "PT LIS",
				"name": "Lisbon"
			  },
			  {
				"code": "PT OPO",
				"name": "Porto"
			  },
			  {
				"code": "GB LON",
				"name": "London"
			  },
			  {
				"code": "GB EDI",
				"name": "Edinburgh"
			  }
			];
			
			if (source == "departure") {
				setDropdownDepartureCityItems(CityItems);
				setDepartureCityResult(true);
				setDepartureLocalCityLoading(false);
			} // end if
			else {
				setDropdownDestinationCityItems(CityItems);
				setDestinationCityResult(true);
				setDestinationLocalCityLoading(false);
			} // end else			
		
			
		},500);	
	
	
}

/* Retrieve cities linked to selcted state */
/*const retrieveDropdownCities = (value) => {
	
	// set loading on
	setCompanyCity('');
	setCityLoading(true);
	setCityResult(false);
	setDropdownCityItems([]);
	
	// set props
	var props = {
		setCityLoading:setCityLoading,
		setCityResult:setCityResult,
		setDropdownCityItems:setDropdownCityItems		
	}	
	// retrieve cities based on state
	//retrieveAppCities(value.code,props);
	
	
}*/

// set Use of history for PUSH 
var navigate = useNavigate();
	
/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}

// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if

  // set scroll to top
  window.scrollTo(0, 1);
  
  setLoading(false);

  // Load stored info
  if (transportFilters.departureLocalCountry) {
	  
		// Transport selection
		setCheckedAll(transportFilters.checkedAll);
		setCheckedAirplane(transportFilters.checkedAirplane);
		setCheckedTrain(transportFilters.checkedTrain);
		setCheckedBus(transportFilters.checkedBus);
		setDirectServices(transportFilters.directServices);

		var checkTransportOption = (checkedAll || checkedAirplane || checkedTrain || checkedBus) ? true : false;
		setSelectedTransport(checkTransportOption);

		setPickReturnDate(transportFilters.pickReturnDate);
		setPickReturn(transportFilters.pickReturn);
		
		processPickReturn(transportFilters.pickReturn);

		setLeaveDate(transportFilters.leaveDate);
		setReturnDate(transportFilters.returnDate);
		
		setPersonAddAdult(transportFilters.adultPassengers);
		setPersonAddYoung(transportFilters.youngPassengers);
		setPersonAddTotal(transportFilters.totalPassengers);
		setPersonAddElder(transportFilters.elderPassengers);

		setSelectedElderAge(transportFilters.selectedElderAge);
		setSelectedYoungAge(transportFilters.selectedYoungAge);
	  
  } // end if
    
  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, []); // end useEffect // Run after mount component



// Populate or not Countries dropdown when type of transport changes
React.useEffect(() => {

	if (checkedAll || checkedAirplane || checkedTrain || checkedBus) {
		
		// Reset fields on change
		setDepartureLocalCountry(null);
		setDestinationLocalCountry(null);
		setDepartureLocalCity(null);
		setDestinationLocalCity(null);
		
		setDestinationCityResult(false);
		setDepartureCityResult(false);		
		
		setSelectedTransport(true);
		
		// Set loading countries for dropdown
		setCountryLoading(true);
		
		// Remote call to countries dropdown
		// ...
		
		setTimeout(function() {
			
			setCountryLoading(false);
						
			const CountryItems = [
			  {
				"code": "AT",
				"name": "Austria"
			  },
			  {
				"code": "BE",
				"name": "Belgium"
			  },
			  {
				"code": "CZ",
				"name": "Czech Republic"
			  },
			  {
				"code": "DK",
				"name": "Denmark"
			  },
			  {
				"code": "FR",
				"name": "France"
			  },
			  {
				"code": "DE",
				"name": "Germany"
			  },
			  {
				"code": "HU",
				"name": "Hungary"
			  },
			  {
				"code": "IT",
				"name": "Italy"
			  },
			  {
				"code": "NL",
				"name": "Netherlands"
			  },
			  {
				"code": "ES",
				"name": "Spain"
			  },
			  {
				"code": "CH",
				"name": "Switzerland"
			  },
			  {
				"code": "PT",
				"name": "Portugal"
			  },
			  {
				"code": "GB",
				"name": "United Kingdom"
			  }
			];
			
			setDropdownCountryItems(CountryItems);
			
			  // Load stored info
			  if (transportFilters.departureLocalCountry) {
				  
					setDepartureLocalCity(null);
					setDestinationLocalCity(null);
					
					// Transport selection
					setDepartureLocalCountry(transportFilters.departureLocalCountry);
					processCountryCities('departure',transportFilters.departureLocalCountry);
					
					setDestinationLocalCountry(transportFilters.destinationLocalCountry);
					processCountryCities('destination',transportFilters.destinationLocalCountry);
					
			  } // end if			
			
			
		},500);
		
		
		
	} // end if
	else {
		setSelectedTransport(false);
		setDepartureLocalCountry(null);	
		setDepartureLocalCity(null);
		setDestinationLocalCountry(null);
		setDestinationLocalCity(null);	
		
		setDropdownCountryItems([]);
		setDropdownDepartureCityItems([]);	
		setDropdownDestinationCityItems([]);	
		
		
	} // end else
	
	  
}, [checkedAll,checkedAirplane,checkedTrain,checkedBus]); // end useEffect 


// Execute generateTotalLabel if value changes
React.useEffect(() => {

	generateTotalLabel();
	  
}, [personAddAdult,personAddYoung,personAddElder]); // end useEffect 


// Populate City items after loading
React.useEffect(() => {

	  // Load stored info
	  if (transportFilters.departureLocalCountry) {
			
			setDepartureLocalCity(transportFilters.departureLocalCity);
			setDestinationLocalCity(transportFilters.destinationLocalCity);						
			
	  } // end if	
	  
}, [dropdownDepartureCityItems,dropdownDestinationCityItems]); // end useEffect 


const stepsData = [
        {
            label: 'Tipo deslocamento',
            command: (event) => {
					navigate("/order");
            }
        },
        {
            label: 'Dados da Viagem'
        },
        {
            label: 'Escolha de passagem',
            command: (event) => {
                console.log("[Clicou Step 3]--->");
            }
        },
        {
            label: 'Confirmação de Dados',
            command: (event) => {
                console.log("[Clicou Step 5]--->");
            }
        },	
        {
            label: 'Confirmação de Reserva',
            command: (event) => {
                console.log("[Clicou Step 4]--->");
            }
        }
];

var youngAgesRangeArray = [];
for (var i = 0; i <= 25; i++) {
	youngAgesRangeArray.push({
		age:i+' anos',
		code:i
	}); 
}
const youngAges = [...youngAgesRangeArray];
const setYoungAge = (index,value) => {

	// Filter for exiting value. IF exist, replace
	var array = [...selectedYoungAge]; // clone
	var indexArray = array.filter(item => item.index != index);	
	
	var arrayObj = {
		index:index,
		value:value
	}
	var finalArray = [...indexArray,arrayObj];
		
	setSelectedYoungAge(finalArray);
}
var elderAgesRangeArray = [];
for (var i = 58; i <= 99; i++) {
	elderAgesRangeArray.push({
		age:i+' anos',
		code:i
	}); 
}
const elderAges = [...elderAgesRangeArray];
const setElderAge = (index,value) => {

	// Filter for exiting value. IF exist, replace
	var array = [...selectedElderAge]; // clone
	var indexArray = array.filter(item => item.index != index);	
	
	var arrayObj = {
		index:index,
		value:value
	}
	var finalArray = [...indexArray,arrayObj];
		
	setSelectedElderAge(finalArray);
}


/*
const checkAll = (e) => {
	setCheckedAll(e.value);
	setCheckedAirplane(false);
	setCheckedTrain(false);
	setCheckedBus(false);
}*/

const executeTransportSearch = ()  => {
	
	// add data to store and set searching mode onChange
	//
	 
	// Redirect to searchResultPage	
	navigate("/order/transport/searchResult");	
	
}

const searchTransport = ()  => {
			
	// Check required fields
	if ( (typeof departureLocalCountry !== 'object' && Array.isArray(departureLocalCountry) && departureLocalCountry === null) || (typeof departureLocalCity !== 'object' && Array.isArray(departureLocalCity) && departureLocalCity === null) || (typeof destinationLocalCountry !== 'object' && Array.isArray(destinationLocalCountry) && destinationLocalCountry === null) || (typeof destinationLocalCity !== 'object' && Array.isArray(destinationLocalCity) && destinationLocalCity === null) || !leaveDate || (pickReturn && !returnDate) ) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Um ou mais campos não estão preenchidos. É necessário que preencha todos os campos marcados com * para prosseguir com o cadastro.");	
		return;
	} // end if	
	
	let currentTotal = personAddAdult + personAddYoung + personAddElder;
	if (currentTotal <= 0) {
		setAlert(true);
		setAlertTitle("Verifique os passageiros");
		setAlertBody("É necessário ao menos um passageiro para fazer a busca.");	
		return;		
	} // end if		
	
	// Set filters and search mode on STORE (redux)
	var data = {
		checkedAll:checkedAll,
		checkedAirplane:checkedAirplane,
		checkedTrain:checkedTrain,
		checkedBus:checkedBus,
		directServices:directServices,		
		departureLocalCountry:departureLocalCountry,
		departureLocalCity:departureLocalCity,
		destinationLocalCountry:destinationLocalCountry,
		destinationLocalCity:destinationLocalCity,
		leaveDate:leaveDate,
		pickReturn:pickReturn,
		returnDate:returnDate,
		totalPassengers:currentTotal,
		adultPassengers:personAddAdult,
		youngPassengers:personAddYoung,
		elderPassengers:personAddElder,
		selectedYoungAge:selectedYoungAge,
		selectedElderAge:selectedElderAge
	} 
	setTransportFilters(data);	
	
	setLoading(true);
	
	// Redirect after 1 sec
	setTimeout(function() {
		executeTransportSearch();
	},1000);
	
}

// Reset all fields on the form
const resetForm = () => {
	
	setTransportFilters({});	
	
	setCheckedAll(false);
	setCheckedAirplane(false);
	setCheckedTrain(false);
	setCheckedBus(false);
	setDirectServices(false);
	setSelectedTransport(false);

	setDepartureLocalCountry(null);
	setDepartureLocalCity(null);
	setDestinationLocalCountry(null);
	setDestinationLocalCity(null);
	setPickReturnDate(false);
	setPickReturn(false);

	setLeaveDate(null);
	setReturnDate(null);

	setMenuOpened(false);
	setPersonAddLabel("1 Adulto(Adicionar Mais Pessoas);");
	setPersonAddAdult(1);
	setPersonAddYoung(0);
	setPersonAddTotal(1);
	setPersonAddElder(0);

	setSelectedElderAge([]);
	setSelectedYoungAge([]);

	setDepartureCityLoading(false);
	setDestinationCityLoading(false);
	setDepartureCityResult(false);
	setDestinationCityResult(false);	
	
}

 
  return (
	<>
	<div id="loginSection" style={{marginTop:"0"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
		<div style={{minHeight:"950px",height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
			<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
			
				<div style={{flex:1,margin:"2rem",marginTop:"50px",minHeight:"520px",marginLeft:"auto",marginRight:"auto"}}>
				
					<div style={{fontSize:"28px",textAlign:"center",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"400",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249",flex:"1"}}>Compra de transporte</div>
					
					<div style={{display:"flex",flexDirection:isTabletOrMobile ? "column" : "row",alignItems:"center",justifyContent:"center",gap:"15px",marginTop:"20px"}}> 
					
						<div style={{marginLeft:"auto",marginRight:"auto",display:"flex",flexDirection:isTabletOrMobile ? "column" : "column",alignItems:"center",justifyContent:"center",gap:"15px",width:"100%",paddingBottom:"20px"}}  className="selectBox">
					
							<div>
								<Steps model={stepsData} activeIndex={1} readOnly={false} />
							</div>

							<div className={loading ? "imagem-zoom serviceTransferSelector" : "imagem-zoom serviceTransferSelector animate"} style={{borderRadius:"30px",backgroundColor:"#FFFFFF", width:"70vw",maxWidth:"1010px", minHeight:"400px",marginTop:"20px",display:"flex"}}> 
							
								<div className="searchWrapper" style={{flex:1,width:"100%",height:"100%",margin:"75px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
							
										<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"35px"}}> 
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em',color: '#FE346E',marginBottom:"15px"}}>Buscar por</div>
											
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em',display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
												
												{/*<div>
													<ToggleButton onLabel="Todos" offLabel="Todos" onIcon="pi pi-check-square" offIcon="pi pi-stop" checked={checkedAll} onChange={(e) => checkAll(e)} />
												</div>
												
												<div style={{marginLeft:"10px"}}>
													<ToggleButton onLabel="Avião" offLabel="Avião" onIcon="pi pi-check-square" offIcon="pi pi-stop" checked={checkedAirplane} onChange={(e) => { setCheckedAirplane(e.value);setCheckedAll(false);} } />
												</div>*/}
												
												<div>
													<ToggleButton onLabel="Trem" offLabel="Trem" onIcon="pi pi-check-square" offIcon="pi pi-stop" checked={checkedTrain} onChange={(e) => { setCheckedTrain(e.value);setCheckedAll(false)}} />
												</div>	
												
												<div style={{marginLeft:"10px"}}>
													<ToggleButton onLabel="Ônibus" offLabel="Ônibus" onIcon="pi pi-check-square" offIcon="pi pi-stop" checked={checkedBus} onChange={(e) => { setCheckedBus(e.value);setCheckedAll(false)}} />
												</div>	
												
												<div style={{marginLeft:"15px"}}>													
													<div className="flex align-items-center" style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"5px"}}>
														<Checkbox inputId="directServicesOnly" name="directServicesOnly" value="1"  onChange={e => setDirectServices(e.checked)}  checked={directServices} />
														<label htmlFor="directServicesOnly" className="ml-2" style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'14px',lineHeight:'24px',letterSpacing:'0.15px',color:'#2E0249'}}>Apenas serviços diretos</label> <div className="tooltip" data-pr-tooltip="Transportes sem conexão ou escala" data-pr-position="bottom"  data-pr-showdelay="300"><img src={tooltipIcon} style={{width:"20px",height:"auto"}} className="noSelect" /></div> <Tooltip target=".tooltip" />
													</div>													 
												</div>	
												
											</div>											
											
										</div>
										
										<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"35px"}}> 
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em',color: '#FE346E',marginBottom:"25px"}}>Local de Partida <small style={{color:"#333"}}>(selecione o país e depois a cidade)</small></div>										
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
											
												<div style={{flex:1}}>										
													<span className="p-float-label">
														<Dropdown id="departureLocalCountry" disabled={saving || !selectedTransport ? true : false} value={departureLocalCountry} onChange={(e) => processCountryCities('departure',e.value)} options={dropdownCountryItems} optionLabel="name" placeholder="Selecione o país de partida" className="w100" style={{height:"56px",alignItems:"center"}} filter={true} filterLocale="pt-BR"></Dropdown>
														<label htmlFor="departureLocalCountry">
														<ProgressSpinner id="departureLocalCityLoading" style={{width: '15px', height: '15px',display:countryLoading ? "inline-block" : "none"}} strokeWidth="4" /><span style={{float:"right",marginLeft:"10px",display:countryLoading ? "inline-block" : "none"}}> Carregando países... </span>
														{selectedTransport && !countryLoading &&	
														<>
															Selecione o país de partida
															<span className="requiredField">*</span>
														</>
														}

														{!selectedTransport && !countryLoading &&
														<>
															Selecione uma ou mais formas de transporte
														</>
														}
														</label>
													</span>
												</div>
												<div style={{flex:1,marginLeft:"15px"}}>										
													<span className="p-float-label">
														<Dropdown id="departureLocalCity" disabled={saving || !departureCityResult ? true : false} value={departureLocalCity} onChange={(e) => setDepartureLocalCity(e.value)} options={dropdownDepartureCityItems} optionLabel="name" placeholder="Select One" className="w100" style={{height:"56px",alignItems:"center"}} filter={true} filterLocale="pt-BR" emptyFilterMessage="Nenhum resultado" emptyMessage="Nenhum resultado" ></Dropdown>
														<label htmlFor="departureLocalCity">
															<ProgressSpinner id="departureLocalCityLoading" style={{width: '15px', height: '15px',display:departureLocalCityLoading ? "inline-block" : "none"}} strokeWidth="4" /><span style={{float:"right",marginLeft:"10px",display:departureLocalCityLoading ? "inline-block" : "none"}}> Carregando cidades... </span>
															<span id="departureLocalCityPlaceholder" style={{display:departureLocalCityLoading ? "none" : "block"}}>
																{selectedTransport && !departureCityResult &&	
																<>
																	- Selecione o país - 
																</>
																}

																{!selectedTransport &&
																<>
																	Selecione uma ou mais formas de transporte
																</>
																}	
															</span>
															<span id="departureLocalCityLabel" style={{display:departureCityResult ? "block" : "none"}}>Selecione a cidade de partida<span className="requiredField">*</span></span>
														</label>
													</span>													
												</div>												
											
											</div>

										</div>
										
										<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"35px"}}> 
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em',color: '#FE346E',marginBottom:"25px"}}>Local de Destino <small style={{color:"#333"}}>(selecione o país e depois a cidade)</small></div>										
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
											
												<div style={{flex:1}}>										
													<span className="p-float-label">
														<Dropdown id="destinationLocalCountry" disabled={saving || !selectedTransport ? true : false} value={destinationLocalCountry} onChange={(e) => processCountryCities('destination',e.value)} options={dropdownCountryItems} optionLabel="name" placeholder="Selecione o país de partida" className="w100" style={{height:"56px",alignItems:"center"}} filter={true} filterLocale="pt-BR"></Dropdown>
														<label htmlFor="destinationLocalCountry">
														<ProgressSpinner id="departureLocalCityLoading" style={{width: '15px', height: '15px',display:countryLoading ? "inline-block" : "none"}} strokeWidth="4" /><span style={{float:"right",marginLeft:"10px",display:countryLoading ? "inline-block" : "none"}}> Carregando países... </span>
														{selectedTransport && !countryLoading &&	
														<>
															Selecione o país de destino
															<span className="requiredField">*</span>
														</>
														}

														{!selectedTransport && !countryLoading &&
														<>
															Selecione uma ou mais formas de transporte
														</>
														}
														</label>
													</span>
												</div>
												<div style={{flex:1,marginLeft:"15px"}}>										
													<span className="p-float-label">
														<Dropdown id="destinationLocalCity" disabled={saving || !destinationCityResult ? true : false} value={destinationLocalCity} onChange={(e) => setDestinationLocalCity(e.value)} options={dropdownDestinationCityItems} optionLabel="name" placeholder="Select One" className="w100" style={{height:"56px",alignItems:"center"}} filter={true} filterLocale="pt-BR" emptyFilterMessage="Nenhum resultado" emptyMessage="Nenhum resultado" ></Dropdown>
														<label htmlFor="destinationLocalCity">
															<ProgressSpinner id="destinationLocalCityLoading" style={{width: '15px', height: '15px',display:destinationLocalCityLoading ? "inline-block" : "none"}} strokeWidth="4" /><span style={{float:"right",marginLeft:"10px",display:destinationLocalCityLoading ? "inline-block" : "none"}}> Carregando cidades... </span>
															<span id="destinationLocalCityPlaceholder" style={{display:destinationLocalCityLoading ? "none" : !destinationCityResult ? "block" : "none"}}>
															{selectedTransport && !destinationCityResult &&	
																<>
																	- Selecione o país - 
																</>
																}

																{!selectedTransport &&
																<>
																	Selecione uma ou mais formas de transporte
																</>
																}
															</span>
															<span id="destinationLocalCityLabel" style={{display:destinationCityResult ? "block" : "none"}}>Selecione a cidade de destino<span className="requiredField">*</span></span>
														</label>
													</span>													
												</div>		
											
											</div>

										</div>
										
										<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"50px"}}> 
										
											<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '14px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
											
												<div style={{flex:1}}>										
													<span className="p-float-label"  >
														<Calendar showButtonBar locale="br"  minDate={minDate} id="leaveDate" value={leaveDate} onChange={(e) => {setLeaveDate(e.value);setReturnDate(null)}} style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
														<label htmlFor="leaveDate">Data de Ida <span className="requiredField">*</span></label>
													</span>													
												</div>
												
												<div style={{flex:1,marginLeft:"15px"}}>

												{pickReturnDate &&
												<>
													<span className="p-float-label"  >
														<Calendar showButtonBar locale="br" minDate={leaveDate} id="returnDate" value={returnDate} onChange={(e) => setReturnDate(e.value)} style={{height:"56px",width:"100%"}} inputStyle={{width:"100%"}} showIcon dateFormat="dd/mm/yy"  />
														<label htmlFor="returnDate">Data de Volta <span className="requiredField">*</span></label>
													</span>	
												</>
												}

													<div className="flex align-items-center" style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"5px",position:pickReturnDate? "absolute" : "relative",left:pickReturnDate? "45%" : "unset",marginTop:pickReturnDate? "7px" : "unset"}}>
														<Checkbox inputId="pickReturn" name="pickReturn" value="1"  onChange={e => processPickReturn(e.checked)}  checked={pickReturn} />
														<label htmlFor="pickReturn" className="ml-2" style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'14px',lineHeight:'24px',letterSpacing:'0.15px',color:'#2E0249'}}>Adicionar Volta</label> <div className="tooltip" data-pr-tooltip="Você pode adicionar uma data de retorno, se desejar (no mesmo tipo de transporte)" data-pr-position="bottom" data-pr-showdelay="300" ><img src={tooltipIcon} style={{width:"20px",height:"auto"}} className="noSelect" /></div> <Tooltip target=".tooltip" />
													</div>													
	
												</div>	

												<div style={{flex:1,marginLeft:"15px"}}>										
															
													<div>
														<Button iconPos="right" icon={!menuOpened ? "pi pi-angle-down" : "pi pi-angle-up"} size="large" rounded text aria-label="Menu" onClick={!menuOpened ? (e) => {op.current.toggle(e);e.currentTarget.blur()} : (e) => {op.current.toggle(e);e.currentTarget.blur()} } label={personAddLabel} style={{fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:600,fontSize:'14px',lineHeight:'120%',textAlign:'center',letterSpacing:'0.1em',color:'#FE346E'}} />
														<OverlayPanel className="overlayPanel" onHide={onHide} onShow={onShow} ref={op} style={{padding:"0 !important",backgroundColor:"#FFFFFF",minHeight:"30vh",width:"300px",maxHeight:"50vh",overflow:"auto"}}>
														
															<div style={{width:"100%",marginBottom:"2px",borderBottom:"1px solid silver",fontWeight:"600",display:"flex",alignItems:"center",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",padding:"15px"}} >
															
																<div style={{flex:1,fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'18px',lineHeight:'24px',letterSpacing:'0.15px',color:'#2E0249'}}>
																	<div style={{fontWeight:"bold"}}>Adulto</div>
																	<small style={{color:"#999"}}>26 a 57 anos</small>
																</div>
																
																<div style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}> 
																	<Button style={{width:"32px",height:"32px"}} icon="pi pi-minus" rounded outlined aria-label="Mais" onClick={()=>setPerson('-','adult')} />
																	<span style={{flex:0.5,textAlign:"center",fontSize:"18px"}}> {personAddAdult} </span>
																	<Button style={{width:"32px",height:"32px"}} icon="pi pi-plus" rounded outlined aria-label="Mais" onClick={()=>setPerson('+','adult')} />
																</div>
															
															</div>					

															<div style={{width:"100%",marginBottom:"2px",borderBottom:"1px solid silver",fontWeight:"600",display:"flex",alignItems:"center",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",padding:"15px"}} >
															
																<div style={{flex:1,fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'18px',lineHeight:'24px',letterSpacing:'0.15px',color:'#2E0249'}}>
																	<div style={{fontWeight:"bold"}}>Jovem</div>
																	<small style={{color:"#999"}}>0-25 anos</small>
																</div>
																
																<div style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}> 
																	<Button style={{width:"32px",height:"32px"}} icon="pi pi-minus" rounded outlined aria-label="Mais" onClick={()=>setPerson('-','young')} />
																	<span style={{flex:0.5,textAlign:"center",fontSize:"18px"}}> {personAddYoung} </span>
																	<Button style={{width:"32px",height:"32px"}} icon="pi pi-plus" rounded outlined aria-label="Mais" onClick={()=>setPerson('+','young')} />
																</div>
															
															</div>	
															
															{[...Array(personAddYoung)].map((x, i) =>  {
															
																var arrayFilter = [...selectedYoungAge];
															
																var dropdownValue = arrayFilter.filter(item => item.index === i).length > 0 ? arrayFilter.filter(item => item.index === i)[0].value : '';

																return (<div key={i} id={"youngAge_"+i} style={{width:"100%",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",padding:"15px",paddingTop:i == 0 ? "15px" : "unset"}}> 
																
																	<div style={{flex:1,fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'18px',lineHeight:'24px',letterSpacing:'0.15px',color:'#2E0249'}}>
																		<div>Jovem {i+1}</div>
																	</div>
																	
																	<div style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																		<Dropdown id={"selectYoungAgeDropdown_"+i} value={dropdownValue} onChange={(e) => setYoungAge(i,e.value)} options={youngAges} optionLabel="age" optionValue="code" placeholder="Idade" className="w-full md:w-14rem" />
																	</div>															
																
																</div>
																)
															}	
															)}															
															
															<div style={{width:"100%",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",padding:"15px"}} >
															
																<div style={{flex:1,fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'18px',lineHeight:'24px',letterSpacing:'0.15px',color:'#2E0249'}}>
																	<div style={{fontWeight:"bold"}}>Sênior</div>
																	<small style={{color:"#999"}}>58+ anos</small>
																</div>
																
																<div style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}> 
																	<Button style={{width:"32px",height:"32px"}} icon="pi pi-minus" rounded outlined aria-label="Mais" onClick={()=>setPerson('-','elder')} />
																	<span style={{flex:0.5,textAlign:"center",fontSize:"18px"}}> {personAddElder} </span>
																	<Button style={{width:"32px",height:"32px"}} icon="pi pi-plus" rounded outlined aria-label="Mais" onClick={()=>setPerson('+','elder')} />
																</div>
															
															</div>	

															{[...Array(personAddElder)].map((x, i) =>  {
															
																var arrayFilter = [...selectedElderAge];
															
																var dropdownValue = arrayFilter.filter(item => item.index === i).length > 0 ? arrayFilter.filter(item => item.index === i)[0].value : '';

																return (<div key={i} id={"elderAge_"+i} style={{width:"100%",marginBottom:"2px",fontWeight:"600",display:"flex",alignItems:"center",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",padding:"15px",paddingTop:"5px",borderTop:i == 0 ? "1px solid silver" : "unset",paddingTop:i == 0 ? "15px" : "unset"}}> 
																
																	<div style={{flex:1,fontFamily:'Titillium Web',fontStyle:'normal',fontWeight:400,fontSize:'18px',lineHeight:'24px',letterSpacing:'0.15px',color:'#2E0249'}}>
																		<div>Sênior {i+1}</div>
																	</div>
																	
																	<div style={{flex:1,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																		<Dropdown id={"selectElderAgeDropdown_"+i} value={dropdownValue} onChange={(e) => setElderAge(i,e.value)} options={elderAges} optionLabel="age" optionValue="code" placeholder="Idade" className="w-full md:w-14rem" />
																	</div>															
																
																</div>
																)
															}	
															)}
															

														</OverlayPanel>													
													</div>

															
												</div>													
											
											</div>

										</div>
										
										<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",gap:"20px"}}> 
										
											<div style={{textAlign:"left",flex:1}}>
												<Button label="Limpar formulário" icon="pi pi-trash" severity="danger" text onClick={()=>resetForm()} />
											</div>												
										
											<Button rounded outlined className="pT10" label="Voltar" onClick={()=>navigate("/order")} />
										
											<Button rounded className="pT10" label="Buscar transportes" loading={saving} disabled={saving} onClick={()=>searchTransport()} />
											
											
										</div>
							
								</div>
							
							</div>


						</div>						

						
					</div>				
				
				</div>
			
			</div> 
		</div>
    </div>
	
	<div id="contatoSection" className="contatoSection__wrapper">

		<div className={isTabletOrMobile ? "contatoSection__wrapper_content_wrapper mobile" : "contatoSection__wrapper_content_wrapper"}  >

			<div className="flex-1 dFlex aItemsC jContentE"> 
			
				<div className="textBlockMargin contatoSection__wrapper_content_text" >
				
					<div className="contatoSection__wrapper_content_header">Central de Relacionamento</div>
				
					Estamos a disposição para atender às suas necessidades e responder às suas perguntas. Conheça as principais formas de entrar em contato conosco:
					
					<div style={{marginTop: "2.8125rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", gap: "15px"}}>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactWhatsappIcon} style={{width:"49px",height:"auto"}} className="noSelect" /></div>
							<div className="contatoOptionText"><Whatsapp phone="552140209749" style={{color:"#FFF",textDecoration:"none"}}>Whatsapp 4020-9749</Whatsapp></div>
						</div>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactPhoneIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Callto phone="+552140209749" style={{color:"#FFF",textDecoration:"none"}}>Telefone 4020-9749</Callto></div>
						</div>
						
						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactEmailIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Button label="Enviar mensagem" rounded className="pT10 mR10 headerButton tColorWhite w100" style={{marginBottom:"0.625rem"}} onClick={()=>setDialog('messageModal',true)} /></div>
						</div>
						

					</div>					
				
				</div> 
				
			</div>
			
			<div className="tAlignL flex-1_5"> 
				<img src={contactConcept} className="contatoSection__wrapper_content_image noSelect" />
			</div>			
			
		</div>
			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
			<MessageView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
    </div>
	
	<div id="memovoSection" className="memovoSection__wrapper">
	
		<div className={isTabletOrMobile ? "memovoSection__wrapper_content_wrapper mobile" : "memovoSection__wrapper_content_wrapper"}>
		
			<div className="flex-1"> 
				<img src={desktopConcept} className="memovoSection__wrapper_content_image noSelect" />
			</div>
			<div className="flex-1 dFlex aItemsC jContentS"> 
			
				<div className="textBlockMargin memovoSection__wrapper_content_text" >
				
					<div className="memovoSection__wrapper_content_header">Inovando o Mercado B2B de Mobilidade</div>
									
					A MEMOVO é uma plataforma B2B inovadora que facilita a busca e comercialização de serviços de transporte e mobilidade. Pioneira como marketplace B2B, oferece passagens aéreas, trens, ônibus, aluguel de veículos e transfers em um só lugar, permitindo a criação de roteiros completos de mobilidade. Com interface amigável e processo simples, a plataforma proporciona agilidade e eficiência aos profissionais do setor, atendendo às necessidades dos usuários e impulsionando o crescimento do seu negócio.
				
				</div> 
				
			</div>
			
		</div>
		
    </div>	
	
	</>
  );
    
}

const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  transportFilters:store.orderReducer.transportFilters
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions,...OrderActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(HomePage);	


