import '../../Assets/Styles/home.css';
import '../../Assets/Styles/order.css';
import '../../Assets/Styles/orderStep2.css';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import React from "react";
import { useMediaQuery } from 'react-responsive'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Link,useNavigate} from 'react-router-dom';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog'; 
import { Steps } from 'primereact/steps';
import { ToggleButton } from 'primereact/togglebutton';
import { Tooltip } from 'primereact/tooltip';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Divider } from 'primereact/divider';
import { Skeleton } from 'primereact/skeleton';
import { Panel } from 'primereact/panel';
import { TabView, TabPanel } from 'primereact/tabview';
import { ProgressBar } from 'primereact/progressbar';
import { InputSwitch } from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import { RadioButton } from 'primereact/radiobutton';
import { Accordion, AccordionTab } from 'primereact/accordion';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import StarsIcon from '@mui/icons-material/Stars';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Cards from 'react-credit-cards-2';

import multiTransportConcept from '../../Assets/Images/multiTransportConcept11.png'; 
import desktopConcept from '../../Assets/Images/desktopConcept.png'; 
import contactConcept from '../../Assets/Images/contactConcept.png'; 
import formPlaceholder from '../../Assets/Images/formPlaceholder.png'; 
import formTopIcons from '../../Assets/Images/formTopIcons.svg'; 
import cornerTop from '../../Assets/Images/cornerTop.svg';
import cornerBottom from '../../Assets/Images/cornerBottom.svg';
import iconMoreOptions from '../../Assets/Images/iconMoreOptions.svg';

import contactWhatsappIcon from '../../Assets/Images/contactWhatsappIcon.svg';
import contactEmailIcon from '../../Assets/Images/contactEmailIcon.svg';
import contactPhoneIcon from '../../Assets/Images/contactPhoneIcon.svg';
import transportPicture from '../../Assets/Images/transportPicture.svg';
import rentcarPicture from '../../Assets/Images/rentcarPicture.svg';
import transferPicture from '../../Assets/Images/transferPicture.svg';
import transportIcon from '../../Assets/Images/transportIcons.svg';
import transferIcon from '../../Assets/Images/transferIcon.svg';
import rentcarIcon from '../../Assets/Images/rentcarIcon.svg';
import tooltipIcon from '../../Assets/Images/tooltipIcon.svg';
import iconAlert from '../../Assets/Images/iconAlert.svg';
import roteiroIcon from '../../Assets/Images/roteiroIcon.svg';
import reservaIcon from '../../Assets/Images/reservaIcon.svg';
import paymentIcon from '../../Assets/Images/paymentIcon.svg';

import titleTrainIcon from '../../Assets/Images/titleTrainIcon.svg';
import titleBusIcon from '../../Assets/Images/titleBusIcon.svg';
import obbProviderLogo from '../../Assets/Images/obb-provider.svg';
import flixbusProviderLogo from '../../Assets/Images/flixbus-provider.png';
import qrCodeImage from '../../Assets/Images/qrCodeImage.svg';

// Load Actions
import * as AppActions from '../../Store/Actions/App'
import * as OrderActions from '../../Store/Actions/Order'
import * as RegisterActions from '../../Store/Actions/Register';

// Load Componentes
import MessageView from '../../Components/MessageModal';
import AlertView from '../../Components/AlertModal';
import ExpireView from '../../Components/ExpireModal';
import {
	validarCNPJ,
	validarCPF,
	validarEmail,
	validarTelefone,
	validarNome,
	maskCNPJ,
	maskCPF,
	maskPhone,
	maskCreditcard
} from '../../Components/Utils';

import {
	Callto,
	Whatsapp
} from '../../Components/Utils';

/*
import {
	stepsData
} from '../../Components/StaticData';*/

// export default function TransferSearchResult() {
const TransferSearchResult = (props) => {
	
// Set var from props
const {
	setDialog,
	dialog,
	retrieveAppAddressGooglePlaces,
	retrieveAppAddressesGoogleMatrixDistance,
	transportFilters,
	retrievePersonDataByDocument
} = props;


const [loading, setLoading] = React.useState(true); 
const [saving, setSaving] = React.useState(false); 
const [loadingContent, setLoadingContent] = React.useState(true); 
const [loadingContentTrain, setLoadingContentTrain] = React.useState(true); 
const [loadingContentBus, setLoadingContentBus] = React.useState(true); 

const [processing, setProcessing] = React.useState(false); 

 const [counter, setCounter] = React.useState(1200);
 const [counterInitial, setCounterInitial] = React.useState(1200);
 
 // const [counter, setCounter] = React.useState(20); // for test. Force popup. 
 const [counterLabel, setCounterLabel] = React.useState("00:20:00");
 const [counterValue, setCounterValue] = React.useState(100);

const [loadingRightPanel, setLoadingRightPanel] = React.useState(true); 

// Right Panel Status
const [rightPanel, setRightPanel] = React.useState(false); 
const [rightPanelBlock, setRightPanelBlock] = React.useState(false); 
	
const isDesktopOrLaptop = useMediaQuery({
query: '(min-width: 1024px)'
})
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' })

const [email, setEmail] = React.useState('');
const [password, setPassword] = React.useState('');
const [stillConnected, setStillConnected] = React.useState(false);
const onStillConnectedChange = (e) => {
	if (e.checked)
		setStillConnected(true);
	else
		setStillConnected(false);
}  // end function
	
// Alert control
const [alert, setAlert] = React.useState(false); 
const [alertTitle, setAlertTitle] = React.useState("Informações"); 
const [alertBody, setAlertBody] = React.useState("Conteúdo dinâmico"); 

// Expire Dialog control
const [alertExpire, setAlertExpire] = React.useState(false); 
const [alertTitleExpire, setAlertTitleExpire] = React.useState("Informações"); 
const [alertBodyExpire, setAlertBodyExpire] = React.useState("Conteúdo dinâmico"); 
const [alertActionExpire, setAlertActionExpire] = React.useState("restartTransportSearch"); 

const [forgotPassword, setForgotPassword] = React.useState(false); 		

// Transport selection
const [checkedAll, setCheckedAll] = React.useState(false);
const [checkedAirplane, setCheckedAirplane] = React.useState(false);
const [checkedTrain, setCheckedTrain] = React.useState(false);
const [checkedBus, setCheckedBus] = React.useState(false);
const [directServices, setDirectServices] = React.useState(false);

const [departureCityLoading, setDepartureCityLoading] = React.useState(false);
const [destinationCityLoading, setDestinationCityLoading] = React.useState(false);
const [departureCityResult, setDepartureCityResult] = React.useState(false);
const [destinationCityResult, setDestinationCityResult] = React.useState(false);

const [departureLocalCountry, setDepartureLocalCountry] = React.useState("TESTE");
const [departureLocalCity, setDepartureLocalCity] = React.useState(null);
const [destinationLocalCountry, setDestinationLocalCountry] = React.useState(null);
const [destinationLocalCity, setDestinationLocalCity] = React.useState(null);

const [departureDate, setDepartureDate] = React.useState(null);
const [departureTime, setDepartureTime] = React.useState(null);

const [destinationLocal, setDestinationLocal] = React.useState(null);

const [destinationDate, setDestinationDate] = React.useState(null);
const [destinationTime, setDestinationTime] = React.useState(null);	

const [pickReturnDate, setPickReturnDate] = React.useState(false);
const [pickReturn, setPickReturn] = React.useState(false);

const [leaveDate, setLeaveDate] = React.useState(null);
const [returnDate, setReturnDate] = React.useState(null);

const [value, setValue] = React.useState('');
const [items, setItems] = React.useState([]);

/* Person select START */
const [menuOpened,setMenuOpened] = React.useState(false);
const [personAddLabel,setPersonAddLabel] = React.useState("1 Adulto (Adicionar Mais Pessoas)"); // default
const [personAddLabelSimple,setPersonAddLabelSimple] = React.useState("1"); // default
const [personAddAdult,setPersonAddAdult] = React.useState(1); // default adult
const [personAddYoung,setPersonAddYoung] = React.useState(0); // default young
const [personAddTotal,setPersonAddTotal] = React.useState(1); 
const [personAddYoungAges,setPersonAddYoungAges] = React.useState([]); 
const [personAddElder,setPersonAddElder] = React.useState(0); // default elder
const [personAddElderAges,setPersonAddElderAges] = React.useState([]); 


const [luggageAddLabel,setLuggageAddLabel] = React.useState("1 Bagagem (Adicionar Mais)"); // default
const [luggageAdd,setLuggage] = React.useState(1); // default adult
const [menuLugOpened,setMenuLugOpened] = React.useState(false);
const [menuPriceOpened,setMenuPriceOpened] = React.useState(false);

const [selectedElderAge,setSelectedElderAge] = React.useState([]);
const [selectedYoungAge,setSelectedYoungAge] = React.useState([]); 
const [selectedElderAgeLabel,setSelectedElderAgeLabel] = React.useState("");
const [selectedYoungAgeLabel,setSelectedYoungAgeLabel] = React.useState(""); 

const [menuOpenedOrder,setMenuOrderOpened] = React.useState(false);

const [orderByLabel,setOrderByLabel] = React.useState("Ordenar por"); // default
const [orderByFieldLabel,setOrderByFieldLabel] = React.useState(""); // default

const [lastObjectId,setLastObjectId] = React.useState({}); // default
const [selectedTransportPrice,setSelectedTransportPrice] = React.useState("Carregando..."); // default

const [transportType,setTransportType] = React.useState(null); // default

// temp
const [checked, setChecked] = React.useState([]);
const [seat, setSeat] = React.useState('');


const setCheckbox = (index,value) => {
	
	var temp = [...checked]
	
	temp[index] = value;
	setChecked(temp);
	
}


/* Passengers*/
const [loadingPersonData, setPersonLoadingData] = React.useState(false);
const [personName, setPersonName] = React.useState('Felipe Moreno');
const [birthday, setBirthday] = React.useState('12/10/1077');
const [personDocument, setPersonDocument] = React.useState('078.160.607-18');
const [personPhone, setPersonPhone] = React.useState('(21) 98341-2800');
const [personEmail, setPersonEmail] = React.useState('fmoreno@realweb.com.br');

// Credit card and pix info
const [activeIndex, setActiveIndex] = React.useState(0);
const [paymentOption, setPaymentOption] = React.useState("cc");
const [creditcardName, setCreditcardName] = React.useState("");
const [creditcardNumber, setCreditcardNumber] = React.useState("");
const [cardFocus, setCardFocus] = React.useState("number");
const [creditcardExpireDate, setCreditcardExpireDate] = React.useState("");
const [creditcardSecurityCode, setCreditcardSecurityCode] = React.useState("");
const [pixQrcode, setPixQrcode] = React.useState(null);
const [pixUrl, setPixUrl] = React.useState(null);
const [pixStatus, setPixStatus] = React.useState('waiting');
const [creditcardStatus, setCredicardStatus] = React.useState('waiting');

const [pixDisableTabStatus, setPixDisableTabStatus] = React.useState(false);
const [ccDisableTabStatus, setCcDisableTabStatus] = React.useState(false);
const [creditcardTransactionStatus, setCreditcardTransactionStatus] = React.useState('');


//console.log("[paymentOption]--->"+JSON.stringify(paymentOption,null,2));


const [acceptTerm, setAcceptTerm] = React.useState(false);
const [autoFields, setAutoFields] = React.useState(true); // if true, fields are disabled
const [autoFieldsFill, setAutoFieldsFill] = React.useState(true); // if true, fields are auto filled. No manual.
const [autoFieldsFilled, setAutoFieldsFilled] = React.useState(false); // if true, fields was returned
const [autoPersonFieldsFilled, setAutoPersonFieldsFilled] = React.useState(false); // if true, fields was returned
const [documentReady, setDocumentReady] = React.useState(false); // if true, document is ok and ready
const [personDocumentReady, setPersonDocumentReady] = React.useState(false); // if true, document is ok and ready


let today = new Date();
let month = today.getMonth();
let year = today.getFullYear();
let minDate = new Date();

addLocale('br', {
	firstDayOfWeek: 1,
	dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
	dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
	dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
	monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
	monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
	today: 'Hoje',
	clear: 'Limpar'
});

const op = React.useRef(null);
const op2 = React.useRef(null);
const opLug = React.useRef(null);
const opPrice = React.useRef(null);

const onHide = () => {
setMenuOpened(false);
}  
const onShow = () => {
setMenuOpened(true);
}    

const onHideOrder = () => {
setMenuOrderOpened(false);
}  
const onShowOrder = () => {
setMenuOrderOpened(true);
}    

const onLugHide = () => {
setMenuLugOpened(false);
}  
const onLugShow = () => {
setMenuLugOpened(true);
}    


const onHidePrice = () => {
setMenuPriceOpened(false);
}  
const onShowPrice = () => {
setMenuPriceOpened(true);
}    
 

 
const setPerson = (operation,type) => {
	
	// Set total passengers
	let currentTotal = personAddAdult + personAddYoung + personAddElder;
	
	if (type == 'adult') {
		
		let newValue = operation == '+' ? personAddAdult + 1 : personAddAdult - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 50 || operation == '-') {
			setPersonAddAdult(newValue);
		}
		
	} // end if
	else if (type == 'young') {
		
		let newValue = operation == '+' ? personAddYoung + 1 : personAddYoung - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 50 || operation == '-') {
			setPersonAddYoung(newValue);
		}
		
		// reset ages
		if (operation == '-') { setSelectedYoungAge([]); }
		
	} // end if
	else if (type == 'elder') {
		
		let newValue = operation == '+' ? personAddElder + 1 : personAddElder - 1;
		newValue = newValue < 0 ? 0 : newValue;
		if (currentTotal < 50 || operation == '-') {
			setPersonAddElder(newValue);	
		}
		
		// reset ages
		if (operation == '-') { setSelectedElderAge([]); }		
		
	} // end if

	
} // end function
/* Person select END */


const generateTotalLabel = () => {

	// Set total passengers
	let currentTotal = personAddAdult + personAddYoung + personAddElder;

	if (personAddAdult == 1 && personAddYoung == 0 && personAddElder == 0) {
		var label = "1 Viajante ";	
	} // end if
	else if (personAddAdult > 1 && personAddYoung == 0 && personAddElder == 0) {
		var label = ""+personAddAdult+" Viajantes ";	
	} // end else if
	else if (currentTotal == 0) {
		var label = "Nenhum Viajante";
	} // end else if	
	else if (currentTotal == 1) {
		var label = ""+currentTotal+" Viajante ";
	} // end else if	
	else {
		var label = ""+currentTotal+" Viajantes";	
	} // end else
	
	// Set Person Control Label
	setPersonAddLabel(label);
	setPersonAddLabelSimple(currentTotal);
	
}


const processPickReturn = (value) => {
	
	if (value) {
		setPickReturnDate(true);
	} // end if
	else {
		setPickReturnDate(false);
	} // end else

	setPickReturn(value);		
	
}

// set Use of history for PUSH 
var navigate = useNavigate();
	
/* Execute recaptcha to retrieve token*/
const recaptchaRef = window.grecaptcha;
const executeReCaptcha = async () => {
	const token = await recaptchaRef.execute();
	return token;
}


// Run after mount component
React.useEffect(() => {

	// Retrieve User Data Obj. 
	var result = localStorage.getItem("@memovo/userData", undefined,true);
	var userData = JSON.parse(result);  

	  // If token dont exist, redirect to onboarding or register
	  if (!userData) {
		  navigate("/home");
		  return;
	  } // end if
	  	  
	// If there is no filter, go back to filtering page
	if (!transportFilters.departureLocalCountry) {  
		  navigate("/order/transport");
		  return;
	} // end if	  
	  
	 setLoading(false);

  // set scroll to top
  window.scrollTo(0, 1);
  
// Load stored info
  if (transportFilters.departureLocalCountry) {
	  
		// Transport selection
		setCheckedAll(transportFilters.checkedAll);
		setCheckedAirplane(transportFilters.checkedAirplane);
		setCheckedTrain(transportFilters.checkedTrain);
		setCheckedBus(transportFilters.checkedBus);
		setDirectServices(transportFilters.directServices);
		
		setPersonAddAdult(transportFilters.adultPassengers);
		setPersonAddYoung(transportFilters.youngPassengers);
		setPersonAddTotal(transportFilters.totalPassengers);
		setPersonAddElder(transportFilters.elderPassengers);

		setSelectedElderAge(transportFilters.selectedElderAge);
		setSelectedYoungAge(transportFilters.selectedYoungAge);
		
		var arrayFilter = [...transportFilters.selectedElderAge];
		let elderAgeLabelTmp = convertAgeArray(arrayFilter);
 		setSelectedElderAgeLabel(elderAgeLabelTmp);
		
		var arrayFilter = [...transportFilters.selectedYoungAge];
		let youngAgeLabelTmp = convertAgeArray(arrayFilter);
		setSelectedYoungAgeLabel(youngAgeLabelTmp);		
	  
  } // end if  

  
setTimeout(function() {
	
	var objPrice = "129,00 €";
	var objTransportType = "train";
	setTransportType(objTransportType);
	setSelectedTransportPrice(objPrice);
	
	setLoadingRightPanel(false);
	
},1500);  
    
  // Runs when UNMOUNT component
  return () => {
    
	// unmount...	
	
  };
  
}, []); // end useEffect 

// Extra values to a plain array
const convertAgeArray = (value) => {
	
	let finalString = "";
	for (var i = 0; i < value.length; i++) {
		
		finalString += value[i].value+" anos,";
		
	} // end for	
	
	var finalStringConcat = finalString.substr(0,finalString.length-1);

	return finalStringConcat;
	
}


// Execute generateTotalLabel if value changes
React.useEffect(() => {

	generateTotalLabel();
		  
}, [personAddAdult,personAddYoung,personAddElder]); // end useEffect 

const stepsData = [
        {
            label: 'Tipo deslocamento',
            command: (event) => {
					navigate("/order");
            }
        },
        {
            label: 'Dados da Viagem',
			 command: (event) => { 
                navigate("/order/transport");
            }
        },
        {
            label: 'Escolha de passagem',
            command: (event) => {
                navigate("/order/transport/searchResult");
            }
        },
        {
            label: 'Confirmação de Dados',
            command: (event) => {
                console.log("[Clicou Step 5]--->");
            }
        },	
        {
            label: 'Confirmação de Reserva',
            command: (event) => {
                console.log("[Clicou Step 4]--->");
            }
        }
];

var youngAgesRangeArray = [];
for (var i = 0; i <= 25; i++) {
	youngAgesRangeArray.push({
		age:i+" anos",
		code:i
	}); 
}
const youngAges = [...youngAgesRangeArray];
const setYoungAge = (index,value) => {

	// Filter for exiting value. IF exist, replace
	var array = [...selectedYoungAge]; // clone
	var indexArray = array.filter(item => item.index != index);	
	
	var arrayObj = {
		index:index,
		value:value
	}
	var finalArray = [...indexArray,arrayObj];
		
	setSelectedYoungAge(finalArray);
}
var elderAgesRangeArray = [];
for (var i = 58; i <= 99; i++) {
	elderAgesRangeArray.push({
		age:i+" anos",
		code:i
	}); 
}
const elderAges = [...elderAgesRangeArray];
const setElderAge = (index,value) => {

	// Filter for exiting value. IF exist, replace
	var array = [...selectedElderAge]; // clone
	var indexArray = array.filter(item => item.index != index);	
	
	var arrayObj = {
		index:index,
		value:value
	}
	var finalArray = [...indexArray,arrayObj];
		
	setSelectedElderAge(finalArray);
}

/*
const checkAll = (e) => {
	setCheckedAll(e.value);
	setCheckedAirplane(false);
	setCheckedTrain(false);
	setCheckedBus(false);
}*/

const executeTransferSearch = ()  => {
	
	// add data to store and set searching mode onChange
	//
	
	// Redirect to searchResultPage	
	navigate("/order/transport/searchResult");	
	
}


const searchTransport = ()  => {
	
	executeTransferSearch();
		
	// Check required fields
	/*if ( (typeof departureLocal !== 'object' && Array.isArray(value) && departureLocal === null) || (typeof destinationLocal !== 'object' && Array.isArray(destinationLocal) && destinationLocal === null) || !departureTime || !departureDate) {
		setAlert(true);
		setAlertTitle("Verifique as informações");
		setAlertBody("Um ou mais campos não estão preenchidos. É necessário que preencha todos os campos marcados com * para prosseguir com o cadastro.");	
		return;
	} // end if	
	
	let currentTotal = personAddAdult + personAddYoung + personAddElder;
	if (currentTotal <= 0) {
		setAlert(true);
		setAlertTitle("Verifique os passageiros");
		setAlertBody("É necessário ao menos um passageiro para fazer a busca.");	
		return;		
	} // end if		
	*/
	
	
}

// If there is no filter, go back to filtering page
if (!transportFilters.departureLocalCountry) {  
	  return(null);
} // end if	 


const togglePanel = (e) => {
	
	// Clean last selectors
	if (lastObjectId.id) {
		
		var element = document.getElementById(lastObjectId.id);
		element.style.border = "0px";	
		
		let objString = lastObjectId.id;
		var tmp = objString.split("_");
		let lastElement = tmp[tmp.length - 1];
		
		var element = document.getElementById("resultBlock_"+lastElement+"");
		// element.style.border = "0px";
		element.style.filter = "unset";
		element.style.boxShadow = "unset";
	} 
	
	// set lastObject Id
	setLastObjectId(e.currentTarget);
			
	// Set current object bordes
	e.currentTarget.style.border = "1px solid purple";
	
	// Retrieve Price to update overall
	// ...
	
	// Get Data Attribute from selected button
	var buttonElement = e.currentTarget;
	var objToken = buttonElement.dataset.token;
	var objTariff = buttonElement.dataset.tariff;
	var objPrice = buttonElement.dataset.price;
	var objClass = buttonElement.dataset.class;
	var objTransportType = buttonElement.dataset.transporttype;
	
	// Set TransportType
	setTransportType(objTransportType);

	// Set Prices
	setSelectedTransportPrice(objPrice);
	
	// Set result block id
	let objString = e.currentTarget.id;
	var tmp = objString.split("_");
	let lastElement = tmp[tmp.length - 1];
  	var element = document.getElementById("resultBlock_"+lastElement+"");
	//element.style.border = "1px solid purple";
	//element.style.filter = "drop-shadow(1px 1px 4px #9C9C9C)";	
	//element.style.boxShadow = "rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;";
	element.style.boxShadow = "0px 2px 5px 0px rgba(19, 41, 104, 0.2)";

	
	// Set loading on
	setLoadingRightPanel(true);
	
	if (rightPanel) {
				
		/*setRightPanelBlock(false);
		
		setTimeout(function() {
			setRightPanel(false);
		},500);		*/
		
		setTimeout(function() {
			setLoadingRightPanel(false);
		},2000);			
		
	} // end if
	else {
		setRightPanel(true);
		
		setTimeout(function() {
			setRightPanelBlock(true);
			
			setTimeout(function() {
				setLoadingRightPanel(false);
			},2500);			
			
		},500);
		
	} // end else
		
}

/* Check if document is valid,. If yes, release button. If not, alert.*/
const handlePersonDocumentChange = (e) => {

	var currentValue = e.target.value;
	
	setPersonDocument(maskCPF(e.target.value));
	
	if (currentValue.length >= 14) {
		
		// check if cnpj is valid
		if (!validarCPF(currentValue)) {
			setAlert(true);
			setAlertTitle("Verifique o CPF");
			setAlertBody("O CPF informado é inválido. Verifique novamente.");	
			return;	
		} // end if	
		else {
			setPersonDocumentReady(true);
		} // end else
		
	} // end if
	else {
		setPersonDocumentReady(false);
		setAutoPersonFieldsFilled(false);
		setPersonName("");		
	} // end else
	
}  // end function


/* Check if document is valid,. If yes, release button. If not, alert.*/
const handlePersonDocumentForeignerChange = (e) => {

	var currentValue = e.target.value;
	
	setPersonDocument(e.target.value);
	
}  // end function

const retrievePersonDocumentInfo = () => {
	
	setPersonLoadingData(true);
	
	var props = {
		setSaving:setSaving,
		setAlert:setAlert,
		setAlertTitle:setAlertTitle,
		setAlertBody:setAlertBody,
		setAutoPersonFieldsFilled:setAutoPersonFieldsFilled,
		setPersonLoadingData:setPersonLoadingData,
		setPersonName:setPersonName,
		setBirthday:setBirthday
	}
	var data = {
	  personDocument: personDocument
	}
	retrievePersonDataByDocument(data,props);
	

} // end retrieveDocumentInfo

// Process payment
const processPayment = (option) => {
	
	if (option == "cc") {
		
		// Reset pix status
		setPixStatus("waiting"); 
		
		// Frezee payment tabs
		setPixDisableTabStatus(true); 
		
		setProcessing(true);
		
		setTimeout(function() {
			
				setProcessing(false);
				setCredicardStatus("generated");	

				var random  = Math.floor(1 + Math.random()*(2 - 1 + 1))
				if (random === 1) {
					setCreditcardTransactionStatus('success');
				} // end if
				else {
					setCreditcardTransactionStatus('fail');
				} // end else				
			
		},2000);
		
	} // end if
	else {

		// Reset pix status
		setCredicardStatus("waiting"); 
		
		// Frezee payment tabs
		setCcDisableTabStatus(true); 
		
		setProcessing(true);
		
		setTimeout(function() {
			
				setProcessing(false);
				setPixStatus("generated");				
			
		},2000);
		
	} // end else
		
} // end processPayment	
	

// Cancel payment
const cancelPayment = (option) => {
	
	if (option == "cc") {
		
		// Reset pix status
		setCredicardStatus("waiting"); 
		
		// Frezee payment tabs
		setPixDisableTabStatus(false); 
		
		setProcessing(false);
	
	
	} // end if
	else {

		// Reset pix status
		setPixStatus("waiting");			
		
		// Frezee payment tabs
		setCcDisableTabStatus(false); 
		
		setProcessing(false);
		
	} // end else


} // end cancelPayment

  return (
	<>
	<div id="loginSection" style={{marginTop:"0"}} className={isTabletOrMobile ? "login__wrapper mobile" : "login__wrapper"} >
		<div style={{minHeight:"728px",height:"auto"}} className={isTabletOrMobile ? "login__wrapper_form_wrapper mobile" : "login__wrapper_form_wrapper"}> 		
			<div className={isTabletOrMobile ? "login__form_wrapper mobile" : "login__form_wrapper"} style={{alignItems:"flex-start"}}>
						
				<div style={{flex:1,margin:"2rem",marginTop:"50px",minHeight:"520px",marginLeft:"auto",marginRight:"auto"}}>
				
					<div style={{fontSize:"28px",textAlign:"center",letterSpacing:"0.1em",lineHeight:"120%",fontWeight:"400",fontStyle:"normal",fontFamily:"Titillium Web",color:"#2E0249",flex:"1"}}>Compra de transporte</div>
					
					<div style={{display:"flex",flexDirection:isTabletOrMobile ? "column" : "row",alignItems:"center",justifyContent:"center",gap:"15px",marginTop:"20px"}}> 
					
						<div style={{marginLeft:"auto",marginRight:"auto",display:"flex",flexDirection:isTabletOrMobile ? "column" : "column",alignItems:"center",justifyContent:"center",gap:"15px",width:"100%",paddingBottom:"20px"}}  className="selectBox">
					
							<div>
								<Steps model={stepsData} activeIndex={4} readOnly={false} />
							</div>
							
							<Divider type="dotted" style={{maxWidth:"1170px"}} />
							   
								<div className={loading ? "imagem-zoom serviceTransferSelector" : "imagem-zoom serviceTransferSelector animate"} style={{backgroundColor:"transparent", width:"100%",maxWidth:"1170px", minHeight:"400px",display:"flex"}}> 
					
								
									<div className="searchWrapper" style={{flex:1,width:"100%",height:"100%",margin:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
																			
										<Grid container spacing={6} style={{marginTop:"0px"}}>
										 
											<Grid className="leftPanel animate" item xs={12} sm={8} style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center"}}>
											 	
												 <Grid item xs={12} sm={12} style={{display:checkedTrain ? "flex" : "none",flexDirection:"row",justifyContent:"center",alignItems:"center",width:"100%",maxHeight:"70px",marginBottom:"25px"}}>
												
													<div style={{flex:1,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start"}}>
													
														<div style={{marginBottom:"10px",color: "#2E0249", fontFamily: "Titillium Web", fontSize: "28px", fontStyle: "normal", fontWeight: 600, lineHeight: "120%", letterSpacing: "2.8px"}}>Efetuar Pagamento. Roteiro <strong> Europa 10/2023</strong></div>
														<div style={{color: "rgba(34, 34, 34, 0.60)", textAlign: "left", fontFamily: "Titillium Web", fontSize: "18px", fontStyle: "normal", fontWeight: 400, lineHeight: "120%", letterSpacing: "1.8px"}}>Efetue o pagamento de uma ou mais reservas contidas no roteiro.</div>
													
													</div>
													
													
													
												</Grid>	
																					

										
										{/* Card 1 START*/}
										<div style={{width:"100%",display:"flex",flexdirection:"row",alignItems:"stretch",justifyContent:"center"}}>

												<div style={{width:"80px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:"auto"}}>
											
													<InputSwitch checked={checked[0]} onChange={(e) => setCheckbox(0,e.value)} />

												</div>

												{/* Current Trip Card */}											
												<div style={{flex:1,borderRadius:"10px",width:"100%",margin:"0px",backgroundColor:"#fff",marginBottom:"15px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"10px",cursor:"initial",gap:"5px"}}>
	
												<div style={{textAlign:"left",padding:"0px"}}>
												
													<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
															<span style={{textAlign:"left",color:"#2E0249",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}>Trem <img src={titleTrainIcon} style={{marginLeft:"5px",width:"18px",height:"auto"}} className="noSelect" /></span>													
													
															<div style={{textAlign:"right",flex:1.2}}>
																<Message className="cardLabelStatusPayment" severity="warn" style={{textAlign:"left",marginBottom:"0px"}} text="Aguardando pagamento" />	
															</div>		
													</div>
													
													<Divider type="dotted" style={{margin:0,marginTop:"0.5rem",marginBottom:"0.5rem"}} />
													
												</div>												
												
												<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:"1",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
																 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249"}}>	
																					<span style={{fontWeight:"bold"}}>Portugal</span>, Lisboa
																				</div>
																			
																			</div>

																		</div>
																 </div>
																  
																<div  style={{textAlign:"left",alignSelf:"center"}}>
																	<i className="pi pi-arrow-right" style={{ color: 'slateblue',fontSize:"18px",width:"100%",height:"auto"}}></i>
																</div>
																
																<div>
															
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"5px"}}> 

																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			<div style={{flex:1,color:"#2E0249"}}>	
																					<span style={{fontWeight:"bold"}}>England</span>, London
																			</div>
																		</div>

																	</div>												
																			
																 </div>
																 
															 </div>
																										 
													
													</div>			

													<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:2,width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
															 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249",fontWeight:"normal"}}>	
																					29 de setembro de 2023 às 09:00	
																				</div>
																			
																			</div>

																		</div>
																 </div>
																 																 
															 </div>
													
													</div>													
													
													<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:2,width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
															 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249",fontWeight:"normal"}}>	
																					Responsável: Newton Santos CPF: 123.456.789-10
																				</div>
																			
																			</div>

																		</div>
																 </div>
																 																 
															 </div>
													
													</div>																				

													<div style={{textAlign:"left"}}>
														
																																															<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",marginBottom:"5px"}}> 
															
															<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
															
																<div style={{flex:1,color: '#FE346E', fontFamily: 'Titillium Web', fontSize: '15px', fontStyle: 'normal', fontWeight: 700, lineHeight: '120%', letterSpacing: '1.8px'}}>	
																	Valor a pagar: 129,90 &euro;	
																</div>
															
															</div>
															
																			
															<div style={{textAlign:"right",flex:1.2}}>
															
																<Button icon="pi pi-search" size="small" label="Abrir reserva" rounded text raised aria-label="Search" />
															
															</div>																

														</div>
														
													</div>												
												
												</div>
												
												
											</div>
											{/* Card 1 END */}
											
											
											
										{/* Card 3 START*/}
										<div style={{width:"100%",display:"flex",flexdirection:"row",alignItems:"stretch",justifyContent:"center"}}>

												<div style={{width:"80px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:"auto"}}>
											
													<InputSwitch checked={checked[3]} onChange={(e) => setCheckbox(3,e.value)} />

												</div>

												{/* Current Trip Card */}											
												<div style={{flex:1,borderRadius:"10px",width:"100%",margin:"0px",backgroundColor:"#fff",marginBottom:"15px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"10px",cursor:"initial",gap:"5px"}}>
	
												<div style={{textAlign:"left",padding:"0px"}}>
												
													<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
															<span style={{textAlign:"left",color:"#2E0249",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}>Trem <img src={titleTrainIcon} style={{marginLeft:"5px",width:"18px",height:"auto"}} className="noSelect" /></span>													
													
															<div style={{textAlign:"right",flex:1.2}}>
																<Message className="cardLabelStatusPayment" severity="warn" style={{textAlign:"left",marginBottom:"0px"}} text="Aguardando pagamento" />	
															</div>		
													</div>
													
													<Divider type="dotted" style={{margin:0,marginTop:"0.5rem",marginBottom:"0.5rem"}} />
													
												</div>												
												
												<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:"1",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
																 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249"}}>	
																					<span style={{fontWeight:"bold"}}>Portugal</span>, Lisboa
																				</div>
																			
																			</div>

																		</div>
																 </div>
																  
																<div  style={{textAlign:"left",alignSelf:"center"}}>
																	<i className="pi pi-arrow-right" style={{ color: 'slateblue',fontSize:"18px",width:"100%",height:"auto"}}></i>
																</div>
																
																<div>
															
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"5px"}}> 

																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			<div style={{flex:1,color:"#2E0249"}}>	
																					<span style={{fontWeight:"bold"}}>England</span>, London
																			</div>
																		</div>

																	</div>												
																			
																 </div>
																 
															 </div>
																										 
													
													</div>			

													<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:2,width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
															 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249",fontWeight:"normal"}}>	
																					29 de setembro de 2023 às 09:00	
																				</div>
																			
																			</div>

																		</div>
																 </div>
																 																 
															 </div>
													
													</div>													
													
													<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:2,width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
															 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249",fontWeight:"normal"}}>	
																					Responsável: Laysa Silva CPF: 321.456.789-10
																				</div>
																			
																			</div>

																		</div>
																 </div>
																 																 
															 </div>
													
													</div>																				

													<div style={{textAlign:"left"}}>
														
																																															<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",marginBottom:"5px"}}> 
															
															<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
															
																<div style={{flex:1,color: '#FE346E', fontFamily: 'Titillium Web', fontSize: '15px', fontStyle: 'normal', fontWeight: 700, lineHeight: '120%', letterSpacing: '1.8px'}}>	
																	Valor a pagar: 129,90 &euro;	
																</div>
															
															</div>
															
																			
															<div style={{textAlign:"right",flex:1.2}}>
															
																<Button icon="pi pi-search" size="small" label="Abrir reserva" rounded text raised aria-label="Search" />
															
															</div>																

														</div>
														
													</div>												
												
												</div>
												
												
											</div>
											{/* Card 3 END */}											
											
											

											{/* Card 2 START*/}
											<div style={{width:"100%",display:"flex",flexdirection:"row",alignItems:"stretch",justifyContent:"center"}}>


											
												<div style={{width:"80px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:"auto"}}>
											
											{/*<InputSwitch className="paid" disabled={true} checked={true} onChange={(e) => setCheckbox(1,e.value)} />*/}
													<Button icon="pi pi-check" rounded raised aria-label="Close Panel" onClick={() =>console.log("test")} style={{border:"unset",backgroundColor:"#e4f8f0",color:"#1ea97c",fontSize:"18px"}} />

												</div>

												{/* Current Trip Card */}											
												<div style={{flex:1,borderRadius:"10px",width:"100%",margin:"0px",backgroundColor:"#fff",marginBottom:"15px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",padding:"10px",cursor:"initial",gap:"5px"}}>
											
												<div style={{textAlign:"left",padding:"0px"}}>
												
													<div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
															<span style={{textAlign:"left",color:"#2E0249",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em'}}>Trem <img src={titleTrainIcon} style={{marginLeft:"5px",width:"18px",height:"auto"}} className="noSelect" /></span>													
													
															<div style={{textAlign:"right",flex:1.2}}>
																<Message className="cardLabelStatusPayment" severity="success" style={{textAlign:"left",marginBottom:"0px"}} text="Pagamento realizado" />
															</div>		
													</div>
													
													<Divider type="dotted" style={{margin:0,marginTop:"0.5rem",marginBottom:"0.5rem"}} />
													
												</div>		
												
												<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:"1",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
																 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249"}}>	
																					<span style={{fontWeight:"bold"}}>England </span>, London											
																				</div>
																			
																			</div>

																		</div>
																 </div>
																  
																<div  style={{textAlign:"left",alignSelf:"center"}}>
																	<i className="pi pi-arrow-right" style={{ color: 'slateblue',fontSize:"18px",width:"100%",height:"auto"}}></i>
																</div>
																
																<div>
															
																	<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"5px"}}> 

																		<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			<div style={{flex:1,color:"#2E0249"}}>	
																					<span style={{fontWeight:"bold"}}>Italy</span>, Rome			
																			</div>
																		</div>

																	</div>												
																			
																 </div>
																 
															 </div>
																										 
													
													</div>			

													<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:2,width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
															 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249",fontWeight:"normal"}}>	
																					30 de setembro de 2023 às 09:00	
																				</div>
																			
																			</div>

																		</div>
																 </div>
																 																 
															 </div>
																			
													
													</div>	

													<div style={{flex:1,display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start"}}>
													
														<div style={{flex:2,width:"100%",display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center", gap:"15px",padding:"5px"}}>
																
															 <div>
																		<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"stretch",justifyContent:"flex-start",marginBottom:"5px"}}> 
																			
																			<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																			
																				<div style={{flex:1,color:"#2E0249",fontWeight:"normal"}}>	
																					Responsável: Laysa Silva CPF: 321.567.789-10
																				</div>
																			
																			</div>

																		</div>
																 </div>
																 																 
															 </div>
													
													</div>													
													

													<div style={{textAlign:"left"}}>
														
																																															<div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center",marginBottom:"5px"}}> 
															
															<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '15px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
															
																<div style={{flex:1,color: '#FE346E', fontFamily: 'Titillium Web', fontSize: '15px', fontStyle: 'normal', fontWeight: 700, lineHeight: '120%', letterSpacing: '1.8px'}}>	
																	Valor pago: 329,90 &euro;	
																</div>
															
															</div>

															<div style={{textAlign:"right",flex:1.2}}>
															
																<Button icon="pi pi-search" size="small" label="Abrir reserva" rounded text raised aria-label="Search" />
															
															</div>	

														</div>
														
													</div>												
												
												</div>
												{/* Card 2 END */}
											</div>


																
											 </Grid>
											 
											 
											 <Grid item xs={12} sm={4} className="rightPanel animate">
											 
												 <div style={{position:"sticky",zIndex:"9999",top:"120px"}}>
												 
												{loadingRightPanel &&
												<>
													<div style={{backgroundColor:"transparent", width:"100%",maxWidth:"1170px", marginTop:"30px",marginBottom:"50px",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"row"}}>
													
															<ProgressSpinner style={{width: '40px', height: '40px',marginRight: "10px",marginLeft: "10px",marginTop:"10px"}} strokeWidth="4" />
															<div style={{fontSize:"16px",marginTop:"10px"}}> Carregando... </div>
																											
													</div>
													
													<Skeleton width="100%" height="8rem" ></Skeleton>
													<br />
													<Skeleton width="100%" height="8rem" ></Skeleton>
													<br />
													<Skeleton width="100%" height="8rem" ></Skeleton>
													
												</>
												}												 
												 
												 
												{!loadingRightPanel &&
												<>		
												
{/* Reservation Responsible Block */}
													<div style={{marginTop:"0px",display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center",backgroundColor:"#FFF"}}>
														
														<div style={{padding:"20px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px'}}>Responsável pelo pagamento</div>
																									
														
														<div style={{padding:"00px",paddingTop:"0",marginTop:"10px"}}>
															
															<div style={{padding:"20px",paddingTop:"0px"}}>
															

																<Grid container spacing={4} style={{marginTop:"0px"}}>
															
																<Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
																	<div className="p-float-label w100">
																		<InputText id="personDocument" autoComplete="new-password" disabled={true}  value={personDocument} onBlur={(e) => handlePersonDocumentChange(e)} onChange={(e) => handlePersonDocumentChange(e)} className="w100" tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus'}}  style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																		<label htmlFor="personDocument">CPF <span className="requiredField">*</span></label>
																	</div>
																  </Grid>
																  
																  <Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personName" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={personName} onChange={(e) => setPersonName(e.target.value)} style={{width:"100%"}} />
																		<label htmlFor="personName">Nome Completo<span className="requiredField">*</span></label>
																	</span>		
																  </Grid>				
		
																  
																  <Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personBirthday" autoComplete="new-password" disabled={saving || autoFields ? true : false} value={birthday} onChange={(e) => setBirthday(e.target.value)} style={{width:"100%"}} />
																		<label htmlFor="peronName">Data de Nascimento <span className="requiredField">*</span></label>
																	</span>		
																  </Grid>
													  

																  <Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personEmail" autoComplete="new-password" disabled={true} value={personEmail} onChange={(e) => setPersonEmail(e.target.value)} style={{width:"100%"}} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
																		<label htmlFor="personEmail">Email <span className="requiredField">*</span></label>								
																	</span>	
																  </Grid>
																  
																  <Grid item xs={12} sm={12}>
																	<span className="p-float-label" >
																		<InputText id="personPhone" autoComplete="new-password" value={personPhone} disabled={true}  onChange={(e) => setPersonPhone(maskPhone(e.target.value))} style={{width:"100%"}} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
																		<label htmlFor="personPhone">Telefone Celular <span className="requiredField">*</span></label>								
																	</span>	
																  </Grid>
																  
																</Grid>
																
																<div style={{marginTop:"20px",marginBottom:"10px",marginLeft:"10px",textAlign:"left",fontStyle:"italic"}}><i className="pi pi-exclamation-triangle" style={{ color: "red",fontSize:"20px" }}></i> O responsável pela reserva deve ser o responsável pela forma de pagamento. Caso queira alterar essa condição, entre em contato com a central de atendimento via telefone ou mensagem.</div>
																												
																
															</div>														
																											
														
														</div>
														
														
													</div>			


													{/* Reservation Responsible Block */}
													<div style={{marginTop:"15px",display:"flex",flexDirection:"column",alignItems:"flex-start",justifyContent:"center",backgroundColor:"#FFF"}}>
														
														<div style={{padding:"20px",paddingBottom:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'20px',fontStyle:'normal',fontWeight:600,lineHeight:'150%',letterSpacing:'2px'}}>Formas de Pagamento</div>
														
														<div style={{padding:"20px",paddingBottom:"0",paddingTop:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'150%',letterSpacing:'2px',textAlign:"left"}}>Escolha a forma de pagamento desejada e preencha os dados.</div>
																					
														<div style={{padding:"10px",paddingTop:"0",marginTop:"20px",width:"100%"}}>
														
																	<TabView style={{width:"100%",padding:"0px"}} activeIndex={activeIndex} onTabChange={(e) => {setActiveIndex(e.index);var paymentValue = e.index === 0 ? "cc" : "pix";setPaymentOption(paymentValue)}}>
																	
																		<TabPanel disabled={ccDisableTabStatus} header="Cartão de Crédito" style={{margin:"0px",padding:"0px"}} onClick={()=>setPaymentOption("cc")}>
																			
																			<div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",padding:0}}>
																				
																				<div style={{width:"100%",padding:"20px"}}>
																					
																				{processing &&
																				<>
																					<div style={{backgroundColor:"transparent", width:"100%",maxWidth:"1170px", marginTop:"30px",marginBottom:"50px",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"row"}}>
																					
																							<ProgressSpinner style={{width: '40px', height: '40px',marginRight: "10px",marginLeft: "10px",marginTop:"10px"}} strokeWidth="4" />
																							<div style={{fontSize:"16px",marginTop:"10px"}}> Processando pagamento... </div>
																																			
																					</div>
																					
																					<Skeleton width="100%" height="8rem" ></Skeleton>
																					<br />
																					<Skeleton width="100%" height="8rem" ></Skeleton>									
																				</>
																				}	
																				
																				{!processing && creditcardStatus == "waiting" &&
																				<>
																					<Grid container spacing={4} style={{marginTop:"0px"}}>
																				
																						<Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
																							<div className="p-float-label w100">
																								<InputText id="personDocument" autoComplete="new-password" value={creditcardName} onChange={(e) => setCreditcardName(e.target.value)} className="w100" tooltip="Digite com consta no cartão" tooltipOptions={{ position: 'bottom',event: 'focus'}}  style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																								<label htmlFor="personDocument">Nome Completo<span className="requiredField">*</span></label>
																							</div>
																						  </Grid>
															   							  
																						  <Grid item xs={12} sm={12}>
																							<div className="p-float-label w100">
																								<InputText id="personName" autoComplete="new-password" disabled={saving} maxLength={19} value={creditcardNumber} onChange={(e) => setCreditcardNumber(maskCreditcard(e.target.value))} tooltip="Digite apenas números" tooltipOptions={{ position: 'bottom',event: 'focus'}}  style={{borderTopRightRadius:0,borderBottomRightRadius:0,width:"100%"}} />
																								<label htmlFor="personName">Número do Cartão<span className="requiredField">*</span></label>
																							</div>		
																						  </Grid>				
								
																						  
																						  <Grid item xs={12} sm={12}>
																							<div className="p-float-label w100">
																								<InputText id="personBirthday" autoComplete="new-password" disabled={saving} maxLength={5} value={creditcardExpireDate} onChange={(e) => setCreditcardExpireDate(e.target.value)}  tooltip="Digite apenas números, mês/ano" tooltipOptions={{ position: 'bottom',event: 'focus'}}  style={{borderTopRightRadius:0,borderBottomRightRadius:0,width:"100%"}} />
																								<label htmlFor="peronName">Data de Validade (MM/AA)<span className="requiredField">*</span></label>
																							</div>		
																						  </Grid>
																			  

																						  <Grid item xs={12} sm={12}>
																							<div className="p-float-label w100">
																								<InputText id="personEmail" autoComplete="new-password" value={creditcardSecurityCode} maxLength={3} onFocus={()=>setCardFocus("cvc")} onBlur={()=>setCardFocus("number")} onChange={(e) => setCreditcardSecurityCode(e.target.value)} style={{width:"100%"}} tooltip="Digite dois ou três números" tooltipOptions={{ position: 'bottom',event: 'focus' }} />
																								<label htmlFor="personEmail">Código de Segurança<span className="requiredField">*</span></label>					
																							</div>	
																						  </Grid>
																						  
																						  <Grid item xs={12} sm={12}>
																							  <Cards
																								number={creditcardNumber}
																								expiry={creditcardExpireDate}
																								cvc={creditcardSecurityCode}
																								name={creditcardName}
																								focused={cardFocus}
																							  />
																						  </Grid>
																					  
																					</Grid>
																				</>
																				}
																				
																				{!processing && creditcardStatus == "generated" &&
																				<>
																					<div style={{marginBottom:"10px",padding:"0px",paddingBottom:"0",paddingTop:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'150%',letterSpacing:'2px',textAlign:"left"}}>Confira abaixo resultado da transação.</div>
																					
																					<Grid container spacing={4} style={{marginTop:"0px"}}>
																						<Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center"}}>

																							<div className="statusButton" style={{width:"90%"}}>
																							
																							{creditcardTransactionStatus == 'success' &&
																							<>
																								<Message severity="success" text="Pagamento confirmado" style={{width:"100%"}} /> 
																							</>
																							}
																							
																							{creditcardTransactionStatus == 'fail' &&
																							<>
																								<Message severity="error" text="Pagamento Falhou" style={{width:"100%"}} /> <br />
																								<small> Transação não autorizada </small>
																							</>
																							}		
																								<br />
																							</div>

																						 </Grid>
																						 
																						 <Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center",gap:"20px"}}>

																							<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"0px"}}> 
																						
																								<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#FE346E',marginBottom:"10px"}}>Código da transação</div>
																								
																								<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																									<div style={{flex:1}}>	
																									122282-DFG12-458583-A10
																									</div>
																								
																								</div>
																							</div>
																						 </Grid>

																						 <Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center",gap:"20px"}}>

																							<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"0px"}}> 
																						
																								<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#FE346E',marginBottom:"10px"}}>TEF</div>
																								
																								<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																									<div style={{flex:1}}>	
																									99887-ADF-4585983-A109
																									</div>
																								
																								</div>
																							</div>
																						 </Grid>
																						 
																						<Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center",gap:"20px"}}>

																							<div style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",marginBottom:"0px"}}> 
																						
																								<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em',color: '#FE346E',marginBottom:"10px"}}>Código de Captura</div>
																								
																								<div style={{textAlign:"left",fontFamily: 'Titillium Web',fontStyle: 'normal',fontWeight: 400,fontSize: '16px',lineHeight: '120%',letterSpacing: '0.1em', display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
																									<div style={{flex:1}}>	
																									99887-ADF-4585983-A109
																									</div>
																								
																								</div>
																							</div>
																						 </Grid>																						 
																						 
																					</Grid>
																				</>
																				}							
																					
																				</div>
																				
																			</div>
																			
																		</TabPanel>
																		<TabPanel disabled={pixDisableTabStatus} header="PIX" onClick={()=>setPaymentOption("pix")}>
																			<div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
																				
																				<div style={{width:"100%",minHeight:"530px",padding:"20px"}}>
																				
																				{pixStatus == 'waiting' &&
																				<>
																					<div style={{marginTop:"20px",marginBottom:"10px",marginLeft:"10px",textAlign:"left",fontStyle:"italic"}}><i className="pi pi-exclamation-triangle" style={{ color: "orange",fontSize:"20px" }}></i> Para visualizar os dados de pagamento com PIX, selecione "Prosseguir para pagamento" mais abaixo.</div>
																				</>
																				}
																				
																				{processing &&
																				<>
																					<div style={{backgroundColor:"transparent", width:"100%",maxWidth:"1170px", marginTop:"30px",marginBottom:"50px",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"row"}}>
																					
																							<ProgressSpinner style={{width: '40px', height: '40px',marginRight: "10px",marginLeft: "10px",marginTop:"10px"}} strokeWidth="4" />
																							<div style={{fontSize:"16px",marginTop:"10px"}}> Gerando dados... </div>
																																			
																					</div>
																					
																					<Skeleton width="100%" height="8rem" ></Skeleton>
																					<br />
																					<Skeleton width="100%" height="8rem" ></Skeleton>									
																				</>
																				}	
																				
																				{!processing &&
																				<>
																					{pixStatus == 'generated' &&
																					<>
																					<div style={{marginBottom:"10px",padding:"0px",paddingBottom:"0",paddingTop:"0",color:'#222',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'150%',letterSpacing:'2px',textAlign:"left"}}>Utilize o QRCODE para efetuar o pagamento do Pix ou utilize o Pix Copia e Cola.</div>
																					
																					<Grid container spacing={4} style={{marginTop:"0px"}}>
																						<Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",alignItems:"center"}}>
																								<div className="p-float-label w100">
																									<InputText autoComplete="new-password" value="00020126570014br.gov.bcb.pix0111811177100250220testede envio de pix52040000530398654041.235802BR5914testechave cpf6008saopaulo62070503***6304E067" onChange={(e) => setCreditcardName(e.target.value)} className="w100" style={{borderTopRightRadius:0,borderBottomRightRadius:0}} />
																									<label>Pix Copia e Cola</label>
																								</div>
																								<Button outlined label="Copiar" style={{borderLeft:"unset",borderTopLeftRadius:0,borderBottomLeftRadius:0,height:"47px"}} size="small" onClick={() => {navigator.clipboard.writeText("00020126570014br.gov.bcb.pix0111811177100250220testede envio de pix52040000530398654041.235802BR5914testechave cpf6008saopaulo62070503***6304E067")}} />
																						 </Grid>
																						 <Grid item xs={12} sm={12} style={{display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center",gap:"20px"}}>
																							
																							<div><img src={qrCodeImage} style={{width:"220px",height:"220px"}} className="noSelect" /></div>
																							<div className="statusButton">
																								<Message icon="pi pi-spin pi-spinner" severity="warn" text="Pagamento pendente" /> <br />
																								{/*<Message severity="success" text="Pagamento confirmado" /> <br />*/}		
																								<small>Pix pode ser pago até 25/08/2023 às 19:30</small>
																							</div>

																						 </Grid>				
																					</Grid>
																					</>
																					}								
																				</>
																				}																																				
																				</div>
																				
																			</div>
																		</TabPanel>
																	</TabView>						
														
														</div>
														
														
													</div>														
												
													{/* Continue Button Replication */}
													<div style={{marginTop:"20px",height:"110px",minHeight:"110px",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"stretch"}}>
														
														<div style={{backgroundColor:"#2E0249",flex:"1.5",borderRadius:"6px 0px 0px 6px",display:"flex",flexDirection:"column",alignItems:"stretch",justifyContent:"center",paddingLeft:"21px"}}>
														
															<div style={{textAlign:"left",marginTop:"5px",marginBottom:"5px",color:'rgba(255, 255, 255, 0.80)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>
															
																{transportFilters.pickReturn &&
																	<>Ida e Volta</>
																}

																{!transportFilters.pickReturn &&
																	<>Ida</>
																}															
															
															</div>
															<div style={{textAlign:"left",marginBottom:"5px",color:'#FFF',fontFamily:'Titillium Web',fontSize:'24px',fontStyle:'normal',fontWeight:700,lineHeight:'120%',letterSpacing:'2.4px'}}>{selectedTransportPrice}
															</div>
															<div style={{textAlign:"left",marginBottom:"5px",color:'rgba(255, 255, 255, 0.80)',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>{personAddLabel}</div>
															
														</div>
														
														<div style={{backgroundColor:"#FE346E",flex:"1",borderRadius:"0px 6px 6px 0px",display:"flex",justifyContent:"center",alignItems:"center"}}>
														
														{pixStatus == 'generated' &&
														<>
															<Button  className="buttonLeftPanel"  label="Cancelar" style={{flexDirection:"column",fontFamily:"Titillium Web",fontSize:"24px",fontStyle:"bold",fontWeight:800,lineHeight:"120%",letterSpacing:"2.4px",height:"100%",justifyContent:"center"}} onClick={()=>cancelPayment("pix")}><div style={{display:"block",color:'#FFF',textAlign:'center',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>pagamento com PIX</div></Button>														
														</>
														}
														
														{pixStatus != 'generated' && creditcardStatus == 'waiting' &&
														<>
															<Button  className="buttonLeftPanel"  label={paymentOption == "cc" ? "Finalizar" : "Prosseguir"} style={{flexDirection:"column",fontFamily:"Titillium Web",fontSize:"24px",fontStyle:"bold",fontWeight:800,lineHeight:"120%",letterSpacing:"2.4px",height:"100%",justifyContent:"center"}} onClick={()=>processPayment(paymentOption == "cc" ? "cc" : "pix")}><div style={{display:"block",color:'#FFF',textAlign:'center',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}>
															
															{paymentOption == "cc" &&
																<>
																 o pagamento
																</>
															}
															
															{paymentOption == "pix" &&
																<>
																 para o pagamento
																</>															
															}
															
															</div></Button>														
														</>
														}

														{creditcardStatus != 'waiting' &&
														<>
														
														{creditcardTransactionStatus == 'fail' &&
														<>
															<Button  className="buttonLeftPanel"  label="Tentar" style={{flexDirection:"column",fontFamily:"Titillium Web",fontSize:"24px",fontStyle:"bold",fontWeight:800,lineHeight:"120%",letterSpacing:"2.4px",height:"100%",justifyContent:"center"}} onClick={()=>cancelPayment("cc")}><div style={{display:"block",color:'#FFF',textAlign:'center',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}> pagar novamente</div></Button>
														</>
														}	

														{creditcardTransactionStatus == 'success' &&
														<>
															<Button  className="buttonLeftPanel"  label="Ir para" style={{flexDirection:"column",fontFamily:"Titillium Web",fontSize:"24px",fontStyle:"bold",fontWeight:800,lineHeight:"120%",letterSpacing:"2.4px",height:"100%",justifyContent:"center"}} onClick={()=>cancelPayment("cc")}><div style={{display:"block",color:'#FFF',textAlign:'center',fontFamily:'Titillium Web',fontSize:'14px',fontStyle:'normal',fontWeight:400,lineHeight:'120%',letterSpacing:'1.4px'}}> a reserva</div></Button>
														</>
														}	
														
														</>
														}														

														</div>														
														
													</div>													
												
													
												</>
												}
													
												</div>
												
												
											 </Grid>
											 
											  
										</Grid>	
								
									</div>
								
								</div>


						</div>						

					</div>				
						
				
				</div>
			
			</div> 
		</div>
    </div>
	
	<div id="contatoSection" className="contatoSection__wrapper">

		<div className={isTabletOrMobile ? "contatoSection__wrapper_content_wrapper mobile" : "contatoSection__wrapper_content_wrapper"}  >

			<div className="flex-1 dFlex aItemsC jContentE"> 
			
				<div className="textBlockMargin contatoSection__wrapper_content_text" >
				
					<div className="contatoSection__wrapper_content_header">Central de Relacionamento</div>
				
					Estamos a disposição para atender às suas necessidades e responder às suas perguntas. Conheça as principais formas de entrar em contato conosco:
					
					<div style={{marginTop: "2.8125rem", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "stretch", gap: "15px"}}>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactWhatsappIcon} style={{width:"49px",height:"auto"}} className="noSelect" /></div>
							<div className="contatoOptionText"><Whatsapp phone="552140209749" style={{color:"#FFF",textDecoration:"none"}}>Whatsapp 4020-9749</Whatsapp></div>
						</div>

						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactPhoneIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Callto phone="+552140209749" style={{color:"#FFF",textDecoration:"none"}}>Telefone 4020-9749</Callto></div>
						</div>
						
						<div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center",gap:"15px"}}>
							<div><img src={contactEmailIcon} style={{width:"49px",height:"auto"}} className="noSelect"  /></div>
							<div className="contatoOptionText"><Button label="Enviar mensagem" rounded className="pT10 mR10 headerButton tColorWhite w100" style={{marginBottom:"0.625rem"}} onClick={()=>setDialog('messageModal',true)} /></div>
						</div>
						

					</div>					
				
				</div> 
				
			</div>
			
			<div className="tAlignL flex-1_5"> 
				<img src={contactConcept} className="contatoSection__wrapper_content_image noSelect" />
			</div>			
			
		</div>
			<AlertView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
			<ExpireView
				alertExpire={alertExpire}
				setAlertExpire={setAlertExpire}
				alertTitleExpire={alertTitleExpire}
				alertBodyExpire={alertBodyExpire}	
				alertActionExpire={alertActionExpire}	
				scrollBlock={false}				
			/>	
			<MessageView
				alert={alert}
				setAlert={setAlert}
				alertTitle={alertTitle}
				alertBody={alertBody}	
				scrollBlock={false}				
			/>	
    </div>
	
	<div id="memovoSection" className="memovoSection__wrapper">
	
		<div className={isTabletOrMobile ? "memovoSection__wrapper_content_wrapper mobile" : "memovoSection__wrapper_content_wrapper"}>
		
			<div className="flex-1"> 
				<img src={desktopConcept} className="memovoSection__wrapper_content_image noSelect" />
			</div>
			<div className="flex-1 dFlex aItemsC jContentS"> 
			
				<div className="textBlockMargin memovoSection__wrapper_content_text" >
				
					<div className="memovoSection__wrapper_content_header">Inovando o Mercado B2B de Mobilidade</div>
									
					A MEMOVO é uma plataforma B2B inovadora que facilita a busca e comercialização de serviços de transporte e mobilidade. Pioneira como marketplace B2B, oferece passagens aéreas, trens, ônibus, aluguel de veículos e transfers em um só lugar, permitindo a criação de roteiros completos de mobilidade. Com interface amigável e processo simples, a plataforma proporciona agilidade e eficiência aos profissionais do setor, atendendo às necessidades dos usuários e impulsionando o crescimento do seu negócio.
				
				</div> 
				
			</div>
			
		</div>
		
    </div>	
	
	</>
  );
    
}


const mapStateToProps = store => ({
  dialog:store.appReducer.dialog,
  transportFilters:store.orderReducer.transportFilters
 });
const mapDispatchToProps = dispatch => bindActionCreators({ ...AppActions,...OrderActions,...RegisterActions }, dispatch);

export default connect(
		mapStateToProps,
		mapDispatchToProps
	)(TransferSearchResult);	


